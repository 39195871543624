import { Space, Button } from 'antd';
import dayjs from "dayjs";

const currencyFormatOne = (amount) => {
  if (amount) {
    return Number(amount)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, '$&,')
  } else {
    return "-"
  }
}

export function dayFormatOne(value) {
  if (value) {
    // console.log("vv",value)
    return dayjs(value).format('DD-MM-YYYY');
    // return dayjs(value).format('YYYY-MM-DD');
  } else {
    return ""
  }
}


const columnsCl = (handleSetData) => [
  {
    title: "เลขบิล",
    dataIndex: "TMBILL",
    key: 'index',
    align: 'center',
    width: "10%",
    render: (text, record) => (
      <div>
        <div>{record.TMBILL}</div>
        <div>วันที่ใบรับ : {dayFormatOne(record.TMBILDT)}</div>
        <div>สาขาที่รับชำระ : {record.LOCATRECV}</div>
        <div>ล่าสุด : {dayFormatOne(record.arrayDate)}</div>
      </div>
    ),
  },
  {
    title: "สัญญา",
    dataIndex: "CONTNO",
    key: 'index',
    align: 'center',
    width: "5%",
    render: (text, record) => (
      <div>
        {/* <div>{record.TMBILL}</div> */}
        <div>{record.CONTNO}</div>
        <div>สาขา : {record.LOCATPAY}</div>
      </div>
    ),
  },
  {
    title: "รหัสชำระ",
    dataIndex: "PAYFOR",
    key: 'index',
    align: 'center',
    width: "7%",
    render: (text, record) => (
      <div>
        <div>ชำระค่า : {record.PAYFOR}</div>
        <div>ชำระด้วย : {record.PAYTYP}</div>
      </div>
    ),
  },
  {
    title: "ชำระค่างวด",
    dataIndex: "PAYAMT",
    key: 'index',
    align: 'center',
    width: "auto",
    render: (text, record) => (
      <Space size="middle">
        <>{currencyFormatOne(record.PAYAMT)}</>
      </Space>
    ),
  },
  {
    title: "ชำระดอกเบี้ย",
    dataIndex: "PAYAMT_V",
    key: 'index',
    align: 'center',
    width: "auto",
    render: (text, record) => (
      <Space size="middle">
        <>{currencyFormatOne(record.PAYAMT_V)}</>
      </Space>
    ),
  },
  {
    title: "ชำระต้น",
    dataIndex: "PAYAMT_N",
    key: 'index',
    align: 'center',
    width: "auto",
    render: (text, record) => (
      <Space size="middle">
        <>{currencyFormatOne(record.PAYAMT_N)}</>
      </Space>
    ),
  },
  {
    title: "ชำระค่าทวงถาม",
    dataIndex: "INTAMT",
    key: 'index',
    align: 'center',
    width: "7%",
    render: (text, record) => (
      <Space size="middle">
        <>{currencyFormatOne(record.INTAMT)}</>
      </Space>
    ),
  },
  {
    title: "ส่วนลด",
    dataIndex: "DISCT",
    key: 'index',
    align: 'center',
    width: "8%",
    render: (text, record) => (
      <Space size="middle">
        <>{currencyFormatOne(record.DISCT)}</>
      </Space>
    ),
  },
  {
    title: "ส่วนลดค่าทวงถาม",
    dataIndex: "DIFINTAMT",
    key: 'index',
    align: 'center',
    width: "8%",
    render: (text, record) => (
      <Space size="middle">
        <>{currencyFormatOne(record.DIFINTAMT)}</>
      </Space>
    ),
  },
  {
    title: "ชำระสุทธิ",
    dataIndex: "NETPAY",
    key: 'index',
    align: 'center',
    width: "auto",
    render: (text, record) => (
      <Space size="middle">
        <>{currencyFormatOne(record.NETPAY)}</>
      </Space>
    ),
  },
  {
    title: "วันชำระ",
    dataIndex: "PAYDT",
    key: 'index',
    align: 'center',
    width: "auto",
    render: (text, record) => (
      <Space size="middle">
        <>{dayFormatOne(record.PAYDT)}</>
      </Space>
    ),
  },
  {
    title: "สถานะ",
    dataIndex: "DUETONEFF",
    key: 'index',
    align: 'center',
    width: "auto",
    render: (text, record) => (
      <div>
        {
          record.FLAG === "H" ?
            <div><b style={{ color: 'red' }}>ปกติ</b></div>
            : record.FLAG === "C" ?
              <div><b style={{ color: 'red' }}>ยกเลิก</b></div>
              : null
        }
      </div>
    ),
  },
  {
    title: "วันยกเลิก",
    dataIndex: "CANDT",
    key: 'index',
    align: 'center',
    width: "auto",
    render: (text, record) => (
      <div>
        {
          record.CANDT && record.CANDT !== "0001-01-01T00:00:00Z" ?
            <div>{dayFormatOne(record.CANDT)}</div>
            : null
        }
      </div>
    ),
  },
  {
    title: "บันทึก",
    dataIndex: "INPDATE",
    key: 'index',
    align: 'center',
    width: "12%",
    render: (text, record) => (
      <div>
        <div>ผู้บันทึก : {record.USERID}</div>
        <div>วันที่ : {dayFormatOne(record.INPDATE)}</div>
        <div>เวลา : {dayjs(record.INPDATE).format("HH:mm:ss")}</div>
      </div>
    ),
  },
  // {
  //   title: "ผู้บันทึก",
  //   dataIndex: "USERID",
  //   key: 'index',
  //   align: 'center',
  //   width: "auto",
  //   render: (text, record) => (
  //     <Space size="middle">
  //       <>{record.USERID}</>
  //     </Space>
  //   ),
  // },
  {
    key: 'Action',
    title: "Action",
    align: 'center',
    width: "auto",
    render: (record) => (
      <Space size="middle">
        {
          record.flagCancel === true ?
            <Button onClick={() => handleSetData(record)}
            >ยกเลิกบิล</Button>
            : null
        }
      </Space>
    ),
  },
];


const columnsClAr = (handleSetData) => [
  {
    title: "เลขบิล",
    dataIndex: "TMBILL",
    key: 'index',
    align: 'center',
    width: "auto",
    render: (text, record) => (
      <div>
        <div>{record.TMBILL}</div>
        <div>วันที่ใบรับ : {dayFormatOne(record.INPDATE)}</div>
        <div>สาขาที่รับชำระ : {record.LOCATRECV}</div>
      </div>
    ),
  },
  {
    title: "สัญญา",
    dataIndex: "CONTNO",
    key: 'index',
    align: 'center',
    width: "auto",
    render: (text, record) => (
      <div>
        <div>{record.REFDOCNO}</div>
      </div>
    ),
  },
  {
    title: "รหัสชำระ",
    dataIndex: "PAYFOR",
    key: 'index',
    align: 'center',
    width: "auto",
    render: (text, record) => (
      <div>
        <div>ชำระด้วย : <b style={{ color: 'red' }}>{record.PAYTYP}</b></div>
      </div>
    ),
  },
  {
    title: "รายละเอียดชำระ",
    dataIndex: "PAYAMT",
    key: 'index',
    align: 'center',
    width: "auto",
    render: (text, record) => (
      <div>
        <div>ยอดชำระ : <b style={{ color: 'red' }}>{currencyFormatOne(record.CHQAMT)}</b></div>
        <div>วันชำระ : <b style={{ color: 'red' }}>{dayFormatOne(record.PAYDT)}</b></div>
      </div>
    ),
  },
  {
    title: "สถานะ",
    dataIndex: "DUETONEFF",
    key: 'index',
    align: 'center',
    width: "7%",
    render: (text, record) => (
      <div>
        {
          record.FLAG === "H" ?
            <div><b style={{ color: 'red' }}>ปกติ</b></div>
            : record.FLAG === "C" ?
              <div><b style={{ color: 'red' }}>ยกเลิก</b></div>
              : null
        }
      </div>
    ),
  },
  {
    title: "วันยกเลิก",
    dataIndex: "CANDT",
    key: 'index',
    align: 'center',
    width: "auto",
    render: (text, record) => (
      <div>
        {
          record.CANDT && record.CANDT !== "0001-01-01T00:00:00Z" ?
            <div>{dayFormatOne(record.CANDT)}</div>
            : null
        }
      </div>
    ),
  },
  {
    title: "บันทึก",
    dataIndex: "INPDATE",
    key: 'index',
    align: 'center',
    width: "12%",
    render: (text, record) => (
      <div>
        <div>ผู้บันทึก : <b style={{ color: 'red' }}>{record.USERID}</b></div>
        <div>วันที่ : {dayFormatOne(record.INPDT)}</div>
        <div>เวลา : {dayjs(record.INPDATE).format("HH:mm:ss")}</div>
      </div>
    ),
  },
  {
    key: 'Action',
    title: "Action",
    align: 'center',
    width: "auto",
    render: (record) => (
      <Space size="middle">
        {
          record.flagCancel === true ?
            <Button onClick={() => handleSetData(record)}
            >ยกเลิกบิล</Button>
            : null
        }
      </Space>
    ),
  },
];


export { columnsCl, columnsClAr };
