
const backc = {
    "ทั้งหมด": '#e6f4ff',
    "รอธุรการรับ": '#fffbe6',
    "ลูกค้าคอนเฟิร์ม": '#fffbe6',
    "รออนุมัติราคารถ": '#fff7e6',
    "รอวิเคราะห์": '#e6fffb',
    "ผ่านการวิเคราะห์": '#f0f5ff',
    "รออนุมัติ": '#fcffe6',
    "รอตรวจสอบเอกสาร": '#fcffe6',
    "อนุมัติ": '#f6ffed',
    "เสร็จสิ้น": '#f6ffed',
    "เสนอเคสรีแล้ว": '#f6ffed',
    "อนุมัติแบบมีเงื่อนไข": '#f6ffed',
    "โทรติด": '#fff0f6',
    "โทรไม่ติด(ติดต่อไม่ได้)": '#fff2e8',
    "ไม่อนุมัติ": '#fff1f0',
    "ไม่ผ่านการวิเคราะห์": '#fff1f0',
    "รออนุมัติราคาที่ดิน": '#fff1f0',
    "รอทำสัญญา": '#f6ffed',
    "ลูกค้าปฏิเสธ": '#fff1f0',
    "Reject": '#fff1f0',
    "ปัดตก": '#fff1f0',
    "รอพี่หนุ่มรับ": '#fffbe6',
    "รอเคาะราคา": '#e6fffb',
    "ขอเอกสารเพิ่ม": '#e6fffb',
    "เคาะราคาแล้ว": '#f6ffed',
    "ยื่นคำร้อง": '#fffbe6',
    "รับคำร้อง": '#e6fffb',
    "ปฏิเสธคำร้อง": '#fff1f0',
    "คำร้องไม่ถูกต้อง": '#fff1f0',
    "ลูกค้ายกเลิก": '#fff1f0',
    "ระงับเคส": '#fff1f0',
}

const textc = {
    "ทั้งหมด": '#1677ff',
    "รอธุรการรับ": '#e39f23',
    "ลูกค้าคอนเฟิร์ม": '#e39f23',
    "รออนุมัติราคารถ": '#d86d38',
    "รอวิเคราะห์": '#3fbccf',
    "ผ่านการวิเคราะห์": '#384ecb',
    "รออนุมัติ": '#afc222',
    "รอตรวจสอบเอกสาร": '#afc222',
    "อนุมัติ": '#47ad55',
    "เสร็จสิ้น": '#47ad55',
    "เสนอเคสรีแล้ว": '#47ad55',
    "อนุมัติแบบมีเงื่อนไข": '#47ad55',
    "โทรติด": '#c73799',
    "โทรไม่ติด(ติดต่อไม่ได้)": '#e36729',
    "ไม่อนุมัติ": '#ff4d4f',
    "ไม่ผ่านการวิเคราะห์": '#ff4d4f',
    "รออนุมัติราคาที่ดิน": '#ff4d4f',
    "รอทำสัญญา": '#47ad55',
    "ลูกค้าปฏิเสธ": '#ff4d4f',
    "Reject": '#ff4d4f',
    "ปัดตก": '#ff4d4f',
    "รอพี่หนุ่มรับ": '#e39f23',
    "รอเคาะราคา": '#3fbccf',
    "ขอเอกสารเพิ่ม": '#3fbccf',
    "เคาะราคาแล้ว": '#47ad55',
    "ยื่นคำร้อง": '#e39f23',
    "รับคำร้อง": '#3fbccf',
    "ปฏิเสธคำร้อง": '#ff4d4f',
    "คำร้องไม่ถูกต้อง": '#ff4d4f',
    "ลูกค้ายกเลิก": '#ff4d4f',
    "ระงับเคส": '#ff4d4f',
}


export function colorApprovalStatus(productLoanType, approvalStatus) {
    if (approvalStatus === 10) {
        return "green"
    }
    if (approvalStatus === "เสร็จสิ้น") {
        return "green"
    }
    if (approvalStatus === 11) {
        return "gold"
    }
    if (approvalStatus === 12) {
        return "gold"
    }
    if (approvalStatus === 14) {
        return "green"
    }
    if (approvalStatus === 13) {
        return "cyan"
    }
    if (approvalStatus === 16) {
        return "gold"
    }
    if (approvalStatus === 24) {
        return "gold"
    }
    if (approvalStatus === 17) {
        return "blue"
    }
    if (approvalStatus === 18) {
        return "cyan"
    }
    if (approvalStatus === 19) {
        return "geekblue"
    }
    if (approvalStatus === 2) {
        return "lime"
    }
    if (approvalStatus === 26) {
        return "lime"
    }
    if (approvalStatus === 3) {
        return "green"
    }
    if (approvalStatus === 21) {
        return "green"
    }
    if (approvalStatus === 27) {
        return "green"
    }
    if (approvalStatus === 22) {
        return "red"
    }
    if (approvalStatus === 6) {
        return "red"
    }
    if (approvalStatus === 15) {
        return "red"
    }
    if (approvalStatus === 23) {
        return "red"
    }
    if (approvalStatus === 25) {
        return "red"
    }
    if (approvalStatus === 20) {
        return "red"
    }
    if (approvalStatus === 5) {
        return "gold"
    }
    if (approvalStatus === 8) {
        return "red"
    }
    if (approvalStatus === 7) {
        return "cyan"
    }

    if (productLoanType === "ย้ายไฟแนนซ์") {
        return "green"
    }
    if (productLoanType === "ย้ายไฟแนนซ์") {
        return "green"
    }
    if (productLoanType === "ถือเล่มมา") {
        return "geekblue"
    }
    if (productLoanType === "ถือโฉนด" || productLoanType === "ถือโฉนด(ที่ดิน)") {
        return "geekblue"
    }
    if (productLoanType === "ซื้อ-ขาย") {
        return "geekblue"
    }
    if (productLoanType === "ปรับโครงสร้าง") {
        return "geekblue"
    }
    if (productLoanType === "Pre-Aaprove") {
        return "magenta"
    }
    if (productLoanType === "Pre-Aaprove-ถือโฉนด(ที่ดิน)") {
        return "magenta"
    }
    if (productLoanType === "Pre-Aaprove-ถือเล่มมา") {
        return "magenta"
    }
    if (productLoanType === "Pre-Aaprove-ซื้อ-ขาย") {
        return "magenta"
    }
    if (productLoanType === "Pre-Aaprove-ย้ายไฟแนนซ์") {
        return "magenta"
    }
    if (productLoanType === "Pre-Aaprove-รีโอน") {
        return "magenta"
    }
    if (productLoanType === "เปลี่ยนสัญญา") {
        return "magenta"
    }
    if (productLoanType === "Pre-Aaprove-เปลี่ยนสัญญา") {
        return "magenta"
    }
    if (productLoanType === "รี+เปลี่ยนสัญญา") {
        return "magenta"
    }
    if (productLoanType === "ปรับ+เปลี่ยนสัญญา") {
        return "magenta"
    }
    if (productLoanType === "Pre-Aaprove-รี-Ploan") {
        return "magenta"
    }
    if (productLoanType === "รี-3") {
        return "magenta"
    }
    if (productLoanType === "Pre-Aaprove-ปรับโครงสร้าง") {
        return "magenta"
    }
    if (productLoanType === "Pre-Aaprove-รี") {
        return "magenta"
    }
    if (productLoanType === "Pre-Aaprove-รี") {
        return "magenta"
    }
    if (productLoanType === "Pre-Aaprove-รี+เปลี่ยนสัญญา") {
        return "magenta"
    }
    if (productLoanType === "Pre-Aaprove-Ploan") {
        return "magenta"
    }
    if (productLoanType === "ที่ดิน+บ้าน") {
        return "geekblue"
    }
    if (productLoanType === "ฝากโฉนด") {
        return "geekblue"
    }
    if (productLoanType === "Pre-Aaprove-ฝากโฉนด") {
        return "geekblue"
    }
    if (productLoanType === "ติดนายทุน") {
        return "geekblue"
    }
    if (productLoanType === "Pre-Aaprove-Ploan") {
        return "geekblue"
    }

}

export function colorProductLoanType(productLoanType) {
    if (productLoanType === "ย้ายไฟแนนซ์") {
        return "green"
    }
    else if (productLoanType === "ย้ายไฟแนนซ์") {
        return "green"
    }
    else if (productLoanType === "ถือเล่มมา") {
        return "geekblue"
    }
    else if (productLoanType === "ถือโฉนด" || productLoanType === "ถือโฉนด(ที่ดิน)") {
        return "geekblue"
    }
    else if (productLoanType === "ซื้อ-ขาย") {
        return "geekblue"
    }
    else if (productLoanType === "ปรับโครงสร้าง") {
        return "geekblue"
    }
    else if (productLoanType === "Pre-Aaprove") {
        return "magenta"
    }
    else if (productLoanType === "Pre-Aaprove-ถือโฉนด(ที่ดิน)") {
        return "magenta"
    }
    else if (productLoanType === "Pre-Aaprove-ถือเล่มมา") {
        return "magenta"
    }
    else if (productLoanType === "Pre-Aaprove-ซื้อ-ขาย") {
        return "magenta"
    }
    else if (productLoanType === "Pre-Aaprove-ย้ายไฟแนนซ์") {
        return "magenta"
    }
    else if (productLoanType === "Pre-Aaprove-รีโอน") {
        return "magenta"
    }
    else if (productLoanType === "Pre-Approve-รี") {
        return "magenta"
    }
    else if (productLoanType === "รี") {
        return "magenta"
    }
    else if (productLoanType === "เปลี่ยนสัญญา") {
        return "magenta"
    }
    else if (productLoanType === "รี+เปลี่ยนสัญญา") {
        return "magenta"
    }
    else if (productLoanType === "ปรับ+เปลี่ยนสัญญา") {
        return "magenta"
    }
    else if (productLoanType === "Pre-Aaprove-รี-Ploan") {
        return "magenta"
    }
    else if (productLoanType === "รี-3") {
        return "magenta"
    }
    else if (productLoanType === "Pre-Aaprove-ปรับโครงสร้าง") {
        return "magenta"
    }
    else if (productLoanType === "Pre-Aaprove-รี") {
        return "magenta"
    }
    else if (productLoanType === "Pre-Aaprove-รี") {
        return "magenta"
    }
    else if (productLoanType === "Pre-Aaprove-เปลี่ยนสัญญา") {
        return "magenta"
    }
    else if (productLoanType === "Pre-Aaprove-รี+เปลี่ยนสัญญา") {
        return "magenta"
    }
    else if (productLoanType === "Pre-Aaprove-Ploan") {
        return "magenta"
    }
    else if (productLoanType === "ที่ดิน+บ้าน") {
        return "geekblue"
    }
    else if (productLoanType === "ฝากโฉนด") {
        return "geekblue"
    }
    else if (productLoanType === "Pre-Aaprove-ฝากโฉนด") {
        return "geekblue"
    }
    else if (productLoanType === "ติดนายทุน") {
        return "geekblue"
    }
    else if (productLoanType === "Pre-Aaprove-Ploan") {
        return "geekblue"
    }
    else {
        return null
    }
}


const backcAD = {
    "K0000001": '#e6f4ff',
    "K0000002": '#fffbe6',
    "K0000003": '#fffbe6',
    "K0000057": '#fffbe6',
    "K0000063": '#e6fffb',
    "K0000065": '#f0f5ff',
    "K0000074": '#fcffe6',
    "K0000075": '#fcffe6',
    "K0000076": '#f6ffed',
    "MIT00034": '#fcffe6',
    "MIT00026": '#f0f5ff',
    "MIT00075": '#fff1f0',
    "MIT00092": '#fff1f0',
    "MIT00109": '#fcffe6',
    "MIT00115": '#fcffe6',
    "MIT00117": '#fcffe6',
    "MIT00122": '#fcffe6',
}
const textcAD = {
    "K0000001": '#1677ff',
    "K0000002": '#1677ff',
    "K0000003": '#1677ff',
    "K0000057": '#1677ff',
    "K0000063": '#1677ff',
    "K0000065": '#1677ff',
    "K0000074": '#1677ff',
    "K0000075": '#1677ff',
    "K0000076": '#1677ff',
    "MIT00034": '#384ecb',
    "MIT00026": '#384ecb',
    "MIT00075": '#ff4d4f',
    "MIT00092": '#47ad55',
    "MIT00109": '#384ecb',
    "MIT00115": '#384ecb',
    "MIT00117": '#384ecb',
    "MIT00122": '#384ecb',
}

export function ApprovalStatusColor(approvalStatus) {
    // console.log("approvalStatus",approvalStatus)
    if (approvalStatus === 1 || approvalStatus === "") {
        return "gold"
    }
    if (approvalStatus === 2) {
        return "lime"
    }
    if (approvalStatus === 3) {
        return "green"
    }
    if (approvalStatus === 4) {
        return "green"
    }
    if (approvalStatus === 5) {
        return "gold"
    }
    if (approvalStatus === 6) {
        return "red"
    }
    if (approvalStatus === 7) {
        return "cyan"
    }
    if (approvalStatus === 8) {
        return "red"
    }
    if (approvalStatus === 9) {
        return "red"
    }
    if (approvalStatus === 10) {
        return "green"
    }
    if (approvalStatus === 11) {
        return "gold"
    }
    if (approvalStatus === 12) {
        return "gold"
    }
    if (approvalStatus === 13) {
        return "cyan"
    }
    if (approvalStatus === 14) {
        return "gold"
    }
    if (approvalStatus === 15) {
        return "red"
    }
    if (approvalStatus === 16) {
        return "gold"
    }
    if (approvalStatus === 17) {
        return "orange"
    }
    if (approvalStatus === 18) {
        return "cyan"
    }
    if (approvalStatus === 19) {
        return "geekblue"
    }
    if (approvalStatus === 20) {
        return "red"
    }
    if (approvalStatus === 21) {
        return "green"
    }
    if (approvalStatus === 22) {
        return "red"
    }
    if (approvalStatus === 23) {
        return "red"
    }
    if (approvalStatus === 24) {
        return "gold"
    }
    if (approvalStatus === 25) {
        return "red"
    }
    if (approvalStatus === 26) {
        return "lime"
    }
    if (approvalStatus === 27) {
        return "green"
    }
    if (approvalStatus === 28) {
        return "gold"
    }
}


export { backc, textc, backcAD, textcAD };