import React, { useState, useEffect } from 'react'
import { Button, Modal, Card, Typography, Table, Form, Input, Row, Col, Select } from 'antd';
import { irr } from 'node-irr'
import Currency from 'currency.js';
import '../css/Media.css';
import { nopay } from '../../file_mid/all_options';

import { FIFTEENPERCENT, TWENTYFOURPERCENT } from './InstallmentTable'

export const Installment = ({ open, close, money, cp, cp2, cp3, checkGARFE, type, checkPG }) => {

  const [odata, setOdata] = useState()
  const [form] = Form.useForm()
  const { Title } = Typography;
  const [checkAmountt, setCheckAmountt] = useState(cp) // ใช้เรทรถ
  const [loanAmountt, setLoanAmountt] = useState(cp)
  //const [installmentCount, setInstallmentCount] = useState(12)
  const [installmentCount, setInstallmentCount] = useState({})
  const [dataSource, setDataSource] = useState([])
  const [interestt, setInterestt] = useState(0.0)
  const [installmentWithInterestt, setInstallmentWithInterestt] = useState(0.0)
  const [arr, setArr] = useState()
  const [resultIrr, setResultIrr] = useState(0.0)
  const [filteredOptions, setFilteredOptions] = useState([]); //หางวดตามช่วงของเฮียอนุมัติ
  const [checkBTN, setcheckBTN] = useState(false)

  console.log("cp1", cp)
  console.log("cp2", cp2)
  // console.log("checkGARFE", checkGARFE)
  // console.log("type", type)
  // console.log("เงิน",amount)
  // console.log("ดอก",interest)
  // console.log("จ่ายต่อเดือน",arr)
  console.log("installmentCount", installmentCount)

  useEffect(() => {
    setOdata()
    handleCalculate()
  }, [installmentCount, loanAmountt])

  useEffect(() => {
    if (cp2 && cp3) {
      const filtered = nopay.filter(
        (option) =>
          option.value >= cp2 &&
          option.value <= cp3
      );
      // console.log("filtered", filtered)
      setFilteredOptions(filtered);
    }

  }, [cp2, cp3]);

  useEffect(() => {
    if (checkGARFE === 1) {
      // console.log("1")
      setLoanAmountt(cp)
      setInstallmentCount({ installmentAmountt: cp2 })
      setcheckBTN(true)

      form.setFieldsValue(
        {
          installmentCount: cp2,
          loanAmountt: cp
        }
      )
    } else if (checkGARFE === 2) {
      // console.log("2")
      setLoanAmountt(cp)
      setInstallmentCount({ installmentAmountt: cp2 })
      form.setFieldsValue(
        {
          installmentCount: cp2,
          loanAmountt: cp
        }
      )
    } else if (checkGARFE === 3) {
      // console.log("3")
      setLoanAmountt(cp)
      setInstallmentCount({ installmentAmountt: cp2 })

      form.setFieldsValue(
        {
          installmentCount: cp2,
          loanAmountt: cp
        }
      )
    } else {
      form.setFieldsValue(
        {
          loanAmountt: cp
        }
      )
    }
    if (checkGARFE === 4) {
      // cp  approvedLoanAmount
      // cp2  approvedLoanTerm
      // cp3  approvedLoanTermTo
      setcheckBTN(true)
      setLoanAmountt(cp)
      setInstallmentCount({ installmentAmountt: "" })

      form.setFieldsValue(
        {
          installmentCount: "",
          loanAmountt: cp
        }
      )
    } else {
      setLoanAmountt(cp)
      form.setFieldsValue(
        {
          // installmentCount: cp2,
          loanAmountt: cp
        }
      )
    }
  }, [])

  const handleCancel = () => {
    close(false)
  };

  const onFinish = () => {
    //console.log("IRR",resultIrr)
    if (checkAmountt > 0) {
      if (checkPG === 1) {
        console.log("checkPG")
        if (type?.productLoanType === 'ย้ายไฟแนนซ์' || type?.productLoanType === 'Pre-Aaprove-ย้ายไฟแนนซ์') {
          const dataM = { ...installmentCount, loanAmountt, interestt, installmentWithInterestt, ...odata, monthlyPayment: arr, interestRate: resultIrr }
          //console.log("result", dataM)
          money(dataM)
          close(false)
        }
        else if ((loanAmountt <= checkAmountt && type?.productLoanType !== 'ย้ายไฟแนนซ์') || (loanAmountt <= checkAmountt && type?.productLoanType === 'Pre-Aaprove-ย้ายไฟแนนซ์')) {
          const dataM = { ...installmentCount, loanAmountt, interestt, installmentWithInterestt, ...odata, monthlyPayment: arr, interestRate: resultIrr }
          //console.log("result", dataM)
          money(dataM)
          close(false)
        } else {
          const dataM = { ...installmentCount, loanAmountt, interestt, installmentWithInterestt, ...odata, monthlyPayment: arr, interestRate: resultIrr }
          //console.log("result", dataM)
          money(dataM)
          close(false)
        }
      } else {
        console.log("No PG")
        if (type?.productLoanType === 'ย้ายไฟแนนซ์' || type?.productLoanType === 'Pre-Aaprove-ย้ายไฟแนนซ์') {
          const dataM = { ...installmentCount, loanAmountt, interestt, installmentWithInterestt, ...odata, monthlyPayment: arr, interestRate: resultIrr }
          //console.log("result", dataM)
          money(dataM)
          close(false)
        }
        else if ((loanAmountt <= checkAmountt && type?.productLoanType !== 'ย้ายไฟแนนซ์') || (loanAmountt <= checkAmountt && type?.productLoanType === 'Pre-Aaprove-ย้ายไฟแนนซ์')) {
          const dataM = { ...installmentCount, loanAmountt, interestt, installmentWithInterestt, ...odata, monthlyPayment: arr, interestRate: resultIrr }
          //console.log("result", dataM)
          money(dataM)
          close(false)
        }
        else {
          alert("ไม่สามารถใส่วงเงินกู้เกินเรทรถได้")
        }
      }

    } else {
      const dataM = { ...installmentCount, loanAmountt, interestt, installmentWithInterestt, ...odata, monthlyPayment: arr, interestRate: resultIrr }
      //console.log("result", dataM)
      money(dataM)
      close(false)
    }
  };


  const installmentCountList = [
    12, 18, 24, 30, 36, 42, 48, 54, 60, 66, 72, 78, 84, 90, 96, 102, 108, 114, 120,
  ]

  // const handleChangeCount = (event) => {
  //   setInstallmentCount(event.target.value)
  // }

  const handleSelectChange = (e) => {
    console.log("ee", e)
    setInstallmentCount({ installmentAmountt: e })
  };

  const handleChangeAmount = (event) => {
    console.log("event", event.target.value)
    if (!event.target.value) {
      console.log("1")
      setLoanAmountt(0)
    }
    const pAmount = parseInt(event.target.value)
    setLoanAmountt(pAmount)
  }

  const handleCalculate = () => {
    setDataSource([])
    if (loanAmountt && installmentCount.installmentAmountt) {
      let loanAmount = 0.0
      let interest = 0.0

      TWENTYFOURPERCENT.map((value) => {
        if (value.installment === ~~installmentCount.installmentAmountt) {
          loanAmount = value.interest * 0.7
        }
      })

      FIFTEENPERCENT.map((value) => {
        if (value.installment === ~~installmentCount.installmentAmountt) {
          interest = value.interest * 0.3
        }
      })

      let installmentAmount = Math.round((interest + loanAmount) * loanAmountt)

      // IRR Calculate
      var array = []
      array.push(-loanAmountt)
      for (var i = 0; i < installmentCount.installmentAmountt; i++) {
        array.push(installmentAmount)
      }

      let IRR = irr(array) * 100

      //console.log("IRR",IRR)

      if (IRR) {
        var TotalPrincipleBalance = loanAmountt //ยอดทั้งหมด
        var totalInterest = 0.0 // ดอกเบี้ย
        //var result = ~~loanAmountt
        var result = loanAmountt
        for (var j = 1; j <= installmentCount.installmentAmountt; j++) {     // installmentCount จำนวนงวด
          let principle = TotalPrincipleBalance     // เงินต้น = ยอดทั้งหมด
          let no = j   // งวดที่ 
          let interestAmount = Math.round((TotalPrincipleBalance * IRR) / 100) // จำนวนดอกเบี้ย = 
          let principleAmount = installmentAmount - interestAmount    //  จำนวนงวด - จำนวนดอกเบี้ย
          let principleBalance = principle - principleAmount          //  ดอกเบี้ยคงเหลือ = 

          totalInterest += interestAmount // totalInterest = totalInterest + interestAmount

          let interestBalance = totalInterest // ดอกเบี้ยคงเหลือ = ยอดรวมดอกเบี้ย

          setDataSource((pre) => [
            ...pre,
            {
              installmentNo: no,                          // งวดที่ 
              installmentAmount: installmentAmount,       // จำนวนงวด
              interestAmount: interestAmount,             // จำนวนดอกเบี้ย
              principleAmount: principleAmount,           // เงินต้น
              principleBalance: principleBalance,         // เงินต้นคงเหลือ
              interestBalance: interestBalance,           // ดอกเบี้ยคงเหลือ
            },
          ])
          // TotalPrincipleBalance = TotalPrincipleBalance - principleAmount, ยอดทั้งหมด
          TotalPrincipleBalance -= principleAmount
        }
        // result += totalInterest
        result = installmentAmount * installmentCount.installmentAmountt
        var interestNEW = result - loanAmountt
        setResultIrr(Currency(IRR, { precision: 5 }).value)
        setArr(installmentAmount)
        setInterestt(interestNEW)
        // setInterestt(totalInterest)
        setInstallmentWithInterestt(result)
      }
    }
  }

  const currencyFormat = (loanAmountt) => {
    return Number(loanAmountt)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, '$&,')
  }

  const column = [
    {
      key: '1',
      title: 'งวดที่',
      dataIndex: 'installmentNo',
    },
    {
      key: '2',
      title: 'ค่างวด',
      dataIndex: 'installmentAmount',
      render: (record) => {
        return currencyFormat(record)
      },
    },
    {
      key: '3',
      title: 'ดอกเบี้ย',
      dataIndex: 'interestAmount',
      render: (record) => {
        return currencyFormat(record)
      },
    },
    {
      key: '4',
      title: 'เงินต้น',
      dataIndex: 'principleAmount',
      render: (record) => {
        return currencyFormat(record)
      },
    },
    {
      key: '5',
      title: 'เงินต้นคงเหลือ',
      dataIndex: 'principleBalance',
      render: (record) => {
        return currencyFormat(record)
      },
    },
    {
      key: '6',
      title: 'ดอกเบี้ยคงเหลือ',
      render: (record) => {
        // let sum = 0.0
        // dataSource.forEach((element) => {
        //   sum += element.interestAmount
        // })

        return currencyFormat(interestt - record.interestBalance)
      },
    },
  ]

  return (
    <Card>
      <Modal
        open={open}
        width={'1000px'}
        closable={false}
        footer={[
          <>
            {checkGARFE === 4 ?
              <>
                <Button
                  form="myForm"
                  key="submit"
                  htmlType="submit"
                // disabled={checkBTN}
                >
                  ยืนยัน
                </Button>
              </>
              :
              <>
                <Button
                  form="myForm"
                  key="submit"
                  htmlType="submit"
                  disabled={checkBTN}>
                  ยืนยัน
                </Button>,
              </>
            }

            <Button onClick={handleCancel} key="close">
              ยกเลิก
            </Button>
          </>
        ]}
      >
        <Row justify={'center'}>
          <Form
            id="myForm"
            name="basic"
            labelCol={{
              span: 10,
            }}
            wrapperCol={{
              span: 24,
            }}
            style={{
              width: '100%',
            }}
            initialValues={{
              remember: true,
            }}
            form={form}
            onFinish={onFinish}
            autoComplete="off"
          >
            <Row gutter={32} justify={'center'}>
              <Col className='gutter-row'>
                <b><h3>หาค่างวด</h3></b>
              </Col>
            </Row>
            <Row className='main2' justify={'center'}>
              <aside>
                <Col style={{ margin: 10 }}>
                  <Form.Item label='วงเงินกู้' name="loanAmountt"
                    rules={[
                      {
                        required: true,
                        message: 'Please input !',
                      },]}>
                    <Input
                      name="loanAmountt"
                      style={{ width: '200px', color: 'black' }}
                      disabled={checkBTN}
                      placeholder={'กรอกยอด'}
                      // defaultValue={loanAmountt}
                      onChange={handleChangeAmount}
                      onKeyDown={(e) => (e.key === 'Enter' ? handleCalculate() : null)}
                    />
                  </Form.Item>
                </Col>
                <Col style={{ margin: 10 }}>
                  <Form.Item
                    label='จำนวนงวด'
                    name='installmentCount'
                    style={{ color: 'black' }}
                    rules={[
                      {
                        required: true,
                        message: 'Please input !',
                      },]}
                  >
                    {cp3 ?
                      <>
                        <Select
                          placeholder={'เลือกจำนวนงวด' + cp2 + ' - ' + cp3}
                          style={{ width: '200px', color: 'black' }}
                          onChange={(value) => handleSelectChange(value)}
                          options={filteredOptions}
                        />
                      </>
                      :
                      <>
                        <Select
                          style={{ width: '200px', color: 'black' }}
                          placeholder={'เลือก'}
                          disabled={checkBTN}
                          //onChange={handleChangeCount}
                          onChange={(value) => setInstallmentCount({ ...installmentCount, installmentAmountt: value })}
                          label="จำนวนงวด"
                          options={[
                            { label: '12', value: 12 }, { label: 18, value: 18 }, { label: '24', value: 24 },
                            { label: '30', value: 30 }, { label: 36, value: 36 }, { label: '42', value: 42 },
                            { label: '48', value: 48 }, { label: 54, value: 54 }, { label: '60', value: 60 },
                            { label: '66', value: 66 }, { label: 72, value: 72 }, { label: '78', value: 78 },
                            { label: '84', value: 84 }, { label: 90, value: 90 }, { label: '96', value: 96 },
                            { label: '102', value: 102 }, { label: 108, value: 108 }, { label: '114', value: 114 },
                            { label: '120', value: 120 }
                          ]}
                        >
                        </Select>
                      </>
                    }
                  </Form.Item>
                </Col>
              </aside>
            </Row>
            <Row gutter={32} style={{ marginBottom: 0 }}>
              <Col className='gutter-row' span={12} style={{ margin: 0 }}>
                <Typography
                  variant="subtitle1"
                  component="h5"
                >
                  {/* <b> ยอดกู้ : {currencyFormat(loanAmountt)}</b> */}
                  <b> ยอดกู้ : {isNaN(loanAmountt) ? "-" : currencyFormat(loanAmountt)} </b>
                </Typography>
              </Col>
              <Col className='gutter-row' span={12}>
                <Typography
                  variant="subtitle1"
                  component="h5"
                >
                  <b> ดอกเบี้ย : {currencyFormat(interestt)}</b>
                </Typography>
              </Col>
            </Row>
            <Row gutter={32}>
              <Col className='gutter-row' span={24}>
                <Table
                  style={{ textAlign: 'center' }}
                  dataSource={dataSource}
                  columns={column}
                  scroll={{
                    x: 700,
                  }}

                />
              </Col>
            </Row>
          </Form>
        </Row>
      </Modal>
    </Card>
  )
}
