import dayjs from "dayjs";


export function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}


export function currencyFormat(amount) {
  if (amount) {
    return Number(amount)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  } else {
    return "-";
  }
}


export function currencyFormatOne(amount) {
  if (amount) {
    return Number(amount)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, '$&,')
  } else {
    return "-"
  }
}

export function dayFormatOne(value) {
  if (value) {
    // console.log("vv",value)
    return dayjs(value).format('DD-MM-YYYY');
    // return dayjs(value).format('YYYY-MM-DD');
  } else {
    return ""
  }
}

export function msOK(api, placement) {
  api.success({
    message: "สำเร็จ",
    description:
      'ทำรายการสำเร็จ',
    placement,
  });
};
export function msOKMemo1(api, placement) {
  api.success({
    message: "สำเร็จ",
    description:
      'บันทึกโน๊ตสำเร็จ',
    placement,
  });
};
export function msCancel(api, placement) {
  api.success({
    message: "ยกเลิก",
    description:
      'ยกเลิกทำรายการสำเร็จ',
    placement,
  });
};
export function msPAYCODE(api, placement) {
  api.error({
    message: "คำเตือน !!!",
    description:
      'กรุณาเลือกประเภทการจ่ายก่อน',
    placement,
  });
};
export function msSale(api, placement) {
  api.error({
    message: "คำเตือน !!!",
    description:
      'ไม่สามารถใส่ส่วนลดเกินจำนวนดอกเบี้ยได้',
    placement,
  });
};
export function msSaleFollow(api, placement) {
  api.error({
    message: "คำเตือน !!!",
    description:
      'ไม่สามารถใส่ส่วนลดเกินจำนวนค่าทวงถามได้',
    placement,
  });
};
export function msError(api, placement) {
  api.error({
    message: "error",
    description:
      'บันทึกไม่สำเร็จ กรุณาติดต่อไอที',
    placement,
  });
};
export function msList(api, placement) {
  api.warning({
    message: "คำเตือน !!!",
    description:
      'กรุณาเลือกรายการก่อน',
    placement,
  });
};
export function msMoneyList(api, placement) {
  api.warning({
    message: "คำเตือน !!!",
    description:
      'จำนวนเงินกรอกต้องไม่น้อยกว่า ยอดที่เลือกในรายการ',
    placement,
  });
};
export function msErrorInst(api, placement) {
  api.error({
    message: "error",
    description:
      'บันทึกไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
    placement,
  });
};
export function msErrorInsert(api, placement) {
  api.error({
    message: "error",
    description:
      'ไม่สามารถ Insert ข้อมูลซ้ำได้ กรุณาอัพเดตข้อมูลโดยการกดค้นหาและคำนวณยอดใหม่',
    placement,
  });
};
export function msErrorInsert2(api, placement) {
  api.error({
    message: "error",
    description:
      'ไม่สามารถ Insert ข้อมูลซ้ำได้ กรุณาอัพเดตข้อมูลโดยการกดค้นหาใหม่',
    placement,
  });
};
export function msDue(api, placement) {
  api.error({
    message: "error",
    description:
      'ไม่พบข้อมูลตารางดิว',
    placement,
  });
};
export function msUnCancel(api, placement) {
  api.error({
    message: "error",
    description:
      'บิลนี้ ไม่สามารถยกเลิกได้ กรุณาติดต่อไอที !!!',
    placement,
  });
};
export function msConnt(api, placement) {
  api.error({
    message: "error",
    description:
      'ไม่พบข้อมูลสัญญานี้ กรุณาตรวจสอบเลขที่สัญญา ว่าถูกต้องหรือไม่ ?',
    placement,
  });
};
export function msPrint(api, placement) {
  api.error({
    message: "error",
    description:
      'ไม่พบข้อมูลสัญญาหรือเลขบิลนี้ กรุณาติดต่อไอที !!!',
    placement,
  });
};
export function msNil(api, placement) {
  api.error({
    message: "error",
    description:
      'กรุณากรอกข้อมูลให้ถูกต้อง *เลขที่สัญญา *ยอดที่จ่ายต้องมากกว่าหรือเท่ากับค่างวด *ยอดที่จ่ายต้องไม่เป็น 0 หรือมีค่าติดลบ ',
    placement,
  });
};
export function msCal(api, placement) {
  api.warning({
    message: "คำเตือน",
    description:
      'เมื่อมีการเปลี่ยนเลขสัญญา บัตรปชช.หรือเลขทะเบียน กรุณากดค้นหาและคำนวณก่อน !!!',
    placement,
  });
};
export function msCal2(api, placement) {
  api.warning({
    message: "คำเตือน",
    description:
      'เมื่อมีการเปลี่ยนเลขสัญญา บัตรปชช.หรือเลขทะเบียน กรุณากดค้นหาเพื่ออัพเดตข้อมูลก่อน !!!',
    placement,
  });
};
export function msPayGRADE(api, placement) {
  api.warning({
    message: "คำเตือน",
    description:
      'ยอดเงินที่กรอก ต้องไม่น้อยกว่า รับชำระขั้นต่ำ !!!',
    placement,
  });
};
export function msClose(api, placement) {
  api.error({
    message: "error",
    description:
      'ไม่สามารถปิดบช.ได้ กรุณาติดต่อไอที',
    placement,
  });
};
export function msLimit(api, placement) {
  api.warning({
    message: "คำเตือน",
    description:
      'จำนวนเงินที่จ่ายเข้ามา มากกว่าเงินต้นคงเหลือ',
    placement,
  });
};
export function msCreateDue(api, placement) {
  api.warning({
    message: "คำเตือนควรสร้างดิว",
    description:
      'จำนวนเงินที่จ่ายเข้ามา น้อยกว่าดอกเบี้ย',
    placement,
  });
};
export function msDueNil(api, placement) {
  api.warning({
    message: "คำเตือน",
    description:
      'ไม่พบดิวล่าสุด',
    placement,
  });
};

export function msData(api, placement) {
  api.warning({
    message: "คำเตือน",
    description:
      'ไม่พบข้อมูล',
    placement,
  });
};

export function msDataContnoZum(api, placement) {
  api.warning({
    message: "คำเตือน",
    description:
      'คำเตือน ข้อมูลที่ค้นหามีรายการซ้ำ กรุณาจัดการกับข้อมูลซ้ำก่อน !!!',
    placement,
  });
};

export function msDataPro(api, placement) {
  api.warning({
    message: "คำเตือน",
    description:
      'คำเตือน ระบบกำลังดำเนินการอยู่ !!!',
    placement,
  });
};

export function msContnoData(api, placement) {
  api.warning({
    message: "คำเตือน",
    description:
      'สัญญาในวันที่นี้ถูกนำเข้าแล้ว',
    placement,
  });
};

export function msPays(api, placement) {
  api.warning({
    message: "คำเตือน",
    description:
      'กรุณาเลือกสัญญาที่จะตัดเข้าก่อน',
    placement,
  });
};

export function msReceipt(api, placement) {
  api.warning({
    message: "คำเตือน",
    description:
      'กรุณากรอกข้อมูลให้ถูกต้อง !',
    placement,
  });
};