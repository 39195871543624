import React, { useEffect, useState, useRef } from "react";
import { Modal, Card, Spin, Input, Button, message, Form, Row, Select, Col, Divider, Space, notification, Image, FloatButton, } from "antd";
import { ExclamationCircleFilled, DownloadOutlined, CloseOutlined, } from "@ant-design/icons";
import axios from "axios";
import dayjs from "dayjs";
import Currency from "currency.js";
import html2canvas from "html2canvas";
import moment from "moment";
import { createyellowcard, loadDetailAunSendPG, showcausename } from "../../../file_mid/all_api";
import { HirePurchase } from "../../../offer_case/hire_purchase/HirePurchase";
import { Installment } from "../../../offer_case/ploan/Installment";
import { nopay } from "../../../file_mid/all_options";
const MemoOne = ({ open, close, keys, data, closeModal, shootdata, dataApproved, sendForshow, continoRe, carsApproveve, status, handleResetPage }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    // const token = localStorage.getItem("token");
    const user = localStorage.getItem("username");
    const elementRef2 = useRef(null);
    const { confirm } = Modal;
    // const guarantor = "car";
    const appStatus = "อนุมัติ";
    // const [numForCheck, setNumForCheck] = useState({});
    // const [addNote, setAddNote] = useState({ name: "" });
    // const [options, setOptions] = useState([]);
    // const [api, contextHolder] = notification.useNotification();
    const [statusAccept, setstatusAccept] = useState({});

    //ส่งค่าไปในตาราง p-loan
    // const [installmentAmountt, setinstallmentAmountt] = useState({})
    const [checkType, setCheckType] = useState();
    const [typeCar, setTypeCar] = useState();
    const [checkDis, setCheckDis] = useState(true);
    const [checkCar23, setCheckCar23] = useState(false);
    const [openTable, setOpenTable] = useState(false); //ตารางค่างวด p-loan
    const [openTableHP, setOpenTableHP] = useState(false); //ตารางค่างวด เช่าซื้อ
    // const [isModalOpen, setIsModalOpen] = useState(false);
    const [money] = useState({}); //loan
    const branch = localStorage.getItem("branch");
    const [preData, setpreData] = useState(); //car
    const [checkApprovalConditions] = useState(0);
    // const [carsApproveve, setCarsApproveve] = useState();
    const [dataDB2, setDataDB2] = useState();
    const [notesCarsApprove, setNotesCarsApprove] = useState();
    const [filteredOptions, setFilteredOptions] = useState([]); //หางวดตามช่วงของเฮียอนุมัติ
    // const [dataTonew, setDataToNew] = useState({}); //หางวดตามช่วงของเฮียอนุมัติ
    const [openMemo, setOpenMemo] = useState(false);
    const [checkkeys, setCheckKeys] = useState(keys);
    const [checkSendForshow, setCheckSendForshow] = useState(sendForshow);
    const [outstandingdebt, setOutstandingdebt] = useState(); // outstandingdebt มูลหนี้คงเหลือ
    const [deductdiscount, setDeductdiscount] = useState(); // deductdiscount หักส่วนลด
    const [remaining, setRemaining] = useState(); // remaining  คงเหลือ
    const [pay, setPay] = useState(); // pay   จ่ายเงิน
    const [Excellent, setExcellent] = useState(); // Excellent   ยอดจัด
    const [checkTypeCar, setCheckTypeCar] = useState(true);

    // console.log("checkkeys", checkkeys)
    // console.log("carsApproveve", carsApproveve)
    // console.log("data", data)
    // console.log("มูลหนี้คงเหลือ setOutstandingdebt", outstandingdebt)
    // console.log("หักส่วนลด setDeductdiscount", deductdiscount)
    // console.log("คงเหลือ setRemaining ", remaining)
    // console.log("จ่ายเงิน setPay", pay)
    // console.log("ยอดจัด setExcellent", Excellent)

    useEffect(() => {
        setOpenMemo(open);
        loadDataReAun();
        // console.log("openMemo status",openMemo, status);
        if (openMemo === true) {
            loadDataReAun();
            // loadCauseName();
            if (data?.DATA_TYPE === "sfhp" || data?.DATA_TYPE === "vsfhp") {
                console.log("เช่าซื้อ");
                if (data?.products === "car") {
                    setpreData({
                        ...dataApproved,
                        // apProductType: data?.products,
                        // apProductLoanType: data?.productLoanType ? data?.productLoanType : null,
                        apLoanAmount: dataApproved?.approvedLoanAmount ? dataApproved?.approvedLoanAmount : null,
                        // apDownPayment: data?.downPayment ? data?.downPayment : null,
                        // apInterest: data?.interest ? data?.interest : null,
                        // apInterestRate: data?.interestRate ? data?.interestRate : null,
                        pgLoanTerm: dataApproved?.approvedLoanTerm,
                        pgLoanTermTo: dataApproved?.approvedLoanTermTo,
                        // apMonthlyPayment: data?.monthlyPayment ? data?.monthlyPayment : null,
                        // apInstallmentWithInterest: ~~data?.monthlyPayment * ~~data?.loanTerm,
                    });
                } else if (data?.products === "land") {
                }
            } else {
                console.log("p-loan");
            }
        }
        if (status === "close") {
            setTypeCar(dataApproved?.productTypeCar);
            setpreData({
                ...dataApproved?.carsApproveCeo,
                apLoanAmount: dataApproved?.carsApproveCeo?.approvedLoanAmount,
                pgLoanTerm: dataApproved?.carsApproveCeo?.approvedLoanTerm,
                pgLoanTermTo: dataApproved?.carsApproveCeo?.approvedLoanTermTo,
            })
            form.setFieldsValue({
                ProductType: dataApproved?.productTypeCar,
                apLoanAmount: dataApproved?.carsApproveCeo?.approvedLoanAmount,
                pgLoanTerm: dataApproved?.carsApproveCeo?.approvedLoanTerm,
                pgLoanTermTo: dataApproved?.carsApproveCeo?.approvedLoanTermTo,
            })
        }
    }, [openMemo, carsApproveve?.pgLoanTerm, checkkeys, sendForshow]);

    useEffect(() => {
        if (status === "close") {
            if (dataApproved?.carsApproveCeo?.approvedLoanTerm && dataApproved?.carsApproveCeo?.approvedLoanTermTo) {
                const filtered = nopay.filter(
                    (option) =>
                        option.value >= dataApproved.carsApproveCeo?.approvedLoanTerm &&
                        option.value <= dataApproved.carsApproveCeo?.approvedLoanTermTo
                );
                // console.log("filtered", filtered);
                setFilteredOptions(filtered);
            }
        } else {
            if (dataApproved?.approvedLoanTerm && dataApproved?.approvedLoanTermTo) {
                const filtered = nopay.filter(
                    (option) =>
                        option.value >= dataApproved.approvedLoanTerm &&
                        option.value <= dataApproved.approvedLoanTermTo
                );
                // console.log("filtered", filtered);
                setFilteredOptions(filtered);
            }
        }

    }, [dataApproved]);

    useEffect(() => {
        if (carsApproveve?.id > 0) {
            setOutstandingdebt(
                carsApproveve?.neekong ? (~~carsApproveve?.neekong + ~~carsApproveve?.OTHR + (data.DATA_TYPE === "rpsl" ? ~~carsApproveve?.followPay : ~~carsApproveve?.beeypup)) : null
            );
            setDeductdiscount(
                carsApproveve?.neekong
                    ? (~~carsApproveve?.neekong + ~~carsApproveve?.OTHR + ~~carsApproveve?.beeypup) - (~~carsApproveve?.approvedLoanAmount + ~~carsApproveve?.newDamt + ~~carsApproveve?.followPay)
                    : null
            );
            setRemaining(
                carsApproveve?.neekong ? ~~outstandingdebt - ~~deductdiscount : null
            );
            setPay(
                carsApproveve?.newDamt
                    ? ~~carsApproveve?.newDamt + ~~carsApproveve?.followPay
                    : null
            );
            setExcellent(
                carsApproveve?.approvedLoanAmount
                    ? ~~carsApproveve?.approvedLoanAmount
                    : null
            );
            {
                /* 
                        มูลหนี้ balance
                        หักส่วนลด (balance-(approvedLoanAmount+(newDamt+followPay))
                        คงเหลือ  มูลหนี้ - หักส่วนลด
                        จ่ายเงิน (newDamt+followPay)
                        ยอดจัด approvedLoanAmount
                    */
            }
        }
    }, [deductdiscount, carsApproveve]);


    useEffect(() => {
        if (preData?.pgLoanTerm && preData?.apInterestRate) {
            if (status === "close") {
                if (dataApproved?.carId !== 0) {
                    if (checkType === "เช่าซื้อ1") {
                        console.log("เช่าซื้อ1")
                        car_Calculate();
                    } else if (typeCar !== 1 && checkType === "เช่าซื้อ") {
                        console.log("เช่าซื้อ รถใหญ่")
                        car_Calculate();
                    }
                } else {
                    // if (appStatus === "อนุมัติ" || appStatus === "อนุมัติแบบมีเงื่อนไข") {
                    land_Calculate();
                    // }
                    // console.log("land")
                }
            } else {
                if (data?.products === "car") {
                    if (checkType === "เช่าซื้อ1") {
                        console.log("เช่าซื้อ1")
                        car_Calculate();
                    } else if (typeCar !== 1 && checkType === "เช่าซื้อ") {
                        console.log("เช่าซื้อ รถใหญ่")
                        car_Calculate();
                    }
                } else if (data?.products === "land") {
                    // if (appStatus === "อนุมัติ" || appStatus === "อนุมัติแบบมีเงื่อนไข") {
                    land_Calculate();
                    // }
                    // console.log("land")
                }
            }

        }
    }, [
        preData?.apLoanAmount,
        preData?.pgLoanTerm,
        preData?.apInterestRate,
        checkType,
    ]);

    const sendLoadTopage = () => {
        const num = 1
        sendForshow(num)
    }

    // const loadCauseName = async () => {
    //     await axios
    //         .get(showcausename)
    //         .then((res) => {
    //             if (res.status === 200) {
    //                 setOptions(res.data);
    //             }
    //         })
    //         .catch((err) => console.log(err));
    // };

    const currencyFormatOne = (amount) => {
        if (amount) {
            return Number(amount)
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, "$&,");
        }
    };

    const loadDataReAun = async () => {
        // setLoading(true)
        axios
            .post(loadDetailAunSendPG, { contno: data?.CONTNO })
            .then((res) => {
                if (res.status === 200) {
                    // console.log("res.dataNewPageAun", res.data);
                    // setCarsApproveve(res?.data?.carsApproveve);
                    setDataDB2(res.data?.dataDB2);
                    setNotesCarsApprove(res?.data?.notesCarsApprove);
                    // setLoading(false)
                }
            })
            .catch((err) => {
                console.log("err", err);
                // setLoading(false)
            });
    };

    const handleMoney = (values) => {
        //console.log("values",values)
        setpreData({
            ...preData,
            apInterestRate: values?.interestRate,
            apLoanAmount: values?.loanAmountt,
            pgLoanTerm: values?.installmentAmountt,
            pgLoanTermTo: dataApproved?.approvedLoanTermTo ? dataApproved?.approvedLoanTermTo : null,
            apInterest: values?.interestt,
            apMonthlyPayment: values?.monthlyPayment,
            apInstallmentWithInterest: values?.installmentWithInterestt,
        });
        form.setFieldsValue({
            apLoanAmount: values?.loanAmountt,
            apInterestRate: values?.interestRate,
            pgLoanTerm: values?.installmentAmountt,
            apInterest: currencyFormatOne(values?.interestt),
            apMonthlyPayment: currencyFormatOne(values?.monthlyPayment),
            apInstallmentWithInterest: currencyFormatOne(
                values?.installmentWithInterestt
            ),
        });
    };

    const handleMoneyHP = (values) => {
        // console.log("valueeeee", values);
        const secondDecimalDigit = Math.floor(values?.interestRate * 100) % 10; // หาตำแหน่งทศนิยมที่ 2
        let roundedRate;

        if (secondDecimalDigit > 5) {
            roundedRate = Math.ceil(values?.interestRate * 100) / 100;
        } else {
            roundedRate = Math.floor(values?.interestRate * 100) / 100;
        }

        // ((parseFloat(preData.apLoanAmount) * Currency(interestRateCar, { precision: 5 }).value) * preData.pgLoanTerm)
        setpreData({
            ...preData,
            apLoanAmount: values?.loanAmountt,
            apInterestRate: roundedRate ? roundedRate : values?.interestRate,
            pgLoanTerm: values?.installmentAmountt,
            pgLoanTermTo: dataApproved?.approvedLoanTermTo ? carsApproveve?.approvedLoanTermTo : null,
            apInterest: values?.interestt,
            apMonthlyPayment: values?.monthlyPayment,
            apInstallmentWithInterest: values?.installmentWithInterestt,
        });
        form.setFieldsValue({
            apLoanAmount: currencyFormatOne(values?.loanAmountt),
            apInterestRate: roundedRate,
            pgLoanTerm: values?.installmentAmountt,
            apInterest: currencyFormatOne(values?.interestt),
            apMonthlyPayment: currencyFormatOne(values?.monthlyPayment),
            apInstallmentWithInterest: currencyFormatOne(
                values?.installmentWithInterestt
            ),
        });
    };

    const changeLoanTerm = (value) => {
        setpreData({ ...preData, pgLoanTerm: value });
    };

    const changeInterestRate = (value) => {
        setpreData({ ...preData, apInterestRate: value });
    };

    // const handleClose = () => {
    //     console.log("close")
    //     close(false);
    // };

    const handleChangePLorCH = (value) => {
        // console.log("value เช่าซื้อ1 หรือ เช่าซื้อ", value);
        // console.log("typeCar", typeCar);
        setCheckType(value);
        if (status === "close") {
            form.setFieldsValue({
                // ...preData,
                apLoanAmount: dataApproved?.carsApproveCeo?.approvedLoanAmount,
                pgLoanTerm: "",
                apInterestRate: "",
                apInterest: "",
                apLoanAmount: "",
                apMonthlyPayment: "",
                apInstallmentWithInterest: "",
                apProductType: "",
                // apProductType1: "",
            });
            setpreData({
                // ...preData,
                apLoanAmount: dataApproved?.carsApproveCeo?.approvedLoanAmount,
                pgLoanTerm: "",
                apInterestRate: "",
                apInterest: "",
                apLoanAmount: "",
                apMonthlyPayment: "",
                apInstallmentWithInterest: "",
                apProductType: "",
            });
            if (typeCar === 1) {
                setCheckCar23(false);
                if (value === "เช่าซื้อ") {
                    setCheckDis(true);
                    setpreData({
                        apLoanAmount: dataApproved?.carsApproveCeo?.approvedLoanAmount,
                        apProductType: "เช่าซื้อ",
                        pgLoanTermTo: dataApproved?.carsApproveCeo?.approvedLoanTermTo ? dataApproved?.carsApproveCeo?.approvedLoanTermTo : null,
                    });
                    form.setFieldsValue({
                        apLoanAmount: currencyFormatOne(dataApproved?.carsApproveCeo?.approvedLoanAmount),
                    })
                } else if (value === "เช่าซื้อ1") {
                    setCheckDis(false);
                    form.setFieldsValue({
                        apLoanAmount: dataApproved?.carsApproveCeo?.approvedLoanAmount,
                        pgLoanTerm: "",
                        apInterestRate: "",
                        apInterest: "",
                        apMonthlyPayment: "",
                        apInstallmentWithInterest: "",
                        apProductType: "",
                    });
                    setpreData({
                        apProductType: "เช่าซื้อ1",
                        apLoanAmount: dataApproved?.carsApproveCeo?.approvedLoanAmount,
                        pgLoanTermTo: dataApproved?.carsApproveCeo?.approvedLoanTermTo ? dataApproved?.carsApproveCeo?.approvedLoanTermTo : null,
                    });
                }
            } else if (typeCar === 2 || typeCar === 3) {
                //รถบรรทุก
                //รถเครื่องจักรการเกษตร 
                if (value === "p-loan") {
                    setCheckDis(true);
                    setCheckCar23(false);
                } else if (value === "เช่าซื้อ") {
                    setCheckDis(false);
                    setCheckCar23(true);
                    form.setFieldsValue({
                        apLoanAmount: dataApproved?.carsApproveCeo?.approvedLoanAmount,
                        pgLoanTerm: "",
                        apInterestRate: "",
                        apInterest: "",
                        apMonthlyPayment: "",
                        apInstallmentWithInterest: "",
                        apProductType: "",
                    });
                    setpreData({
                        apProductType: "เช่าซื้อ",
                        apLoanAmount: dataApproved?.carsApproveCeo?.approvedLoanAmount,
                        pgLoanTermTo: dataApproved?.carsApproveCeo?.approvedLoanTermTo ? dataApproved?.carsApproveCeo?.approvedLoanTermTo : null,
                    });
                }
            };
        } else {
            form.setFieldsValue({
                // ...preData,
                apLoanAmount: dataApproved?.approvedLoanAmount,
                pgLoanTerm: "",
                apInterestRate: "",
                apInterest: "",
                apLoanAmount: "",
                apMonthlyPayment: "",
                apInstallmentWithInterest: "",
                apProductType: "",
                // apProductType1: "",
            });
            setpreData({
                // ...preData,
                apLoanAmount: dataApproved?.approvedLoanAmount,
                pgLoanTerm: "",
                apInterestRate: "",
                apInterest: "",
                apLoanAmount: "",
                apMonthlyPayment: "",
                apInstallmentWithInterest: "",
                apProductType: "",
            });
            if (typeCar === 1) {
                setCheckCar23(false);
                if (value === "เช่าซื้อ") {
                    setCheckDis(true);
                    setpreData({
                        apLoanAmount: dataApproved?.approvedLoanAmount,
                        apProductType: "เช่าซื้อ",
                        pgLoanTermTo: dataApproved?.approvedLoanTermTo ? dataApproved?.approvedLoanTermTo : null,
                    });
                    form.setFieldsValue({
                        apLoanAmount: currencyFormatOne(dataApproved?.approvedLoanAmount),
                    })
                } else if (value === "เช่าซื้อ1") {
                    setCheckDis(false);
                    form.setFieldsValue({
                        apLoanAmount: dataApproved?.approvedLoanAmount,
                        pgLoanTerm: "",
                        apInterestRate: "",
                        apInterest: "",
                        apMonthlyPayment: "",
                        apInstallmentWithInterest: "",
                        apProductType: "",
                    });
                    setpreData({
                        apProductType: "เช่าซื้อ1",
                        apLoanAmount: dataApproved?.approvedLoanAmount,
                        pgLoanTermTo: dataApproved?.approvedLoanTermTo ? dataApproved?.approvedLoanTermTo : null,
                    });
                }
            } else if (typeCar === 2 || typeCar === 3) {
                //รถบรรทุก
                //รถเครื่องจักรการเกษตร 
                if (value === "p-loan") {
                    setCheckDis(true);
                    setCheckCar23(false);
                } else if (value === "เช่าซื้อ") {
                    setCheckDis(false);
                    setCheckCar23(true);
                    form.setFieldsValue({
                        apLoanAmount: dataApproved?.approvedLoanAmount,
                        pgLoanTerm: "",
                        apInterestRate: "",
                        apInterest: "",
                        apMonthlyPayment: "",
                        apInstallmentWithInterest: "",
                        apProductType: "",
                    });
                    setpreData({
                        apProductType: "เช่าซื้อ",
                        apLoanAmount: dataApproved?.approvedLoanAmount,
                        pgLoanTermTo: dataApproved?.approvedLoanTermTo ? dataApproved?.approvedLoanTermTo : null,
                    });
                }
            };
        }
    }

    const car_Calculate = () => {
        //แก้ใหม่
        var interestRateCar = parseFloat(preData.apInterestRate) / 100; // อัตราดอก / 100
        var rateCar =
            parseFloat(preData.apLoanAmount) *
            Currency(interestRateCar, { precision: 5 }).value *
            preData.pgLoanTerm; // (ยอดจัด * อัตราดอกที่หาร100แล้ว ,precision: 3 คือทศนิยม 3 )*งวด
        var loanTermCar =
            (rateCar + parseFloat(preData.apLoanAmount)) /
            parseInt(preData.pgLoanTerm); // (ดอกเบี้ยทั้งหมด + ยอดจัด)/ งวด
        var newLoanTermCar = Math.ceil(Currency(loanTermCar * 0.07 + loanTermCar), {
            precision: 5,
        }); // งวดละใหม่ = ( งวดละ * 0.07 ) + งวดละ
        var resultRateCar =
            Math.ceil(
                Currency(newLoanTermCar, { precision: 5 }) *
                parseInt(preData.pgLoanTerm)
            ) - parseFloat(preData.apLoanAmount); // งวดละใหม่ * งวด - ยอดจัด
        var newResultPriceCar = Math.ceil(
            Currency(newLoanTermCar) * parseInt(preData.pgLoanTerm),
            { precision: 5 }
        ); // งวดละใหม่ * งวด

        setpreData({
            ...preData,
            apInterestRate: Math.ceil(preData.apInterestRate * 100) / 100,
            apMonthlyPayment: newLoanTermCar,
            apInterest: resultRateCar,
            apInstallmentWithInterest: newResultPriceCar,
            apLoanAmount: preData.apLoanAmount,
            pgLoanTerm: preData.pgLoanTerm,
        });

        form.setFieldsValue({
            apMonthlyPayment: currencyFormatOne(newLoanTermCar),
            apInterest: currencyFormatOne(resultRateCar),
            apInstallmentWithInterest: currencyFormatOne(newResultPriceCar),
        });
    };

    const land_Calculate = () => {
        var interestRateLand = parseFloat(shootdata.lands.landLoanDetails.interestRateLand) / 100; // อัตราดอก / 100
        var rate = parseFloat(preData?.apLoanAmount) * Currency(interestRateLand, { precision: 3 }).value * parseInt(preData?.pgLoanTerm); // (ยอดจัด * อัตราดอกที่หาร100แล้ว ,precision: 3 คือทศนิยม 3 )*งวด
        var loanTerm = Math.ceil(Currency((rate + parseFloat(preData?.apLoanAmount)) / parseInt(preData?.pgLoanTerm), { precision: 2 }).value
        ); // (ดอกเบี้ยทั้งหมด + ยอดจัด)/ งวด
        var resultRate = loanTerm * parseInt(preData?.pgLoanTerm) - parseFloat(preData?.apLoanAmount);
        var resultPrice = loanTerm * parseInt(preData?.pgLoanTerm);

        form.setFieldsValue({
            apMonthlyPayment: loanTerm,
            apInterest: resultRate,
            apInstallmentWithInterest: resultPrice,
        });

        if (appStatus === 3 && checkApprovalConditions === 0) {
            setstatusAccept({
                ...statusAccept,
                apMonthlyPayment: loanTerm,
                apInterest: resultRate,
                apInstallmentWithInterest: resultPrice,
                apLoanAmount: preData?.apLoanAmount,
                pgLoanTerm: preData?.pgLoanTerm,

                status: appStatus,
                landId: shootdata?.lands?.landId,
                loanId: shootdata?.lands?.landLoanDetails?.landLoanId,
                customerId: shootdata?.customerId,
                approvalStatus: appStatus,
                appStatus: appStatus,
                ApprovedBy: user,

                apProductType: shootdata?.lands?.landLoanDetails?.productType,
                apProductLoanType:
                    shootdata?.lands?.landLoanDetails?.productLoanLandType,
                // apLoanAmount: shootdata?.lands?.landLoanDetails?.loanAmountLand,
                apDownPayment: shootdata?.lands?.landLoanDetails?.downPayment,
                // apInterest: shootdata?.lands?.landLoanDetails?.interestLand,
                apInterestRate: parseFloat(
                    shootdata?.lands?.landLoanDetails?.interestRateLand
                ),
                // pgLoanTerm: shootdata?.lands?.landLoanDetails?.loanLandTerm,
                // apMonthlyPayment: shootdata?.lands?.landLoanDetails?.monthlyPayment,
                apProposalBy: shootdata?.lands?.landLoanDetails?.proposalBy,
                apReviewedBy: shootdata?.lands?.landLoanDetails?.reviewedBy,
                apapprovalStatus: appStatus,
                apApprovedBy: user,
                apApprovalDate: dayjs(),

                apCheckGua: shootdata?.lands?.landLoanDetails?.checkGua,
                apBranch: branch,
                approvalConditions: false,
            });
        } else {
            setstatusAccept({
                ...statusAccept,
                apMonthlyPayment: loanTerm,
                apInterest: resultRate,
                apInstallmentWithInterest: resultPrice,
                apLoanAmount: preData?.apLoanAmount,
                pgLoanTerm: preData?.pgLoanTerm,

                status: appStatus,
                landId: shootdata?.lands?.landId,
                loanId: shootdata?.lands?.landLoanDetails?.landLoanId,
                customerId: shootdata?.customerId,
                approvalStatus: appStatus,
                appStatus: appStatus,
                ApprovedBy: user,
            });
        }
    };

    // console.log("dataApproved", dataApproved)

    const handleSubmit = async () => {
        // console.log("Save")
        setLoading(true)
        let dataSave
        if (status === "close") {
            dataSave = {
                id: dataApproved?.carsApproveCeo?.id,
                pgLoanTerm: preData?.pgLoanTerm ? preData?.pgLoanTerm.toString() : '',
                installment: preData?.apMonthlyPayment ? preData?.apMonthlyPayment.toString() : '',
                interestRate: preData?.apInterestRate.toString(),
                productTypeCar: typeCar,
                productType: checkType
            }
        } else {
            dataSave = {
                id: dataApproved?.id,
                pgLoanTerm: preData?.pgLoanTerm ? preData?.pgLoanTerm.toString() : '',
                installment: preData?.apMonthlyPayment ? preData?.apMonthlyPayment.toString() : '',
                interestRate: preData?.apInterestRate.toString(),
                productTypeCar: typeCar,
                productType: checkType
            }
        }
        console.log("dataSave", dataSave)
        await axios.post(createyellowcard, dataSave)
            .then((res) => {
                // console.log("res.data", res.data)
                sendForshow1(1)
                keys1("1")
                if (status === "close") {
                    handleResetPage()
                }
                setTimeout(() => {
                    message.success(
                        `บันทึกสำเร็จ`
                    );
                    setLoading(false)
                    closeModal(false)
                }, 1500)
                sendLoadTopage()

            }).catch((err) => {
                console.log("error", err)
            })
    };

    const sendForshow1 = (value) => {
        setCheckSendForshow(value);
    };

    const keys1 = (value) => {
        // console.log("value2", value)
        setCheckKeys(value);
    };

    const currencyFormat = (amount) => {
        if (amount) {
            return Number(amount)
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, "$&,");
        } else {
            return "-";
        }
    };

    const htmlToImageConvert = (value, typeDoc) => {
        html2canvas(value?.current, { cacheBust: false })
            .then((canvas) => {
                canvas.toBlob((blob) => {
                    console.log(blob);
                    if (blob) {
                        const file = new File(
                            [blob],
                            `${typeDoc} ${continoRe} ${moment()
                                .add(543, "year")
                                .format("DD/MM/YYYY")}.png`,
                            { type: "image/png" }
                        );

                        const link = document.createElement("a");
                        link.href = URL.createObjectURL(file);
                        link.download = file.name;
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                        message.success(`บันทึกรูปภาพสำเร็จ ${continoRe}`);
                    } else {
                        message.error(
                            `ไม่สามารถบันทึกรูปภาพได้โปรดเข้าออกใหม่ ${continoRe}`
                        );
                    }
                }, "image/png");
            })
            .catch((err) => {
                console.log("เกิดข้อผิดพลาด ", err);
                message.error("ยกเลิกการบันทึกภาพ");
            });
    };

    const showConfirmSave = () => {
        confirm({
            title: "คำร้องขอบันทึกรูปภาพ",
            icon: <ExclamationCircleFilled />,
            content: "คุณต้องการบันทึกรูปภาพใช่หรือไม่",
            okText: "บันทึก",
            cancelText: "ยกเลิก",
            onOk() {
                htmlToImageConvert(elementRef2, "ใบเหลือง เลขที่สัญญา:");
            },
            onCancel() {
                console.log("Cancel");
            },
        });
    };

    /////////////// เลือก ประเภทรถ ///////////////////
    const handleTypeCars = (value) => {
        setCheckTypeCar(false)
        setCheckType("");
        setCheckCar23(false)
        setCheckDis(true)
        setTypeCar(value);
        if (value = 1) {
            setCheckCar23(false)
            form.setFieldsValue({
                apProductType2: "",
                apProductType1: "",
                pgLoanTerm: "",
                apInterestRate: "",
                apLoanAmount: "",
                apInterest: "",
                apMonthlyPayment: "",
                apInstallmentWithInterest: "",
            });
        } else if (value === 2 || value === 3) {
            setCheckCar23(true)
            form.setFieldsValue({
                apProductType1: "",
                apProductType2: "",
                pgLoanTerm: "",
                apInterestRate: "",
                apLoanAmount: "",
                apInterest: "",
                apMonthlyPayment: "",
                apInstallmentWithInterest: "",
            });
        }
        // setShootdata(value)
        //  1 คือ รถเล็ก(รถยนต์)
        //  2 คือ รถบรรทุก(รถใหญ่)
    };
    const handleCancel = () => {
        closeModal(false);
    };

    const getTable = () => {
        setOpenTable(true);
    };
    const getTableHP = () => {
        setOpenTableHP(true);
    };

    // checkkeys === '2' && data?.sendPg && carsApproveve?.installment !== null && carsApproveve?.pgLoanTerm !== null

    // {checkkeys === '2' && !data?.sendPg && carsApproveve?.installment === null && carsApproveve?.pgLoanTerm === null ? (
    // {data?.products === "car" ? (

    // console.log("carsApproveve", carsApproveve)
    // console.log("checkkeys", checkkeys)
    // console.log("data?.sendPg", data?.sendPg)
    // console.log("carsApproveve?.installment", carsApproveve?.installment)
    // console.log("carsApproveve?.pgLoanTerm", carsApproveve?.pgLoanTerm)
    // console.log("typeCar", typeCar)
    // console.log("dataApproved", dataApproved)
    // console.log("dataApproved?.productTypeCar", dataApproved?.productTypeCar)

    return (
        <>
            <Card>
                <Form
                    form={form}
                    labelCol={{
                        span: 12,
                    }}
                    wrapperCol={{
                        span: 24,
                    }}
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={handleSubmit}
                    autoComplete="off"
                >
                    <Spin spinning={loading} size="large" tip=" Loading... ">
                        <Row>
                            <Col span={22}>
                                {checkkeys === '2' && !data?.sendPg && carsApproveve?.installment === null && carsApproveve?.pgLoanTerm === null ? (
                                    <>
                                        {data?.products === "car" ? (
                                            <>
                                                <Form.Item label="ประเภทรถ" name="ProductType">
                                                    <Select
                                                        placeholder={"เลือก"}
                                                        style={{ height: "40px" }}
                                                        onChange={(value) => {
                                                            handleTypeCars(value);
                                                        }}
                                                        options={[
                                                            {
                                                                label: "รถยนต์",
                                                                value: 1,
                                                            },
                                                            {
                                                                label: "รถบรรทุก",
                                                                value: 2,
                                                            },
                                                            {
                                                                label: "รถเครื่องจักรการเกษตร",
                                                                value: 3,
                                                            },
                                                        ]}
                                                    />
                                                </Form.Item>
                                                {
                                                    typeCar === 1 ?
                                                        <>
                                                            <Form.Item
                                                                label="ประเภทค่างวด"
                                                                name="apProductType1"
                                                            >
                                                                <Select
                                                                    placeholder={"เลือก"}
                                                                    style={{ height: "40px" }}
                                                                    onChange={(value) => {
                                                                        handleChangePLorCH(value);
                                                                    }}
                                                                    options={[
                                                                        {
                                                                            label: 'P-LOAN',
                                                                            value: 'p-loan',
                                                                        },
                                                                        {
                                                                            label: "เช่าซื้อ",
                                                                            value: "เช่าซื้อ",
                                                                        },
                                                                        {
                                                                            label: "เช่าซื้อ (0.84-0.75)",
                                                                            value: "เช่าซื้อ1",
                                                                        },
                                                                    ]}
                                                                />
                                                            </Form.Item>
                                                        </>
                                                        : typeCar === 2 ?
                                                            <>
                                                                <Form.Item
                                                                    label="ประเภทค่างวด"
                                                                    name="apProductType2"
                                                                >
                                                                    <Select
                                                                        placeholder={"เลือก"}
                                                                        style={{ height: "40px" }}
                                                                        onChange={(value) => {
                                                                            handleChangePLorCH(value);
                                                                        }}
                                                                        options={[
                                                                            {
                                                                                label: 'P-LOAN',
                                                                                value: 'p-loan',
                                                                            },
                                                                            // {
                                                                            //   label: 'เช่าซื้อ',
                                                                            //   value: 'เช่าซื้อ',
                                                                            // },
                                                                            {
                                                                                label: "เช่าซื้อ",
                                                                                value: "เช่าซื้อ",
                                                                            },
                                                                        ]}
                                                                    />
                                                                </Form.Item>
                                                            </>
                                                            :
                                                            <>
                                                                <Form.Item
                                                                    label="ประเภทค่างวด"
                                                                    name="apProductType2"
                                                                >
                                                                    <Select
                                                                        disabled={checkTypeCar}
                                                                        placeholder={"เลือก"}
                                                                        style={{ height: "40px" }}
                                                                        onChange={(value) => {
                                                                            handleChangePLorCH(value);
                                                                        }}
                                                                        options={[
                                                                            {
                                                                                label: 'P-LOAN',
                                                                                value: 'p-loan',
                                                                            },
                                                                            // {
                                                                            //   label: 'เช่าซื้อ',
                                                                            //   value: 'เช่าซื้อ',
                                                                            // },
                                                                            {
                                                                                label: "เช่าซื้อ",
                                                                                value: "เช่าซื้อ",
                                                                            },
                                                                        ]}
                                                                    />
                                                                </Form.Item>
                                                            </>
                                                }
                                                {checkType === "p-loan" ? (
                                                    <>
                                                        {/* ติดต่อไอที */}
                                                        <Form.Item label='ตารางค่างวด'>
                                                            <Button type="primary" onClick={getTable}>
                                                                ตารางค่างวด P-LOAN
                                                            </Button>
                                                        </Form.Item>
                                                        {
                                                            openTable ?
                                                                <Installment open={openTable} close={setOpenTable} money={handleMoney}
                                                                    type={money}
                                                                    cp={
                                                                        ~~preData.approvedLoanAmount
                                                                            ? ~~preData?.approvedLoanAmount
                                                                            : ~~carsApproveve?.approvedLoanAmount
                                                                    }
                                                                    cp2={~~carsApproveve?.approvedLoanTerm}
                                                                    cp3={~~carsApproveve?.approvedLoanTermTo}
                                                                    checkGARFE={4}
                                                                    checkPG={1} />
                                                                : null
                                                        }
                                                    </>
                                                ) : checkType === "เช่าซื้อ" && typeCar === 1 ? (
                                                    <>
                                                        <Form.Item label="ตารางค่างวด">
                                                            <Button type="primary"
                                                                onClick={getTableHP}
                                                            >
                                                                ตารางค่างวด เช่าซื้อ
                                                            </Button>
                                                        </Form.Item>
                                                        {openTableHP ? (
                                                            <HirePurchase
                                                                open={openTableHP}
                                                                close={setOpenTableHP}
                                                                money={handleMoneyHP}
                                                                type={money}
                                                                cp={
                                                                    ~~preData.approvedLoanAmount
                                                                        ? ~~preData?.approvedLoanAmount
                                                                        : ~~dataApproved?.approvedLoanAmount
                                                                }
                                                                cp2={~~dataApproved?.approvedLoanTerm}
                                                                cp3={~~dataApproved?.approvedLoanTermTo}
                                                                checkGARFE={4}
                                                                checkPG={1}
                                                            />
                                                        ) : null}
                                                    </>
                                                ) : checkType === "เช่าซื้อ" && typeCar !== 1 ? (
                                                    <>
                                                    </>
                                                ) : null}
                                                <Form.Item
                                                    label="ยอดที่อนุมัติ"
                                                    name="apLoanAmount"
                                                    style={{ lineHeight: "50px" }}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "กรุณากรอกข้อมูล !",
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        // disabled={checkDis}
                                                        disabled
                                                        style={{ color: "black" }}
                                                        suffix="บาท"
                                                        onChange={(e) =>
                                                            setpreData({
                                                                ...preData,
                                                                apLoanAmount: parseInt(e.target.value),
                                                            })
                                                        }
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    label="จำนวนงวด"
                                                    name="pgLoanTerm"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "กรุณากรอกข้อมูล !",
                                                        },
                                                    ]}
                                                >
                                                    <Select
                                                        disabled={checkDis}
                                                        style={{ height: "40px" }}
                                                        placeholder="เลือกจำนวนงวด"
                                                        onChange={changeLoanTerm}
                                                        options={filteredOptions}
                                                    />
                                                </Form.Item>
                                                {checkCar23 === true ? (
                                                    <Form.Item
                                                        label="อัตราดอกเบี้ย"
                                                        name="apInterestRate"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "กรุณากรอกข้อมูล !",
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            disabled={checkDis}
                                                            style={{ color: "black", height: "40px" }}
                                                            suffix="% +VAT"
                                                            onChange={(e) =>
                                                                setpreData({
                                                                    ...preData,
                                                                    apInterestRate: parseFloat(
                                                                        e.target.value
                                                                    ),
                                                                })
                                                            }
                                                        />
                                                    </Form.Item>
                                                ) : (
                                                    <Form.Item
                                                        label="อัตราดอกเบี้ย"
                                                        name="apInterestRate"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "กรุณากรอกข้อมูล !",
                                                            },
                                                        ]}
                                                    >
                                                        <Select
                                                            disabled={checkDis}
                                                            style={{ height: "40px" }}
                                                            placeholder="เลือกอัตราดอกเบี้ย"
                                                            onChange={changeInterestRate}
                                                            options={[
                                                                {
                                                                    label: 0.84,
                                                                    value: 0.84,
                                                                },
                                                                {
                                                                    label: 0.75,
                                                                    value: 0.75,
                                                                },
                                                            ]}
                                                        />
                                                    </Form.Item>
                                                )}
                                                <Form.Item label="งวดละ" name="apMonthlyPayment">
                                                    <Input
                                                        disabled
                                                        style={{ color: "black" }}
                                                        suffix="บาท"
                                                        onChange={(e) =>
                                                            setstatusAccept({
                                                                ...statusAccept,
                                                                apMonthlyPayment: e.target.value,
                                                            })
                                                        }
                                                    />
                                                </Form.Item>
                                                <Form.Item label="ดอกเบี้ย" name="apInterest">
                                                    <Input
                                                        disabled
                                                        style={{ color: "black" }}
                                                        suffix="บาท"
                                                        onChange={(e) =>
                                                            setstatusAccept({
                                                                ...statusAccept,
                                                                apInterest: e.target.value,
                                                            })
                                                        }
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    label="รวมราคา"
                                                    name="apInstallmentWithInterest"
                                                >
                                                    <Input
                                                        disabled
                                                        style={{ color: "black" }}
                                                        suffix="บาท"
                                                        onChange={(e) =>
                                                            setstatusAccept({
                                                                ...statusAccept,
                                                                apInstallmentWithInterest: e.target.value,
                                                            })
                                                        }
                                                    />
                                                </Form.Item>
                                                <Row>
                                                    <Col span={24}>
                                                        <div style={{ textAlign: "center" }}>
                                                            <Space>
                                                                <Button
                                                                    key="back"
                                                                    type="primary"
                                                                    danger
                                                                    onClick={handleCancel}
                                                                >
                                                                    ปิด
                                                                </Button>

                                                                <Button
                                                                    type="primary"
                                                                    htmlType='submit'
                                                                    style={{ backgroundColor: "Green" }}
                                                                >
                                                                    บันทึก
                                                                </Button>
                                                            </Space>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </>
                                        ) :
                                            null
                                        }
                                    </>
                                ) :
                                    <>
                                        {
                                            (checkkeys === '1' || checkkeys === '2') &&
                                                !data?.sendPg && carsApproveve?.installment !== null &&
                                                carsApproveve?.pgLoanTerm !== null
                                                ?
                                                <>
                                                    <Row justify={'center'}>
                                                        <Col
                                                            span={24}
                                                            style={{
                                                                marginTop: 10,
                                                                marginBottom: 10,
                                                                textAlign: "left",
                                                                backgroundColor: "#ffad01",
                                                            }}
                                                            className="center"
                                                            ref={elementRef2}
                                                        >
                                                            <Col span={24} style={{ textAlign: "center" }} >
                                                                <Image
                                                                    width={200}
                                                                    src={`${process.env.PUBLIC_URL}/logo.jpg`}
                                                                    alt="My Image"
                                                                />
                                                            </Col>
                                                            <Divider style={{ margin: 8 }} />
                                                            <aside>
                                                                <div>
                                                                    <span>
                                                                        วันที่ :{" "}
                                                                        <b>
                                                                            {carsApproveve?.approvalDate
                                                                                ? dayjs(carsApproveve?.approvalDate).format(
                                                                                    "DD-MM-YYYY"
                                                                                )
                                                                                : "-"}
                                                                        </b>
                                                                    </span>
                                                                </div>
                                                                <div>
                                                                    <span>
                                                                        เลขที่สัญญา :
                                                                        <b>
                                                                            {carsApproveve?.CONTNO ? carsApproveve?.CONTNO : null}
                                                                        </b>
                                                                    </span>
                                                                </div>
                                                                <div>
                                                                    <span>
                                                                        {dataDB2?.PRODUCTS === "car" ? (
                                                                            <>
                                                                                {" "}
                                                                                รถ :
                                                                                <b>
                                                                                    {" "}
                                                                                    {dataDB2?.BAAB +
                                                                                        "  " +
                                                                                        dataDB2?.MODEL +
                                                                                        "  " +
                                                                                        dataDB2?.TYPE}
                                                                                </b>
                                                                            </>
                                                                        ) : (
                                                                            <></>
                                                                        )}
                                                                    </span>
                                                                </div>
                                                                <div>
                                                                    <span>
                                                                        {" "}
                                                                        {dataDB2?.PRODUCTS === "car" ? (
                                                                            <>
                                                                                {" "}
                                                                                ทะเบียน : <b>{dataDB2?.REGNO}</b>
                                                                            </>
                                                                        ) : (
                                                                            <></>
                                                                        )}
                                                                    </span>
                                                                </div>
                                                                <div>
                                                                    <span>
                                                                        ชื่อผู้เช่าซื้อ :{" "}
                                                                        <b>
                                                                            {dataDB2?.SNAM +
                                                                                " " +
                                                                                dataDB2?.NAME1 +
                                                                                " " +
                                                                                dataDB2?.NAME2}
                                                                        </b>
                                                                    </span>
                                                                </div>
                                                                <div>
                                                                    <span>
                                                                        มูลหนี้เช่าซื้อคงเหลือ :{" "}
                                                                        <b>
                                                                            {currencyFormat(outstandingdebt) + " บาท"}

                                                                        </b>
                                                                    </span>
                                                                </div>
                                                                <div>
                                                                    <span>
                                                                        หักส่วนลด :{" "}
                                                                        <b>
                                                                            {currencyFormat(deductdiscount) + " บาท"}
                                                                        </b>
                                                                    </span>
                                                                </div>
                                                                <div>
                                                                    <span>
                                                                        คงเหลือ :{" "}
                                                                        <b>

                                                                            {currencyFormat(remaining) + " บาท"}


                                                                        </b>
                                                                    </span>
                                                                </div>
                                                                <div>
                                                                    <span>
                                                                        จ่ายเงิน :
                                                                        <b>
                                                                            {currencyFormat(pay) + " บาท"}

                                                                        </b>
                                                                    </span>
                                                                </div>
                                                                <div>
                                                                    <span>
                                                                        ยอดจัด :{" "}
                                                                        <b>
                                                                            {currencyFormat(Excellent) + " บาท"}

                                                                        </b>
                                                                    </span>
                                                                </div>
                                                                <div>
                                                                    <span>
                                                                        จำนวนงวด :{" "}
                                                                        <b>
                                                                            {carsApproveve?.pgLoanTerm + ' งวด'}
                                                                        </b>
                                                                    </span>
                                                                </div>
                                                                <div>
                                                                    <span>
                                                                        ผ่อน :{" "}
                                                                        <b>
                                                                            {/* apMonthlyPayment จำนวนงวด ชื่อตัวแปรเอาไว้โชว์*/}
                                                                            {/* installment จำนวนงวด ชื่อตัวแปรในฐานข้อมูล*/}
                                                                            {currencyFormat(carsApproveve?.installment) + ' X ' + carsApproveve?.pgLoanTerm + ' งวด ' + '  (รวมภาษีมูลค่าเพิ่ม)  '}

                                                                        </b>
                                                                    </span>
                                                                </div>

                                                            </aside>
                                                        </Col>
                                                    </Row>
                                                </>
                                                :
                                                <>
                                                    {checkkeys === '2' && data?.sendPg && carsApproveve?.installment !== null && carsApproveve?.pgLoanTerm !== null
                                                        ?
                                                        <>
                                                            <Row justify={'center'}>
                                                                <Col
                                                                    span={24}
                                                                    style={{
                                                                        marginTop: 10,
                                                                        marginBottom: 10,
                                                                        textAlign: "left",
                                                                        backgroundColor: "#ffad01",
                                                                    }}
                                                                    className="center"
                                                                    ref={elementRef2}
                                                                >
                                                                    <Col span={24} style={{ textAlign: "center" }} >
                                                                        <Image
                                                                            width={200}
                                                                            src={`${process.env.PUBLIC_URL}/logo.jpg`}
                                                                            alt="My Image"
                                                                        />
                                                                    </Col>
                                                                    <Divider style={{ margin: 8 }} />
                                                                    <aside>
                                                                        <div>
                                                                            <span>
                                                                                วันที่ :{" "}
                                                                                <b>
                                                                                    {carsApproveve?.approvalDate
                                                                                        ? dayjs(carsApproveve?.approvalDate).format(
                                                                                            "DD-MM-YYYY"
                                                                                        )
                                                                                        : "-"}
                                                                                </b>
                                                                            </span>
                                                                        </div>
                                                                        <div>
                                                                            <span>
                                                                                เลขที่สัญญา :
                                                                                <b>
                                                                                    {carsApproveve?.CONTNO ? carsApproveve?.CONTNO : null}
                                                                                </b>
                                                                            </span>
                                                                        </div>
                                                                        <div>
                                                                            <span>
                                                                                {dataDB2?.PRODUCTS === "car" ? (
                                                                                    <>
                                                                                        {" "}
                                                                                        รถ :
                                                                                        <b>
                                                                                            {" "}
                                                                                            {dataDB2?.BAAB +
                                                                                                "  " +
                                                                                                dataDB2?.MODEL +
                                                                                                "  " +
                                                                                                dataDB2?.TYPE}
                                                                                        </b>
                                                                                    </>
                                                                                ) : (
                                                                                    <></>
                                                                                )}
                                                                            </span>
                                                                        </div>
                                                                        <div>
                                                                            <span>
                                                                                {" "}
                                                                                {dataDB2?.PRODUCTS === "car" ? (
                                                                                    <>
                                                                                        {" "}
                                                                                        ทะเบียน : <b>{dataDB2?.REGNO}</b>
                                                                                    </>
                                                                                ) : (
                                                                                    <></>
                                                                                )}
                                                                            </span>
                                                                        </div>
                                                                        <div>
                                                                            <span>
                                                                                ชื่อผู้เช่าซื้อ :{" "}
                                                                                <b>
                                                                                    {dataDB2?.SNAM +
                                                                                        " " +
                                                                                        dataDB2?.NAME1 +
                                                                                        " " +
                                                                                        dataDB2?.NAME2}
                                                                                </b>
                                                                            </span>
                                                                        </div>
                                                                        <div>
                                                                            <span>
                                                                                มูลหนี้เช่าซื้อคงเหลือ :{" "}
                                                                                <b>
                                                                                    {currencyFormat(outstandingdebt) + " บาท"}

                                                                                </b>
                                                                            </span>
                                                                        </div>
                                                                        <div>
                                                                            <span>
                                                                                หักส่วนลด :{" "}
                                                                                <b>

                                                                                    {currencyFormat(deductdiscount) + " บาท"}


                                                                                </b>
                                                                            </span>
                                                                        </div>
                                                                        <div>
                                                                            <span>
                                                                                คงเหลือ :{" "}
                                                                                <b>
                                                                                    {currencyFormat(remaining) + " บาท"}
                                                                                </b>
                                                                            </span>
                                                                        </div>
                                                                        <div>
                                                                            <span>
                                                                                จ่ายเงิน :
                                                                                <b>
                                                                                    {currencyFormat(pay) + " บาท"}

                                                                                </b>
                                                                            </span>
                                                                        </div>
                                                                        <div>
                                                                            <span>
                                                                                ยอดจัด :{" "}
                                                                                <b>
                                                                                    {currencyFormat(Excellent) + " บาท"}

                                                                                </b>
                                                                            </span>
                                                                        </div>
                                                                        <div>
                                                                            <span>
                                                                                จำนวนงวด :{" "}
                                                                                <b>
                                                                                    {carsApproveve?.pgLoanTerm + ' งวด'}
                                                                                </b>
                                                                            </span>
                                                                        </div>
                                                                        <div>
                                                                            <span>
                                                                                ผ่อน :{" "}
                                                                                <b>
                                                                                    {/* apMonthlyPayment จำนวนงวด ชื่อตัวแปรเอาไว้โชว์*/}
                                                                                    {/* installment จำนวนงวด ชื่อตัวแปรในฐานข้อมูล*/}
                                                                                    {currencyFormat(carsApproveve?.installment) + ' X ' + carsApproveve?.pgLoanTerm + ' งวด ' + '  (รวมภาษีมูลค่าเพิ่ม)  '}

                                                                                </b>
                                                                            </span>
                                                                        </div>

                                                                    </aside>
                                                                </Col>
                                                            </Row>
                                                        </>
                                                        :
                                                        <>
                                                            {/* <Row justify={'center'}>
                                                            <b style={{ fontSize: '16px', width: '250px', color: 'blue' }}>
                                                                <u>ยังไม่มีจำนวนงวดและค่างวด</u>
                                                            </b>
                                                            </Row>
                                                            แก้เป็นให้กรอกข้อมูลใบเหลือง */}
                                                            {dataApproved?.carId !== 0 ? (
                                                                <>
                                                                    <Form.Item label="ประเภทรถ" name="ProductType">
                                                                        <Select
                                                                            placeholder={"เลือก"}
                                                                            style={{ height: "40px" }}
                                                                            onChange={(value) => {
                                                                                handleTypeCars(value);
                                                                            }}
                                                                            options={[
                                                                                {
                                                                                    label: "รถยนต์",
                                                                                    value: 1,
                                                                                },
                                                                                {
                                                                                    label: "รถบรรทุก",
                                                                                    value: 2,
                                                                                },
                                                                                {
                                                                                    label: "รถเครื่องจักรการเกษตร",
                                                                                    value: 3,
                                                                                },
                                                                            ]}
                                                                        />
                                                                    </Form.Item>
                                                                    {
                                                                        typeCar === 1 ?
                                                                            <>
                                                                                <Form.Item
                                                                                    label="ประเภทค่างวด"
                                                                                    name="apProductType1"
                                                                                >
                                                                                    <Select
                                                                                        placeholder={"เลือก"}
                                                                                        style={{ height: "40px" }}
                                                                                        onChange={(value) => {
                                                                                            handleChangePLorCH(value);
                                                                                        }}
                                                                                        options={[
                                                                                            {
                                                                                                label: 'P-LOAN',
                                                                                                value: 'p-loan',
                                                                                            },
                                                                                            {
                                                                                                label: "เช่าซื้อ",
                                                                                                value: "เช่าซื้อ",
                                                                                            },
                                                                                            {
                                                                                                label: "เช่าซื้อ (0.84-0.75)",
                                                                                                value: "เช่าซื้อ1",
                                                                                            },
                                                                                        ]}
                                                                                    />
                                                                                </Form.Item>
                                                                            </>
                                                                            : typeCar === 2 ?
                                                                                <>
                                                                                    <Form.Item
                                                                                        label="ประเภทค่างวด"
                                                                                        name="apProductType2"
                                                                                    >
                                                                                        <Select
                                                                                            placeholder={"เลือก"}
                                                                                            style={{ height: "40px" }}
                                                                                            onChange={(value) => {
                                                                                                handleChangePLorCH(value);
                                                                                            }}
                                                                                            options={[
                                                                                                {
                                                                                                    label: 'P-LOAN',
                                                                                                    value: 'p-loan',
                                                                                                },
                                                                                                // {
                                                                                                //   label: 'เช่าซื้อ',
                                                                                                //   value: 'เช่าซื้อ',
                                                                                                // },
                                                                                                {
                                                                                                    label: "เช่าซื้อ",
                                                                                                    value: "เช่าซื้อ",
                                                                                                },
                                                                                            ]}
                                                                                        />
                                                                                    </Form.Item>
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    <Form.Item
                                                                                        label="ประเภทค่างวด"
                                                                                        name="apProductType2"
                                                                                    >
                                                                                        <Select
                                                                                            disabled={checkTypeCar}
                                                                                            placeholder={"เลือก"}
                                                                                            style={{ height: "40px" }}
                                                                                            onChange={(value) => {
                                                                                                handleChangePLorCH(value);
                                                                                            }}
                                                                                            options={[
                                                                                                {
                                                                                                    label: 'P-LOAN',
                                                                                                    value: 'p-loan',
                                                                                                },
                                                                                                // {
                                                                                                //   label: 'เช่าซื้อ',
                                                                                                //   value: 'เช่าซื้อ',
                                                                                                // },
                                                                                                {
                                                                                                    label: "เช่าซื้อ",
                                                                                                    value: "เช่าซื้อ",
                                                                                                },
                                                                                            ]}
                                                                                        />
                                                                                    </Form.Item>
                                                                                </>
                                                                    }
                                                                    {checkType === "p-loan" ? (
                                                                        <>
                                                                            {/* ติดต่อไอที */}
                                                                            <Form.Item label='ตารางค่างวด'>
                                                                                <Button type="primary" onClick={getTable}>
                                                                                    ตารางค่างวด P-LOAN
                                                                                </Button>
                                                                            </Form.Item>
                                                                            {
                                                                                openTable ?
                                                                                    <Installment open={openTable} close={setOpenTable} money={handleMoney}
                                                                                        type={money}
                                                                                        cp={
                                                                                            ~~preData.approvedLoanAmount
                                                                                                ? ~~preData?.approvedLoanAmount
                                                                                                : ~~carsApproveve?.approvedLoanAmount
                                                                                        }
                                                                                        cp2={~~carsApproveve?.approvedLoanTerm}
                                                                                        cp3={~~carsApproveve?.approvedLoanTermTo}
                                                                                        checkGARFE={4}
                                                                                        checkPG={1} />
                                                                                    : null
                                                                            }
                                                                        </>
                                                                    ) : checkType === "เช่าซื้อ" && typeCar === 1 ? (
                                                                        <>
                                                                            <Form.Item label="ตารางค่างวด">
                                                                                <Button type="primary"
                                                                                    onClick={getTableHP}
                                                                                >
                                                                                    ตารางค่างวด เช่าซื้อ
                                                                                </Button>
                                                                            </Form.Item>
                                                                            {openTableHP ? (
                                                                                <HirePurchase
                                                                                    open={openTableHP}
                                                                                    close={setOpenTableHP}
                                                                                    money={handleMoneyHP}
                                                                                    type={money}
                                                                                    cp={
                                                                                        ~~preData.approvedLoanAmount
                                                                                            ? ~~preData?.approvedLoanAmount
                                                                                            : ~~dataApproved?.approvedLoanAmount
                                                                                    }
                                                                                    cp2={~~dataApproved?.approvedLoanTerm}
                                                                                    cp3={~~dataApproved?.approvedLoanTermTo}
                                                                                    checkGARFE={4}
                                                                                    checkPG={1}
                                                                                />
                                                                            ) : null}
                                                                        </>
                                                                    ) : checkType === "เช่าซื้อ" && typeCar !== 1 ? (
                                                                        <>
                                                                        </>
                                                                    ) : null}
                                                                    <Form.Item
                                                                        label="ยอดที่อนุมัติ"
                                                                        name="apLoanAmount"
                                                                        style={{ lineHeight: "50px" }}
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: "กรุณากรอกข้อมูล !",
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <Input
                                                                            // disabled={checkDis}
                                                                            disabled
                                                                            style={{ color: "black" }}
                                                                            suffix="บาท"
                                                                            onChange={(e) =>
                                                                                setpreData({
                                                                                    ...preData,
                                                                                    apLoanAmount: parseInt(e.target.value),
                                                                                })
                                                                            }
                                                                        />
                                                                    </Form.Item>
                                                                    <Form.Item
                                                                        label="จำนวนงวด"
                                                                        name="pgLoanTerm"
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: "กรุณากรอกข้อมูล !",
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <Select
                                                                            disabled={checkDis}
                                                                            style={{ height: "40px" }}
                                                                            placeholder="เลือกจำนวนงวด"
                                                                            onChange={changeLoanTerm}
                                                                            options={filteredOptions}
                                                                        />
                                                                    </Form.Item>
                                                                    {checkCar23 === true ? (
                                                                        <Form.Item
                                                                            label="อัตราดอกเบี้ย"
                                                                            name="apInterestRate"
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: "กรุณากรอกข้อมูล !",
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <Input
                                                                                disabled={checkDis}
                                                                                style={{ color: "black", height: "40px" }}
                                                                                suffix="% +VAT"
                                                                                onChange={(e) =>
                                                                                    setpreData({
                                                                                        ...preData,
                                                                                        apInterestRate: parseFloat(
                                                                                            e.target.value
                                                                                        ),
                                                                                    })
                                                                                }
                                                                            />
                                                                        </Form.Item>
                                                                    ) : (
                                                                        <Form.Item
                                                                            label="อัตราดอกเบี้ย"
                                                                            name="apInterestRate"
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: "กรุณากรอกข้อมูล !",
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <Select
                                                                                disabled={checkDis}
                                                                                style={{ height: "40px" }}
                                                                                placeholder="เลือกอัตราดอกเบี้ย"
                                                                                onChange={changeInterestRate}
                                                                                options={[
                                                                                    {
                                                                                        label: 0.84,
                                                                                        value: 0.84,
                                                                                    },
                                                                                    {
                                                                                        label: 0.75,
                                                                                        value: 0.75,
                                                                                    },
                                                                                ]}
                                                                            />
                                                                        </Form.Item>
                                                                    )}
                                                                    <Form.Item label="งวดละ" name="apMonthlyPayment">
                                                                        <Input
                                                                            disabled
                                                                            style={{ color: "black" }}
                                                                            suffix="บาท"
                                                                            onChange={(e) =>
                                                                                setstatusAccept({
                                                                                    ...statusAccept,
                                                                                    apMonthlyPayment: e.target.value,
                                                                                })
                                                                            }
                                                                        />
                                                                    </Form.Item>
                                                                    <Form.Item label="ดอกเบี้ย" name="apInterest">
                                                                        <Input
                                                                            disabled
                                                                            style={{ color: "black" }}
                                                                            suffix="บาท"
                                                                            onChange={(e) =>
                                                                                setstatusAccept({
                                                                                    ...statusAccept,
                                                                                    apInterest: e.target.value,
                                                                                })
                                                                            }
                                                                        />
                                                                    </Form.Item>
                                                                    <Form.Item
                                                                        label="รวมราคา"
                                                                        name="apInstallmentWithInterest"
                                                                    >
                                                                        <Input
                                                                            disabled
                                                                            style={{ color: "black" }}
                                                                            suffix="บาท"
                                                                            onChange={(e) =>
                                                                                setstatusAccept({
                                                                                    ...statusAccept,
                                                                                    apInstallmentWithInterest: e.target.value,
                                                                                })
                                                                            }
                                                                        />
                                                                    </Form.Item>
                                                                    <Row>
                                                                        <Col span={24}>
                                                                            <div style={{ textAlign: "center" }}>
                                                                                <Space>
                                                                                    <Button
                                                                                        key="back"
                                                                                        type="primary"
                                                                                        danger
                                                                                        onClick={handleCancel}
                                                                                    >
                                                                                        ปิด
                                                                                    </Button>
                                                                                    <Button
                                                                                        type="primary"
                                                                                        htmlType='submit'
                                                                                        style={{ backgroundColor: "Green" }}
                                                                                    >
                                                                                        บันทึก
                                                                                    </Button>
                                                                                </Space>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </>
                                                            ) :
                                                                null
                                                            }
                                                        </>
                                                    }
                                                </>}
                                    </>
                                }
                            </Col>
                        </Row>
                        <FloatButton.Group open={open} style={{ right: 24, zIndex: 100 }}>
                            <FloatButton
                                icon={<DownloadOutlined />}
                                title="บันทึกรูปภาพ"
                                style={{ boxShadow: "0 4px 8px", }}
                                onClick={showConfirmSave}
                            />
                            <FloatButton
                                icon={<CloseOutlined />}
                                title="ปิด"
                                style={{ boxShadow: "0 4px 8px", zIndex: 200 }}
                                onClick={() => {
                                    handleCancel();
                                    console.log("ปิด modal");
                                }}
                            ></FloatButton>
                        </FloatButton.Group>
                    </Spin>
                </Form>
            </Card >
        </>
    );
};

export default MemoOne;
