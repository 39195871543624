import React, { useState, useEffect } from "react";
import { Button, Form, Row, Col, Table, DatePicker, Spin, Select } from "antd";
import axios from "axios";
import DataColumnsTabel from "./data/DataColumnsTabel";
//import DataCase from "./data/DataCase";
import moment from "moment/moment";
import SearchPDF from "./SearchPDF";
import { mainReceiptAuto } from "./../../file_mid/all_api";

function SearchTabel() {
  //รับข้อมูลวันเวลา จาก input
  const [loading, setLoading] = useState(false);
  const [inputDate, setInputDate] = useState(false);
  const [tableSearch, setTableSearch] = useState([]);
  const [dataType, setDataType] = useState([]);
  const [tableSearchType, setTableSearchType] = useState([]);
  const onChangeDate = (date, dateString) => {
    // ตรวจสอบว่า dateSelect ไม่เป็น null (เพื่อป้องกันการผิดพลาด)
    // if(dateString){
    //   const startDate = moment(dateString).format("YYYY-MM-DD");
    //   const endDate = startDate;
    //   setInputDate({ startDate, endDate });
    // }
    // console.log(dateString);
  };
    //รับข้อมูลประเภท จาก input
    const handleChangeType = (value) => {
      //console.log(`selected ${value}`);
      const dType = value;
      setDataType(dType);
    };
  //form-Error
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  //form-Success
  const onFinish = (values) => {
    //console.log("values", values);
    const startDate = moment(values.SearchDate.$d).format("YYYY-MM-DD");
    const endDate = startDate;
    setInputDate({ startDate, endDate });
  };
  //ผู้พิมพ์
  const userFirstname = localStorage.getItem("firstname");
  const userLastname = localStorage.getItem("lastname");
  const userPrint = `${userFirstname} ${userLastname}`;
  //ดึงข้อมูลค้นหา
  useEffect(() => {
    const fetchData = async () => {
      console.log("inputDate", inputDate.startDate, "--", inputDate.endDate);
      try {
        const res = await axios.post(mainReceiptAuto, {
          day1: inputDate.startDate,
          day2: inputDate.endDate,
        });
        const dataB = res.data;
        //console.log("res2468", dataB);
        setTableSearch(dataB);
        setLoading(false);
      } catch (err) {
        console.log(err.message);
        setLoading(false);
      }
    };
    if (inputDate) {
      setLoading(true);
      fetchData();
    }
  }, [inputDate]);

  useEffect(() => {
    // Filter หาเฉพาะรายการที่มีค่าเป็น "AY"
    const filteredData = tableSearch.filter((item) =>
      item.TMBILL.includes(dataType)
    );
    console.log(dataType, "filteredData", filteredData);
    setTableSearchType(filteredData);
  }, [tableSearch, dataType]);

  return (
    <>
      <div style={{ padding: "10px" }}>
        <Spin spinning={loading} size='large' tip=" Loading... ">
          <h1 style={{ textAlign: "center", color: "black" }}>
            ฟอร์ม-การเงินออโต้
          </h1>
          <Form
            layout="vertical"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            {" "}
            <Row>
              <Col span={8}>
                <Form.Item
                  label="เลือกวันเดือนปี"
                  name="SearchDate"
                  rules={[
                    {
                      required: true,
                      message: "เลือกวันเดือนปี!",
                    },
                  ]}
                >
                  <DatePicker
                    placeholder={["เลือกวันเดือนปี"]}
                    style={{ margin: "0 4px 4px" }}
                    format="DD-MM-YYYY"
                    onChange={(date, dateString) => {
                      onChangeDate(date, dateString);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
              <Form.Item
                label="เลือกประเภทเอกสาร"
                name="SelectDataType"
                rules={[
                  {
                    required: true,
                    message: "เลือกวันที่!",
                  },
                ]}
              >
                <Select
                  style={{ width: 240 }}
                  onChange={handleChangeType}
                  options={[
                    { value: "AY", label: "AY" },
                    { value: "JBF", label: "JBF" },
                  ]}
                />
              </Form.Item>
            </Col>             
              <Col span={2}>
                <Form.Item label=" ">
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ margin: "0 2px 4px" }}
                  >
                    ค้นหา
                  </Button>
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item label=" ">
                  <SearchPDF tableSearch={tableSearchType} userPrint={userPrint} dataType={dataType}></SearchPDF>
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Table
            columns={DataColumnsTabel}
            dataSource={
              tableSearchType && tableSearchType.length > 0
                ? tableSearchType.map((item, index) => ({
                  ...item,
                  key: index,
                  TMBILDT: moment(item.TMBILDT).format("DD/MM/YYYY HH:mm:ss "),
                }))
                : []
            }
          />
        </Spin>
      </div>
    </>
  );
}
export default SearchTabel;
