import axios from 'axios';
import { mainArothr } from "../../file_mid/all_api"

export const loadDataMainArothr = async (value, value2, isClickedTy) => {
    console.log("loadDataMainArothr")
    if (value) {
        try {
            const response = await axios.post(mainArothr, { textData: value, textData2: value2, isClickedTy: isClickedTy });
            if (response.status === 200) {
                console.log("response.data", response.data)
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            console.log("err", error);
            return null;
        }
    }
};