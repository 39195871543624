import React, { useRef, useState, useEffect } from "react";
import { useReactToPrint } from "react-to-print";
import { Button, Card, Col, Divider, Form, Input, Row, Space, Spin, Table, notification, DatePicker, Select, Modal, Checkbox, InputNumber } from 'antd';
import axios from 'axios';
import dayjs from "dayjs";
import { PrinterOutlined } from '@ant-design/icons';
import { dayFormatOne, msConnt, msData, msCancel, currencyFormatOne, msList, msOK, msError, msMoneyList, msCal2, msErrorInsert2, msErrorInst, msPrint } from "../file_mid/allFormat";
import { loadDataMainArothr } from "./load_data/data_other";
import { contnoArothr, confirmPayArothr } from "../file_mid/all_api";
import { optionsSearchPay } from "../file_mid/all_options";
import { loadDataPayment, loadPrintDataPaymentArothr } from "../loan_history/load_data_print/data_pay";
import Payment from "../file_mid/reportPayment/PaymentOther";

export default function MainOther() {
    // const { TextArea } = Input;
    const { confirm } = Modal
    const componentPDF = useRef();
    const generatePDF = useReactToPrint({ content: () => componentPDF.current, documentTitle: "ใบรับเงิน", }); // บิลปกติ
    const token = localStorage.getItem('token');
    // const currentToken = localStorage.getItem("currentToken");
    const currentDateTime = dayjs()
    const [form] = Form.useForm();
    const [form2] = Form.useForm();
    const [form3] = Form.useForm();
    const [formKey, setFormKey] = useState(0);
    const [formKey2, setFormKey2] = useState(0);
    const [formKey3, setFormKey3] = useState(0);
    const [loading, setLoading] = useState(false);
    const [dataRec, setDataRec] = useState(null);
    const [showTable, setShowTable] = useState(false);
    const [isClickedTy, setIsClickedTy] = useState(1); // เลือกค้นหาจาก
    const [checkContnoNew, setCheckContnoNew] = useState(false);
    const [checkDataInsert, setCheckDataInsert] = useState(false);
    // const [reload, setReload] = useState(1); // reload
    const [api, contextHolder] = notification.useNotification();
    const [dataCal, setDataCal] = useState({ sumCont: 0, sumAmt: 0, payCont: 0, payAmt: 0 })
    const [dataCode, setDataCode] = useState({ codeQ: "" })
    const [dataSend, setDataSend] = useState({
        textData: "",
        textData2: "",
        CONTNO: "",
        money: 0,
        sale: 0,
        saleFollow: 0,
        date: currentDateTime,
        payType: "จ่ายเข้าตารางดิว",
        PAYCODE: "01",
        selectPay: 1,
        MEMO1: "",
    });
    const [getPAYCODE, setGetPAYCODE] = useState([]);
    const [findPAYTYP, setFindPAYTYP] = useState([]);
    // const [dataAll, setDataAll] = useState(null);
    const [dataTable, setDataTable] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);

    useEffect(() => {
        loadData()
    }, [])
    const loadData = async () => {
        setLoading(true)
        try {
            const result = await loadDataPayment();
            setGetPAYCODE(result)
        } catch (error) {
            console.error("Error in loadData: ", error);
            setGetPAYCODE([])
        } finally {
            setLoading(false);
        }
    }

    const showConfirm = () => {
        confirm({
            title: (
                <Row>
                    <Form form={form}>
                        <Col span={24}>
                            <Form.Item label="" style={{ margin: 0 }}>
                                <b>{"คอมเฟิร์มทำรายการ"}</b>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label="เลขที่สัญญา" style={{ margin: 0 }}>
                                <b style={{ color: 'blue' }}>{dataSend?.CONTNO}</b>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label="จำนวนเงิน" style={{ margin: 0 }}>
                                <b style={{ color: 'blue' }}>{currencyFormatOne(dataSend?.money)}</b>
                                <span style={{ marginLeft: '10px' }}>บาท</span>
                            </Form.Item>
                        </Col>
                    </Form>
                </Row>
            ),
            content: 'กด OK เพื่อยืนยัน',
            onOk() {
                setLoading(true);
                setFormKey(prevKey => prevKey + 1);
                setFormKey2(prevKey => prevKey + 1);
                setFormKey3(prevKey => prevKey + 1);
                callHandleSubmitPay();
            },
            onCancel() {
                setFormKey(prevKey => prevKey + 1);
                setFormKey2(prevKey => prevKey + 1);
                setFormKey3(prevKey => prevKey + 1);
                msCancel(api, "top");
            },
        });
    };
    const callHandleSubmitPay = async () => {
        console.log("selectedRows ==>", selectedRows)
        let mix = {}
        setFormKey2(prevKey => prevKey + 1);
        if (checkContnoNew === false) {
            if (checkDataInsert === false) {
                if (dataSend?.money === dataCal.payAmt) { // ต้องจ่ายเท่ากับที่เลือก
                    if (selectedRows?.length > 0) {
                        // setReload(2)
                        const tk = JSON.parse(token)
                        const headers = {
                            "Authorization": `Bearer ${tk}`,
                            "Menu": JSON.stringify("26")
                        }
                        mix = { PAYCODE: dataSend?.PAYCODE, money: dataSend?.money, datalist: selectedRows }
                        console.log("mix", mix)
                        // await axios.post("http://localhost:8070/auth-pay/confirm-pay", mix, { headers: headers })
                        await axios.post(confirmPayArothr, mix, { headers: headers })
                            .then(async (res) => {
                                console.log("ok insert", res.data)
                                if (res.status === 200) {
                                    // setTimeout(() => {
                                    //     setCheckAgain(false)
                                    //     if (res.data?.codeQ !== "" && res.data?.codeCCB !== "") {
                                    //         form3.setFieldsValue({ codeQ: res.data?.codeQ, codeCCB: res.data?.codeCCB });
                                    //         setDataCode({ codeQ: res.data?.codeQ, codeCCB: res.data?.codeCCB })
                                    //     } else {
                                    //         form3.setFieldsValue({ codeQ: res.data?.codeQ, codeCCB: "" });
                                    //         setDataCode({ codeQ: res.data?.codeQ, codeCCB: "" })
                                    //     }
                                    setDataCode({ codeQ: res.data })
                                    form3.setFieldsValue({ codeQ: res.data });
                                    setCheckDataInsert(true);
                                    setLoading(false)
                                    msOK(api, "top")
                                    // }, 1000);
                                } else {
                                    setDataCode({ codeQ: "" })
                                    form3.setFieldsValue({ codeQ: "" });
                                    msErrorInst(api, "top")
                                    setLoading(false)
                                }
                            })
                            .catch((err) => {
                                console.log("err", err)
                                msError(api, "top")
                                setDataCode({ codeQ: "" })
                                form3.setFieldsValue({ codeQ: "" });
                                setLoading(false)
                            })
                    } else {
                        msList(api, "top");
                        setLoading(false);
                    }
                } else {
                    msMoneyList(api, "top");
                    setLoading(false);
                }
            } else {
                msErrorInsert2(api, "top");
                setLoading(false);
            }
        } else {
            msCal2(api, "top");
            setLoading(false);
        }
    }
    const onSelectAllChange = (e) => {
        console.log("e.target.checked", e.target.checked)
        let payConts = 0;
        let payAmts = 0;
        if (e.target.checked) {
            setSelectedRows(dataTable);
            dataTable.forEach((item) => {
                payConts = item.keyId;
                payAmts += item.PAYAMT - item.SMPAY;
            })
            setDataCal({ ...dataCal, payCont: payConts, payAmt: payAmts });
            setDataSend({ ...dataSend, money: payAmts })
            form2.setFieldsValue({ money: payAmts, });
        } else {
            setSelectedRows([]); // Deselect all
            setDataCal({ ...dataCal, payCont: 0, payAmt: 0 });
            setDataSend({ ...dataSend, money: 0 });
            form2.setFieldsValue({ money: "", });
        }
    };
    const onSelectChange = (record) => {
        console.log("record =>", record)
        var results = {}
        let payConts
        let payAmts = 0;
        if (selectedRows.find(row => row.keyId === record.keyId)) { // คลิกเอาออก
            payConts = 0;
            // console.log("if 1", selectedRows)
            results = selectedRows.filter(row => row.keyId !== record.keyId)
            setSelectedRows(results);
            results.forEach((item) => { payAmts += item.PAYAMT - item.SMPAY; payConts++ })
        } else { // คลิกเพิ่ม
            payConts = 1;
            if (selectedRows?.length > 0) {
                // console.log("if 2", selectedRows)
                selectedRows.forEach((item) => { payAmts += item.PAYAMT - item.SMPAY; payConts++ })
            }
            setSelectedRows([...selectedRows, record]);
            payAmts += record.PAYAMT - record.SMPAY
        }
        setDataCal({ ...dataCal, payCont: payConts, payAmt: payAmts });
        setDataSend({ ...dataSend, money: payAmts });
        form2.setFieldsValue({ money: payAmts, });
    };
    const columns = [
        {
            title: (
                <Checkbox
                    style={{ transform: 'scale(1.5)' }}
                    onChange={onSelectAllChange}
                    indeterminate={selectedRows.length > 0 && selectedRows.length < dataTable.length}
                />
            ),
            dataIndex: 'select',
            key: 'select',
            align: 'center',
            width: "10%",
            render: (text, record) => (
                <>
                    <Checkbox
                        style={{ transform: 'scale(1.5)' }}
                        onChange={() => onSelectChange(record)}
                        checked={selectedRows.find(row => row.keyId === record.keyId)}
                    />
                </>
            ),
        },
        {
            key: 'index',
            title: "ลำดับ",
            dataIndex: "index",
            width: "5%",
            align: 'center',
            render: (text, object, index) => index + 1
        },
        {
            title: "รายละเอียดสัญญา",
            dataIndex: "CONTNO",
            key: 'index',
            align: 'center',
            width: "auto",
            render: (text, record) => (
                <>
                    <div>สาขาสัญญา : <b style={{ fontSize: '16px', color: 'red' }}>{record.LOCAT}</b></div>
                    <div>เลขสัญญา : <b style={{ fontSize: '16px', color: 'red' }}>{record.CONTNO}</b></div>
                </>
            ),
        },
        {
            title: "รายละเอียด",
            dataIndex: "FORCODE",
            key: 'index',
            align: 'center',
            width: "auto",
            render: (text, record) => (
                <>
                    <div>ARCONT : <b style={{ fontSize: '16px', color: 'red' }}>{record.ARCONT}</b></div>
                    <div>CODE : <b style={{ fontSize: '16px', color: 'red' }}>{record.FORCODE}</b></div>
                    <div>ข้อความ : <b style={{ fontSize: '16px', color: 'red' }}>{record.FORDESC}</b></div>
                </>
            ),
        },
        {
            title: "ลูกหนี้อื่นๆ",
            dataIndex: "PAYAMT",
            key: 'index',
            align: 'center',
            width: "auto",
            render: (text, record) => (
                <>
                    <div>วันที่ : <b style={{ fontSize: '16px', color: 'red' }}>{dayFormatOne(record.INPDT)}</b></div>
                    <div>จำนวน : <b style={{ fontSize: '16px', color: 'red' }}>{currencyFormatOne(record.PAYAMT)}</b></div>
                </>
            ),
        },
    ];
    const handleSelectCONTNO = async (value) => {
        console.log(value)
        if (value) {
            setLoading(true);
            setTimeout(() => {
                form2.setFieldsValue({ money: "", });
                setSelectedRows([])
                setCheckContnoNew(true);
                setCheckDataInsert(false);
                setDataSend({ ...dataSend, CONTNO: value, money: 0 })
                setDataCal({ sumCont: 0, sumAmt: 0, payCont: 0, payAmt: 0 });
                setDataTable([]);
                setLoading(false);
            }, 1000);
        }
    }
    const handleCONTNO = (e, number) => {
        setSelectedRows([]);
        setDataTable([]);
        setDataCal({ sumCont: 0, sumAmt: 0, payCont: 0, payAmt: 0 });
        setCheckContnoNew(true);
        setCheckDataInsert(false);
        form2.setFieldsValue({ money: "", });
        if (number === 31 || number === 32) {
            if (number === 31) {
                setDataSend({ ...dataSend, CONTNO: "", textData: e.target.value, money: 0 })
            } else {
                setDataSend({ ...dataSend, CONTNO: "", textData2: e.target.value, money: 0 })
            }
            setFindPAYTYP([])
            form.setFieldsValue({ CONTNO: "", })
        } else {
            if (isClickedTy === 1) {
                console.log("e.target.value", e.target.value)
                setDataSend({ ...dataSend, CONTNO: e.target.value, textData: "", money: 0 })
            } else {
                console.log("e.target.value", e.target.value)
                // setLoading(true);
                // setTimeout(() => {
                setDataSend({ ...dataSend, CONTNO: "", textData: e.target.value, money: 0 })
                setFindPAYTYP([])
                //   setLoading(false);
                // }, 1000);
                form.setFieldsValue({ CONTNO: "", })
            }
        }
    }

    const funcMoney = (value) => {
        console.log("value =:", value)
        if (value) {
            setDataSend({ ...dataSend, money: parseFloat(value) })
        } else {
            setDataSend({ ...dataSend, money: 0 })
        }
    }
    const changePAYCODE = (value) => {
        console.log("value =:", value)
        setDataSend({ ...dataSend, PAYCODE: value })
    }

    const handleSubmit = async () => { // ค้นหา
        console.log(dataSend?.CONTNO, isClickedTy, dataSend.textData)
        setFormKey(prevKey => prevKey + 1);
        setDataRec(null);
        setDataCode({ codeQ: "" })
        form3.setFieldsValue({ codeQ: "" });
        // setReload(1)
        let sumConts = 0;
        let sumAmts = 0;
        setSelectedRows([]);
        setCheckContnoNew(false);
        setCheckDataInsert(false);
        // setDataAll(null);
        setShowTable(false);
        form2.setFieldsValue({ money: "", });
        if (dataSend?.CONTNO !== "") {
            setLoading(true)
            await axios.post(contnoArothr, dataSend) // รายละเอียดลูกหนี้ในสัญญา
                .then(async (res) => {
                    console.log("res", res.data)
                    if (res.status === 200) {
                        setDataTable(res.data)
                        if (res.data) {
                            await res.data.forEach((item) => {
                                sumAmts += item.PAYAMT - item.SMPAY;
                                sumConts++;
                            });
                            setDataCal({ sumCont: sumConts, sumAmt: sumAmts, payCont: 0, payAmt: 0 });
                        }
                    } else {
                        msData(api, "top")
                        setDataCal({ sumCont: 0, sumAmt: 0, payCont: 0, payAmt: 0 });
                        setDataTable([])
                    }
                    form2.setFieldsValue({ money: "", PAYCODE: "01" });
                    setDataSend({ ...dataSend, money: 0, sale: 0, saleFollow: 0, date: currentDateTime, payType: "จ่ายเข้าตารางดิว", PAYCODE: "01", selectPay: 1, MEMO1: "" })
                    setLoading(false)
                })
                .catch((err) => {
                    console.log("err", err)
                    clearData(0)
                    msData(api, "top")
                    setLoading(false)
                })
        } else if (isClickedTy === 2 || isClickedTy === 3 || isClickedTy === 4) {
            if (dataSend.textData !== "") {
                setLoading(true)
                try {
                    const results = await loadDataMainArothr(dataSend.textData, dataSend.textData2, isClickedTy); // เอาสัญญาออกมา
                    if (!results) {
                        msData(api, "top")
                        setFindPAYTYP([])
                    } else {
                        setFindPAYTYP(results)
                    }
                } catch (error) {
                    console.error("Error in loadData: ", error);
                    msData(api, "top")
                    setFindPAYTYP([])
                } finally {
                    setDataCal({ sumCont: 0, sumAmt: 0, payCont: 0, payAmt: 0 });
                    setLoading(false);
                }
            }
        } else {
            setDataCal({ sumCont: 0, sumAmt: 0, payCont: 0, payAmt: 0 });
            msConnt(api, "top")
        }
    }

    const showConfirmClear = () => {
        confirm({
            title: 'คุณต้องการที่ล้างข้อมูลนี้ใช่หรือไม่ ?',
            content: 'กด OK เพื่อยืนยัน',
            onOk() {
                clearData(0)
            },
            onCancel() {
                msCancel(api, "top")
            },
        });
    }
    const clearData = async (value) => {
        setLoading(true);
        console.log("clearData", value)
        if (value > 0) {
            setIsClickedTy(value)
            form.setFieldsValue({ CONTNO: "", textData: "", textData2: "", searchPay: value })
        } else {
            setIsClickedTy(1)
            form.setFieldsValue({ CONTNO: "", textData: "", textData2: "", searchPay: 1 })
        }
        form2.setFieldsValue({ money: "", });
        form3.setFieldsValue({ codeQ: "" });
        setTimeout(() => {
            // setReload(1)
            setDataCode({ codeQ: "" });
            setDataRec(null);
            setCheckContnoNew(false);
            setCheckDataInsert(false);
            setShowTable(false);
            setFindPAYTYP([])
            setDataSend({ textData: "", textData2: "", CONTNO: "", money: 0, sale: 0, saleFollow: 0, date: currentDateTime, payType: "จ่ายเข้าตารางดิว", PAYCODE: "01", selectPay: 1, MEMO1: "", });
            // setDataAll(null);
            setDataCal({ sumCont: 0, sumAmt: 0, payCont: 0, payAmt: 0 });
            setSelectedRows([]);
            setDataTable([])
            setLoading(false);
        }, 1000);
    }

    const printpay = async () => {
        if (dataSend.CONTNO !== "" && dataCode.codeQ !== "") {
            let printData = {}
            let result = {}
            console.log("บิลปกติ")
            setLoading(true)
            printData = { "CONTNO": dataSend.CONTNO, "TMBILL": dataCode.codeQ }
            try {
                result = await loadPrintDataPaymentArothr(printData);
                setDataRec(result)
            } catch (error) {
                console.error("Error in handleInsert: ", error);
            } finally {

            }
            if (result === null) {
                console.log("เกิดข้อผิดพลาด");
                msPrint(api, "top")
                setDataRec(null)
                setLoading(false);
            } else {
                setShowTable(true);
                setTimeout(async () => {
                    setLoading(false);
                    generatePDF();
                }, 1000);
            }
        } else {
            msData(api, "top")
        }
    }

    return (
        <Card style={{ height: '100%' }}>
            <Row style={{ textAlign: 'center' }}>
                <Col span={24}>
                    <Spin spinning={loading} size='large' tip="Loading...">
                        <Col span={24}>
                            <div className='text-center'>
                                <h2>รับอื่นๆ</h2>
                            </div>
                            <div className='text-right'>
                                <Space>
                                    <Button onClick={printpay} type="primary" icon={<PrinterOutlined style={{ fontSize: '16px' }} />}
                                        style={{ backgroundColor: '#5ceb97', borderColor: '#5ceb97' }}
                                    >
                                        พิมพ์ใบเสร็จ</Button>
                                    {/* <Button disabled={!dataAll} type='primary' onClick={showConfirm} >บันทึกข้อมูล</Button> */}
                                </Space>
                            </div>
                        </Col>
                        <Divider />
                        <Row gutter={32} justify={'center'}>
                            <Col span={8}>
                                <Card title="รายละเอียดสัญญา" bordered={false}>
                                    <Form
                                        form={form}
                                        key={formKey}
                                        onFinish={handleSubmit}
                                    >
                                        <Form.Item label="วันที่ชำระ" name="date" style={{ height: '30px', width: '100%' }}>
                                            <DatePicker format={'YYYY-MM-DD'}
                                                disabled
                                                defaultValue={currentDateTime}
                                                style={{ height: '40px', width: '100%' }}
                                                onChange={(e) => {
                                                    if (e) {
                                                        setDataSend({ ...dataSend, date: e.format("YYYY-MM-DDTHH:mm:ssZ") })
                                                    }
                                                }} />
                                        </Form.Item>
                                        <Form.Item label="ค้นหาจาก" name="searchPay">
                                            <Select
                                                style={{ width: '100%', height: '40px' }}
                                                defaultValue={isClickedTy}
                                                onChange={clearData}
                                                options={optionsSearchPay}
                                            />
                                        </Form.Item>
                                        {
                                            isClickedTy === 1 ?
                                                <Form.Item label="เลขสัญญา" name="CONTNO" style={{ height: '30px', width: '100%' }}>
                                                    <Input onChange={(e) => handleCONTNO(e, 0)}></Input>
                                                </Form.Item>
                                                :
                                                <>
                                                    {
                                                        isClickedTy === 3 ?
                                                            <>
                                                                <Form.Item label="ชื่อ-สกุล" name="textData" style={{ height: '30px', width: '100%' }}
                                                                    rules={[{ required: true, message: 'กรุณากรอกชื่อ-สกุล !' },]}
                                                                >
                                                                    <Input onChange={(e) => handleCONTNO(e, 31)}></Input>
                                                                </Form.Item>
                                                                <Form.Item label="" name="snam" style={{ height: '30px', width: '100%' }}>
                                                                    <b style={{ fontSize: '14px', color: 'red' }}>{"***ไม่จำเป็นต้องใส่คำนำหน้าชื่อ***"}</b>
                                                                </Form.Item>
                                                            </>
                                                            :
                                                            <Form.Item label="ข้อความ" name="textData" style={{ height: '30px', width: '100%' }}>
                                                                <Input onChange={(e) => handleCONTNO(e, 0)}></Input>
                                                            </Form.Item>
                                                    }
                                                    {
                                                        findPAYTYP?.length > 0 ?
                                                            <Form.Item label="เลือกสัญญา" name="CONTNO">
                                                                <Select
                                                                    showSearch
                                                                    style={{ width: '100%', height: '40px' }}
                                                                    onChange={handleSelectCONTNO}
                                                                    optionFilterProp="children"
                                                                    placeholder="Search to Select"
                                                                    filterOption={(inputValue, option) =>
                                                                        option.children.join('').toLowerCase().includes(inputValue.toLowerCase())
                                                                    }
                                                                >
                                                                    {findPAYTYP?.map((e) => {
                                                                        return (
                                                                            <Select.Option key={e.keyId} value={e.CONTNO}>
                                                                                {e.CONTNO} (ทะเบียน {e.REGNO}) (ชื่อ {e.NAME1} {e.NAME2})
                                                                                {/* ({e.PAYDESC}) */}
                                                                            </Select.Option>
                                                                        )
                                                                    })}
                                                                </Select>
                                                            </Form.Item>
                                                            : null
                                                    }
                                                </>
                                        }
                                        <Space>
                                            <Form.Item label="" style={{ height: '30px', width: '100%', margin: '10px' }}>
                                                <Button disabled={loading} type='primary' htmlType="submit" >ค้นหา</Button>
                                            </Form.Item>
                                            <Form.Item label="" style={{ height: '30px', width: '100%', margin: '10px' }}>
                                                <Button disabled={loading} onClick={showConfirmClear} >Clear</Button>
                                            </Form.Item>
                                        </Space>
                                    </Form >
                                </Card>
                            </Col>
                            <Col span={8}>
                                <Card title="รับเงิน" bordered={false}>
                                    <Form form={form2} key={formKey2} onFinish={showConfirm}>
                                        <Form.Item label="ประเภท" name="PAYCODE">
                                            <Select
                                                showSearch
                                                style={{ width: '100%', height: '40px' }}
                                                onChange={changePAYCODE}
                                                optionFilterProp="children"
                                                placeholder="Search to Select"
                                                filterOption={(inputValue, option) =>
                                                    option.children.join('').toLowerCase().includes(inputValue.toLowerCase())
                                                }
                                            >
                                                {getPAYCODE?.map((e) => {
                                                    return (
                                                        <Select.Option key={e.keyId} value={e.PAYCODE}>
                                                            {e.PAYCODE} ({e.PAYDESC})
                                                        </Select.Option>
                                                    )
                                                })}
                                            </Select>
                                        </Form.Item>
                                        <Form.Item label="จำนวนเงิน" name="money" rules={[{ required: true, message: 'กรุณากรอกจำนวนเงิน !' },]}>
                                            <InputNumber
                                                disabled
                                                addonAfter="บาท"
                                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                                size="large"
                                                placeholder="กรอกจำนวนเงิน"
                                                style={{ width: '100%', color: 'black' }}
                                                onChange={(value) => funcMoney(value)}
                                            />
                                        </Form.Item>
                                        <Divider />
                                        <Form.Item label="">
                                            {
                                                <Button disabled={loading} type='primary' htmlType="submit" >บันทึกข้อมูล</Button>
                                            }
                                        </Form.Item>
                                    </Form >
                                </Card>
                            </Col>
                            <Col span={8}>
                                <Card title="รายละเอียดที่คำนวณได้" bordered={false}>
                                    <Form form={form3} key={formKey3}>
                                        <Row gutter={32} justify={"center"}>
                                            <Col span={24}>
                                                <Form.Item label="เลขที่บิล" name="codeQ" style={{ margin: 5 }}>
                                                    <Input
                                                        variant="borderless"
                                                        disabled
                                                        size="large"
                                                        value={dataCode.codeQ}
                                                        style={{ width: '100%', color: 'black' }}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Divider />
                                            <Col span={16}>
                                                <Form.Item label="เลขที่สัญญา" style={{ margin: 0 }}>
                                                    <b style={{ color: 'blue' }}>{dataSend?.CONTNO}</b>
                                                </Form.Item>
                                                <Form.Item label="รับเงิน" name="money" style={{ margin: 0 }}>
                                                    <b>{currencyFormatOne(dataSend?.money)}</b>
                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}> บาท</span>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Divider />
                                        <Card style={{ borderColor: '#000000', margin: '5px' }}>
                                            <Row gutter={32} justify={"center"} align="middle">
                                                <Col span={16}>
                                                    <Form.Item label="รายการทั้งหมด" style={{ margin: 0 }}>
                                                        <b style={{ fontSize: '14px', color: 'red' }}>{dataCal.sumCont}</b>
                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}> รายการ</span>
                                                    </Form.Item>
                                                    <Form.Item label="ยอดทั้งหมด" style={{ margin: 0 }}>
                                                        <b style={{ fontSize: '14px', color: 'red' }}>{currencyFormatOne(dataCal.sumAmt)}</b>
                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}> บาท</span>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Card>
                                        <Card style={{ borderColor: '#000000', margin: '5px' }}>
                                            <Row gutter={32} justify={"center"} align="middle">
                                                <Col span={16}>
                                                    <Form.Item label="จำนวนที่เลือก" style={{ margin: 0 }}>
                                                        <b style={{ fontSize: '14px', color: 'red' }}>{dataCal.payCont}</b>
                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}> รายการ</span>
                                                    </Form.Item>
                                                    <Form.Item label="ยอดทั้งหมด" style={{ margin: 0 }}>
                                                        <b style={{ fontSize: '14px', color: 'red' }}>{currencyFormatOne(dataCal.payAmt)}</b>
                                                        <span style={{ display: 'inline-block', marginLeft: '10px' }}> บาท</span>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Form >
                                </Card>
                            </Col>
                        </Row>
                        <Divider />
                        {
                            dataTable?.length > 0 ?
                                <Table
                                    rowKey={(record) => record.keyId}
                                    dataSource={dataTable}
                                    columns={columns}
                                    scroll={{
                                        x: 1500,
                                        y: 400,
                                    }}
                                    style={{ padding: 20 }}
                                >
                                </Table>
                                : <>*** ไม่พบข้อมูล ***<Divider /></>
                        }
                    </Spin>
                </Col>
            </Row >
            {contextHolder}
            {
                showTable &&
                <div className="print-only" ref={componentPDF} style={{ textAlign: 'center' }}>
                    <Payment
                        dataPrint={dataRec}
                    />
                </div>
            }
        </Card >
    )
}
