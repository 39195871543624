import React, { useState, useEffect } from "react";
import { Button, Col, Row, Table, Divider, Space, Card, Input, Tag, notification, Spin, Radio } from 'antd';
import dayjs from "dayjs";
import axios from "axios";
import { mainRateZeroBoss } from "../file_mid/all_api";
import Rate from "./modal_rate/Rate";
// const { Text } = Typography;
// const { Countdown } = Statistic;

export default function Main_rate() {
  const [api, contextHolder] = notification.useNotification();
  // const currentDateTime = dayjs()
  // const currentDateDay2 = dayjs()
  // const showDay1 = dayjs("2023-01-01")
  // const [dataPost, setDataPost] = useState({ sec: 0, days1: dayjs("2023-01-01"), days2: currentDateDay2, approvalStatus: '17' })
  const token = localStorage.getItem('token');
  // const user = localStorage.getItem('username');
  // const branch = localStorage.getItem('branch');
  const [editData, setEditData] = useState();
  const [modalCarRate, setModalCarRate] = useState(false); // Edit Car Rate
  const [dataDes, setDataDes] = useState("load");
  const [cData, setCData] = useState({ ApBoss: 0, NoApBoss: 0, AllCase: 0 });
  const [axiosData, setAxiosData] = useState([]); // Table
  const [arrayTable, setArrayTable] = useState(); // Array Table
  const [keyWord, setKeyWord] = useState("noAp");
  // const [statusNoti, setStatusNoti] = useState("0");
  const [loading, setLoading] = useState(false);
  // const [testPage, setTestPage] = useState();
  // const [ssPage, setSsPage] = useState({ numberPage: 1, pageSize: 10 });
  // const [currentPage, setCurrentPage] = useState(1)
  const [query, setQuery] = useState("");
  const options = [
    {
      value: 'all',
      label: `ทั้งหมด (${cData.AllCase}) เคส`,
    },
    {
      value: 'noAp',
      label: `ยังไม่ได้คอนเฟิร์ม (${cData.NoApBoss}) เคส`,
    },
    {
      value: 'ap',
      label: `คอนเฟิร์มแล้ว (${cData.ApBoss}) เคส`,
    },
  ];
  useEffect(() => {
    console.log(dataDes)
    if (dataDes === "load") {
      loadData();
    }
  }, [dataDes])

  const funcRadio = async (e) => {
    setKeyWord(e.target.value)
    if (e.target.value === "ap") {
      const greaterThanTen = axiosData.filter((item) => item.flagApBoss === true);
      setArrayTable(greaterThanTen);
    } else if (e.target.value === "noAp") {
      const greaterThanTen = axiosData.filter((item) => item.flagApBoss === false);
      setArrayTable(greaterThanTen);
    } else {
      setArrayTable(axiosData);
    }
  }
  const search = async (data) => {
    setQuery(data.target.value);
    if (keyWord === "ap") {
      const greaterThanTen = axiosData.filter(
        (item) => item.flagApBoss === true &&
          (item.carBrand.toLowerCase().includes(data.target.value) ||
            item.carModel.toLowerCase().includes(data.target.value) ||
            item.carBaab.toLowerCase().includes(data.target.value))
      );
      setArrayTable(greaterThanTen);
    } else if (keyWord === "noAp") {
      const greaterThanTen = axiosData.filter(
        (item) => item.flagApBoss === false &&
          (item.carBrand.toLowerCase().includes(data.target.value) ||
            item.carModel.toLowerCase().includes(data.target.value) ||
            item.carBaab.toLowerCase().includes(data.target.value))
      );
      setArrayTable(greaterThanTen);
    } else {
      const greaterThanTen = axiosData.filter(
        (item) =>
          item.carBrand.toLowerCase().includes(data.target.value) || item.carModel.toLowerCase().includes(data.target.value) ||
          item.carBaab.toLowerCase().includes(data.target.value)
      );
      setArrayTable(greaterThanTen);
    }
  };

  const loadData = async (data) => {
    setLoading(true)
    let mainData
    const tk = JSON.parse(token)
    const headers = {
      "Authorization": `Bearer ${tk}`,
      "Menu": JSON.stringify("8")
    }
    // if (query !== "" && data !== "stop") {
    //   mainData = { reviewedBy: user, approvalStatus: dataPost.approvalStatus, numberPage: ssPage?.numberPage, pageSize: ssPage?.pageSize, keyword: query, day1: dataPost.days1, day2: dataPost.days2 }
    // } else {
    //   if (data === "stop") {
    //     mainData = { reviewedBy: user, approvalStatus: 17, numberPage: ssPage?.numberPage, pageSize: ssPage?.pageSize, day1: dataPost.days1, day2: dataPost.days2 }
    //   } else {
    //     mainData = { reviewedBy: user, approvalStatus: dataPost.approvalStatus, numberPage: ssPage?.numberPage, pageSize: ssPage?.pageSize, day1: dataPost.days1, day2: dataPost.days2 }
    //   }
    // }
    await axios.post(mainRateZeroBoss, mainData, { headers: headers })
      .then(async (res) => {
        console.log("res.data", res.data)
        if (res.data?.resMainRateZeroBoss) {
          const greaterThanTen = res.data.resMainRateZeroBoss.filter((item) => item.flagApBoss === false);
          setArrayTable(greaterThanTen);
          setAxiosData(res.data.resMainRateZeroBoss)
          setCData({ ApBoss: res.data.apBoss, NoApBoss: res.data.noApBoss, AllCase: res.data.allCase })
        } else {
          setArrayTable([])
          setAxiosData([])
          setCData({ ApBoss: 0, NoApBoss: 0, AllCase: 0 });
        }
        setQuery("");
        setKeyWord("noAp");
        setLoading(false)
      }).catch((err) => {
        console.log(err)
        setQuery("");
        setKeyWord("noAp");
        setCData({ ApBoss: 0, NoApBoss: 0, AllCase: 0 });
        setLoading(false)
      })
  }

  const columns = [
    {
      title: "ลำดับ",
      dataIndex: "index",
      key: 'index',
      width: "5%",
      align: 'center',
      render: (text, object, index) => index + 1
    },
    {
      title: "สถานะเคส",
      dataIndex: "",
      width: "12%",
      align: 'center',
      render: (text, record) => (
        <>
          {
            record.flagApBoss ?
              <> <b style={{ fontSize: '14px', color: 'red' }}>** คอนเฟิร์มแล้ว **</b></>
              :
              <> <b style={{ fontSize: '14px', color: 'red' }}>** ยังไม่ได้คอนเฟิร์ม **</b></>
          }
        </>
      ),
    },
    {
      key: 'carInfo',
      title: "รายละเอียด",
      dataIndex: "carPlateNumber",
      width: "25%",
      align: 'center',
      render: (text, record) => (
        <>
          <div>{record.snam}{record.firstName} {record.lastName}</div>
          {/* <div>
            {
              record.car === "car" ? */}
          <div>
            <div>{record?.carBrand} {record?.carModel} {record.carBaab}</div>
            <div>ปีรถ {parseInt(record.carYear) + 543} เลขทะเบียน {record.carPlateNumber}</div>
          </div>
          {/* :
                <div>
                  <div>{record.carBrand} {record.carModel} ตารางวา {record.carYear} เลขโฉนด {record.carPlateNumber}</div>
                  <div>เสนอโดย <Space style={{ color: "red" }}><b>{record.proposalBy}</b></Space></div>
                  {
                    record.reviewedBy !== null ?
                      <>
                        <div>ผ่านการวิเคราะห์โดย <Space style={{ color: "red" }}><b>{record.reviewedBy}</b></Space></div>
                        <div>เวลาผ่านการวิเคราะห์ <Space style={{ color: "red" }}><b>{dayjs(record.acceptPassDate).format("DD-MM-YYYY HH:mm:ss")}</b></Space></div>
                      </>
                      : null
                  }
                </div>
            }
          </div > */}
        </>
      )
    },
    {
      title: "เรท",
      dataIndex: "carPrice",
      align: 'center',
      width: "10%",
      render: (text, record) => (
        <Space size="middle">
          {
            <Space>
              <>{currencyFormatOne(record.carPrice)}</>
            </Space>
          }
        </Space>
      ),
    },
    {
      title: "วันที่เสนอเคส",
      dataIndex: "carDateStatus",
      align: 'center',
      width: "10%",
      render: (text, record) => (
        <>{dayjs(record.carDateStatus).format("DD-MM-YYYY HH:mm")}</>
      ),

    },
    {
      title: "วันที่ขออนุมัติเรท",
      dataIndex: "dateRateZero",
      width: "10%",
      align: 'center',
      render: (text, record) => (
        <>{dayjs(record.dateRateZero).format("DD-MM-YYYY HH:mm")}</>
      ),
    },
    {
      key: 'approvalStatus',
      title: "สถานะ",
      dataIndex: "approvalStatus",
      width: "10%",
      align: 'center',
      render: (text, record) => {
        let color
        if (record.approvalStatus === 23 || record.approvalStatusRe === 23) {
          color = "red"
        } else {
          color = "orange"
        }
        return (
          <>
            {
              record.approvalStatus > 0 ?
                <Tag color={color}>
                  {record.approvalStatusName}
                </Tag>
                :
                <Tag color={color}>
                  {record.approvalStatusReName}
                </Tag>
            }
          </>
        );
      },
    },
    {
      title: "Action",
      align: 'center',
      width: "12.5%",
      render: (record) => (
        <Space size="middle">
          {
            record.flagRateZero && !record.flagApBoss ?
              <Button style={{ backgroundColor: "blue", color: 'white' }}
                onClick={() => {
                  setModalCarRate(true)
                  setEditData(record)
                }}>คอนเฟิร์มเรทรถ</Button>
              :
              record.flagRateZero && record.flagApBoss ?
                <Button style={{ backgroundColor: "green", color: 'white' }}
                  onClick={() => {
                    setModalCarRate(true)
                    setEditData(record)
                  }}>แก้ไขเรทรถ</Button>
                : null
          }
        </Space>
      ),
    },
  ];

  const currencyFormatOne = (amount) => {
    return Number(amount)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, '$&,')
  }

  return (
    <>
      <Card
        bordered={false}
        style={{
          width: 'auto',
        }}
      >
        <Row style={{ textAlign: 'center' }}>
          <Col span={24}>
            <Spin spinning={loading} size='large' tip=" Loading... ">
              <div className='text-center'>
                <h2>คอนเฟิร์มเรทรถ</h2>
              </div>
              <Row gutter={32} justify={'center'} style={{ margin: 10 }}>
                <Col className='gutter-row' span={24} >
                  <aside>
                    <div>
                      <Radio.Group
                        block
                        value={keyWord}
                        onChange={(e) => funcRadio(e)}
                        options={options}
                        optionType="button"
                        buttonStyle="solid"
                      />
                    </div>
                  </aside>
                </Col>
              </Row>
              <Row gutter={32} style={{ margin: 10 }}>
                <Col className='gutter-row' span={24} style={{ textAlign: 'right' }}>
                  <Input.Search
                    style={{ width: '300px' }}
                    placeholder="ค้นหา...."
                    onChange={search}
                    name="search"
                    id="search"
                    value={query}
                    size="large"
                  />
                </Col>
              </Row>
              <Divider style={{ margin: 5 }} />
              <Row gutter={32}>
                <Col span={24}>
                  <Table
                    // pagination={false}
                    rowKey={(record) => record.keyId}
                    dataSource={arrayTable}
                    columns={columns}
                    scroll={{
                      x: 1500,
                      y: 400,
                    }}
                    style={{ padding: 20 }}
                  >
                  </Table>
                  <Divider />
                  {/* <Pagination
                    current={currentPage}
                    onChange={onChangePagination}
                    onShowSizeChange={onChangePagination}
                    defaultPageSize={10}
                    defaultCurrent={1}
                    total={testPage?.totalCount}
                  /> */}
                  <Divider />
                </Col>
              </Row>
            </Spin>
          </Col>
        </Row>
      </Card>
      {
        modalCarRate ?
          <Rate
            open={modalCarRate}
            close={setModalCarRate}
            dataMain={editData}
            des={setDataDes}
          />
          : null
      }
      {contextHolder}
    </>
  )
}
