import { Card, Modal, Spin, Col, Image, Divider, Space, Button, notification, Row, Form, message, Input } from "antd";
import React, { useEffect, useState, useRef } from "react";
import { DownloadOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import dayjs from "dayjs";
import axios from "axios";
import { aunsendpg, cancelcontnopgre, loadDetailAunSendPG } from "../../../file_mid/all_api";
import html2canvas from "html2canvas";

export default function YellowCardApproval({ closeModal, open, close,data, notSave, onChangeStatus, status, selectedValue, dataTonew, dataDB2, carsApproveve }) {
  const [api, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();
  const token = localStorage.getItem("token");
  const user = localStorage.getItem("username");
  const { confirm } = Modal;
  const [loading, setLoading] = useState(false);
  const elementRef = useRef(null);
  // const [carsApproveve, setCarsApproveve] = useState();
  // const [dataDB2, setDataDB2] = useState();
  const [sendData, setSendData] = useState({
    id: 0,
    contno: "",
    checkRe: false,
    checkPub: false,
    checkChange: false,
    // status: "",
    mk: selectedValue === "1" ? carsApproveve.SALCOD : carsApproveve?.offerBy,
    // checkDate: "",
    checkBy: user,
    installment: null, //ผ่อน
    pgLoanTerm: null,
  });
  const [outstandingdebt, setOutstandingdebt] = useState(); // outstandingdebt มูลหนี้คงเหลือ
  const [deductdiscount, setDeductdiscount] = useState(); // deductdiscount หักส่วนลด
  const [remaining, setRemaining] = useState(); // remaining  คงเหลือ
  const [pay, setPay] = useState(); // pay   จ่ายเงิน
  const [Excellent, setExcellent] = useState(); // Excellent   ยอดจัด
  const [checkbutton, setCheckButton] = useState(0); // relax   ผ่อน
  const [dataNew, setDataNew] = useState();
  const [notesCarsApprove, setNotesCarsApprove] = useState();
  const [notes, setNotes] = useState();
  // const [carsApproveve, setCarsApproveve] = useState();
  // const [dataDB2, setDataDB2] = useState();
  var noteRe = notesCarsApprove?.length;
  var lastIndexRe = noteRe - 1;

  // console.log("notesCarsApprove", notesCarsApprove)
  // console.log("carsApproveve111111", carsApproveve)
  // console.log("dataNew", dataNew)
  console.log("dataTonew", dataTonew)
  // console.log("notes", notes)
  // console.log("yellow", yellow)
  // console.log("lastIndexRe", lastIndexRe)
  // console.log("มูลหนี้คงเหลือ setOutstandingdebt", outstandingdebt)
  // console.log("หักส่วนลด setDeductdiscount", deductdiscount)
  // console.log("คงเหลือ setRemaining ", remaining)
  // console.log("จ่ายเงิน setPay", pay)
  // console.log("ยอดจัด setExcellent", Excellent)



  useEffect(() => {
    loadDataReAun()

    if (carsApproveve?.id > 0) {
      setOutstandingdebt(
        carsApproveve?.neekong ? (~~carsApproveve?.neekong + ~~carsApproveve?.OTHR + (data?.DATA_TYPE === "rpsl" ? ~~carsApproveve?.followPay : ~~carsApproveve?.beeypup)) : null
      );
      setDeductdiscount(
        carsApproveve?.neekong
          ? (~~carsApproveve?.neekong + ~~carsApproveve?.OTHR + ~~carsApproveve?.beeypup) - (~~carsApproveve?.approvedLoanAmount + ~~carsApproveve?.newDamt + ~~carsApproveve?.followPay)
          : null
      );
      setRemaining(
        carsApproveve?.neekong ? ~~outstandingdebt - ~~deductdiscount : null
      );
      setPay(
        carsApproveve?.newDamt
          ? ~~carsApproveve?.newDamt + ~~carsApproveve?.followPay
          : null
      );
      setExcellent(
        carsApproveve?.approvedLoanAmount
          ? ~~carsApproveve?.approvedLoanAmount
          : null
      );
      {
        /* 
                มูลหนี้ balance
                หักส่วนลด (balance-(approvedLoanAmount+(newDamt+followPay))
                คงเหลือ  มูลหนี้ - หักส่วนลด
                จ่ายเงิน (newDamt+followPay)
                ยอดจัด approvedLoanAmount
            */
      }
    }
  }, [notSave, outstandingdebt]);


  const loadDataReAun = async () => {
    setLoading(true);
    axios
      .post(loadDetailAunSendPG, { contno: carsApproveve?.CONTNO })
      .then((res) => {
        if (res.status === 200) {
          console.log("res.dataNewPageAun", res.data);
          setDataNew(res?.data?.carsApproveve);
          setNotesCarsApprove(res?.data?.notesCarsApprove);
          // setDataDB2(res.data?.dataDB2);
          // calData(res?.data?.carsApproveve);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
      });
  };

  const successSend = (placement) => {
    api.success({
      message: `บันทึกสำเร็จ`,
      placement,
    });
  };

  const errorSend = (placement) => {
    api.error({
      message: `บันทึกไม่สำเร็จ`,
      placement,
    });
  };

  const handleSubmit = async () => {
    console.log('GG')
    setLoading(true);
    console.log("success");
    const dataTosendNew = {
      id: dataNew?.id,
      pgLoanTerm: dataNew?.pgLoanTerm !== null ? dataNew?.pgLoanTerm : dataTonew?.pgLoanTerm,
      installment: dataNew?.installment !== null ? dataNew?.installment : dataTonew?.installment,
      interestRate: dataTonew?.interestRate,
      productTypeCar: dataTonew?.productTypeCar,
      productType: dataTonew?.productType,
      note: {
        note: dataTonew?.Memo,
        noteBy: user,
        status: "4",
      },
    };
    console.log("dataTosendNew", dataTosendNew)
    const tk = JSON.parse(token);
    const url = aunsendpg;
    const headers = {
      Authorization: `Bearer ${tk}`,
      Menu: JSON.stringify("25"),
    };
    await axios.post(url, dataTosendNew, { headers: headers })
      .then((res) => {
        if (res) {
          // console.log("123", res.data);
          setCheckButton(1);
          onChangeStatus({ ...dataTonew, approvalStatus: 4 }, status)
          message.success("ยืนยันการส่งข้อมูลสำเร็จ")
          setLoading(false);
          setTimeout(() => (
            closeModal(false)
          ), 500);
        }
      })
      .catch((err) => {
        message.error("เกิดข้อผิดพลาด", err);
        setLoading(false);
      });
  };

  const handleCancel = async () => {
    let sendData2 = { ...sendData, checkDate: dayjs() };
    await axios
      .delete(cancelcontnopgre, { data: sendData2 })
      .then((res) => {
        if (res.status === 200) {
          // console.log("res.data", res.data)
          successSend("top");
          setTimeout(() => {
            handleClose();
            setLoading(false);
          }, 2000);
        }
      })
      .catch((err) => {
        console.log("err", err);
        errorSend("top");
        setLoading(false);
      });
  };
  const currencyFormat = (amount) => {
    if (amount) {
      return Number(amount)
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,");
    } else {
      return "-";
    }
  };

  const handleClose = () => {
    close(false);
  };

  const htmlToImageConvert = () => {
    console.log(elementRef);
    html2canvas(elementRef.current, { cacheBust: false })
      .then((canvas) => {
        canvas.toBlob((blob) => {
          console.log(blob);
          if (blob) {
            const file = new File(
              [blob],
              `ใบเเหลืองก่อนส่ง เลขที่สัญญาที่: ${carsApproveve?.CONTNO} ${dayjs()
                .add(543, "year")
                .format("DD/MM/YYYY")}.png`,
              { type: "image/png" }
            );

            const link = document.createElement("a");
            link.href = URL.createObjectURL(file);
            link.download = file.name;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            message.success(`บันทึกรูปภาพสำเร็จ ${carsApproveve?.CONTNO}`);
          } else {
            message.error(
              `ไม่สามารถบันทึกรูปภาพได้โปรดเข้าออกใหม่ ${carsApproveve?.CONTNO}`
            );
          }
        }, "image/png");
      })
      .catch((err) => {
        console.log("เกิดข้อผิดพลาด ", err);
        message.error("ยกเลิกการบันทึกภาพ");
      });
  };

  const showConfirmSave = () => {
    confirm({
      title: "บันทึกรูปภาพ",
      icon: <ExclamationCircleFilled />,
      content: "คุณต้องการบันทึกรูปภาพใช่หรือไม่",
      okText: "บันทึก",
      cancelText: "ยกเลิก",
      onOk() {
        htmlToImageConvert();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  return (
    <>
      <Modal
        title="ใบอนุมัติยอด"
        open={open}
        onCancel={handleClose}
        width={850}
        footer={[null]}
      >
        <Card>
          <Row style={{ justifyContent: "center" }}>
            <Col style={{ textAlign: 'center' }} onClick={showConfirmSave} className="cursor">
              <div className="center" style={{ textAlign: 'center', color: '#ffffff', background: '#003a8c' }}>
                <span>
                  <b>
                    ดาวโหลดรูปภาพ
                  </b>
                </span>
                <DownloadOutlined
                  style={{ fontSize: "20px" }}
                />
              </div>
            </Col>
          </Row>
          <Spin spinning={loading} size="large" tip="Loading...">
            <div >
              <Form
                form={form}
                style={{ textAlign: "center" }}
                onFinish={handleSubmit}
                labelCol={{
                  span: 8,
                }}
                wrapperCol={{
                  span: 16,
                }}
              >
                <Row justify={'center'}>
                  <Col
                    span={24}
                    style={{
                      marginTop: 10,
                      marginBottom: 10,
                      textAlign: "left",
                      backgroundColor: "#ffad01",
                    }}
                    className="center"
                    ref={elementRef}
                  >
                    <Col span={24} style={{ textAlign: "center" }} >
                      <Image
                        width={200}
                        src={`${process.env.PUBLIC_URL}/logo.jpg`}
                        alt="My Image"
                      />
                    </Col>
                    <Divider style={{ margin: 8 }} />
                    <aside>
                      <div>
                        <span>
                          วันที่ :{" "}
                          <b>
                            {carsApproveve?.approvalDate
                              ? dayjs(carsApproveve?.approvalDate).format(
                                "DD-MM-YYYY"
                              )
                              : "-"}
                          </b>
                        </span>
                      </div>
                      <div>
                        <span>
                          เลขที่สัญญา :
                          <b>
                            {carsApproveve?.CONTNO ? carsApproveve?.CONTNO : null}
                          </b>
                        </span>
                      </div>
                      <div>
                        <span>
                          {dataDB2?.PRODUCTS === "car" ? (
                            <>
                              {" "}
                              รถ :
                              <b>
                                {" "}
                                {dataDB2?.BAAB +
                                  "  " +
                                  dataDB2?.MODEL +
                                  "  " +
                                  dataDB2?.TYPE}
                              </b>
                            </>
                          ) : (
                            <></>
                          )}
                        </span>
                      </div>
                      <div>
                        <span>
                          {" "}
                          {dataDB2?.PRODUCTS === "car" ? (
                            <>
                              {" "}
                              ทะเบียน : <b>{dataDB2?.REGNO}</b>
                            </>
                          ) : (
                            <></>
                          )}
                        </span>
                      </div>
                      <div>
                        <span>
                          ชื่อผู้เช่าซื้อ :{" "}
                          <b>
                            {dataDB2?.SNAM +
                              " " +
                              dataDB2?.NAME1 +
                              " " +
                              dataDB2?.NAME2}
                          </b>
                        </span>
                      </div>
                      <div>
                        <span>
                          มูลหนี้เช่าซื้อคงเหลือ :{" "}
                          <b>
                            {currencyFormat(outstandingdebt) + " บาท"}

                          </b>
                        </span>
                      </div>
                      <div>
                        <span>
                          หักส่วนลด :{" "}
                          <b>
                            {currencyFormat(deductdiscount) + " บาท"}

                          </b>
                        </span>
                      </div>
                      <div>
                        <span>
                          คงเหลือ :{" "}
                          <b>

                            {currencyFormat(remaining) + " บาท"}
                          </b>
                        </span>
                      </div>
                      <div>
                        <span>
                          จ่ายเงิน :
                          <b>
                            {currencyFormat(pay) + " บาท"}

                          </b>
                        </span>
                      </div>
                      <div>
                        <span>
                          ยอดจัด :{" "}
                          <b>
                            {currencyFormat(Excellent) + " บาท"}

                          </b>
                        </span>
                      </div>
                      <div>
                        <span>
                          จำนวนงวด :{" "}
                          <b>
                            {notSave === "5" ? dataTonew?.pgLoanTerm : dataNew?.pgLoanTerm + ' งวด'}
                          </b>
                        </span>
                      </div>
                      <div>
                        <span>
                          ผ่อน :{" "}
                          <b>
                            {/* apMonthlyPayment จำนวนงวด ชื่อตัวแปรเอาไว้โชว์*/}
                            {/* installment จำนวนงวด ชื่อตัวแปรในฐานข้อมูล*/}

                            {notSave === "5" ?
                              <>
                                {currencyFormat(dataTonew?.installment) + ' X ' + dataTonew?.pgLoanTerm + ' งวด ' + '  (รวมภาษีมูลค่าเพิ่ม)  '}
                              </>
                              :
                              <>
                                {currencyFormat(dataNew?.installment) + ' X ' + dataNew?.pgLoanTerm + ' งวด ' + '  (รวมภาษีมูลค่าเพิ่ม)  '}
                              </>
                            }
                          </b>
                        </span>
                      </div>
                    </aside>
                  </Col>
                </Row>
                {carsApproveve?.pgLoanTerm !== null && carsApproveve?.installment !== null ?
                  <>
                    <Row>
                      <Col span={22}>
                        <Form.Item
                          label="ช่องใส่ข้อมูลหมายเหตุ"
                          name="notes"
                          rules={[
                            {
                              required: true,
                              message: "กรุณากรอกข้อมูล !",
                            },
                          ]}
                        >
                          <Input
                            style={{
                              width: "100%",
                              padding: "10px",
                            }}
                            placeholder="กรอกข้อมูลหมายเหตุ"
                            type="text"
                            name="Memo"

                            onChange={(e) =>
                              setNotes({
                                ...notes,
                                Memo: e.target.value,
                              })
                            }
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </>
                  :
                  null
                }

                <Row justify={"center"}>
                  <Space>
                    <Button
                      type="primary"
                      onClick={handleClose}
                      style={{ background: '#8c8c8c' }}
                    >
                      ย้อนกลับ
                    </Button>
                    {checkbutton === 0 ? (
                      <>
                        <Button
                          type="primary"
                          htmlType="submit"
                          style={{ backgroundColor: "green" }}
                        >
                          ส่งเคส
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          type="primary"
                          disabled
                          htmlType="submit"
                          style={{ backgroundColor: "green" }}
                        >
                          ส่งเคส
                        </Button>
                      </>
                    )}
                  </Space>
                </Row>
              </Form>
            </div>

          </Spin>
        </Card>
      </Modal>
    </>
  );
}
