import React, { useRef, useState, useEffect } from "react";
import { useReactToPrint } from "react-to-print";
import { Button } from "antd";
import Payment from "./PaymentAuto";
import PaymentJBF from "./PaymentAutoJBF";
function SearchPDF({ tableSearch, userPrint, dataType }) {
  //PDF
  const [triggerPDF, setTriggerPDF] = useState(false);
  const conponentPDF = useRef();
  const generatePDF = useReactToPrint({
    content: () => conponentPDF.current,
    documentTitle: "ใบรับเงินสัญญา ",
  });

  const handlePDF = () => {
    setTriggerPDF(true);
  };
  //โหลด PDF
  useEffect(() => {
    if (triggerPDF) {
      generatePDF();
      setTriggerPDF(false); // Reset the trigger
    }
  }, [triggerPDF, generatePDF]);

  return (
    <>
      <Button type="primary" onClick={() => handlePDF()} danger>
        พิมพ์
      </Button>
      <div className="print-only" ref={conponentPDF} justify={"center"}>
        {tableSearch && tableSearch.length > 0 ? (
          dataType === "JBF" ? (
            <PaymentJBF tableSearch={tableSearch} userPrint={userPrint} />
          ) : (
            <Payment tableSearch={tableSearch} userPrint={userPrint} />
          )
        ) : (
          []
        )}
      </div>
    </>
  );
}
export default SearchPDF;
