import React, { useState, useEffect } from 'react'
import { Form, Modal, Row, Col, Spin, Card, Button, Tag, Divider, notification, Table, Space, message } from 'antd'
import dayjs from 'dayjs';
import axios from "axios";
import { currencyFormatOne, msConnt, msError, msOK, msUnCancel } from "../file_mid/allFormat";
import { detailArothrCancelpay, updateArothrCancelpay } from "../file_mid/all_api";

export default function ModelCancelOther({ open, close, dataFromTable, closeload }) {
    const { confirm } = Modal
    const token = localStorage.getItem('token')
    const nicknameSalcod = localStorage.getItem('nicknameSalcod')
    const [api, contextHolder] = notification.useNotification();
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false);
    const [dataAll, setDataAll] = useState(null);
    const [dataTable, setDataTable] = useState([]);
    const columnsClAr = [
        {
            title: "เลขบิล",
            dataIndex: "TMBILL",
            key: 'index',
            align: 'center',
            width: "15%",
            render: (text, record) => (
                <div>
                    <div>{record.TMBILLCHQTRAN}</div>
                    <div>วันที่ใบรับ : {dayjs(record.INPDATE).format('DD-MM-YYYY')}</div>
                    <div>สาขาที่รับชำระ : {record.LOCATRECV}</div>
                </div>
            ),
        },
        {
            title: "สัญญา",
            dataIndex: "CONTNO",
            key: 'index',
            align: 'center',
            width: "8%",
            render: (text, record) => (
                <div>
                    <div>{record.REFDOCNO}</div>
                    <div>{record.CONTNOCHQTRAN}</div>
                </div>
            ),
        },
        {
            title: "รหัสชำระ",
            dataIndex: "PAYFOR",
            key: 'index',
            align: 'center',
            width: "7%",
            render: (text, record) => (
                <div>
                    <div>ชำระด้วย : <b style={{ color: 'red' }}>{record.PAYTYP}</b></div>
                    <div>ชำระค่า : <b style={{ color: 'red' }}>{record.FORCODE}</b></div>
                </div>
            ),
        },
        {
            title: "รายละเอียดชำระ",
            dataIndex: "NETPAY",
            key: 'index',
            align: 'center',
            width: "20%",
            render: (text, record) => (
                <div>
                    <div>note : <b style={{ color: 'red' }}>{record.FORDESC}</b></div>
                    <div>ยอดชำระ : <b style={{ color: 'red' }}>{currencyFormatOne(record.NETPAY)}</b></div>
                    <div>วันชำระ : <b style={{ color: 'red' }}>{dayjs(record.PAYDT).format('DD-MM-YYYY')}</b></div>
                </div>
            ),
        },
        {
            title: "สถานะ",
            dataIndex: "DUETONEFF",
            key: 'index',
            align: 'center',
            width: "7%",
            render: (text, record) => (
                <div>
                    {
                        record.FLAGCHQTRAN === "H" ?
                            <div><b style={{ color: 'red' }}>ปกติ</b></div>
                            : record.FLAGCHQTRAN === "C" ?
                                <div>
                                    <div><b style={{ color: 'red' }}>ยกเลิก</b></div>
                                    {
                                        record.CANDT && record.CANDT !== "0001-01-01T00:00:00Z" ?
                                            <div><b style={{ color: 'red' }}>{dayjs(record.CANDT).format('DD-MM-YYYY')}</b></div>
                                            : null
                                    }
                                </div>
                                : null
                    }
                </div>
            ),
        },
        {
            title: "บันทึก",
            dataIndex: "INPDATE",
            key: 'index',
            align: 'center',
            width: "12%",
            render: (text, record) => (
                <div>
                    <div>ผู้บันทึก : <b style={{ color: 'red' }}>{record.USERID}</b></div>
                    <div>วันที่ : {dayjs(record.INPDATE).format('DD-MM-YYYY')}</div>
                    <div>เวลา : {dayjs(record.INPDATE).format("HH:mm:ss")}</div>
                </div>
            ),
        },
    ];

    useEffect(() => {
        console.log("dataFromTable =>", dataFromTable)
        loadData()
    }, [])

    const loadData = async () => {
        setLoading(true)
        let mixData
        if (dataFromTable?.REFDOCNO !== "" && dataFromTable?.TMBILL !== "" && nicknameSalcod !== "") {
            mixData = { TMBILL: dataFromTable?.TMBILL, REFDOCNO: dataFromTable?.REFDOCNO }
            console.log("mixData", mixData)
            await axios.post(detailArothrCancelpay, mixData)
                // await axios.post("http://localhost:8070/api-db2/detail-cancelpay", mixData)
                .then((res) => {
                    if (res.status === 200) {
                        console.log("res.data payamt", res.data)
                        if (res.data) {
                            setDataAll(res.data)
                            setDataTable(res.data)
                        } else {
                            setDataAll(null)
                            setDataTable([])
                        }
                    } else {
                        msConnt(api, "top")
                        setTimeout(() => {
                            setDataAll(null)
                            setDataTable([])
                            setLoading(false)
                            handleCancel()
                        }, 1000)
                    }
                    setLoading(false)
                })
                .catch((err) => {
                    console.log("err", err)
                    msError(api, "top")
                    setTimeout(() => {
                        setDataAll(null)
                        setDataTable([])
                        setLoading(false)
                        handleCancel()
                    }, 1000)
                })
        } else {
            msConnt(api, "top")
            setLoading(false)
        }
    }
    const showConfirm = () => {
        confirm({
            title: 'คุณต้องการที่จะยกเลิกบิลนี้ใช่หรือไม่...?',
            content: 'กด OK เพื่อยืนยัน',
            onOk() {
                setLoading(true)
                okUpdate()
            },
            onCancel() {
                message.error('ยกเลิกการทำรายการ')
            },
        });
    };
    const okUpdate = async () => {
        if (dataAll) {
            const tk = JSON.parse(token)
            const headers = {
                "Authorization": `Bearer ${tk}`,
                "Menu": JSON.stringify("26")
            }
            console.log("dataAll", dataAll)
            await axios.post(updateArothrCancelpay, dataAll, { headers: headers })
                .then((res) => {
                    console.log("res", res.data)
                    msOK(api, "top")
                    setTimeout(() => {
                        setLoading(false)
                        handleCancel("OK")
                    }, 1500)
                }).catch((err) => {
                    console.log(err)
                    msError(api, "top")
                    setLoading(false)
                })
        } else {
            msError(api, "top")
            setLoading(false)
        }
    }
    const handleOk = async () => {
        close(false);
    };
    const handleCancel = (value) => {
        if (value === "OK") {
            closeload(false);
        } else {
            close(false);
        }
    };

    return (
        <>
            <Modal title="รายละเอียด"
                open={open}
                onOk={handleOk}
                onCancel={handleCancel}
                width={'1300px'}
                footer={[
                    <Row justify={'center'}>
                        <Space>
                            <Button disabled={loading} key="close" type='primary' danger onClick={handleCancel}>
                                ปิด
                            </Button>
                            <Button disabled={loading} key="save" type='primary' onClick={showConfirm}>
                                บันทึกข้อมูล
                            </Button>
                        </Space>
                    </Row>
                ]} >
                <Spin spinning={loading} size='large' tip="Loading...">
                    <Row justify={'center'}>
                        <Col className='gutter-row' span={24} style={{ fontSize: '25px', textAlign: 'center' }}>
                            <b>รายละเอียดใบเสร็จ {dataFromTable?.TMBILL}</b>
                        </Col>
                    </Row>
                    <Row justify={'center'}>
                        <Form
                            labelCol={{
                                span: 12,
                            }}
                            wrapperCol={{
                                span: 24,
                            }}
                            style={{
                                width: '90%',
                            }}
                            form={form}
                            autoComplete="off"
                            initialValues={{ remember: true }}
                        >
                            <div className='main'>
                                <Card style={{ width: '100%' }}>
                                    <Row justify={'left'} style={{ margin: 5, }}><b><u>รายละเอียดสัญญา {dataFromTable?.REFDOCNO}</u></b></Row>
                                    <Row gutter={32} justify={'center'}>
                                        <aside style={{ width: '90%' }}>
                                            <b>
                                                <div style={{ margin: 0, }}>
                                                    <span>เลขบิล : </span>
                                                    <b style={{ fontSize: '16px' }}><u>{dataFromTable?.TMBILL}</u></b>
                                                </div>
                                            </b>
                                            <b>
                                                <div style={{ margin: 0, }}>
                                                    <span>รหัสชำระด้วย : </span>
                                                    <b style={{ fontSize: '16px' }}>{dataFromTable?.PAYTYP}</b>
                                                </div>
                                            </b>
                                            <b>
                                                <div style={{ margin: 0, }}>
                                                    <span>ชำระสุทธิ : </span>
                                                    <b style={{ fontSize: '16px', color: 'red' }}>{currencyFormatOne(dataFromTable?.CHQAMT)}</b>
                                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                                        บาท
                                                    </span>
                                                </div>
                                            </b>
                                        </aside>
                                    </Row>
                                    <Divider />
                                    <Table
                                        rowKey={(record) => record.uid}
                                        dataSource={dataTable}
                                        columns={columnsClAr}
                                        scroll={{
                                            x: 1500,
                                            y: 400,
                                        }}
                                        style={{ padding: 20 }}
                                    >
                                    </Table>
                                </Card>
                            </div>
                        </Form>
                    </Row>
                    {contextHolder}
                </Spin>
            </Modal >
        </>
    )
}
