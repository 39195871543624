import React, { useState, useEffect } from 'react'
import { Form, Input, Row, Col, Button, Select, Divider, Space, Spin, Card, Modal } from 'antd'
import axios from 'axios'
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import TableRateCar from '../../offer_case/rate_car/TableRateCar';

import { dataModel, getprice, loadDataBunshe3, loadDetailAunSendPG, loadProvinces, ratecarsmodel } from '../../file_mid/all_api';
import { productLoanTypeCarPAP, loanPLorCH, productLoanTypeCarRE, productLoanTypeCarCHANGE, loanPLorCHBig } from '../../file_mid/all_options';

export default function DetailProduct({ allData, onCancel, data, changepage, dataType, dataOld, againMode }) {
    const { confirm } = Modal
    const { Option } = Select;
    const [form] = Form.useForm();
    const currentTime = dayjs();
    const formattedTime = currentTime.format('YYYY-MM-DDTHH:mm:ssZ');
    const [loading, setLoading] = useState(false)
    const cars = useSelector(state => state.cars)
    const addCars = localStorage.getItem('addCar')
    const dataAddCars = JSON.parse(addCars)
    /////////////////// รถ ////////////////////////////////////
    let countoffer = 1;
    const [flag, setFlag] = useState(1)
    const [dataPost2, setDataPost2] = useState()  //car
    const [resultData, setResultData] = useState([]) // API
    const [typeData, setTypeData] = useState([]) // API
    const [brandData, setBrandData] = useState([]) // API
    const [modelData, setModelData] = useState([]) // API
    const [yearPrice, setYearPrice] = useState([])
    const [provincesCar, setProvincesCar] = useState([]);
    const [typeSelected, setTypeSelected] = useState(null)
    const [openTableCar, setOpenTableCar] = useState(false); //ตารางเรทรถ
    const [counterBrand, setCounterBrand] = useState(false)
    const [counterCarBaab, setCounterCarBaab] = useState(false)
    const [typeCar, setTypeCar] = useState() // ชนิดรถ 1 2 3
    const [dataCarBunche3, setDataCarBunche3] = useState()
    // const [dataAun, setDataAun] = useState()
    // const [dataDB2, setDataDB2] = useState()

    // console.log('data2',dataPost2)
    // console.log('data', data)
    // console.log('dataCarBunche3',dataCarBunche3);
    // console.log('allData', allData)
    // console.log('dataOld', dataOld);
    // console.log('againMode',againMode);
    // console.log('dataPost2',dataPost2);
    // console.log('dataAun',dataAun)

    useEffect(() => {
        if (data.DATA_TYPE === 'rpsl' && !againMode) {
            if (flag === 1) {
               loadDataCarBunshe3()
            }
        }
        // fetchAllProvinces()
        if (dataAddCars) {
            setTypeData(dataAddCars?.productTypeCar)
            setTypeSelected(dataAddCars?.productTypeCar)
            loadDataBrand(dataAddCars?.productTypeCar)
            setDataPost2({ ...dataPost2, ...dataAddCars })
            let typeCar
            if (dataAddCars.productTypeCar === 1) {
                typeCar = "รถยนต์"
            } else if (dataAddCars.productTypeCar === 2) {
                typeCar = "รถเครื่องจักการเกษตร"
            } else if (dataAddCars.productTypeCar === 3) {
                typeCar = "รถบรรทุก"
            }
            if (dataAddCars?.carPrice === 0) {
                AddCarBaab()
                // var newYear = dataAddCars?.carYear
                form.setFieldsValue(
                    {
                        ...dataAddCars,
                        productTypeCar: typeCar,
                        carPrice: currencyFormatOne(dataAddCars?.carPrice),
                        // carYear: dayjs().year(newYear),
                        carYear: dataAddCars?.carYear,
                    }
                )
            } else {
                form.setFieldsValue(
                    {
                        ...dataAddCars,
                        productTypeCar: typeCar,
                        carPrice: currencyFormatOne(dataAddCars?.carPrice),
                    }
                )
            }
        } else {
            if (againMode) {
                setTypeData(dataOld?.productTypeCar)
                setTypeSelected(dataOld?.productTypeCar)
                loadDataBrand(dataOld?.productTypeCar)
                setDataPost2({
                    ...dataPost2,
                    ...dataOld,
                    reLoanId: dataOld?.carLoanDetailsRe?.reLoanId,
                    productLoanType: dataOld?.carLoanDetailsRe?.productLoanType,
                    productType: dataOld?.carLoanDetailsRe?.productType
                })
                let typeCar
                if (dataOld?.productTypeCar === 1) {
                    typeCar = "รถยนต์"
                } else if (dataOld?.productTypeCar === 2) {
                    typeCar = "รถเครื่องจักการเกษตร"
                } else {
                    typeCar = "รถบรรทุก"
                }
                // if(dataType === )
                form.setFieldsValue(
                    {
                        ...dataOld,
                        ...dataOld?.carLoanDetailsRe,
                        // productLoanType: "",
                        productTypeCar: typeCar,
                        carPrice: currencyFormatOne(dataOld?.carPrice),
                    }
                )
            } else {
                loadDataBrand(dataPost2?.productTypeCar)
                setTypeSelected(1)
                setTypeData(1)
                if (dataType === 'รี') {
                    setDataPost2({ ...dataPost2, productLoanType: 'Pre-Aaprove-รี' })
                    form.setFieldsValue({
                        ...dataPost2,
                        productLoanType: 'Pre-Aaprove-รี'
                    })
                }
                if (dataType === 'ปรับโครงสร้าง') {
                    setDataPost2({ ...dataPost2, productLoanType: 'Pre-Aaprove-ปรับโครงสร้าง' })
                    form.setFieldsValue({
                        ...dataPost2,
                        productLoanType: 'Pre-Aaprove-ปรับโครงสร้าง'
                    })
                }
                if (dataType === 'เปลี่ยนสัญญา') {
                    setDataPost2({ ...dataPost2, productLoanType: 'Pre-Aaprove-เปลี่ยนสัญญา' })
                    form.setFieldsValue({
                        ...dataPost2,
                        productLoanType: 'Pre-Aaprove-เปลี่ยนสัญญา'
                    })
                }
            }
        }
        if (data.tell) {
            if (flag === 1) {
                loadDataReAun();
            }
        }
    }, [cars.data])

    // useEffect(()=> {
    //     // if(data.tell){
    //         if(dataAun){
    //             form.setFieldsValue({
    //                 ...dataAun,
    //                 productTypeCar: dataAun?.productLoanType,
    //                 productType: dataAun?.productType,
    //             })
    //             setTypeCar(dataAun?.productTypeCar)
    //             setDataPost2({
    //                 ...dataPost2,
    //                 productTypeCar: dataAun?.productTypeCar,
    //                 productType: dataAun?.productType,
    //             })
    //             setTypeData(dataAun?.productTypeCar)
    //             setTypeSelected(dataAun?.productTypeCar)
    //             loadDataBrand(dataAun?.productTypeCar)
    //         }
    //     // }
    // },[dataAun])

    // useEffect(() => {
    //     if(data.tell){
    //         loadDataReAun();
    //     }  
    // },[])
    // useEffect(() => {
        // if (data.DATA_TYPE === 'rpsl') {
    //         loadDataCarBunshe3()
    //     }
    // }, [])

    const loadDataCarBunshe3 = async () => {
        setLoading(true)
        let year = (allData?.custmast[0]?.MANUYR - 543).toString();

        await axios.post(loadDataBunshe3, { id: allData?.custmast[0]?.MODEL, year: year })
            // await axios.post("http://localhost:8080/api/PostbySumRate", { id: 'ISUZU1025', year: "2020" })
            .then(res => {
                if (res.data) {
                    console.log('resdata3', res.data)

                    const carYear = parseInt(res?.data[0].years, 10);
                    const carPrice = (res?.data[0].price * 1000) || 0
                    const type = res?.data[0].type;
                    const productTypeCar = type === "รถยนต์" ? 1 :
                                           type === "รถเครื่องจักรการเกษตร" ? 2 :
                                           type === "รถบรรทุก" ? 3 : null;

                    let carPriceStatus = carPrice > 0 ? true : false;

                    let productLoanType = '';
                    switch (dataType) {
                        case 'รี':
                            productLoanType = 'Pre-Aaprove-รี';
                            break;
                        case 'ปรับโครงสร้าง':
                            productLoanType = 'Pre-Aaprove-ปรับโครงสร้าง';
                            break;
                        case 'เปลี่ยนสัญญา':
                            productLoanType = 'Pre-Aaprove-เปลี่ยนสัญญา';
                            break;
                        default:
                            productLoanType = ''; // กรณีที่ไม่มีค่าตรงกับเงื่อนไข
                    }

                    form.setFieldsValue({
                        productTypeCar: res?.data[0].type, //ชนิดรถ
                        carBrand: res?.data[0].brand,      //ยี่ห้อ
                        carModel: res?.data[0].model,      //รุ่นรถ
                        carBaab: res?.data[0].models,      //แบบรถ
                        carYear: carYear,                  //ปีรถ
                        carPrice: carPrice,                 //ราคา
                        productType: "P-LOAN",              //เซ็ต P-LOAN  เป็นค่าเริ่มต้น
                        productLoanType: productLoanType


                    })
                    setTypeCar(type)
                    setDataPost2({
                        ...dataPost2,
                        productTypeCar: productTypeCar,  //ชนิดรถ
                        carBrand: res?.data[0].brand,    //ยี่ห้อ
                        carModel: res?.data[0].model,    //รุ่นรถ
                        carBaab: res?.data[0].models,    //แบบรถ
                        carYear: carYear,                //ปีรถ
                        carPrice: carPrice,              //ราคา
                        carInput: formattedTime,
                        carPriceStatus: carPriceStatus,
                        brand: res?.data[0].brand,
                        brand_th: res?.data[0].brand_th,
                        carDateStatus: formattedTime,
                        idModel: res?.data[0].idmodel,
                        models: res?.data[0].models,
                        productType: "p-loan",            //เซ็ต P-LOAN  เป็นค่าเริ่มต้น
                        productLoanType: productLoanType

                    })
                    setTypeData(productTypeCar)
                    setTypeSelected(productTypeCar)
                    loadDataBrand(productTypeCar)
                    setFlag(0)
                    setLoading(false)
                }
            }).catch((err) => {
                console.log("err", err)
                setLoading(false)
            })
    }

    const loadDataReAun = async () => {
        setLoading(true);
        // await axios.post(loadDetailAunSendPG, { contno: "8-0013344" })
        await axios.post(loadDetailAunSendPG, { contno: data?.CONTNO })
            .then((res) => {
                // if (res.status === 200) {
                // console.log('resAun',res.data)
                form.setFieldsValue({
                    ...res?.data?.carsApproveve,
                    productTypeCar: res?.data?.carsApproveve.productTypeCar,
                    productType: res?.data?.carsApproveve.productType,
                })
                setTypeCar(res?.data?.carsApproveve?.productTypeCar)
                setDataPost2({
                    ...dataPost2,
                    productTypeCar: res?.data?.carsApproveve?.productTypeCar,
                    productType: res?.data?.carsApproveve?.productType,
                })
                setTypeData(res?.data?.carsApproveve?.productTypeCar)
                setTypeSelected(res?.data?.carsApproveve?.productTypeCar)
                loadDataBrand(res?.data?.carsApproveve?.productTypeCar)
                setFlag(0);
                setLoading(false);
                // }
            })
            .catch((err) => {
                console.log("err", err);
                setLoading(false);
            });
    };

    const handleSubmit = async () => {
        setLoading(true)
        if (againMode) {
            delete dataPost2?.carLoanDetailsRe
            delete dataPost2?.customer
            delete dataPost2?.img
        }

        const datatoLocal = {
            ...dataPost2,
            ...data,
            productType: dataPost2?.productType,
            productLoanType: dataPost2?.productLoanType,
            carProvince: allData.custmast[0]?.DORECV,
            carPlateNumber: allData.custmast[0]?.REGNO,
            carEngineNumber: allData.custmast[0]?.ENGNO,
            carChassisNumber: allData.custmast[0]?.STRNO,
            carColor: allData.custmast[0]?.COLOR,

        }
        // console.log("datatoLocal", datatoLocal)
        localStorage.setItem('addCar', JSON.stringify(datatoLocal))
        // // if (allData?.custmast[0]?.GRDCOD === "A") {
        changepage('5')
        // // } else {
        // //     changepage('2')
        // // }
        setLoading(false)
    };
    const currencyFormatOne = (amount) => {
        if (amount) {
            return Number(amount)
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, '$&,')
        }
    }
    const loadDataBrand = async (value) => { //รถยนต์ รถเครื่องจักร รถบรรทุก
        setLoading(true)
        await axios.get(`https://shark-app-j9jc9.ondigitalocean.app/ratecars/brand/${value}`)
            .then((res) => {
                setBrandData(res.data)
                setLoading(false)
            })
            .catch((err) => console.log(err))
        setLoading(false)
    }
    const loadDataModel = async (value) => {
        setLoading(true)
        await axios.get(dataModel + value)
            .then((res) => {
                setModelData(res.data)
                form.setFieldsValue(
                    {
                        carModel: "",
                        carBaab: "",
                        carYear: "",
                        carPrice: 0
                    }
                )
                setLoading(false)
            })
            .catch((err) => console.log(err))
        setLoading(false)
    }

    const getResult = async (value) => {
        setLoading(true)
        const result = await axios.get(ratecarsmodel,
            { params: { typeId: typeSelected, modelId: value }, }) 
        setResultData(result.data)
        setLoading(false)
    }
    const onBrandChange = (value) => {
        setDataPost2({ ...dataPost2, carBrand: brandData.find((a) => a.idbrand === value).brand })
        loadDataModel(value)
    }
    const onModelChange = (value) => {
        setCounterBrand(false)
        setDataPost2({ ...dataPost2, carModel: modelData.find((a) => a.idmodel_te === value).model })
        getResult(value)
    }
    const onDetailChange = (value) => {
        setDataPost2({ ...dataPost2, carBaab: resultData.find((a) => a.idmodel === value).models, idModel: value })
        getPrice(typeData, value)
    }
    const onTypeLoanChange = (value) => {
        setDataPost2({ ...dataPost2, productLoanType: value })
    }

    const getPrice = async (typeId, carId) => {
        setLoading(true)
        await axios.get(getprice, { params: { typeId, carId }, })
            .then((res) => {
                if (res.data) {
                    setYearPrice(res.data)
                    setLoading(false)
                }
            })
            .catch((err) => alert('ไม่พบข้อมูล ราคารถ'))
        setLoading(false)
    }

    const onResult = (k, v) => {
        const pYear = parseInt(v.key)
        if (k) {
            setDataPost2({ ...dataPost2, carPriceStatus: true, carPrice: k, carYear: pYear, carInput: formattedTime, carDateStatus: formattedTime })
            parseInt(dataPost2.carYear)
            form.setFieldsValue({
                carPrice: currencyFormatOne(v.value)
            })
        }
    }
    const showConfirmCancel = () => {
        confirm({
            title: 'คุณต้องการยกเลิก...?',
            onOk() {
                onCancel(false);
            },
            onCancel() {
            },
        });
    };

    const onChangeBack = () => {
        changepage('1')
    }

    const onTypeChange = (value) => { 
        setTypeCar(value)
        setDataPost2({
            ...dataPost2,
            productTypeCar: value,
            carYear: 0,
            carPrice: 0,
            carPriceStatus: false,
            carInput: formattedTime,
            carDateStatus: formattedTime
        })
        form.setFieldsValue({
            carBrand: "",
            carModel: "",
            carBaab: "",
            carYear: "",
            carPrice: 0,
            productType: null,
        })
        setTypeData(value)
        setTypeSelected(value)
        loadDataBrand(value)

    }
    const getTableCar = () => {
        setOpenTableCar(true)
        setCounterCarBaab(false)
    }
    const AddCarBaab = () => {
        // console.log("-")
        setCounterCarBaab(true)
        // setDataPost2({ ...dataPost2, carPrice: 0 })
        form.setFieldsValue(
            {
                carBaab: '',
                carYear: '',
                carPrice: 0,
            }
        )
    }

    //////////////// จังหวัด รถ /////////////////////
    const fetchAllProvinces = async () => {
        await axios.get(loadProvinces)
            .then(response => {
                setProvincesCar(response.data);
            }).catch(error => {
                console.error(error);
            });
    }

    const handleRate0 = async () => {
        const filteredBrandData = brandData?.filter(item =>
            item.brand?.toLowerCase().includes(allData?.custmast[0]?.TYPE.toLowerCase()) ||
            item.brand_th?.toLowerCase().includes(allData?.custmast[0]?.TYPE.toLowerCase())
        ).map(item => (item))
        let brandDataNew
        if (filteredBrandData.length !== 0) {
            brandDataNew = filteredBrandData[0].brand
        } else {
            brandDataNew = allData?.custmast[0]?.TYPE
        }
        confirm({
            title: 'คุณต้องการที่จะเลือกรถเรท 0 หรือไม่...?',
            content: 'กรณีที่รถไม่มีในเรท กด OK เพื่อยืนยันการใช้รถเรท 0',
            onOk() {
                setDataPost2({
                    ...dataPost2,
                    carBrand: brandDataNew,
                    carModel: allData?.custmast[0]?.MODEL,
                    carBaab: allData?.custmast[0]?.BAAB,
                    carYear: ~~allData?.custmast[0]?.MANUYR - 543,
                    carInput: new Date().toISOString(),
                    carPrice: 0,
                    carPriceStatus: false
                })
                form.setFieldsValue({
                    carBrand: brandDataNew,
                    carModel: allData?.custmast[0]?.MODEL,
                    carBaab: allData?.custmast[0]?.BAAB,
                    carYear: ~~allData?.custmast[0]?.MANUYR - 543,
                    carPrice: 0
                })
            },
            onCancel() {

            },
        });
    };

    return (
        <Row justify={'center'}>
            <Card style={{ width: '1100px' }}>
                <Col style={{ textAlign: 'center' }} >
                    <b><h2>รายละเอียดสินค้า</h2></b>
                </Col>
                <Spin spinning={loading} size='large' tip=" Loading... ">
                    <Form
                        name="basic"
                        labelCol={{
                            span: 8,
                        }}
                        // wrapperCol={{
                        //     span: 24,
                        // }}
                        initialValues={{
                            remember: true,
                        }}
                        form={form}
                        onFinish={handleSubmit}
                        autoComplete="off"
                    >
                        <div className='main'>
                            <Card style={{ width: '100%' }}>
                                <Col span={24}>
                                    <b>
                                        <div style={{ marginBottom: 0 }}>
                                            <span>เลขสัญญา : </span>{<b style={{ fontSize: '20px', lineHeight: '32px' }}>{data?.CONTNO}</b>}
                                        </div>
                                        <div style={{ marginBottom: 0 }}>
                                            <span>เกรด : </span>{<b style={{ fontSize: '20px', lineHeight: '32px' }}>{data?.GRDCOD}</b>}
                                        </div>
                                    </b>
                                </Col>
                                <Col span={24}>
                                    {
                                        allData ?
                                            <>
                                                <Divider orientation={'left'}><b><u>สินค้า</u></b></Divider>
                                                <aside style={{ width: '100%' }}>
                                                    <div label='ยี่ห้อ' name='TYPE' style={{ marginBottom: 3 }}>
                                                        <span>ยี่ห้อ : </span><b>{allData?.custmast[0]?.TYPE}</b>
                                                    </div>
                                                    <div label='รุ่น' name='MODEL' style={{ marginBottom: 3 }}>
                                                        <span>รุ่น : </span><b>{allData?.custmast[0]?.MODEL}</b>
                                                    </div>
                                                    <div label='แบบ' name='MODEL' style={{ marginBottom: 3 }}>
                                                        <span>แบบ : </span><b>{allData?.custmast[0]?.BAAB}</b>
                                                    </div>
                                                    <div label='ปี' name='MANUYR' style={{ marginBottom: 3 }}>
                                                        <span>ปี : </span><b>{allData?.custmast[0]?.MANUYR}</b>
                                                    </div>
                                                </aside>

                                            </>
                                            : null
                                    }
                                </Col>
                                <Col span={24}>
                                    <Divider orientation={'left'}><b><u>รายละเอียด</u></b></Divider>
                                    <aside style={{ width: '100%' }}>
                                        <Form.Item label='ชนิดรถ' name='productTypeCar'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input !'
                                                },]}>
                                            <Select
                                                disabled={againMode}
                                                // value={dataAun?.productLoanType}
                                                placeholder={'เลือก'}
                                                style={{ color: 'black', height: '40px' }}
                                                allowClear
                                                onChange={onTypeChange}>
                                                <Option value={1}>รถยนต์</Option>
                                                <Option value={2}>รถเครื่องจักการเกษตร</Option>
                                                <Option value={3}>รถบรรทุก</Option>
                                            </Select>
                                        </Form.Item>
                                        <Form.Item label='เรทรถ'>
                                            <Space>
                                                <Button type='primary' disabled={againMode} onClick={getTableCar}>ดู</Button>
                                                <Button type='primary' disabled={againMode} style={{ backgroundColor: "#F7A419" }} onClick={handleRate0}>รถไม่มีในเรท</Button>
                                            </Space>
                                        </Form.Item>
                                        <Form.Item label='ยี่ห้อ'
                                            name='carBrand'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input !'
                                                },]}>
                                            <Select
                                                disabled={againMode}
                                                showSearch
                                                style={{ height: '40px' }}
                                                onChange={onBrandChange}
                                                optionFilterProp="children"
                                                placeholder="Search to Select"
                                                filterOption={(inputValue, option) =>
                                                    option.children.join('').toLowerCase().includes(inputValue.toLowerCase())
                                                }
                                            >
                                                {
                                                    brandData?.map((e) => {
                                                        return (
                                                            <Select.Option key={e.idbrand} value={e.idbrand}>
                                                                {e.brand} ({e.brand_th})
                                                            </Select.Option>
                                                        )
                                                    })}
                                            </Select>
                                        </Form.Item>
                                        <Form.Item label='รุ่นรถ' name='carModel'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input !'
                                                },]}>
                                            <Select
                                                disabled={againMode}
                                                showSearch
                                                style={{ height: '40px' }}
                                                onChange={onModelChange}
                                                placeholder="Search to Select"
                                                optionFilterProp="children"
                                                filterOption={(inputValue, option) =>
                                                    option.children.join('').toLowerCase().includes(inputValue.toLowerCase())
                                                }
                                            >
                                                {modelData?.map((e) => {
                                                    return (
                                                        <Select.Option key={e.idmodel_te} value={e.idmodel_te}>
                                                            {e.model} {e.model_te ? e.model_te : null}
                                                        </Select.Option>
                                                    )
                                                })}
                                            </Select>
                                        </Form.Item>
                                        <Form.Item label='แบบรถ' name='carBaab'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input !'
                                                },]}>
                                            <Select
                                                disabled={againMode}
                                                showSearch
                                                style={{ height: '40px' }}
                                                onChange={(value) => { onDetailChange(value) }}
                                                placeholder="Search to Select"

                                            >
                                                {resultData?.map((e) => {
                                                    return (
                                                        <Select.Option key={e.idmodel} value={e.idmodel}>
                                                            {e.models} {e.idmodel ? e.idmodel : null}
                                                        </Select.Option>
                                                    )
                                                })}
                                            </Select>
                                        </Form.Item>
                                        <Form.Item label='ปีรถ' name='carYear'
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'Please input !'
                                                },]}>
                                            <Select
                                                disabled={againMode}
                                                style={{ height: '40px' }}
                                                onChange={(k, v) => (onResult(k, v))}
                                            >
                                                {Object.entries(yearPrice).map(([k, v]) => {
                                                    if (k.charAt(0) === 'p' && v) {
                                                        const year = k.replace('p', '')
                                                        return (
                                                            <Select.Option key={year} value={v * 1000}>
                                                                {year}
                                                            </Select.Option>
                                                        )
                                                    }
                                                })}
                                            </Select>
                                        </Form.Item>
                                        <Form.Item label='ราคา' name='carPrice'>
                                            <Input disabled defaultValue={0} style={{ color: 'black', height: '40px' }}
                                                onChange={(e) => setDataPost2({ ...dataPost2, carPrice: e.value })} />
                                        </Form.Item>
                                    </aside>
                                </Col>
                                <Col span={24}>
                                    <Divider orientation={'left'}><b><u>ประเภทขอกู้</u></b></Divider>
                                    <aside style={{ width: '100%' }}>
                                        {
                                            dataType === "รี" ?
                                                <div>
                                                    <Form.Item
                                                        name="productLoanType"
                                                        label="ประเภทขอกู้"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: 'โปรดเลือกประเภทขอกู้ !',
                                                            },]}
                                                    >
                                                        <Select
                                                            style={{ height: '40px' }}
                                                            placeholder={'เลือก'}
                                                            onChange={(value) => { onTypeLoanChange(value) }}
                                                            options={productLoanTypeCarRE}
                                                        />
                                                    </Form.Item>
                                                </div>
                                                : dataType === "ปรับโครงสร้าง" ?
                                                    <div>
                                                        <Form.Item
                                                            name="productLoanType"
                                                            label="ประเภทขอกู้"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'โปรดเลือกประเภทขอกู้ !',
                                                                },]}
                                                        >
                                                            <Select
                                                                style={{ height: '40px' }}
                                                                placeholder={'เลือก'}
                                                                onChange={(value) => { onTypeLoanChange(value) }}
                                                                options={productLoanTypeCarPAP}
                                                            />
                                                        </Form.Item>
                                                    </div>
                                                    :
                                                    <div>
                                                        <Form.Item
                                                            name="productLoanType"
                                                            label="ประเภทขอกู้"
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'โปรดเลือกประเภทขอกู้ !',
                                                                },]}
                                                        >
                                                            <Select
                                                                style={{ height: '40px' }}
                                                                placeholder={'เลือก'}
                                                                onChange={(value) => { onTypeLoanChange(value) }}
                                                                options={productLoanTypeCarCHANGE}
                                                            />
                                                        </Form.Item>
                                                    </div>
                                        }


                                        {/* <div style={{ margin: 0, }}> */}
                                        <Form.Item label='ประเภทค่างวด' name='productType'
                                            // style={{ textAlign: 'center', width: '200px' }}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'โปรดเลือกประเภทค่างวด !',
                                                },]}
                                        >
                                            {
                                                typeCar === 1 ?
                                                    <Select
                                                        style={{ height: '40px' }}
                                                        placeholder={'เลือก'}
                                                        disabled={typeCar ? false : true}
                                                        name='productType'
                                                        // value={dataAun?.productType}
                                                        onChange={(e) => setDataPost2({ ...dataPost2, productType: e })}
                                                        // onChange={onProductTypeChange}
                                                        options={loanPLorCH}
                                                    />
                                                    :
                                                    <Select
                                                        style={{ height: '40px' }}
                                                        placeholder={'เลือก'}
                                                        disabled={typeCar ? false : true}
                                                        name='productType'
                                                        // value={dataAun?.productType}
                                                        onChange={(e) => setDataPost2({ ...dataPost2, productType: e })}
                                                        // onChange={onProductTypeChange}
                                                        options={loanPLorCHBig}
                                                    />
                                            }

                                        </Form.Item>
                                        {/* </div> */}
                                    </aside>
                                </Col>
                                <Divider style={{ margin: 5 }} />
                                <Row justify={'center'} >
                                    <Space>
                                        <Button type="primary" onClick={onChangeBack}>
                                            ย้อนกลับ
                                        </Button>
                                        <Button type="primary" style={{ background: "red" }} onClick={showConfirmCancel}>
                                            ยกเลิก
                                        </Button>
                                        <Button type="primary" htmlType="submit" style={{ background: "green" }} >
                                            ต่อไป
                                        </Button>
                                    </Space>
                                </Row>
                                {
                                    openTableCar ?
                                        <TableRateCar
                                            open={openTableCar}
                                            close={setOpenTableCar}
                                            countoffer={countoffer}
                                            productTypeCar={dataPost2?.productTypeCar}
                                        />
                                        : null
                                }
                            </Card>
                        </div>
                    </Form>
                </Spin>
            </Card >
        </Row >
    )
}
