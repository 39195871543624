import dayjs from "dayjs";

const currencyFormatOne = (amount) => {
  if (amount) {
    return Number(amount)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, '$&,')
  } else {
    return "-"
  }
}
export function dayFormatOne(value) {
  if (value) {
    // console.log("vv",value)
    return dayjs(value).format('DD-MM-YYYY');
    // return dayjs(value).format('YYYY-MM-DD');
  } else {
    return ""
  }
}

const columnsMiss = [
  {
    key: 'index',
    title: "ลำดับ",
    dataIndex: "index",
    width: "5%",
    align: 'center',
    render: (text, object, index) => index + 1
  },
  {
    title: "รายละเอียดสัญญา",
    dataIndex: "REF2",
    key: 'index',
    align: 'center',
    width: "auto",
    render: (text, record) => (
      <>
        <div>รหัส REF2 : <b style={{ fontSize: '16px', color: 'red' }}>{record.REF2}</b></div>
      </>
    ),
  },
  {
    key: 'index',
    title: "BANK_CODE",
    dataIndex: "index",
    width: "auto",
    align: 'center',
    render: (text, record) => (
      <>
        <div>ชำระด้วย : <b style={{ fontSize: '14px', color: 'red' }}>{record.BANK_CODE_00}</b></div>
      </>
    ),
  },
  {
    key: 'money',
    title: "รายละเอียดการโอน",
    dataIndex: "money",
    width: "auto",
    align: 'center',
    render: (text, record) => (
      <>
        <div>ยอดเงิน : <b style={{ fontSize: '16px', color: 'red' }}>{currencyFormatOne(record.money)}</b></div>
      </>
    ),
  },
  {
    title: "วันที่ชำระ",
    dataIndex: "PAYMENTDATE",
    key: 'index',
    align: 'center',
    width: "auto",
    render: (text, record) => (
      <>
        <div>วันที่ชำระ : <b style={{ fontSize: '16px', color: 'red' }}>{dayjs(record.PAYMENTDATE).format("DD-MM-YYYY")}</b></div>
      </>
    ),
  }
];

const columnsPay = [
  {
    key: 'index',
    title: "ลำดับ",
    dataIndex: "index",
    width: "5%",
    align: 'center',
    render: (text, object, index) => index + 1
  },
  {
    title: "รายละเอียดใบเสร็จ",
    dataIndex: "tmBill",
    key: 'index',
    align: 'center',
    width: "auto",
    render: (text, record) => (
      <>
        <div>เลขที่ใบเสร็จ : <b style={{ fontSize: '16px', color: 'red' }}>{record.tmBill}</b></div>
        <div>วันที่ออก : <b style={{ fontSize: '16px', color: 'red' }}>{dayjs(record.inputDate).format("DD-MM-YYYY")}</b></div>
      </>
    ),
  },
  {
    title: "รายละเอียดสัญญา",
    dataIndex: "contno",
    key: 'index',
    align: 'center',
    width: "auto",
    render: (text, record) => (
      <>
        <div>เลขสัญญา : <b style={{ fontSize: '16px', color: 'red' }}>{record.contno}</b></div>
      </>
    ),
  },
  {
    title: "รายละเอียดการโอน",
    dataIndex: "amount",
    width: "auto",
    align: 'center',
    render: (text, record) => (
      <>
        <div>ยอดเงิน : <b style={{ fontSize: '16px', color: 'red' }}>{currencyFormatOne(record.amount)}</b></div>
      </>
    ),
  },
  {
    title: "รายละเอียดการรับเข้า",
    dataIndex: "payamt",
    width: "auto",
    align: 'center',
    render: (text, record) => (
      <div>
        <div>จ่ายค่างวด : <b style={{ fontSize: '16px', color: 'red' }}>{currencyFormatOne(record.payamt)}</b></div>
        <div>จ่ายค่าทวงถาม : <b style={{ fontSize: '16px', color: 'red' }}>{currencyFormatOne(record.follow)}</b></div>
      </div>
    ),
  },
  {
    title: "วันที่ชำระ",
    dataIndex: "paymentDate",
    key: 'index',
    align: 'center',
    width: "auto",
    render: (text, record) => (
      <>
        <div>วันที่ชำระ : <b style={{ fontSize: '16px', color: 'red' }}>{dayjs(record.paymentDate).format("DD-MM-YYYY")}</b></div>
      </>
    ),
  }
];


export { columnsMiss, columnsPay };