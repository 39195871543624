import { Space, Button } from 'antd';
import dayjs from "dayjs";

const currencyFormatOne = (amount) => {
    if (amount) {
        return Number(amount)
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, '$&,')
    } else {
        return "-"
    }
}

export function dayFormatOne(value) {
    if (value) {
        // console.log("vv",value)
        return dayjs(value).format('YYYY-MM-DD');
    } else {
        return ""
    }
}


const columnsPay = (handleSetData) => [
    {
        title: "เลขบิล",
        dataIndex: "TMBILL",
        key: 'index',
        align: 'center',
        width: "auto",
        render: (text, record) => (
            <div>
                <div>{record.TMBILL}</div>
                <div>วันที่ใบรับ : {dayFormatOne(record.TMBILDT)}</div>
                <div>สาขาที่รับชำระ : {record.LOCATRECV}</div>
            </div>
        ),
    },
    {
        title: "สัญญา",
        dataIndex: "CONTNO",
        key: 'index',
        align: 'center',
        width: "auto",
        render: (text, record) => (
            <div>
                <div>เลขสัญญา : {record.CONTNO}</div>
            </div>
        ),
    },
    {
        title: "ข้อมูลลูกค้า",
        dataIndex: "CUSCOD",
        key: 'index',
        align: 'center',
        width: "auto",
        render: (text, record) => (
            <div>
                <div>รหัส : {record.CUSCOD}</div>
                <div>ชื่อ : {record.NAME1}</div>
                <div>สกุล : {record.NAME2}</div>
            </div>
        ),
    },
    {
        title: "รหัสชำระ",
        dataIndex: "PAYFOR",
        key: 'index',
        align: 'center',
        width: "auto",
        render: (text, record) => (
            <div>
                <div>ชำระด้วย : {record.PAYTYP}</div>
            </div>
        ),
    },
    // {
    //     title: "รายละเอียดชำระ",
    //     dataIndex: "PAYAMT",
    //     key: 'index',
    //     align: 'center',
    //     width: "auto",
    //     render: (text, record) => (
    //         <div>
    //             <div>ชำระค่างวด : {currencyFormatOne(record.PAYAMT)}</div>
    //             <div>ชำระดอกเบี้ย : {currencyFormatOne(record.PAYAMT_V)}</div>
    //             <div>ชำระต้น : {currencyFormatOne(record.PAYAMT_N)}</div>
    //             <div>ชำระค่าทวงถาม : {currencyFormatOne(record.INTAMT)}</div>
    //         </div>
    //     ),
    // },
    // {
    //     title: "รายละเอียดส่วนลด",
    //     dataIndex: "DISCT",
    //     key: 'index',
    //     align: 'center',
    //     width: "auto",
    //     render: (text, record) => (
    //         <div>
    //             <div>ส่วนลด : {currencyFormatOne(record.DISCT)}</div>
    //             <div>ส่วนลดค่าทวงถาม : {currencyFormatOne(record.DIFINTAMT)}</div>
    //         </div>
    //     ),
    // },
    {
        title: "ชำระ",
        dataIndex: "CHQTMP",
        key: 'index',
        align: 'center',
        width: "auto",
        render: (text, record) => (
            <div>
                <div>ชำระสุทธิ : <b style={{ color: 'red' }}>{currencyFormatOne(record.CHQTMP)}</b></div>
                <div>วันชำระ : {dayFormatOne(record.INPDT)}</div>
                {
                    record.FLAG === "H" ?
                        <div>สถานะ : <b style={{ color: 'red' }}>ปกติ</b></div>
                        : record.FLAG === "C" ?
                            <div>สถานะ : <b style={{ color: 'red' }}>ยกเลิก</b></div>
                            : null
                }
                {
                    record.CANDT && record.CANDT !== "0001-01-01T00:00:00Z" ?
                        <div>วันยกเลิก : <b style={{ color: 'red' }}>{dayFormatOne(record.CANDT)}</b></div>
                        : null
                }
            </div>
        ),
    },
    {
        title: "บันทึก",
        dataIndex: "INPDATE",
        key: 'index',
        align: 'center',
        width: "auto",
        render: (text, record) => (
            <div>
                <div>วันที่บันทึก : {dayFormatOne(record.INPDATE)}</div>
                <div>เวลา : {dayjs(record.INPDATE).format("HH:mm:ss")}</div>
                <div>ผู้บันทึก : {record.USERID}</div>
            </div>
        ),
    },
    {
        key: 'Action',
        title: "Action",
        align: 'center',
        width: "auto",
        render: (record) => (
            <Space size="middle">
                <Button onClick={() => handleSetData(record)}
                >พิมพ์ใบเสร็จ</Button>
            </Space>
        ),
    },
];


export { columnsPay };