//เหลือบาร์โค้ด
// import React, { useRef, useState, useEffect } from "react";
import React from "react";
import { Row, Col } from "antd";
import { dayFormatOne, currencyFormatOne } from "../allFormat";
import { ArabicNumberToText } from "../thaibath";
import moment from "moment";
import "./css/mainPage.css";
import P1 from './Pic/P1.jpg';
import P2 from './Pic/P2.png';
import P3 from './Pic/P3.jpg';
import P4 from './Pic/P4.png';
import P5 from './Pic/P5.png';
function Payment({ dataPrint }) {
  // console.log("dataPrint :", dataPrint)
  const dataCompany = dataPrint?.company
  const dataDetail = dataPrint?.detail
  const dataPay = dataPrint?.pay
  //qrCode
  const QRCODE_URL = "https://asia-east2-fir-express-c1aec.cloudfunctions.net/aa/loans";
  const ref2 = dataDetail[0]?.CONTNOCUT;
  //ชำระผ่านธนาคาร  "0405559001925"
  //const url = `${QRCODE_URL}/qr/0405559001925/00/006/${ref2}/000`;
  //ชำระผ่านเค้าน์เตอร์เซอร์วิส  "0405552000435"
  const url = `${QRCODE_URL}/qr/0405552000435/00/006/${ref2}/000`;
  // const company = 'บริษัท วัน มันนี่ จำกัด (สำนักงานใหญ่)';
   //const companyAddress = '1/20-24 ถ.มิตรภาพ ต.ในเมือง อ.เมือง จ.ขอนแก่น 40000';
   //const companyTel = 'เบอร์โทร 043-239-888 และ 043-239-074';
  // const nameCus = 'นางสาวมิณตรา คำหงษา';
  console.log("dataCompany :", dataCompany)
  console.log("dataDetail :", dataDetail)
  console.log("dataPay :", dataPay)

  const pay0 = dataPay?.[0];
  const pay1 = dataPay?.[1];
  const pay2 = dataPay?.[2];
  //หน้า2
  const pay3 = dataPay?.[3];
  const pay4 = dataPay?.[4];
  const pay5 = dataPay?.[5];
  //หน้า3
  const pay6 = dataPay?.[6];
  const pay7 = dataPay?.[7];
  const pay8 = dataPay?.[8];
  //หน้า4
  const pay9 = dataPay?.[9];
  const pay10 = dataPay?.[10];
  const pay11 = dataPay?.[11];
 //ผลรวม
 const sumNETPAY = 
  (pay0?.NETPAY || 0) + (pay1?.NETPAY || 0) + (pay2?.NETPAY || 0) + 
  (pay3?.NETPAY || 0) + (pay4?.NETPAY || 0) + (pay5?.NETPAY || 0) + 
  (pay6?.NETPAY || 0) + (pay7?.NETPAY || 0) + (pay8?.NETPAY || 0) + 
  (pay9?.NETPAY || 0) + (pay10?.NETPAY || 0) + (pay11?.NETPAY || 0); 

  //ผู้พิมพ์ 
  const userFirstname = localStorage.getItem('firstname');
  const userLastname = localStorage.getItem('lastname');
  const userPrint = `${userFirstname} ${userLastname}`;
  
  return (
    dataPrint ? (
    <>
    <div className="divA4">
        <div className="divEdge30">
          <Row>
            <Col span={12} className="colLeft">{dataCompany[0]?.COMP_NM}</Col>
            <Col span={12} className="colLeft" style={{ fontWeight: 'bold', fontSize: '20px' }}>ใบรับเงิน</Col>
            
            <Col span={18} className="colLeft">{dataCompany[0]?.COMP_ADR1}</Col>
            <Col span={6} className="colLeft">เลขที่ใบเสร็จ : {dataPay[0]?.TMBILL}</Col>

            <Col span={12} className="colLeft">{dataCompany[0]?.TELP}</Col>
            <Col span={6} className="colLeft" style={{ fontWeight: 'bold', fontSize: '20px', color: 'red' }}>{dataPay[0]?.FLAG === 'C' ? 'ยกเลิก' : ''}</Col>
            <Col span={6} className="colLeft">วันที่พิมพ์ : {moment(dataPay[0]?.PAYDT).format('DD/MM/YYYY')}</Col>

            <Col span={13} className="colLeft">ชื่อลูกค้า : {dataDetail[0]?.NAME}</Col>
            <Col span={11} className="colLeft">เลขที่สัญญา : {dataDetail[0]?.CONTNO}</Col>

            <Col span={13} className="colLeft">ที่อยู่ : {dataDetail[0]?.ADDRES} {dataDetail[0]?.SOI} {dataDetail[0]?.TUMB}</Col>
            <Col span={11} className="colLeft">ยี่ห้อ {dataDetail[0]?.TYPE} รุ่น {dataDetail[0]?.MODEL} สี {dataDetail[0]?.COLOR}</Col>

            <Col span={13} className="colLeft">{dataDetail[0]?.AUMP} {dataDetail[0]?.PROVDES} {dataDetail[0]?.ZIP} โทร.{dataDetail[0]?.TELP}</Col>
            <Col span={11} className="colLeft">เลขตัวถัง {dataDetail[0]?.STRNO} ทะเบียน {dataDetail[0]?.REGNO}</Col>

            <Col span={24} className="colLeft" style={{marginBottom:'12px' }}>ชำระโดย : {dataPay[0]?.PAYDESC}</Col>
            {/* <Col span={11} className="colLeft">คงเหลือยกมา : {currencyFormatOne(dataPay[0]?.NCARCST)}</Col> */}

            <div className="table">
              <Row className="rowTable">
                <Col span={2} className="colTable">ลำดับ</Col>
                <Col span={11} className="colTable">ชำระค่า</Col>
                <Col span={2} className="colTable">หักเงินต้น</Col>
                <Col span={2} className="colTable">ดอกเบี้ย</Col>
                <Col span={2} className="colTable">ส่วนลด</Col>
                <Col span={2} className="colTable">ค่าทวงถาม</Col>
                <Col span={3} className="colTable">ลดค่าทวงถาม</Col>
                <Col span={2} className="colTable">รับสุทธิ</Col>
              </Row>
              <Row className="rowTable">
                <Col span={2} className="colTable" style={{ height: '100px' }}>1<br />{pay1 && 2}<br />{pay2 && 3}</Col>
                <Col span={11} className="colTableLeft">&nbsp;&nbsp;&nbsp;{dataPay[0]?.FORDESC}<br />
                &nbsp;&nbsp;&nbsp;{pay1 && pay1.FORDESC}<br />
                &nbsp;&nbsp;&nbsp;{pay2 && pay2.FORDESC}</Col>
                <Col span={2} className="colTable">{currencyFormatOne(dataPay[0]?.PAYAMT_N)}<br />
                {pay1 && currencyFormatOne(pay1.PAYAMT_N)}<br />
                {pay2 && currencyFormatOne(pay2.PAYAMT_N)}</Col>
                <Col span={2} className="colTable">{currencyFormatOne(dataPay[0]?.PAYAMT_V)}<br />
                {pay1 && currencyFormatOne(pay1.PAYAMT_V)}<br />
                {pay2 && currencyFormatOne(pay2.PAYAMT_V)}</Col>
                <Col span={2} className="colTable">{currencyFormatOne(dataPay[0]?.DISCT)}<br />
                {pay1 && currencyFormatOne(pay1.DISCT)}<br />
                {pay2 && currencyFormatOne(pay2.DISCT)}</Col>
                <Col span={2} className="colTable">{currencyFormatOne(dataPay[0]?.INTAMT)}<br />
                {pay1 && currencyFormatOne(pay1.INTAMT)}<br />
                {pay2 && currencyFormatOne(pay2.INTAMT)}</Col>
                <Col span={3} className="colTable">{currencyFormatOne(dataPay[0]?.DIFINTAMT)}<br />
                {pay1 && currencyFormatOne(pay1.DIFINTAMT)}<br />
                {pay2 && currencyFormatOne(pay2.DIFINTAMT)}</Col>
                <Col span={2} className="colTable">{currencyFormatOne(dataPay[0]?.NETPAY)}<br />
                {pay1 && currencyFormatOne(pay1.NETPAY)}<br />
                {pay2 && currencyFormatOne(pay2.NETPAY)}</Col>
              </Row>
              <Row className="rowTable">
                <Col span={4} className="colTable" style={{ flex: 2 }}>จำนวนเงินเป็นตัวอักษร</Col>
                <Col span={11} className="colTable" style={{ flex: 3 }}>{!pay3 && ArabicNumberToText(sumNETPAY)}</Col>
                <Col span={4} className="colTable">จำนวนเงิน</Col>
                <Col span={5} className="colTable">{!pay3 && currencyFormatOne(sumNETPAY)}</Col>
              </Row>
            </div>
            {/* <Col span={24} className="colLeft">ยอดเงินคงเหลือ : {currencyFormatOne(dataPay[0]?.Balance)}</Col> */}

            {/* <Col span={18} className="colLeft">ค้างชำระ 1.00 งวด เป็นเงิน 445.00 บาท จากงวดที่ 46.00 ถึงงวดที่ 46.00 ค้างดอกเบี้ยล่าช้า 0.00 บาท</Col> */}
            <Col span={18} className="colLeft"></Col>
            <Col span={6} className="colCenter">ผู้รับเงิน {userPrint}</Col>

            <Col span={18} className="colLeft" style={{marginBottom:'12px' }}>ใบเสร็จรับเงินฉบับนี้จะสมบูรณ์เมื่อปรากฎลายเซ็นของผู้รับเงินครบถ้วนและเช็คของท่านผ่านบัญชีบริษัทฯ เรียบร้อยแล้ว</Col>
            <Col span={6} className="colCenter">( ออกโดยระบบอัตโนมัติ )</Col>

            <Col span={24} className="colUnderline" />
            {/* ส่วนกลาง */}
            <Col span={13} className="colLeft">ใบนำฝากชำระเงินค่าสินค้าหรือบริการ (Bill Payment Pay-in-silp)</Col>
            <Col span={11} className="colRight">ส่วนของลูกค้า</Col>

            <Col span={13} className="colLeft">{dataCompany[0]?.COMP_NM}</Col>
            <Col span={11} className="colRight" style={{ marginBottom:'5px'}}>โปรดเรียกเก็บค่าธรรมเนียมจากผู้ชำระเงิน</Col>

            <Col span={12} className="colLeft">{dataCompany[0]?.COMP_ADR1} โทร.{dataCompany[0]?.TELP}</Col>
            <Col span={6} className="colTable">สาขา................................................</Col>
            <Col span={6} className="colTable">วันที่.................................................</Col>

            <Col span={12} className="colLeft" style={{ lineHeight: '16px',marginBottom:'12px'}}>
              <div className="box"></div><img src={P1} alt="" className="picA" />ธ.กสิกรไทย A/C No. (15/20)
              <br /><div className="box"></div><img src={P2} alt="" className="picA" />ธ.กรุงไทย A/C No. CODE : 6624 (15)
              <br /><div className="box"></div><img src={P3} alt="" className="picA" /> ธ. ไทยพานิชย์ A/C No. (Bill Payment) (15/20)
            </Col>

            <Col span={12} className="colTable" style={{ lineHeight: '16px',textAlign: 'Left' }}>&nbsp;ชื่อลูกค้า &nbsp;&nbsp;&nbsp; {dataDetail[0]?.NAME}
              <br />&nbsp;Customer No (Ref. No.1) &nbsp;&nbsp;&nbsp; 006
              <br />&nbsp;Customer No (Ref. No.2) &nbsp;&nbsp;&nbsp; {dataDetail[0]?.CONTNOCUT}</Col>
            <Col span={24} className="colLeft" style={{lineHeight: '16px',marginBottom:'20px' }}>
              <div className="box"></div><img src={P4} alt="" className="picA" />เคาน์เตอร์เซอร์วิส
              <br /> <div className="box"></div><img src={P5} alt="" className="picA" />เทสโก้โลตัส และ เทสโก้โลตัส เอ็กซ์เพรส</Col>

            <Col span={9} className="colCenter">รับชำระเป็นเงินสดเท่านั้น</Col>
            <Col span={5} className="colTableB">จำนวนเงิน/Amount</Col>
            <Col span={5} className="colTableB"></Col>
            <Col span={5} className="colTableB">บาท/Baht</Col>

            <Col span={9} className="colTableB">จำนวนเงินเป็นตัวอักษร/Amount in Words</Col>
            <Col span={15} className="colTableB"></Col>


            <Col span={15} className="colLeft">ผู้นำฝาก / Deposit By ........................................</Col>
            <Col span={9} className="colTableB">สำหรับเจ้าหน้าที่ธนาคาร</Col>

            <Col span={15} className="colLeft">โทรศัพท์ / Telephone ........................................</Col>
            <Col span={9} className="colTableB">ผู้รับเงิน ................................................................................</Col>

            <Col span={24} className="colUnderline" />
            {/* ส่วนล่าง */}
            <Col span={13} className="colLeft">ใบนำฝากชำระเงินค่าสินค้าหรือบริการ (Bill Payment Pay-in-silp)</Col>
            <Col span={11} className="colRight">ส่วนของธนาคาร</Col>

            <Col span={13} className="colLeft">{dataCompany[0]?.COMP_NM}</Col>
            <Col span={11} className="colRight" style={{ marginBottom:'5px'}}>โปรดเรียกเก็บค่าธรรมเนียมจากผู้ชำระเงิน</Col>

            <Col span={12} className="colLeft">{dataCompany[0]?.COMP_ADR1} โทร.{dataCompany[0]?.TELP}</Col>
            <Col span={6} className="colTable">สาขา................................................</Col>
            <Col span={6} className="colTable">วันที่.................................................</Col>

            <Col span={12} className="colLeft" style={{ lineHeight: '16px'}}>
              <div className="box"></div><img src={P1} alt="" className="picA" />ธ.กสิกรไทย A/C No. (15/20)
              <br /><div className="box"></div><img src={P2} alt="" className="picA" />ธ.กรุงไทย A/C No. CODE : 6624 (15)
              <br /><div className="box"></div><img src={P3} alt="" className="picA" /> ธ. ไทยพานิชย์ A/C No. (Bill Payment) (15/20)
            </Col>

            <Col span={12} className="colTable" style={{ lineHeight: '16px',textAlign: 'Left' }}>&nbsp;ชื่อลูกค้า &nbsp;&nbsp;&nbsp; {dataDetail[0]?.NAME}
              <br />&nbsp;Customer No (Ref. No.1) &nbsp;&nbsp;&nbsp; 006
              <br />&nbsp;Customer No (Ref. No.2) &nbsp;&nbsp;&nbsp; {dataDetail[0]?.CONTNOCUT}</Col>
            <Col span={24} className="colLeft" style={{lineHeight: '16px',marginBottom:'20px'}}>
              <div className="box"></div><img src={P4} alt="" className="picA" />เคาน์เตอร์เซอร์วิส
              <br /> <div className="box"></div><img src={P5} alt="" className="picA" />เทสโก้โลตัส และ เทสโก้โลตัส เอ็กซ์เพรส</Col>

            <Col span={9} className="colCenter">รับชำระเป็นเงินสดเท่านั้น</Col>
            <Col span={5} className="colTableB">จำนวนเงิน/Amount</Col>
            <Col span={5} className="colTableB"></Col>
            <Col span={5} className="colTableB">บาท/Baht</Col>

            <Col span={9} className="colTableB">จำนวนเงินเป็นตัวอักษร/Amount in Words</Col>
            <Col span={15} className="colTableB"></Col>

            <Col span={9} className="colLeft">ผู้นำฝาก / Deposit By ........................................</Col>
            <Col span={6} className="colLeft"><img alt="" style={{ position: "absolute", zIndex: -1,  border: "2px solid black"}} width={100} src={url} /></Col>
            <Col span={9} className="colTableB">สำหรับเจ้าหน้าที่ธนาคาร</Col>

            <Col span={9} className="colLeft">โทรศัพท์ / Telephone ........................................</Col>
            <Col span={6} className="colLeft"></Col>
            <Col span={9} className="colTableB">ผู้รับเงิน ................................................................................</Col>
          
          </Row>
        </div>
    </div>
    {pay3 && pay3.length !== 0 &&
    <div className="divA4">
      <div className="divEdge30">
        <Row>
          <Col span={12} className="colLeft">{dataCompany[0]?.COMP_NM}</Col>
          <Col span={12} className="colLeft" style={{ fontWeight: 'bold', fontSize: '20px' }}>ใบรับเงิน</Col>
          
          <Col span={18} className="colLeft">{dataCompany[0]?.COMP_ADR1}</Col>
          <Col span={6} className="colLeft">เลขที่ใบเสร็จ : {dataPay[0]?.TMBILL}</Col>

          <Col span={12} className="colLeft">{dataCompany[0]?.TELP}</Col>
          <Col span={6} className="colLeft" style={{ fontWeight: 'bold', fontSize: '20px', color: 'red' }}>{dataPay[0]?.FLAG === 'C' ? 'ยกเลิก' : ''}</Col>
          <Col span={6} className="colLeft">วันที่พิมพ์ : {moment(dataPay[0]?.PAYDT).format('DD/MM/YYYY')}</Col>

          <Col span={13} className="colLeft">ชื่อลูกค้า : {dataDetail[0]?.NAME}</Col>
          <Col span={11} className="colLeft">เลขที่สัญญา : {dataDetail[0]?.CONTNO}</Col>

          <Col span={13} className="colLeft">ที่อยู่ : {dataDetail[0]?.ADDRES} {dataDetail[0]?.SOI} {dataDetail[0]?.TUMB}</Col>
          <Col span={11} className="colLeft">ยี่ห้อ {dataDetail[0]?.TYPE} รุ่น {dataDetail[0]?.MODEL} สี {dataDetail[0]?.COLOR}</Col>

          <Col span={13} className="colLeft">{dataDetail[0]?.AUMP} {dataDetail[0]?.PROVDES} {dataDetail[0]?.ZIP} โทร.{dataDetail[0]?.TELP}</Col>
          <Col span={11} className="colLeft">เลขตัวถัง {dataDetail[0]?.STRNO} ทะเบียน {dataDetail[0]?.REGNO}</Col>

          <Col span={24} className="colLeft" style={{marginBottom:'12px' }}>ชำระโดย : {dataPay[0]?.PAYDESC}</Col>
          {/* <Col span={11} className="colLeft">คงเหลือยกมา : {currencyFormatOne(dataPay[0]?.NCARCST)}</Col> */}

          <div className="table">
            <Row className="rowTable">
              <Col span={2} className="colTable">ลำดับ</Col>
              <Col span={11} className="colTable">ชำระค่า</Col>
              <Col span={2} className="colTable">หักเงินต้น</Col>
              <Col span={2} className="colTable">ดอกเบี้ย</Col>
              <Col span={2} className="colTable">ส่วนลด</Col>
              <Col span={2} className="colTable">ค่าทวงถาม</Col>
              <Col span={3} className="colTable">ลดค่าทวงถาม</Col>
              <Col span={2} className="colTable">รับสุทธิ</Col>
            </Row>
            <Row className="rowTable">
              <Col span={2} className="colTable" style={{ height: '100px' }}>4<br />{pay4 && 5}<br />{pay5 && 6}</Col>
              <Col span={11} className="colTableLeft">&nbsp;&nbsp;&nbsp;{pay3 && pay3.FORDESC}<br />
              &nbsp;&nbsp;&nbsp;{pay4 && pay4.FORDESC}<br />
              &nbsp;&nbsp;&nbsp;{pay5 && pay5.FORDESC}</Col>
              <Col span={2} className="colTable">{pay3 && currencyFormatOne(pay3.PAYAMT_N)}<br />
              {pay4 && currencyFormatOne(pay4.PAYAMT_N)}<br />
              {pay5 && currencyFormatOne(pay5.PAYAMT_N)}</Col>
              <Col span={2} className="colTable">{pay3 && currencyFormatOne(pay3.PAYAMT_V)}<br />
              {pay4 && currencyFormatOne(pay4.PAYAMT_V)}<br />
              {pay5 && currencyFormatOne(pay5.PAYAMT_V)}</Col>
              <Col span={2} className="colTable">{pay3 && currencyFormatOne(pay3.DISCT)}<br />
              {pay4 && currencyFormatOne(pay4.DISCT)}<br />
              {pay5 && currencyFormatOne(pay5.DISCT)}</Col>
              <Col span={2} className="colTable">{pay3 && currencyFormatOne(pay3.INTAMT)}<br />
              {pay4 && currencyFormatOne(pay4.INTAMT)}<br />
              {pay5 && currencyFormatOne(pay5.INTAMT)}</Col>
              <Col span={3} className="colTable">{pay3 && currencyFormatOne(pay3.DIFINTAMT)}<br />
              {pay4 && currencyFormatOne(pay4.DIFINTAMT)}<br />
              {pay5 && currencyFormatOne(pay5.DIFINTAMT)}</Col>
              <Col span={2} className="colTable">{pay3 && currencyFormatOne(pay3.NETPAY)}<br />
              {pay4 && currencyFormatOne(pay4.NETPAY)}<br />
              {pay5 && currencyFormatOne(pay5.NETPAY)}</Col>
            </Row>
            <Row className="rowTable">
              <Col span={4} className="colTable" style={{ flex: 2 }}>จำนวนเงินเป็นตัวอักษร</Col>
              <Col span={11} className="colTable" style={{ flex: 3 }}>{!pay6 && ArabicNumberToText(sumNETPAY)}</Col>
              <Col span={4} className="colTable">จำนวนเงิน</Col>
              <Col span={5} className="colTable">{!pay6 && currencyFormatOne(sumNETPAY)}</Col>
            </Row>
          </div>
          {/* <Col span={24} className="colLeft">ยอดเงินคงเหลือ : {currencyFormatOne(dataPay[0]?.Balance)}</Col> */}

          {/* <Col span={18} className="colLeft">ค้างชำระ 1.00 งวด เป็นเงิน 445.00 บาท จากงวดที่ 46.00 ถึงงวดที่ 46.00 ค้างดอกเบี้ยล่าช้า 0.00 บาท</Col> */}
          <Col span={18} className="colLeft"></Col>
          <Col span={6} className="colCenter">ผู้รับเงิน {userPrint}</Col>

          <Col span={18} className="colLeft" style={{marginBottom:'12px' }}>ใบเสร็จรับเงินฉบับนี้จะสมบูรณ์เมื่อปรากฎลายเซ็นของผู้รับเงินครบถ้วนและเช็คของท่านผ่านบัญชีบริษัทฯ เรียบร้อยแล้ว</Col>
          <Col span={6} className="colCenter">( ออกโดยระบบอัตโนมัติ )</Col>

          <Col span={24} className="colUnderline" />
          {/* ส่วนกลาง */}
          <Col span={13} className="colLeft">ใบนำฝากชำระเงินค่าสินค้าหรือบริการ (Bill Payment Pay-in-silp)</Col>
          <Col span={11} className="colRight">ส่วนของลูกค้า</Col>

          <Col span={13} className="colLeft">{dataCompany[0]?.COMP_NM}</Col>
          <Col span={11} className="colRight" style={{ marginBottom:'5px'}}>โปรดเรียกเก็บค่าธรรมเนียมจากผู้ชำระเงิน</Col>

          <Col span={12} className="colLeft">{dataCompany[0]?.COMP_ADR1} โทร.{dataCompany[0]?.TELP}</Col>
          <Col span={6} className="colTable">สาขา................................................</Col>
          <Col span={6} className="colTable">วันที่.................................................</Col>

          <Col span={12} className="colLeft" style={{ lineHeight: '16px',marginBottom:'12px'}}>
            <div className="box"></div><img src={P1} alt="" className="picA" />ธ.กสิกรไทย A/C No. (15/20)
            <br /><div className="box"></div><img src={P2} alt="" className="picA" />ธ.กรุงไทย A/C No. CODE : 6624 (15)
            <br /><div className="box"></div><img src={P3} alt="" className="picA" /> ธ. ไทยพานิชย์ A/C No. (Bill Payment) (15/20)
          </Col>

          <Col span={12} className="colTable" style={{ lineHeight: '16px',textAlign: 'Left' }}>&nbsp;ชื่อลูกค้า &nbsp;&nbsp;&nbsp; {dataDetail[0]?.NAME}
            <br />&nbsp;Customer No (Ref. No.1) &nbsp;&nbsp;&nbsp; 006
            <br />&nbsp;Customer No (Ref. No.2) &nbsp;&nbsp;&nbsp; {dataDetail[0]?.CONTNOCUT}</Col>
          <Col span={24} className="colLeft" style={{lineHeight: '16px',marginBottom:'20px' }}>
            <div className="box"></div><img src={P4} alt="" className="picA" />เคาน์เตอร์เซอร์วิส
            <br /> <div className="box"></div><img src={P5} alt="" className="picA" />เทสโก้โลตัส และ เทสโก้โลตัส เอ็กซ์เพรส</Col>

          <Col span={9} className="colCenter">รับชำระเป็นเงินสดเท่านั้น</Col>
          <Col span={5} className="colTableB">จำนวนเงิน/Amount</Col>
          <Col span={5} className="colTableB"></Col>
          <Col span={5} className="colTableB">บาท/Baht</Col>

          <Col span={9} className="colTableB">จำนวนเงินเป็นตัวอักษร/Amount in Words</Col>
          <Col span={15} className="colTableB"></Col>


          <Col span={15} className="colLeft">ผู้นำฝาก / Deposit By ........................................</Col>
          <Col span={9} className="colTableB">สำหรับเจ้าหน้าที่ธนาคาร</Col>

          <Col span={15} className="colLeft">โทรศัพท์ / Telephone ........................................</Col>
          <Col span={9} className="colTableB">ผู้รับเงิน ................................................................................</Col>

          <Col span={24} className="colUnderline" />
          {/* ส่วนล่าง */}
          <Col span={13} className="colLeft">ใบนำฝากชำระเงินค่าสินค้าหรือบริการ (Bill Payment Pay-in-silp)</Col>
          <Col span={11} className="colRight">ส่วนของธนาคาร</Col>

          <Col span={13} className="colLeft">{dataCompany[0]?.COMP_NM}</Col>
          <Col span={11} className="colRight" style={{ marginBottom:'5px'}}>โปรดเรียกเก็บค่าธรรมเนียมจากผู้ชำระเงิน</Col>

          <Col span={12} className="colLeft">{dataCompany[0]?.COMP_ADR1} โทร.{dataCompany[0]?.TELP}</Col>
          <Col span={6} className="colTable">สาขา................................................</Col>
          <Col span={6} className="colTable">วันที่.................................................</Col>

          <Col span={12} className="colLeft" style={{ lineHeight: '16px'}}>
            <div className="box"></div><img src={P1} alt="" className="picA" />ธ.กสิกรไทย A/C No. (15/20)
            <br /><div className="box"></div><img src={P2} alt="" className="picA" />ธ.กรุงไทย A/C No. CODE : 6624 (15)
            <br /><div className="box"></div><img src={P3} alt="" className="picA" /> ธ. ไทยพานิชย์ A/C No. (Bill Payment) (15/20)
          </Col>

          <Col span={12} className="colTable" style={{ lineHeight: '16px',textAlign: 'Left' }}>&nbsp;ชื่อลูกค้า &nbsp;&nbsp;&nbsp; {dataDetail[0]?.NAME}
            <br />&nbsp;Customer No (Ref. No.1) &nbsp;&nbsp;&nbsp; 006
            <br />&nbsp;Customer No (Ref. No.2) &nbsp;&nbsp;&nbsp; {dataDetail[0]?.CONTNOCUT}</Col>
          <Col span={24} className="colLeft" style={{lineHeight: '16px',marginBottom:'20px'}}>
            <div className="box"></div><img src={P4} alt="" className="picA" />เคาน์เตอร์เซอร์วิส
            <br /> <div className="box"></div><img src={P5} alt="" className="picA" />เทสโก้โลตัส และ เทสโก้โลตัส เอ็กซ์เพรส</Col>

          <Col span={9} className="colCenter">รับชำระเป็นเงินสดเท่านั้น</Col>
          <Col span={5} className="colTableB">จำนวนเงิน/Amount</Col>
          <Col span={5} className="colTableB"></Col>
          <Col span={5} className="colTableB">บาท/Baht</Col>

          <Col span={9} className="colTableB">จำนวนเงินเป็นตัวอักษร/Amount in Words</Col>
          <Col span={15} className="colTableB"></Col>

          <Col span={9} className="colLeft">ผู้นำฝาก / Deposit By ........................................</Col>
          <Col span={6} className="colLeft"><img alt="" style={{ position: "absolute", zIndex: -1,  border: "2px solid black"}} width={100} src={url} /></Col>
          <Col span={9} className="colTableB">สำหรับเจ้าหน้าที่ธนาคาร</Col>

          <Col span={9} className="colLeft">โทรศัพท์ / Telephone ........................................</Col>
          <Col span={6} className="colLeft"></Col>
          <Col span={9} className="colTableB">ผู้รับเงิน ................................................................................</Col>
        
        </Row>
      </div>
    </div>
    }
    {pay6 && pay6.length !== 0 &&
    <div className="divA4">
      <div className="divEdge30">
        <Row>
          <Col span={12} className="colLeft">{dataCompany[0]?.COMP_NM}</Col>
          <Col span={12} className="colLeft" style={{ fontWeight: 'bold', fontSize: '20px' }}>ใบรับเงิน</Col>
          
          <Col span={18} className="colLeft">{dataCompany[0]?.COMP_ADR1}</Col>
          <Col span={6} className="colLeft">เลขที่ใบเสร็จ : {dataPay[0]?.TMBILL}</Col>

          <Col span={12} className="colLeft">{dataCompany[0]?.TELP}</Col>
          <Col span={6} className="colLeft" style={{ fontWeight: 'bold', fontSize: '20px', color: 'red' }}>{dataPay[0]?.FLAG === 'C' ? 'ยกเลิก' : ''}</Col>
          <Col span={6} className="colLeft">วันที่พิมพ์ : {moment(dataPay[0]?.PAYDT).format('DD/MM/YYYY')}</Col>

          <Col span={13} className="colLeft">ชื่อลูกค้า : {dataDetail[0]?.NAME}</Col>
          <Col span={11} className="colLeft">เลขที่สัญญา : {dataDetail[0]?.CONTNO}</Col>

          <Col span={13} className="colLeft">ที่อยู่ : {dataDetail[0]?.ADDRES} {dataDetail[0]?.SOI} {dataDetail[0]?.TUMB}</Col>
          <Col span={11} className="colLeft">ยี่ห้อ {dataDetail[0]?.TYPE} รุ่น {dataDetail[0]?.MODEL} สี {dataDetail[0]?.COLOR}</Col>

          <Col span={13} className="colLeft">{dataDetail[0]?.AUMP} {dataDetail[0]?.PROVDES} {dataDetail[0]?.ZIP} โทร.{dataDetail[0]?.TELP}</Col>
          <Col span={11} className="colLeft">เลขตัวถัง {dataDetail[0]?.STRNO} ทะเบียน {dataDetail[0]?.REGNO}</Col>

          <Col span={24} className="colLeft" style={{marginBottom:'12px' }}>ชำระโดย : {dataPay[0]?.PAYDESC}</Col>
          {/* <Col span={11} className="colLeft">คงเหลือยกมา : {currencyFormatOne(dataPay[0]?.NCARCST)}</Col> */}

          <div className="table">
            <Row className="rowTable">
              <Col span={2} className="colTable">ลำดับ</Col>
              <Col span={11} className="colTable">ชำระค่า</Col>
              <Col span={2} className="colTable">หักเงินต้น</Col>
              <Col span={2} className="colTable">ดอกเบี้ย</Col>
              <Col span={2} className="colTable">ส่วนลด</Col>
              <Col span={2} className="colTable">ค่าทวงถาม</Col>
              <Col span={3} className="colTable">ลดค่าทวงถาม</Col>
              <Col span={2} className="colTable">รับสุทธิ</Col>
            </Row>
            <Row className="rowTable">
              <Col span={2} className="colTable" style={{ height: '100px' }}>7<br />{pay7 && 8}<br />{pay8 && 9}</Col>
              <Col span={11} className="colTableLeft">&nbsp;&nbsp;&nbsp;{pay6 && pay6.FORDESC}<br />
              &nbsp;&nbsp;&nbsp;{pay7 && pay7.FORDESC}<br />
              &nbsp;&nbsp;&nbsp;{pay8 && pay8.FORDESC}</Col>
              <Col span={2} className="colTable">{pay6 && currencyFormatOne(pay6.PAYAMT_N)}<br />
              {pay7 && currencyFormatOne(pay7.PAYAMT_N)}<br />
              {pay8 && currencyFormatOne(pay8.PAYAMT_N)}</Col>
              <Col span={2} className="colTable">{pay6 && currencyFormatOne(pay6.PAYAMT_V)}<br />
              {pay7 && currencyFormatOne(pay7.PAYAMT_V)}<br />
              {pay8 && currencyFormatOne(pay8.PAYAMT_V)}</Col>
              <Col span={2} className="colTable">{pay6 && currencyFormatOne(pay6.DISCT)}<br />
              {pay7 && currencyFormatOne(pay7.DISCT)}<br />
              {pay8 && currencyFormatOne(pay8.DISCT)}</Col>
              <Col span={2} className="colTable">{pay6 && currencyFormatOne(pay6.INTAMT)}<br />
              {pay7 && currencyFormatOne(pay7.INTAMT)}<br />
              {pay8 && currencyFormatOne(pay8.INTAMT)}</Col>
              <Col span={3} className="colTable">{pay6 && currencyFormatOne(pay6.DIFINTAMT)}<br />
              {pay7 && currencyFormatOne(pay7.DIFINTAMT)}<br />
              {pay8 && currencyFormatOne(pay8.DIFINTAMT)}</Col>
              <Col span={2} className="colTable">{pay6 && currencyFormatOne(pay6.NETPAY)}<br />
              {pay7 && currencyFormatOne(pay7.NETPAY)}<br />
              {pay8 && currencyFormatOne(pay8.NETPAY)}</Col>
            </Row>
            <Row className="rowTable">
              <Col span={4} className="colTable" style={{ flex: 2 }}>จำนวนเงินเป็นตัวอักษร</Col>
              <Col span={11} className="colTable" style={{ flex: 3 }}>{!pay9 && ArabicNumberToText(sumNETPAY)}</Col>
              <Col span={4} className="colTable">จำนวนเงิน</Col>
              <Col span={5} className="colTable">{!pay9 && currencyFormatOne(sumNETPAY)}</Col>
            </Row>
          </div>
          {/* <Col span={24} className="colLeft">ยอดเงินคงเหลือ : {currencyFormatOne(dataPay[0]?.Balance)}</Col> */}

          {/* <Col span={18} className="colLeft">ค้างชำระ 1.00 งวด เป็นเงิน 445.00 บาท จากงวดที่ 46.00 ถึงงวดที่ 46.00 ค้างดอกเบี้ยล่าช้า 0.00 บาท</Col> */}
          <Col span={18} className="colLeft"></Col>
          <Col span={6} className="colCenter">ผู้รับเงิน {userPrint}</Col>

          <Col span={18} className="colLeft" style={{marginBottom:'12px' }}>ใบเสร็จรับเงินฉบับนี้จะสมบูรณ์เมื่อปรากฎลายเซ็นของผู้รับเงินครบถ้วนและเช็คของท่านผ่านบัญชีบริษัทฯ เรียบร้อยแล้ว</Col>
          <Col span={6} className="colCenter">( ออกโดยระบบอัตโนมัติ )</Col>

          <Col span={24} className="colUnderline" />
          {/* ส่วนกลาง */}
          <Col span={13} className="colLeft">ใบนำฝากชำระเงินค่าสินค้าหรือบริการ (Bill Payment Pay-in-silp)</Col>
          <Col span={11} className="colRight">ส่วนของลูกค้า</Col>

          <Col span={13} className="colLeft">{dataCompany[0]?.COMP_NM}</Col>
          <Col span={11} className="colRight" style={{ marginBottom:'5px'}}>โปรดเรียกเก็บค่าธรรมเนียมจากผู้ชำระเงิน</Col>

          <Col span={12} className="colLeft">{dataCompany[0]?.COMP_ADR1} โทร.{dataCompany[0]?.TELP}</Col>
          <Col span={6} className="colTable">สาขา................................................</Col>
          <Col span={6} className="colTable">วันที่.................................................</Col>

          <Col span={12} className="colLeft" style={{ lineHeight: '16px',marginBottom:'12px'}}>
            <div className="box"></div><img src={P1} alt="" className="picA" />ธ.กสิกรไทย A/C No. (15/20)
            <br /><div className="box"></div><img src={P2} alt="" className="picA" />ธ.กรุงไทย A/C No. CODE : 6624 (15)
            <br /><div className="box"></div><img src={P3} alt="" className="picA" /> ธ. ไทยพานิชย์ A/C No. (Bill Payment) (15/20)
          </Col>

          <Col span={12} className="colTable" style={{ lineHeight: '16px',textAlign: 'Left' }}>&nbsp;ชื่อลูกค้า &nbsp;&nbsp;&nbsp; {dataDetail[0]?.NAME}
            <br />&nbsp;Customer No (Ref. No.1) &nbsp;&nbsp;&nbsp; 006
            <br />&nbsp;Customer No (Ref. No.2) &nbsp;&nbsp;&nbsp; {dataDetail[0]?.CONTNOCUT}</Col>
          <Col span={24} className="colLeft" style={{lineHeight: '16px',marginBottom:'20px' }}>
            <div className="box"></div><img src={P4} alt="" className="picA" />เคาน์เตอร์เซอร์วิส
            <br /> <div className="box"></div><img src={P5} alt="" className="picA" />เทสโก้โลตัส และ เทสโก้โลตัส เอ็กซ์เพรส</Col>

          <Col span={9} className="colCenter">รับชำระเป็นเงินสดเท่านั้น</Col>
          <Col span={5} className="colTableB">จำนวนเงิน/Amount</Col>
          <Col span={5} className="colTableB"></Col>
          <Col span={5} className="colTableB">บาท/Baht</Col>

          <Col span={9} className="colTableB">จำนวนเงินเป็นตัวอักษร/Amount in Words</Col>
          <Col span={15} className="colTableB"></Col>


          <Col span={15} className="colLeft">ผู้นำฝาก / Deposit By ........................................</Col>
          <Col span={9} className="colTableB">สำหรับเจ้าหน้าที่ธนาคาร</Col>

          <Col span={15} className="colLeft">โทรศัพท์ / Telephone ........................................</Col>
          <Col span={9} className="colTableB">ผู้รับเงิน ................................................................................</Col>

          <Col span={24} className="colUnderline" />
          {/* ส่วนล่าง */}
          <Col span={13} className="colLeft">ใบนำฝากชำระเงินค่าสินค้าหรือบริการ (Bill Payment Pay-in-silp)</Col>
          <Col span={11} className="colRight">ส่วนของธนาคาร</Col>

          <Col span={13} className="colLeft">{dataCompany[0]?.COMP_NM}</Col>
          <Col span={11} className="colRight" style={{ marginBottom:'5px'}}>โปรดเรียกเก็บค่าธรรมเนียมจากผู้ชำระเงิน</Col>

          <Col span={12} className="colLeft">{dataCompany[0]?.COMP_ADR1} โทร.{dataCompany[0]?.TELP}</Col>
          <Col span={6} className="colTable">สาขา................................................</Col>
          <Col span={6} className="colTable">วันที่.................................................</Col>

          <Col span={12} className="colLeft" style={{ lineHeight: '16px'}}>
            <div className="box"></div><img src={P1} alt="" className="picA" />ธ.กสิกรไทย A/C No. (15/20)
            <br /><div className="box"></div><img src={P2} alt="" className="picA" />ธ.กรุงไทย A/C No. CODE : 6624 (15)
            <br /><div className="box"></div><img src={P3} alt="" className="picA" /> ธ. ไทยพานิชย์ A/C No. (Bill Payment) (15/20)
          </Col>

          <Col span={12} className="colTable" style={{ lineHeight: '16px',textAlign: 'Left' }}>&nbsp;ชื่อลูกค้า &nbsp;&nbsp;&nbsp; {dataDetail[0]?.NAME}
            <br />&nbsp;Customer No (Ref. No.1) &nbsp;&nbsp;&nbsp; 006
            <br />&nbsp;Customer No (Ref. No.2) &nbsp;&nbsp;&nbsp; {dataDetail[0]?.CONTNOCUT}</Col>
          <Col span={24} className="colLeft" style={{lineHeight: '16px',marginBottom:'20px'}}>
            <div className="box"></div><img src={P4} alt="" className="picA" />เคาน์เตอร์เซอร์วิส
            <br /> <div className="box"></div><img src={P5} alt="" className="picA" />เทสโก้โลตัส และ เทสโก้โลตัส เอ็กซ์เพรส</Col>

          <Col span={9} className="colCenter">รับชำระเป็นเงินสดเท่านั้น</Col>
          <Col span={5} className="colTableB">จำนวนเงิน/Amount</Col>
          <Col span={5} className="colTableB"></Col>
          <Col span={5} className="colTableB">บาท/Baht</Col>

          <Col span={9} className="colTableB">จำนวนเงินเป็นตัวอักษร/Amount in Words</Col>
          <Col span={15} className="colTableB"></Col>

          <Col span={9} className="colLeft">ผู้นำฝาก / Deposit By ........................................</Col>
          <Col span={6} className="colLeft"><img alt="" style={{ position: "absolute", zIndex: -1,  border: "2px solid black"}} width={100} src={url} /></Col>
          <Col span={9} className="colTableB">สำหรับเจ้าหน้าที่ธนาคาร</Col>

          <Col span={9} className="colLeft">โทรศัพท์ / Telephone ........................................</Col>
          <Col span={6} className="colLeft"></Col>
          <Col span={9} className="colTableB">ผู้รับเงิน ................................................................................</Col>
        
        </Row>
      </div>
    </div>
    }
    {pay9 && pay9.length !== 0 &&
    <div className="divA4">
      <div className="divEdge30">
        <Row>
          <Col span={12} className="colLeft">{dataCompany[0]?.COMP_NM}</Col>
          <Col span={12} className="colLeft" style={{ fontWeight: 'bold', fontSize: '20px' }}>ใบรับเงิน</Col>
          
          <Col span={18} className="colLeft">{dataCompany[0]?.COMP_ADR1}</Col>
          <Col span={6} className="colLeft">เลขที่ใบเสร็จ : {dataPay[0]?.TMBILL}</Col>

          <Col span={12} className="colLeft">{dataCompany[0]?.TELP}</Col>
          <Col span={6} className="colLeft" style={{ fontWeight: 'bold', fontSize: '20px', color: 'red' }}>{dataPay[0]?.FLAG === 'C' ? 'ยกเลิก' : ''}</Col>
          <Col span={6} className="colLeft">วันที่พิมพ์ : {moment(dataPay[0]?.PAYDT).format('DD/MM/YYYY')}</Col>

          <Col span={13} className="colLeft">ชื่อลูกค้า : {dataDetail[0]?.NAME}</Col>
          <Col span={11} className="colLeft">เลขที่สัญญา : {dataDetail[0]?.CONTNO}</Col>

          <Col span={13} className="colLeft">ที่อยู่ : {dataDetail[0]?.ADDRES} {dataDetail[0]?.SOI} {dataDetail[0]?.TUMB}</Col>
          <Col span={11} className="colLeft">ยี่ห้อ {dataDetail[0]?.TYPE} รุ่น {dataDetail[0]?.MODEL} สี {dataDetail[0]?.COLOR}</Col>

          <Col span={13} className="colLeft">{dataDetail[0]?.AUMP} {dataDetail[0]?.PROVDES} {dataDetail[0]?.ZIP} โทร.{dataDetail[0]?.TELP}</Col>
          <Col span={11} className="colLeft">เลขตัวถัง {dataDetail[0]?.STRNO} ทะเบียน {dataDetail[0]?.REGNO}</Col>

          <Col span={24} className="colLeft" style={{marginBottom:'12px' }}>ชำระโดย : {dataPay[0]?.PAYDESC}</Col>
          {/* <Col span={11} className="colLeft">คงเหลือยกมา : {currencyFormatOne(dataPay[0]?.NCARCST)}</Col> */}

          <div className="table">
            <Row className="rowTable">
              <Col span={2} className="colTable">ลำดับ</Col>
              <Col span={11} className="colTable">ชำระค่า</Col>
              <Col span={2} className="colTable">หักเงินต้น</Col>
              <Col span={2} className="colTable">ดอกเบี้ย</Col>
              <Col span={2} className="colTable">ส่วนลด</Col>
              <Col span={2} className="colTable">ค่าทวงถาม</Col>
              <Col span={3} className="colTable">ลดค่าทวงถาม</Col>
              <Col span={2} className="colTable">รับสุทธิ</Col>
            </Row>
            <Row className="rowTable">
              <Col span={2} className="colTable" style={{ height: '100px' }}>10<br />{pay10 && 11}<br />{pay11 && 12}</Col>
              <Col span={11} className="colTableLeft">&nbsp;&nbsp;&nbsp;{pay9 && pay9.FORDESC}<br />
              &nbsp;&nbsp;&nbsp;{pay10 && pay10.FORDESC}<br />
              &nbsp;&nbsp;&nbsp;{pay11 && pay11.FORDESC}</Col>
              <Col span={2} className="colTable">{pay9 && currencyFormatOne(pay9.PAYAMT_N)}<br />
              {pay10 && currencyFormatOne(pay10.PAYAMT_N)}<br />
              {pay11 && currencyFormatOne(pay11.PAYAMT_N)}</Col>
              <Col span={2} className="colTable">{pay9 && currencyFormatOne(pay9.PAYAMT_V)}<br />
              {pay10 && currencyFormatOne(pay10.PAYAMT_V)}<br />
              {pay11 && currencyFormatOne(pay11.PAYAMT_V)}</Col>
              <Col span={2} className="colTable">{pay9 && currencyFormatOne(pay9.DISCT)}<br />
              {pay10 && currencyFormatOne(pay10.DISCT)}<br />
              {pay11 && currencyFormatOne(pay11.DISCT)}</Col>
              <Col span={2} className="colTable">{pay9 && currencyFormatOne(pay9.INTAMT)}<br />
              {pay10 && currencyFormatOne(pay10.INTAMT)}<br />
              {pay11 && currencyFormatOne(pay11.INTAMT)}</Col>
              <Col span={3} className="colTable">{pay9 && currencyFormatOne(pay9.DIFINTAMT)}<br />
              {pay10 && currencyFormatOne(pay10.DIFINTAMT)}<br />
              {pay11 && currencyFormatOne(pay11.DIFINTAMT)}</Col>
              <Col span={2} className="colTable">{pay9 && currencyFormatOne(pay9.NETPAY)}<br />
              {pay10 && currencyFormatOne(pay10.NETPAY)}<br />
              {pay11 && currencyFormatOne(pay11.NETPAY)}</Col>
            </Row>
            <Row className="rowTable">
              <Col span={4} className="colTable" style={{ flex: 2 }}>จำนวนเงินเป็นตัวอักษร</Col>
              <Col span={11} className="colTable" style={{ flex: 3 }}>{ArabicNumberToText(sumNETPAY)}</Col>
              <Col span={4} className="colTable">จำนวนเงิน</Col>
              <Col span={5} className="colTable">{currencyFormatOne(sumNETPAY)}</Col>
            </Row>
          </div>
          {/* <Col span={24} className="colLeft">ยอดเงินคงเหลือ : {currencyFormatOne(dataPay[0]?.Balance)}</Col> */}

          {/* <Col span={18} className="colLeft">ค้างชำระ 1.00 งวด เป็นเงิน 445.00 บาท จากงวดที่ 46.00 ถึงงวดที่ 46.00 ค้างดอกเบี้ยล่าช้า 0.00 บาท</Col> */}
          <Col span={18} className="colLeft"></Col>
          <Col span={6} className="colCenter">ผู้รับเงิน {userPrint}</Col>

          <Col span={18} className="colLeft" style={{marginBottom:'12px' }}>ใบเสร็จรับเงินฉบับนี้จะสมบูรณ์เมื่อปรากฎลายเซ็นของผู้รับเงินครบถ้วนและเช็คของท่านผ่านบัญชีบริษัทฯ เรียบร้อยแล้ว</Col>
          <Col span={6} className="colCenter">( ออกโดยระบบอัตโนมัติ )</Col>

          <Col span={24} className="colUnderline" />
          {/* ส่วนกลาง */}
          <Col span={13} className="colLeft">ใบนำฝากชำระเงินค่าสินค้าหรือบริการ (Bill Payment Pay-in-silp)</Col>
          <Col span={11} className="colRight">ส่วนของลูกค้า</Col>

          <Col span={13} className="colLeft">{dataCompany[0]?.COMP_NM}</Col>
          <Col span={11} className="colRight" style={{ marginBottom:'5px'}}>โปรดเรียกเก็บค่าธรรมเนียมจากผู้ชำระเงิน</Col>

          <Col span={12} className="colLeft">{dataCompany[0]?.COMP_ADR1} โทร.{dataCompany[0]?.TELP}</Col>
          <Col span={6} className="colTable">สาขา................................................</Col>
          <Col span={6} className="colTable">วันที่.................................................</Col>

          <Col span={12} className="colLeft" style={{ lineHeight: '16px',marginBottom:'12px'}}>
            <div className="box"></div><img src={P1} alt="" className="picA" />ธ.กสิกรไทย A/C No. (15/20)
            <br /><div className="box"></div><img src={P2} alt="" className="picA" />ธ.กรุงไทย A/C No. CODE : 6624 (15)
            <br /><div className="box"></div><img src={P3} alt="" className="picA" /> ธ. ไทยพานิชย์ A/C No. (Bill Payment) (15/20)
          </Col>

          <Col span={12} className="colTable" style={{ lineHeight: '16px',textAlign: 'Left' }}>&nbsp;ชื่อลูกค้า &nbsp;&nbsp;&nbsp; {dataDetail[0]?.NAME}
            <br />&nbsp;Customer No (Ref. No.1) &nbsp;&nbsp;&nbsp; 006
            <br />&nbsp;Customer No (Ref. No.2) &nbsp;&nbsp;&nbsp; {dataDetail[0]?.CONTNOCUT}</Col>
          <Col span={24} className="colLeft" style={{lineHeight: '16px',marginBottom:'20px' }}>
            <div className="box"></div><img src={P4} alt="" className="picA" />เคาน์เตอร์เซอร์วิส
            <br /> <div className="box"></div><img src={P5} alt="" className="picA" />เทสโก้โลตัส และ เทสโก้โลตัส เอ็กซ์เพรส</Col>

          <Col span={9} className="colCenter">รับชำระเป็นเงินสดเท่านั้น</Col>
          <Col span={5} className="colTableB">จำนวนเงิน/Amount</Col>
          <Col span={5} className="colTableB"></Col>
          <Col span={5} className="colTableB">บาท/Baht</Col>

          <Col span={9} className="colTableB">จำนวนเงินเป็นตัวอักษร/Amount in Words</Col>
          <Col span={15} className="colTableB"></Col>


          <Col span={15} className="colLeft">ผู้นำฝาก / Deposit By ........................................</Col>
          <Col span={9} className="colTableB">สำหรับเจ้าหน้าที่ธนาคาร</Col>

          <Col span={15} className="colLeft">โทรศัพท์ / Telephone ........................................</Col>
          <Col span={9} className="colTableB">ผู้รับเงิน ................................................................................</Col>

          <Col span={24} className="colUnderline" />
          {/* ส่วนล่าง */}
          <Col span={13} className="colLeft">ใบนำฝากชำระเงินค่าสินค้าหรือบริการ (Bill Payment Pay-in-silp)</Col>
          <Col span={11} className="colRight">ส่วนของธนาคาร</Col>

          <Col span={13} className="colLeft">{dataCompany[0]?.COMP_NM}</Col>
          <Col span={11} className="colRight" style={{ marginBottom:'5px'}}>โปรดเรียกเก็บค่าธรรมเนียมจากผู้ชำระเงิน</Col>

          <Col span={12} className="colLeft">{dataCompany[0]?.COMP_ADR1} โทร.{dataCompany[0]?.TELP}</Col>
          <Col span={6} className="colTable">สาขา................................................</Col>
          <Col span={6} className="colTable">วันที่.................................................</Col>

          <Col span={12} className="colLeft" style={{ lineHeight: '16px'}}>
            <div className="box"></div><img src={P1} alt="" className="picA" />ธ.กสิกรไทย A/C No. (15/20)
            <br /><div className="box"></div><img src={P2} alt="" className="picA" />ธ.กรุงไทย A/C No. CODE : 6624 (15)
            <br /><div className="box"></div><img src={P3} alt="" className="picA" /> ธ. ไทยพานิชย์ A/C No. (Bill Payment) (15/20)
          </Col>

          <Col span={12} className="colTable" style={{ lineHeight: '16px',textAlign: 'Left' }}>&nbsp;ชื่อลูกค้า &nbsp;&nbsp;&nbsp; {dataDetail[0]?.NAME}
            <br />&nbsp;Customer No (Ref. No.1) &nbsp;&nbsp;&nbsp; 006
            <br />&nbsp;Customer No (Ref. No.2) &nbsp;&nbsp;&nbsp; {dataDetail[0]?.CONTNOCUT}</Col>
          <Col span={24} className="colLeft" style={{lineHeight: '16px',marginBottom:'20px'}}>
            <div className="box"></div><img src={P4} alt="" className="picA" />เคาน์เตอร์เซอร์วิส
            <br /> <div className="box"></div><img src={P5} alt="" className="picA" />เทสโก้โลตัส และ เทสโก้โลตัส เอ็กซ์เพรส</Col>

          <Col span={9} className="colCenter">รับชำระเป็นเงินสดเท่านั้น</Col>
          <Col span={5} className="colTableB">จำนวนเงิน/Amount</Col>
          <Col span={5} className="colTableB"></Col>
          <Col span={5} className="colTableB">บาท/Baht</Col>

          <Col span={9} className="colTableB">จำนวนเงินเป็นตัวอักษร/Amount in Words</Col>
          <Col span={15} className="colTableB"></Col>

          <Col span={9} className="colLeft">ผู้นำฝาก / Deposit By ........................................</Col>
          <Col span={6} className="colLeft"><img alt="" style={{ position: "absolute", zIndex: -1,  border: "2px solid black"}} width={100} src={url} /></Col>
          <Col span={9} className="colTableB">สำหรับเจ้าหน้าที่ธนาคาร</Col>

          <Col span={9} className="colLeft">โทรศัพท์ / Telephone ........................................</Col>
          <Col span={6} className="colLeft"></Col>
          <Col span={9} className="colTableB">ผู้รับเงิน ................................................................................</Col>
        
        </Row>
      </div>
    </div>
    }

 


      </>
    ) : null
  );
}
export default Payment;
