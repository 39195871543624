import React, { useState, useEffect } from "react";
import { Button, Col, Row, Tag, Space, Card, message, Form, Divider, Image, Modal, notification, Spin, Checkbox } from 'antd';
// import { PrinterOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux';
import axios from "axios";
import ModalAddGuarantor from "./Guarantor/ModalAddGuarantor";
import dayjs from 'dayjs';
import ModalEditGuarantor from "./Guarantor/ModalEditGuarantor";
// import ViewGuarantor from "./Guarantor/ViewGuarantor";
// import ModalDrescriptionPrint from "./ModalDrescriptionPrint";
import Note from "./Note";
import TextArea from "antd/es/input/TextArea";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import ModalShowImages from "../../../ModalShowImageSlide/ModalShowImages";
import {
  addcheckbox1, checkguarantors1, findcarpg, findlandpn, loadOJS, makegarno, updatgarno1, getImagess,
  getAllFolderGuaImg, detailBroker, getImagessGua,
  loadDetailAunSendPG,
  getImagessPre,
  loadBunshe3
} from "../../../file_mid/all_api";
import { ShowDataBrokersAD } from '../../../file_mid/show_data_broker';
import ModalMainNote from "./ModalMainNote";

import {
  DownloadOutlined,
  LeftOutlined,
  RightOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
  SwapOutlined,
  UndoOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
} from '@ant-design/icons';
import { auto } from "async";

function Conclusion({ close, getData1, sendback, memo, loanRe, isu, dataFromTable }) {

  const token = localStorage.getItem('token');
  const branch = localStorage.getItem('branch');
  const username = localStorage.getItem('username');
  const [form] = Form.useForm()
  const [formcb] = Form.useForm()
  const { confirm } = Modal
  const customers = useSelector(state => state.customers)
  const guarantor = useSelector(state => state.guarantor)
  const [dataPost] = useState(customers.data); //มาจากรีดัค
  const [getNotification, setGetNotification] = useState();

  const [modalAddGuarantor, setModalAddGuarantor] = useState(false)
  const [ModalEditData, setModalEditData] = useState(false)

  const [dataBroker, setDataBroker] = useState([])
  const [modalnote, setModalNote] = useState(false)
  const [appStatus, setAppStatus] = useState({})

  const [getData] = useState({})
  const [getCarId] = useState(getData1?.car?.carId)
  const [dataedit, setDataEdit] = useState([]); // for edit
  const [index1, setIndex] = useState();
  const [guarantorData, setGuarantorData] = useState([])
  const [api, contextHolder] = notification.useNotification();

  const [address, setAddresss] = useState(getData1?.address)
  const [dataPhoneCus, setDataPhoneCus] = useState(getData1?.phones);
  const [dataSocials] = useState([]);
  const [loading, setLoading] = useState(false);
  const [addCount, setAddCount] = useState(0);
  const [dataNote, setDataNote] = useState([]);
  const [checkApprove, setCheckApprove] = useState(0);
  const [resetPage, setResetPage] = useState();
  const [career, setCareer] = useState([]) // เก็บข้อมูลอาชีพ
  const [cbData, setCbData] = useState();
  const [dataMemo, setDataMemo] = useState(memo);

  // image
  const [openShowImage, setOpenShowImage] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  // แยก type image car
  const [carimage1, setCarImage1] = useState([]);
  const [carimage2, setCarImage2] = useState([]);
  const [carimage3, setCarImage3] = useState([]);
  const [carimage4, setCarImage4] = useState([]);
  const [carimage5, setCarImage5] = useState([]);
  const [carimage6, setCarImage6] = useState([]);
  const [carimage7, setCarImage7] = useState([]);
  const [carimage8, setCarImage8] = useState([]);
  const [carimage9, setCarImage9] = useState([]);
  const [carimage10, setCarImage10] = useState([]);
  const [carimage11, setCarImage11] = useState([]);
  const [carimage12, setCarImage12] = useState([]);
  const [carimage14, setCarImage14] = useState([]);
  const [nuarantorNum, setGuarantorNum] = useState(); //send to Modal Show Image
  const [nuarantorNew, setGuarantorNew] = useState([]); //send to Modal Show Image
  const [carshowMadol, setCarShowModal] = useState([]); //send to Modal Show Image

  const [getimages, setGetImages] = useState([]);
  const [newDataLand, setNewDataLand] = useState([]);
  const [checkGua, setCheckGua] = useState();

  const [moonnee, setMoonnee] = useState()
  const [hug, setHug] = useState()
  const [kong, setKong] = useState()
  const [jaimoney, setJaimoney] = useState()
  const [yodjut, setYodjut] = useState()
  const [dateceo, setDateceo] = useState()
  const [exp, setExp] = useState()
  const [ngod, setNgod] = useState()
  const [pon, setPon] = useState()
  const [dataApproved, setDataApproved] = useState();

  const [imageBlobzz, setImageBlobzz] = useState([]);
  const [current, setCurrent] = React.useState(0);

  const [selectedImages, setSelectedImages] = useState([]);
  const splitISSUNO = dataFromTable?.ISSUNO?.split("-");
  const [newdata, setNewData] = useState();
  const [DATELOAD, setDATELOAD] = useState(dayjs().add(7, 'day').format('YYYY-MM-DD'))

  // console.log('dataFromTable', dataFromTable)

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'ArrowRight') {
        setCurrentIndex((prevItem) => (prevItem + 1));
        // setCurrentIndex((prevItem) => (prevItem + 1) % carimage1.length);
      } else if (event.key === 'ArrowLeft') {
        setCurrentIndex((prevItem) => (prevItem - 1));
        // setCurrentIndex((prevItem) => (prevItem - 1) % carimage1.length);
      }
    };

    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  const imageCount1 = carimage1?.length || 0; // นับจำนวนภาพจริง
  const imageCount2 = carimage2?.length || 0; // นับจำนวนภาพจริง
  const imageCount3 = carimage3?.length || 0; // นับจำนวนภาพจริง
  const imageCount4 = carimage4?.length || 0; // นับจำนวนภาพจริง
  const imageCount5 = carimage5?.length || 0; // นับจำนวนภาพจริง
  const imageCount6 = carimage6?.length || 0; // นับจำนวนภาพจริง
  const imageCount7 = carimage7?.length || 0; // นับจำนวนภาพจริง
  const imageCount8 = carimage8?.length || 0; // นับจำนวนภาพจริง
  const imageCount9 = carimage9?.length || 0; // นับจำนวนภาพจริง
  const imageCount10 = carimage10?.length || 0; // นับจำนวนภาพจริง
  const imageCount11 = carimage11?.length || 0; // นับจำนวนภาพจริง
  const imageCount12 = carimage12?.length || 0; // นับจำนวนภาพจริง
  const imageCount14 = carimage14?.length || 0; // นับจำนวนภาพจริง

  useEffect(() => {
    loadBroker()
    loadCareer()
    // loadData()
    setAddresss(getData1?.address)
    setDataPhoneCus(getData1?.phones)
    setDataMemo(memo)
    // setDataEmail(getData1.emails)
    // setDataSocials(getData1.socials)
    if (guarantor.data.typeLoan === "car") {
      setCheckGua(getData1?.cars?.carLoanDetails?.checkGua)
      if (getData1?.cars?.carLoanDetails?.checkbox?.cbId === 0) {
        setCbData({
          ...cbData,
          loantype: "car",
          loanId: getData1?.cars?.carLoanDetails?.loanId,
          cbCar: false,
          cbLand: false,
          cbHome: false,
          cbHomeRent: false,
          cbHomeGov: false,
          cbPark: false,
          cbPreaaprove: false,
          cbGps: false,
          cbVat: false,
          cbVdo: false,
        })
      } else {
        setCbData(getData1?.cars?.carLoanDetails?.checkbox)
        formcb.setFieldsValue({
          ...cbData
        })
      }
      setDataNote(getData1?.cars?.carLoanDetails?.notes)
      // var dataloadGua = 
      loadGuarantors({ typeLoan: guarantor.data.typeLoan, loanId: getData1?.cars?.carLoanDetails?.loanId })
      calYellowCard(getData1?.cars?.carsApproveCeo)
    } else if (guarantor.data.typeLoan === "land") {
      setCheckGua(getData1?.lands?.landLoanDetails?.checkGua)
      loadDataNew()
      if (getData1?.lands?.landLoanDetails?.checkbox?.cbId === 0) {
        setCbData({
          ...cbData,
          loantype: "land",
          loanId: getData1?.lands?.landLoanDetails?.landLoanId,
          cbCar: false,
          cbLand: false,
          cbHome: false,
          cbHomeRent: false,
          cbHomeGov: false,
          cbPark: false,
          cbPreaaprove: false,
          cbGps: false,
          cbVat: false,
          cbVdo: false,
        })
      } else {
        setCbData(getData1?.lands?.landLoanDetails?.checkbox)
        formcb.setFieldsValue({
          ...cbData
        })
      }
      setDataNote(getData1?.lands?.landLoanDetails?.notes)
      loadGuarantors({ typeLoan: guarantor.data.typeLoan, loanId: getData1?.lands?.landLoanDetails?.landLoanId })
    }
    if (getData1?.cars?.carLoanDetails?.approvalStatus === 3 || getData1?.lands?.landLoanDetails?.approvalStatus === 3) {
      setTimeout(() => {
        onApprove()
        close(false)
      }, 1500)
    } else if (getData1?.cars?.carLoanDetails?.approvalStatus === 21 || getData1?.lands?.landLoanDetails?.approvalStatus === 21) {
      setTimeout(() => {
        onApprove()
        close(false)
      }, 1500)
    } else if (getData1?.cars?.carLoanDetails?.approvalStatus === 22 || getData1?.lands?.landLoanDetails?.approvalStatus === 22) {
      setTimeout(() => {
        onUnApprove()
        close(false)
      }, 1500)
    } else if (getData1?.cars?.carLoanDetails?.approvalStatus === 23 || getData1?.lands?.landLoanDetails?.approvalStatus === 23) {
      setTimeout(() => {
        handleReject()
        close(false)
      }, 1500)
    }

    var typeimg = guarantor.data.typeLoan
    // console.log("typeimg,", typeimg)
    loadImage(typeimg)

  }, [getData1, resetPage])

  useEffect(() => {
    if (getNotification === 1) {
      ApproveSuccess("top")
      setGetNotification(0)
    }
    if (getNotification === 2) {
      UnApproveSuccess("top")
      setGetNotification(0)
    }
    if (getNotification === 3) {
      RejectSuccess("top")
      setGetNotification(0)
    }
  }, [modalnote])

  useEffect(() => {
    if (addCount === 1) {
      handleAddGua()
    }
    if (addCount === 2) {
      updatgarno()
    }
  }, [guarantorData])

  useEffect(() => {
    newDataLand.map((e, index) => {
      form.setFieldsValue({
        [`mixNote${index}`]: e?.approval?.mixNote,
      })
    })
  }, [newDataLand])

  // useEffect(() => {
  //   if (splitISSUNO !== undefined) {
  //     if (splitISSUNO[0] === "3") {
  //       loadDataBunshe3();
  //     }
  //   }

  // }, [])

  // const loadDataBunshe3 = async () => {
  //   // console.log("loadDataBunshe3ISSUNO", dataFromTable?.ISSUNO)
  //   setLoading(true)
  //   await axios.post(loadBunshe3, { CONTNO: dataFromTable?.ISSUNO, DATE: DATELOAD })
  //     .then(async res => {
  //       if (res.data) {
  //         // console.log("loadDataBunshe3", res.data)
  //         setNewData(res.data)
  //         setLoading(false)
  //       }
  //     }).catch((err) => {
  //       console.log("err", err)
  //     })
  //   setLoading(false)
  // }

  const onDownload = () => {
    const imageObj = imageBlobzz[current]; // ดึง object ของภาพที่ต้องการ
    const filename = `${Date.now()}_${imageObj.name}`; // สร้างชื่อไฟล์

    // ใช้ fetch ดึงรูปภาพจาก path
    fetch(imageObj.path)
      .then((response) => response.blob()) // เปลี่ยน response เป็น blob
      .then((blob) => {
        // สร้าง Blob URL สำหรับการดาวน์โหลด
        const blobUrl = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = filename; // ตั้งชื่อไฟล์สำหรับการดาวน์โหลด
        document.body.appendChild(link);
        link.click(); // จำลองการคลิกเพื่อดาวน์โหลด
        URL.revokeObjectURL(blobUrl); // ยกเลิก Blob URL หลังการใช้งาน
        link.remove(); // ลบ link ออกจาก DOM
      })
      .catch((error) => {
        console.error("Error downloading the image:", error);
      });
  };

  const handleSelectImage = (e, image) => {
    // ล็อกิกการเพิ่มรูปภาพเข้าใน selectedImages
    console.log("ee", e)
    console.log("image", image)
    setSelectedImages(prevSelected => [...prevSelected, e]);
  };

  const calYellowCard = (value) => {
    // console.log("calYellowCard",value)
    // console.log("calYellowCard", value)
    // มูลหนี้ balanc
    // หักส่วนลด (balanc-(approvedLoanAmount+(newDamt+followPay)))
    // คงเหลือ มูลหนี้ - หักส่วนลด
    // จ่ายเงิน (newDamt+followPay)
    // ยอดจัด approvedLoanAmount
    // วันที่ approvalDate
    // ใบนี้หมดอายุ ณ วันที่ newOfferDate
    // จำนวนงวด pgLoanTerm
    // ผ่อน installment x pgLoanTerm งวด (รวมภาษีมูลค่าเพิ่ม) 
    // วันที่ dayjs(dataCarAun?.approvalDate).format("DD-MM-YYYY")

    setMoonnee(currencyFormatOne(~~value?.balanc))
    setHug(currencyFormatOne(~~value?.balanc - (~~value?.approvedLoanAmount + (~~value?.newDamt + ~~value?.followPay))))
    setKong(currencyFormatOne(~~value?.balanc - (~~value?.balanc - (~~value?.approvedLoanAmount + (~~value?.newDamt + ~~value?.followPay)))))
    setJaimoney(currencyFormatOne(~~value?.newDamt + ~~value?.followPay))
    setYodjut(currencyFormatOne(~~value?.approvedLoanAmount))
    setDateceo(dayjs(value?.approvalDate).format("DD-MM-YYYY"))
    setExp(dayjs(value?.newOfferDate).format("DD-MM-YYYY"))
    setNgod(~~value?.pgLoanTerm)
    setPon(currencyFormatOne(~~value?.installment) + " x " + ~~value?.pgLoanTerm)
  }

  const loadDataReAun = async () => {
    setLoading(true);
    await axios.post(loadDetailAunSendPG, { contno: isu })
      // await axios
      //   .post(loadDetailAunSendPG, { contno: "8-0012601" })
      .then((res) => {
        // if (res.status === 200) {
        // console.log("res.dataNewPageAun", res.data);
        setDataApproved(res?.data?.carsApproveve);
        // setDataDB2(res.data?.dataDB2);
        // calData(res?.data?.carsApproveve);
        setLoading(false);
        // }
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
      });
  };

  const loadDataNew = async () => {
    await axios.post(findlandpn, { landLoanId: getData1?.lands?.landLoanId })
      .then(res => {
        if (res.status === 200) {
          setNewDataLand(res.data)
        }
      }).catch((err) => {
        console.log("err", err)
      })
  }
  const loadBroker = async () => {
    const result = { loanId: getData1?.cars?.carLoanDetails?.loanId }
    setLoading(true)
    await axios.post(detailBroker, result)
      .then((res) => {
        if (res.data) {
          console.log("OK", res.data)
          setDataBroker(res.data)
        }
      })
      .catch((err) => console.log(err))
    setLoading(false)
  }

  ////////////////////////////////////////////////////////////////
  const openModalImages = (e, sg) => {
    // console.log("e", e)
    if (e.type === 1) {
      setCarShowModal(carimage1)
    } else if (e.type === 2) {
      setCarShowModal(carimage2)
    } else if (e.type === 3) {
      setCarShowModal(carimage3)
    } else if (e.type === 4) {
      setCarShowModal(carimage4)
    } else if (e.type === 5) {
      setCarShowModal(carimage5)
      setGuarantorNew(sg)
      const n = 5;
      setGuarantorNum(n)
    } else if (e.type === 6) {
      setCarShowModal(carimage6)
    } else if (e.type === 7) {
      setCarShowModal(carimage7)
    } else if (e.type === 8) {
      setCarShowModal(carimage8)
    } else if (e.type === 9) {
      setCarShowModal(carimage9)
    } else if (e.type === 10) {
      setCarShowModal(carimage10)
    } else if (e.type === 11) {
      setCarShowModal(carimage11)
    } else if (e.type === 12) {
      setCarShowModal(carimage12)
    } else if (e.type === 14) {
      setCarShowModal(carimage14)
    } else {

    }
    setOpenShowImage(true)
    // จะทำตอนกดภาพแล้วภาพใหญ่ขึ้น
  };

  // ปุ่มกดถัดไป
  const handleNext = () => {// กดต่อไปของรูปภาพ
    setCurrentIndex(currentIndex + 1);
  };
  const handlePrev = () => {// กดย้อนกลับของรูปภาพ
    setCurrentIndex(currentIndex - 1);
  };

  const onChangePreaaprove = (e) => {
    setCbData({ ...cbData, cbPreaaprove: e.target.checked })
  };
  const onChangeCar = (e) => {
    setCbData({ ...cbData, cbCar: e.target.checked })
  };
  const onChangeLand = (e) => {
    setCbData({ ...cbData, cbLand: e.target.checked })
  };
  const onChangeHome = (e) => {
    setCbData({ ...cbData, cbHome: e.target.checked })
  };
  const onChangeHomeRent = (e) => {
    setCbData({ ...cbData, cbHomeRent: e.target.checked })
  };
  const onChangeHomeGov = (e) => {
    setCbData({ ...cbData, cbHomeGov: e.target.checked })
  };
  const onChangePark = (e) => {
    setCbData({ ...cbData, cbPark: e.target.checked })
  };
  const onChangeVat = (e) => {
    setCbData({ ...cbData, cbVat: e.target.checked })
  };
  const onChangeVDO = (e) => {
    setCbData({ ...cbData, cbVdo: e.target.checked })
  };
  const onChangeGPS = (e) => {
    setCbData({ ...cbData, cbGps: e.target.checked })
  };

  const loadCareer = async () => {
    const id = { customerId: customers.data }
    setLoading(true)
    await axios.post(loadOJS, id)
      .then((res) => {
        setCareer(res.data)
        setLoading(false)
      })
      .catch((err) => console.log(err))
    setLoading(false)
  }

  // const loadGuarantors = async (value) => {
  //   await axios.post(checkguarantors1, value)
  //     .then(res => {
  //       if (res.status === 200) {
  //         setGuarantorData(res.data)
  //         // getImg()
  //       } else if (res.status === 204) {
  //         // console.log("ไม่พบข้อมูล")
  //       }
  //     }).catch((err) => {
  //       console.log("err", err)
  //     })
  // }

  const loadGuarantors = async (value) => {
    if (value.typeLoan && value.loanId) {
      await axios.post(checkguarantors1, value)
        .then(res => {
          console.log('datra', res.data)
          if (res.status === 200) {
            setGuarantorData(res.data)
            // getImg()
          } else if (res.status === 204) {
            // console.log("ไม่พบข้อมูล")
            setGuarantorData([])
          }
        }).catch((err) => {
          console.log("err", err)
        })
    }
  }

  // const getImg = async () => {
  //   var path = `one_images/${guarantor?.data?.typeLoan}/${guarantor?.data?.carId}/5`
  //   setLoading(true)
  //   await axios.get(`${getAllFolderGuaImg}/${path}?token=${token}`)
  //     .then(res => {
  //       if (res.data) {
  //         setGetImages(res.data)
  //         setLoading(false)
  //       } else {
  //         setGetImages([])
  //       }
  //     })
  //     .catch((err) => {
  //       console.log("err", err)
  //       setLoading(false)
  //     })
  //   setLoading(false)
  // }
  function extractFolderGroup(path) {
    const match = path?.pathImage?.match(/\/5\/(\d+)\//);  // ดึงตัวเลขที่อยู่ในรูปแบบ /5/x/
    return match ? match[1] : null;
  }


  const loadImage = async (value) => {
    var carimg1 = [];
    var carimg2 = [];
    var carimg3 = [];
    var carimg4 = [];
    var carimg5 = [];
    var carimg6 = [];
    var carimg7 = [];
    var carimg8 = [];
    var carimg9 = [];
    var carimg10 = [];
    var carimg11 = [];
    var carimg12 = [];
    var carimg14 = [];
    if (value === "car") {
      setImageBlobzz(getData1?.cars?.img)
      getData1?.cars?.img?.map((car, i) => {
        if (car.type === 1) {
          carimg1.push(car)
          setCarImage1(carimg1)
        } else if (car.type === 2) {
          carimg2.push(car)
          setCarImage2(carimg2)
        } else if (car.type === 4) {
          carimg4.push(car)
          setCarImage4(carimg4)
        } else if (car.type === 5) {
          var group1 = [];
          const group = extractFolderGroup(car);
          if (group === '1') {
            group1.push({ ...car, num: 1 });
          } else if (group === '2') {
            group1.push({ ...car, num: 2 });
          } else if (group === '3') {
            group1.push({ ...car, num: 3 });
          } else if (group === '4') {
            group1.push({ ...car, num: 4 });
          } else if (group === '5') {
            group1.push({ ...car, num: 5 });
          } else if (group === '6') {
            group1.push({ ...car, num: 6 });
          }
          carimg5.push(...group1)
          setCarImage5(carimg5)
        } else if (car.type === 6) {
          carimg6.push(car)
          setCarImage6(carimg6)
        } else if (car.type === 7) {
          carimg7.push(car)
          setCarImage7(carimg7)
        } else if (car.type === 8) {
          carimg8.push(car)
          setCarImage8(carimg8)
        } else if (car.type === 9) {
          carimg9.push(car)
          setCarImage9(carimg9)
        } else if (car.type === 14) {
          carimg14.push(car)
          setCarImage14(carimg14)
        } else {
          carimg12.push(car)
          setCarImage12(carimg12)
        }
      })
    } else {
      setImageBlobzz(getData1?.lands?.img)
      getData1?.lands?.img?.map((land, i) => {
        if (land.type === 1) {
          carimg1.push(land)
          setCarImage1(carimg1)
        } else if (land.type === 3) {
          carimg3.push(land)
          setCarImage3(carimg3)
        } else if (land.type === 4) {
          carimg4.push(land)
          setCarImage4(carimg4)
        } else if (land.type === 5) {
          var group1 = [];
          const group = extractFolderGroup(land);
          if (group === '1') {
            group1.push({ ...land, num: 1 });
          } else if (group === '2') {
            group1.push({ ...land, num: 2 });
          } else if (group === '3') {
            group1.push({ ...land, num: 3 });
          } else if (group === '4') {
            group1.push({ ...land, num: 4 });
          } else if (group === '5') {
            group1.push({ ...land, num: 5 });
          } else if (group === '6') {
            group1.push({ ...land, num: 6 });
          }
          carimg5.push(...group1)
          setCarImage5(carimg5)
        } else if (land.type === 6) {
          carimg6.push(land)
          setCarImage6(carimg6)
        } else if (land.type === 7) {
          carimg7.push(land)
          setCarImage7(carimg7)
        } else if (land.type === 8) {
          carimg8.push(land)
          setCarImage8(carimg8)
        } else if (land.type === 9) {
          carimg9.push(land)
          setCarImage9(carimg9)
        } else if (land.type === 10) {
          carimg10.push(land)
          setCarImage10(carimg10)
        } else if (land.type === 11) {
          carimg11.push(land)
          setCarImage11(carimg11)
        } else if (land.type === 14) {
          carimg14.push(land)
          setCarImage14(carimg14)
        } else {
          carimg12.push(land)
          setCarImage12(carimg12)
        }
      })
    }
  }



  const currencyFormatOne = (amount) => {
    return Number(amount)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, '$&,')
  }

  const handleAddGua = async (e) => {
    await axios.post(makegarno, guarantorData)
      .then(res => {
        if (res.status === 200) {
          if (res.data === "พบข้อมูลซ้ำ") {
            setResetPage(dayjs())
            Duplicate("top")
          }
          setAddCount(0)
          setLoading(false)
        }
      })
      .catch(err => {
        console.log(err)
      }
      )
    setLoading(false)
  }

  const updatgarno = async () => {
    setLoading(true)
    await axios.put(updatgarno1, guarantorData)
      .then(res => {
        if (res.status === 200) {
          setAddCount(0)
          setLoading(false)
        }
      })
      .catch(err => {
        console.log(err)
      })
    setLoading(false)
  }

  const AddCheckbox = async () => {
    await axios.post(addcheckbox1, cbData)
      .then(res => {
        if (res.status === 200) {
          CheckboxSuccess("top")
        }
      }).catch((err) => {
        console.log("err", err)
      })
  }

  const showConfirm = (value, index) => {
    confirm({
      title: 'คุณต้องการที่จะลบข้อมูลหรือไม่...?',
      content: 'กด OK เพื่อยืนยันการลบ',
      onOk() {
        setLoading(true)
        const data = guarantorData.filter(item => item.garno !== index + 1);

        // เปลี่ยน "garno" ที่มีค่าเป็น 3 เป็น 2
        data.forEach((item, index) => {
          item.garno = index + 1;
        });
        setGuarantorData(data)
        // deleteData(value)
        setAddCount(2)

      },
      onCancel() {
        message.error('ยกเลิกการลบข้อมูล')
      },
    });
  };

  const handleReject = async (e) => {
    setAppStatus("Reject")
    setModalNote(true)
  }

  // const onChangeBack = () => {
  //   changepage(page - 1)
  // }


  const ApproveSuccess = (placement) => {
    api.success({
      message: "อนุมัติ",
      // description:
      //     'หมายเหตุที่ถูกเพิ่ม สามารถเลือกได้ในช่อง หมายเหตุ',
      placement,
    });
  };

  const UnApproveSuccess = (placement) => {
    api.warning({
      message: "ไม่อนุมัติ",
      // description:
      //     'หมายเหตุที่ถูกเพิ่ม สามารถเลือกได้ในช่อง หมายเหตุ',
      placement,
    });
  };

  const RejectSuccess = (placement) => {
    api.warning({
      message: "Reject",
      // description:
      //     'หมายเหตุที่ถูกเพิ่ม สามารถเลือกได้ในช่อง หมายเหตุ',
      placement,
    });
  };

  const Duplicate = (placement) => {
    api.error({
      message: "พบข้อมูลผู้ค้ำประกันซ้ำกัน บันทึกไม่สำเร็จ",
      description:
        'ไม่สามารถบันทึกได้ กรุณาเพิ่มผู้ค้ำประกันคนใหม่',
      placement,
    });
  };

  const CheckboxSuccess = (placement) => {
    api.success({
      message: "เพิ่ม Checkbox แล้ว",
      // description:
      //     'หมายเหตุที่ถูกเพิ่ม สามารถเลือกได้ในช่อง หมายเหตุ',
      placement,
    });
  };

  const onUnApprove = async () => {
    setAppStatus("ไม่อนุมัติ")
    setModalNote(true)
  }

  const onApprove = async () => {
    setAppStatus("อนุมัติ")
    setModalNote(true)
  }
  const onApproveCon = async () => {
    setAppStatus("อนุมัติแบบมีเงื่อนไข")
    setModalNote(true)
  }

  const handleCancel = () => {
    close()
  }

  const fucnEdit = ({ value, getIndex }) => {
    let Theedit = [...guarantorData]
    Theedit[getIndex] = value
    //ข้อมูลใหม่แทนที่ข้อมูลเดิมในช่อง Input
    setGuarantorData(Theedit);
    setAddCount(2)
  }

  const fucnAdd = (value) => {
    setLoading(true)
    if (guarantorData.length === 0) {
      setGuarantorData(pre => [...pre, { ...value.value, garno: 1 }]);
    } else {
      setGuarantorData(pre => [...pre, { ...value.value, garno: guarantorData.length + 1 }]);
    }
    setAddCount(1)
  }

  const renderGuarantor = ({ item, index }) => { //item คือตัวแปรที่เอาไว้ดึงค่าของข้อมูลออกมาแสดง โดยใช้ item.ชื่อฟิวล์

    return (
      <>
        <Row >
          {/* <Card
          title={"ลำดับที่ " + (item.garno)}
          style={{ textAlign: "center" }}
        > */}
          {/* <Col span={24} style={{ textAlign: "left" }}> */}
          <Col className='gutter-row' span={8}>
            <Form.Item style={{ marginBottom: 0 }} label='ชื่อ-นามสกุล'>
              <b>{item.snam + '' + item.firstname + ' ' + item.lastname}</b>
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={8}>
            <Form.Item style={{ marginBottom: 0 }} label='เบอร์โทรติดต่อ'>
              <b>{item.phones[0]?.telp}</b>
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={8}>
            <Form.Item style={{ marginBottom: 0 }} label='ความสัมพันธ์'>
              <b>{item.description}</b>
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={8}>
            <Form.Item style={{ marginBottom: 0 }} label='ที่อยู่'>
              <b>{item.address[0]?.houseNumber} {item.address[0]?.subdistrict} {item.address[0]?.district} {item.address[0]?.province} {item.address[0]?.zipCode}</b>
            </Form.Item>
          </Col>
          {/* <Divider></Divider> */}
          {/* <div style={{ textAlign: "center" }} >
              <Space>
                <Button
                  type='primary'
                  style={{ background: "#389e0d" }}
                  icon={<SearchOutlined />}
                  onClick={() => { handleView(guarantorData[index], index); }}
                >ดูรายละเอียด</Button>
                <Button
                  type="primary"
                  style={{ background: "#0958d9" }}
                  icon={<EditFilled />}
                  onClick={() => { handleEdit(guarantorData[index], index); }}
                >Edit</Button>
                <Button
                  type="primary"
                  icon={<DeleteOutlined />}
                  style={{ background: "#cf1322" }}
                  onClick={() => showConfirm(guarantorData[index], index)}
                >Delete</Button>
              </Space>
            </div> */}
          {/* </Col> */}
          {/* </Card> */}
        </Row>
        <Divider />
      </>
    )
  }

  const renderGuarantorNewImg = ({ item, index }) => { //item คือตัวแปรที่เอาไว้ดึงค่าของข้อมูลออกมาแสดง โดยใช้ item.ชื่อฟิวล์

    var itemSplit = []
    var itemSplitnum = []
    if (item?.pathImg) {
      itemSplit = item.pathImg.split("/")
    }
    carimage5?.map((e, i) => {
      const segments = e?.pathImage.split("/")
      if (itemSplit[4] === segments[4]) {
        itemSplitnum.push(e)
      }
    })
    const count = itemSplitnum.length || 0;

    return (
      <>
        <Row>
          <Col className='gutter-row' span={8}>
            <Form.Item style={{ marginBottom: 0 }} label='ชื่อ-นามสกุล'>
              <b>{item.snam + '' + item.firstname + ' ' + item.lastname}</b>
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={8}>
            <Form.Item style={{ marginBottom: 0 }} label='เบอร์โทรติดต่อ'>
              <b>{item?.phones[0]?.telp}</b>
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={8}>
            <Form.Item style={{ marginBottom: 0 }} label='ความสัมพันธ์'>
              <b>{item?.description}</b>
            </Form.Item>
          </Col>
          <Col className='gutter-row' span={8}>
            <Form.Item style={{ marginBottom: 0 }} label='ที่อยู่'>
              <b>{item.address[0]?.houseNumber} {item.address[0]?.subdistrict} {item.address[0]?.district} {item.address[0]?.province} {item.address[0]?.zipCode}</b>
            </Form.Item>
          </Col>
        </Row>
        {/* <Divider /> */}
        {itemSplit.length > 0 ? (
          <>
            <Row justify={"center"}>

              <Image.PreviewGroup
                preview={{
                  countRender: (index, total) => {
                    const displayedIndex = Math.min(index, count);
                    const displayTotal = Math.max(count, count);
                    return `${displayedIndex} / ${displayTotal}`;
                  }, // ใช้เพื่อแสดงจำนวนภาพ
                  toolbarRender: (
                    _,
                    {
                      transform: { scale },
                      actions: {
                        onActive,
                        onFlipY,
                        onFlipX,
                        onRotateLeft,
                        onRotateRight,
                        onZoomOut,
                        onZoomIn,
                        onReset,
                      },
                    },
                  ) => (
                    <>
                      <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                        {imageBlobzz?.map((e, index) => {
                          const segments = e?.pathImage.split("/")
                          if (itemSplit[4] === segments[4] && e.type === 5) {
                            return (
                              <Image
                                width={auto}
                                key={index}
                                onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                src={`${getImagessGua}/${e.pathImage}?token=${token}`}
                                alt={`รูปภาพ ${index + 1}`}
                                style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                              />
                            )
                          }
                          return null;
                        }
                        )}
                      </Row>
                      <Row style={{ zIndex: 2 }}>
                        <Space size={12} className="toolbar-wrapper">
                          {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                                    <RightOutlined onClick={() => onActive?.(1)} /> */}
                          <DownloadOutlined onClick={onDownload} />
                          <SwapOutlined rotate={90} onClick={onFlipY} />
                          <SwapOutlined onClick={onFlipX} />
                          <RotateLeftOutlined onClick={onRotateLeft} />
                          <RotateRightOutlined onClick={onRotateRight} />
                          <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                          <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                          <UndoOutlined onClick={onReset} />
                        </Space>
                      </Row>
                    </>
                  ),
                  onChange: (index) => {
                    setCurrent(index);
                  },
                }}
              >
                <Row gutter={32} justify={'center'}>
                  {imageBlobzz?.map((e, index) => {
                    const segments = e?.pathImage.split("/")
                    if (itemSplit[4] === segments[4] && e.type === 5) {
                      return (
                        <Image
                          width={150}
                          key={index}
                          onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                          src={`${getImagessGua}/${e.pathImage}?token=${token}`}
                          alt={`รูปภาพ ${index + 1}`}
                          style={{ cursor: 'pointer' }}
                        />

                      )
                    }
                    return null;
                  }
                  )}
                </Row>

              </Image.PreviewGroup>
              {/* {imageBlobzz?.map((items, index) => {
                const segments = items?.pathImage.split("/")
                if (itemSplit[4] === segments[4] && items.type === 5) {
                  return (
                    // <Image width={'50px'} key={index} src={`data:${items?.Attr};base64,${items?.data?.toString('base64')}`} alt={`Image ${index + 1}`} />
                    // <Image width={"50px"} key={index} src={`${getImagessGua}/${items.pathImage}?token=${token}`} alt={`รูปภาพ ${index + 1}`} />
                    <div key={index}>
                      <img
                        onClick={(v) => openModalImages(items, segments[4])}
                        style={{ width: 200 }}
                        src={`${getImagessGua}/${items.pathImage}?token=${token}`}
                        alt={`รูปภาพ ${index + 1}`}
                      />
                    </div>

                  )
                }
                return null;
              }
              )} */}
            </Row>
          </>
        ) : null
        }
        {/* </Col> */}
        {/* </Card> */}
        {/* </Row> */}
        <Divider />
      </>
    )
  }

  const renderItemAddress = ({ item, index }) => { //item คือตัวแปรที่เอาไว้ดึงค่าของข้อมูลออกมาแสดง โดยใช้ item.ชื่อฟิวล์
    return (
      <>
        <Form
          name='basic'
          labelCol={{
            span: 12,
          }}
          wrapperCol={{
            span: 24,
          }}
          style={{
            width: '100%',
          }}
          autoComplete="off"
          initialValues={{ remember: true }}
        >
          <Row gutter={32}>
            <Col className='gutter-row' span={9}>
              <Form.Item
                style={{ marginBottom: 0, textAlign: 'left' }}
                label='เลขทะเบียนบ้าน'
              >
                <b>{item.houseRegistrationNumber}</b>
              </Form.Item>
              <Form.Item
                style={{ marginBottom: 0, textAlign: 'left' }}
                label='ถนน'
              >
                <b>{item.road}</b>
              </Form.Item>
              <Form.Item
                style={{ marginBottom: 0, textAlign: 'left' }}
                label='ตำบล'
              >
                <b>{item.subdistrict}</b>
              </Form.Item>

            </Col>
            <Col className='gutter-row' span={7}>
              <Form.Item
                style={{ marginBottom: 0, textAlign: 'left' }}
                label='บ้านเลขที่'
              >
                <b>{item.houseNumber}</b>
              </Form.Item>
              <Form.Item
                style={{ marginBottom: 0, textAlign: 'left' }}
                label='หมู่บ้าน'
              >
                <b>{item.village}</b>
              </Form.Item>
              <Form.Item
                style={{ marginBottom: 0, textAlign: 'left' }}
                label='อำเภอ'
              >
                <b>{item.district}</b>
              </Form.Item>

            </Col>
            <Col className='gutter-row' span={8}>
              <Form.Item
                style={{ marginBottom: 0, textAlign: 'left' }}
                label='หมู่ / ซอย'
              >
                <b>{item.soi}</b>
              </Form.Item>

              <Form.Item
                style={{ marginBottom: 0, textAlign: 'left' }}
                label='จังหวัด'
              >
                <b>{item.province}</b>
              </Form.Item>
              <Form.Item
                style={{ marginBottom: 0, textAlign: 'left' }}
                label='รหัสไปรษณีย์'
              >
                <b>{item.zipCode}</b>
              </Form.Item>

            </Col>
          </Row>
        </Form>
        <Divider style={{ margin: 5 }} />
      </>
    )
  }

  const renderItemPhones = ({ item, index }) => { //item คือตัวแปรที่เอาไว้ดึงค่าของข้อมูลออกมาแสดง โดยใช้ item.ชื่อฟิวล์
    return (
      <>
        <Form
          name='basic'
          labelCol={{
            span: 15,
          }}
          wrapperCol={{
            span: 24,
          }}
          style={{
            width: '100%',
          }}
          autoComplete="off"
          initialValues={{ remember: true }}
        >
          <Row gutter={32}>
            <Col className='gutter-row' span={24}>
              <Form.Item
                style={{ marginBottom: 0 }}
                label={item?.phoneType}
              >
                <b> {item?.telp}</b>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </>
    )
  }
  const renderDataNote = ({ item, index }) => {
    return (
      <>
        <Row gutter={32} justify={'center'}>
          <Col className='gutter-row' span={24}>
            {guarantor?.data?.typeLoan === "car" ?
              <>
                <Card
                  key={index}
                  style={{ width: '300px', textAlign: 'center' }}>
                  <>
                    <Form.Item label="สาเหตุ" style={{ marginBottom: 0 }}>
                      <b>{item.cause}</b>
                    </Form.Item>
                    <Form.Item label="หมายเหตุ" style={{ marginBottom: 0 }}>
                      {/* <b> {item.note}</b> */}
                      {
                        item.note ?
                          <b>{item.note}</b>
                          :
                          <b>-</b>
                      }

                    </Form.Item>
                    <Form.Item label="ผู้สร้างหมายเหตุ" style={{ marginBottom: 0 }}>
                      <b>{item.noteBy}</b>
                    </Form.Item>
                    <Form.Item label="สถานะที่หมายเหตุ" style={{ marginBottom: 0 }}>
                      <b>{item.status}</b>
                    </Form.Item>
                  </>
                </Card>
              </>
              :
              <>
                <Card
                  key={index}
                  style={{ width: '300px', textAlign: 'center' }}>
                  <>
                    <Form.Item label="สาเหตุ" style={{ marginBottom: 0 }}>
                      <b> {item.cause}</b>
                    </Form.Item>
                    <Form.Item label="หมายเหตุ" style={{ marginBottom: 0 }}>
                      {
                        item.note ?
                          <b>{item.note}</b>
                          :
                          <b>-</b>
                      }

                    </Form.Item>
                    <Form.Item label="ผู้สร้างหมายเหตุ" style={{ marginBottom: 0 }}>
                      <b>{item.noteBy}</b>
                    </Form.Item>
                    <Form.Item label="สถานะที่หมายเหตุ" style={{ marginBottom: 0 }}>
                      <b> {item.status}</b>
                    </Form.Item>
                  </>
                </Card>
              </>
            }
          </Col>
        </Row>
      </>
    )
  }

  const renderLandNew = ({ item, index }) => {
    return (
      <>
        <Card style={{ width: '1000px' }}>
          <Row justify={'left'}><b><u>รายละเอียดที่ดิน ({item.numberLand}) {newDataLand.length > 1 ? <>{item.landMain ? <b style={{ color: "red" }} >(ที่ดินหลัก)</b> : null}</> : null}</u></b></Row>
          <Row gutter={32} justify={'center'}>
            <Col className='gutter-row' span={9}>
              <Form.Item style={{ marginBottom: 0, textAlign: 'left' }} label='กลุ่มสินค้า'>
                <b>{getData1?.lands?.landLoanDetails?.productType}</b>
              </Form.Item>
              <Form.Item style={{ marginBottom: 0, textAlign: 'left' }} label='เลขที่ดินหรือระวาง'>
                <b>{getData1?.lands?.numberLandlawang}</b>
              </Form.Item>
              <Form.Item style={{ marginBottom: 0, textAlign: 'left' }} label='พื้นที่ไร่'>
                <b>{item?.rai}</b>
                <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                  ไร่
                </span>
              </Form.Item>
              <Form.Item style={{ marginBottom: 0, textAlign: 'left' }} label='ราคาประเมินที่ดิน'>
                <b>{currencyFormatOne(item?.landPrice)}</b> บาท/ตารางวา
              </Form.Item>
            </Col>
            <Col className='gutter-row' span={7}>
              <Form.Item style={{ marginBottom: 0, textAlign: 'left' }} label='ประเภทเอกสาร'>
                <b>{item?.landLoanDetails?.productLoanLandType}</b>
              </Form.Item>
              <Form.Item style={{ marginBottom: 0, textAlign: 'left' }} label='จังหวัด'>
                <b>{item?.provinces}</b>
              </Form.Item>
              <Form.Item style={{ marginBottom: 0, textAlign: 'left' }} label='พื้นที่งาน'>
                <b>{item?.workArea}</b>
                <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                  งาน
                </span>
              </Form.Item>
              <Form.Item style={{ marginBottom: 0, textAlign: 'left' }} label='ราคารวม'>
                <b>{currencyFormatOne(item?.resultLandPrice)}</b>
                <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                  บาท
                </span>
              </Form.Item>
            </Col>
            <Col className='gutter-row' span={8}>
              <Form.Item style={{ marginBottom: 0, textAlign: 'left' }} label='เลขโฉนดที่ดิน'>
                <b>{item?.numberLand}</b>
              </Form.Item>
              <Form.Item style={{ marginBottom: 0, textAlign: 'left' }} label='อำเภอ'>
                <b>{item?.district}</b>
              </Form.Item>
              <Form.Item style={{ marginBottom: 0, textAlign: 'left' }} label='พื้นที่ตารางวา'>
                <b>{item?.squareWaArea}</b>
                <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                  บาท
                </span>
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </>
    )
  }
  const renderPriceApprove = ({ item, index }) => {
    return (
      <>
        <Card style={{ width: '1000px' }}>
          <Row justify={'left'}><b><u>ราคาประเมินที่ดิน ({item?.numberLand})</u></b></Row>
          <Row style={{ width: '100%' }}>
            <Col className='gutter-row' span={8}>
              <Form.Item
                style={{ marginBottom: 0, textAlign: 'left' }}
                label='ราคาต่ำสุด'
              >
                <b>{currencyFormatOne(item.approval?.minPrice)}</b>
                <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                  บาท
                </span>
              </Form.Item>
            </Col>

            <Col className='gutter-row' span={8}>
              <Form.Item
                style={{ marginBottom: 0, textAlign: 'left' }}
                label='ราคาสูงสุด'
              >
                <b>{currencyFormatOne(item.approval?.maxPrice)}</b>
                <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                  บาท
                </span>
              </Form.Item>
            </Col>

            <Col className='gutter-row' span={8}>
              <Form.Item
                style={{ marginBottom: 0, textAlign: 'left' }}
                label='หมายเหตุ'
              >
                <b>{item.approval?.note}</b>
                <span style={{ display: 'inline-block', marginLeft: '30px' }}></span>
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </>
    )
  }
  const renderMixNote = ({ item, index }) => {
    return (
      <>
        {
          index === 0 ?
            null :
            <>
              <Card style={{ width: '1000px' }}>
                <Row justify={'left'}><b><u>หมายเหตุจดรวมโฉนด</u></b></Row>
                <Row gutter={32} justify={'center'}>
                  <Col className='gutter-row' span={24}>
                    <Form.Item style={{ marginBottom: 0, textAlign: 'center' }} label='' name={`mixNote${index}`}>
                      <TextArea rows={5} disabled name={`mixNote${index}`} style={{ color: "blue" }}></TextArea>
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </>
        }
      </>
    )
  }

  const renderTitleDeed = ({ item, mapData, index }) => {
    return (
      <>
        <Row justify={'left'}><b><u>รูปโฉนด/หน้า-หลัง ({item.numberLand})</u></b></Row>
        <Space>
          <Image.PreviewGroup
            key={index}
            preview={{
              countRender: (index, total) => {
                const displayIndex = Math.min(index, imageCount3);
                const displayTotal = Math.min(imageCount3, imageCount3);
                return `${displayIndex} / ${displayTotal}`;
              }, // ใช้เพื่อแสดงจำนวนภาพ
              toolbarRender: (
                _,
                {
                  transform: { scale },
                  actions: {
                    onActive, // support after 5.21.0
                    onFlipY,
                    onFlipX,
                    onRotateLeft,
                    onRotateRight,
                    onZoomOut,
                    onZoomIn,
                    onReset,
                  },
                },
              ) => (
                <>
                  <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                    {carimage3?.map((e, index) => {
                      return (
                        <>
                          <Image
                            width={auto}
                            key={index}
                            onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                            src={`${getImagess}/${e.pathImage}?token=${token}`}
                            alt={`รูปภาพ ${index + 1}`}
                            style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                          />
                        </>
                      );
                    })}
                  </Row>
                  <Row style={{ zIndex: 2 }}>
                    <Space size={12} className="toolbar-wrapper">
                      {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                      <RightOutlined onClick={() => onActive?.(1)} /> */}
                      <DownloadOutlined onClick={onDownload} />
                      <SwapOutlined rotate={90} onClick={onFlipY} />
                      <SwapOutlined onClick={onFlipX} />
                      <RotateLeftOutlined onClick={onRotateLeft} />
                      <RotateRightOutlined onClick={onRotateRight} />
                      <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                      <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                      <UndoOutlined onClick={onReset} />
                    </Space>
                  </Row>
                </>
              ),
              onChange: (indexs) => {
                setCurrent(indexs);
              },
            }}
          >
            <Row gutter={32} justify={'center'}>
              {carimage3?.map((e, index) => {
                return (
                  <>
                    <Image
                      width={150}
                      key={index}
                      onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                      src={`${getImagess}/${e.pathImage}?token=${token}`}
                      alt={`รูปภาพ ${index + 1}`}
                      style={{ cursor: 'pointer' }}
                    />
                  </>
                );
              })}
            </Row>
          </Image.PreviewGroup>
        </Space >
        <Divider />
      </>
    )
  }
  const renderDivision = ({ item, mapData, index }) => {
    return (
      <>
        <Row justify={'left'}><b><u>ใบแบ่งใบต่อ ({item.numberLand})</u></b></Row>
        <Space>
          <Image.PreviewGroup
            preview={{
              countRender: (index, total) => {
                const displayIndex = Math.min(index, imageCount10);
                const displayTotal = Math.min(imageCount10, imageCount10);
                return `${displayIndex} / ${displayTotal}`;
              }, // ใช้เพื่อแสดงจำนวนภาพ
              toolbarRender: (
                _,
                {
                  transform: { scale },
                  actions: {
                    onActive,
                    onFlipY,
                    onFlipX,
                    onRotateLeft,
                    onRotateRight,
                    onZoomOut,
                    onZoomIn,
                    onReset,
                  },
                },
              ) => (
                <>
                  <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                    {carimage10?.map((e, index) => {
                      return (
                        <>
                          <Image
                            width={auto}
                            key={index}
                            onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                            src={`${getImagess}/${e.pathImage}?token=${token}`}
                            alt={`รูปภาพ ${index + 1}`}
                            style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                          />
                        </>
                      );
                    })}
                  </Row>
                  <Row style={{ zIndex: 2 }}>
                    <Space size={12} className="toolbar-wrapper">
                      {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                      <RightOutlined onClick={() => onActive?.(1)} /> */}
                      <DownloadOutlined onClick={onDownload} />
                      <SwapOutlined rotate={90} onClick={onFlipY} />
                      <SwapOutlined onClick={onFlipX} />
                      <RotateLeftOutlined onClick={onRotateLeft} />
                      <RotateRightOutlined onClick={onRotateRight} />
                      <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                      <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                      <UndoOutlined onClick={onReset} />
                    </Space>
                  </Row>
                </>
              ),
              onChange: (index) => {
                setCurrent(index);
              },
            }}
          >
            <Row gutter={32} justify={'center'}>
              {carimage10?.map((e, index) => {
                return (
                  <>
                    <Image
                      width={150}
                      key={index}
                      onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                      src={`${getImagess}/${e.pathImage}?token=${token}`}
                      alt={`รูปภาพ ${index + 1}`}
                      style={{ cursor: 'pointer' }}
                    />
                  </>
                );
              })}
            </Row>
          </Image.PreviewGroup>
        </Space>
        <Divider />
      </>
    )
  }
  const renderLawang = ({ item, mapData, index }) => {
    return (
      <>
        <Row justify={'left'}><b><u>ระวาง ({item.numberLand})</u></b></Row>
        <Space>
          <Image.PreviewGroup
            preview={{
              countRender: (index, total) => {
                const displayIndex = Math.min(index, imageCount11);
                const displayTotal = Math.min(imageCount11, imageCount11);
                return `${displayIndex} / ${displayTotal}`;
              }, // ใช้เพื่อแสดงจำนวนภาพ
              toolbarRender: (
                _,
                {
                  transform: { scale },
                  actions: {
                    onActive,
                    onFlipY,
                    onFlipX,
                    onRotateLeft,
                    onRotateRight,
                    onZoomOut,
                    onZoomIn,
                    onReset,
                  },
                },
              ) => (
                <>
                  <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                    {carimage11?.map((e, index) => {
                      return (
                        <>
                          <Image
                            width={auto}
                            key={index}
                            onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                            src={`${getImagess}/${e.pathImage}?token=${token}`}
                            alt={`รูปภาพ ${index + 1}`}
                            style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                          />
                        </>
                      );
                    })}
                  </Row> <Row style={{ zIndex: 2 }}>
                    <Space size={12} className="toolbar-wrapper">
                      {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                      <RightOutlined onClick={() => onActive?.(1)} /> */}
                      <DownloadOutlined onClick={onDownload} />
                      <SwapOutlined rotate={90} onClick={onFlipY} />
                      <SwapOutlined onClick={onFlipX} />
                      <RotateLeftOutlined onClick={onRotateLeft} />
                      <RotateRightOutlined onClick={onRotateRight} />
                      <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                      <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                      <UndoOutlined onClick={onReset} />
                    </Space>
                  </Row>
                </>
              ),
              onChange: (index) => {
                setCurrent(index);
              },
            }}
          >
            <Row gutter={32} justify={'center'}>
              {carimage11?.map((e, index) => {
                return (
                  <>
                    <Image
                      width={150}
                      key={index}
                      onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                      src={`${getImagess}/${e.pathImage}?token=${token}`}
                      alt={`รูปภาพ ${index + 1}`}
                      style={{ cursor: 'pointer' }}
                    />
                  </>
                );
              })}
            </Row>
          </Image.PreviewGroup>
        </Space>
        <Divider />
      </>
    )
  }
  const renderLandBuildings = ({ item, mapData, index }) => {
    return (
      <>
        <Row justify={'left'}><b><u>รูปที่ดิน/สิ่งปลูกสร้าง-ที่ดินปัจจุบัน ({item.numberLand})</u></b></Row>
        <Space>
          <Image.PreviewGroup
            preview={{
              countRender: (index, total) => {
                const displayIndex = Math.min(index, imageCount1);
                const displayTotal = Math.min(imageCount1, imageCount1);
                return `${displayIndex} / ${displayTotal}`;
              }, // ใช้เพื่อแสดงจำนวนภาพ
              toolbarRender: (
                _,
                {
                  transform: { scale },
                  actions: {
                    onActive,
                    onFlipY,
                    onFlipX,
                    onRotateLeft,
                    onRotateRight,
                    onZoomOut,
                    onZoomIn,
                    onReset,
                  },
                },
              ) => (
                <>
                  <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                    {carimage1?.map((e, index) => {
                      return (
                        <>
                          <Image
                            width={auto}
                            key={index}
                            onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                            src={`${getImagess}/${e.pathImage}?token=${token}`}
                            alt={`รูปภาพ ${index + 1}`}
                            style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                          />
                        </>
                      );
                    })}
                  </Row>
                  <Row style={{ zIndex: 2 }}>
                    <Space size={12} className="toolbar-wrapper">
                      {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                      <RightOutlined onClick={() => onActive?.(1)} /> */}
                      <DownloadOutlined onClick={onDownload} />
                      <SwapOutlined rotate={90} onClick={onFlipY} />
                      <SwapOutlined onClick={onFlipX} />
                      <RotateLeftOutlined onClick={onRotateLeft} />
                      <RotateRightOutlined onClick={onRotateRight} />
                      <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                      <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                      <UndoOutlined onClick={onReset} />
                    </Space>
                  </Row>
                </>
              ),
              onChange: (index) => {
                setCurrent(index);
              },
            }}
          >
            <Row gutter={32} justify={'center'}>
              {carimage1?.map((e, index) => {
                return (
                  <>
                    <Image
                      width={150}
                      key={index}
                      onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                      src={`${getImagess}/${e.pathImage}?token=${token}`}
                      alt={`รูปภาพ ${index + 1}`}
                      style={{ cursor: 'pointer' }}
                    />
                  </>
                );
              })}
            </Row>
          </Image.PreviewGroup>
        </Space>
        <Divider />
      </>
    )
  }

  return (
    <>
      <Spin di tip="Loading" spinning={loading}>
        <Row justify={'center'}>
          <Col className='gutter-row' span={24} style={{ fontSize: '25px', textAlign: 'center' }}>
            <b>รายละเอียดและคนค้ำประกัน</b>
          </Col>
        </Row>
        <Row justify={'center'}>
          <Divider style={{ margin: 5 }} />
          <Form
            labelCol={{
              span: 12,
            }}
            wrapperCol={{
              span: 24,
            }}
            style={{
              width: '100%',
            }}
            form={form}
            initialValues={{ remember: true }}
          >
            <style>
              {`
                .toolbar-wrapper {
                    padding: 0px 24px;
                    color: #fff;
                    font-size: 20px;
                    background-color: rgba(0, 0, 0, 0.1);
                    border-radius: 100px;
                }

                .toolbar-wrapper .anticon {
                    padding: 12px;
                    cursor: pointer;
                }

                .toolbar-wrapper .anticon[disabled] {
                    cursor: not-allowed;
                    opacity: 0.3;
                }

                .toolbar-wrapper .anticon:hover {
                    opacity: 0.3;
                }
                .ant-image-preview-count {
                    display: none !important;
                }
                `}
            </style>
            <Row gutter={32} justify={'center'}>
              <Card style={{ width: '1000px' }}>
                <Row gutter={32}>
                  <Col span={12} className='gutter-row' style={{ textAlign: 'left' }}>
                    {
                      guarantor?.data?.typeLoan === "car" ?
                        <div>
                          <b>{getData1?.cars?.ISSUNO ? <span>เลขขสัญญา : {getData1?.cars?.ISSUNO}</span> : null}</b>
                        </div>
                        :
                        <div>
                          <b>{getData1?.lands?.ISSUNO ? <span>เลขขสัญญา : {getData1?.lands?.ISSUNO}</span> : null}</b>
                        </div>
                    }
                    {
                      guarantor?.data?.typeLoan === "car" ?
                        <div>
                          <b>{getData1?.cars?.carLoanDetailsRe?.GRDCOD ? <span>เกรด : {getData1?.cars?.carLoanDetailsRe?.GRDCOD}</span> : null}</b>
                        </div>
                        :
                        <div>
                          <b>{getData1?.lands?.landLoanDetailsRe?.GRDCOD ? <span>เกรด : {getData1?.lands?.landLoanDetailsRe?.GRDCOD}</span> : null}</b>
                        </div>
                    }
                  </Col>
                  <Col span={12} className='gutter-row' style={{ textAlign: 'right' }}>
                    <Form.Item style={{ marginBottom: 0 }}>
                      {
                        guarantor?.data?.typeLoan === "car" ?
                          <>
                            สถานะ : <b><Tag color="cyan">{getData1?.cars?.carLoanDetails?.approvalStatusTable?.name}</Tag></b>
                          </>
                          :
                          <>
                            สถานะ : <b><Tag color="cyan">{getData1?.lands?.landLoanDetails?.approvalStatusTable?.name}</Tag></b>
                          </>
                      }
                    </Form.Item>
                    <Form.Item style={{ marginBottom: 0 }}>
                      {
                        guarantor?.data?.typeLoan === "car" ?
                          <>
                            ผ่านการวิเคราะห์โดย : <b><Tag color="cyan">{getData1?.cars?.carLoanDetails?.reviewedBy}</Tag></b>
                          </>
                          :
                          <>
                            ผ่านการวิเคราะห์โดย : <b><Tag color="cyan">{getData1?.lands?.landLoanDetails?.reviewedBy}</Tag></b>
                          </>
                      }
                    </Form.Item>
                  </Col>
                </Row>
                {
                  getData1?.nationalId === 'นิติบุคคล' ?
                    <>
                      <Row justify={'left'}><b><u>รายละเอียดผู้ขอกู้</u></b></Row>
                      <Row gutter={32} justify={'center'}>
                        {/* <aside style={{ width: '90%' }}> */}
                        <Col className='gutter-row' span={8}>
                          <div label='ประเภท' name='identificationId' style={{ marginBottom: 3 }}>
                            <span>ประเภท : </span> <b>{getData1?.nationalId}</b>
                          </div>
                          <div label='วันจดทะเบียน' name='nickname' style={{ marginBottom: 3 }}>
                            <span>วันจดทะเบียน : </span> <b> {getData1?.birthdate}</b>
                          </div>

                        </Col>
                        <Col className='gutter-row' span={8}>
                          <div label='เลขประจำตัวผู้เสียภาษี' name='identificationId' style={{ marginBottom: 3 }}>
                            <span>เลขประจำตัวผู้เสียภาษี : </span> <b>{getData1.identificationId}</b>
                          </div>
                          <div style={{ marginBottom: 0, }}>
                            <b>

                              {
                                dataPhoneCus?.map((item, index) => {
                                  return renderItemPhones({ item, index, key: `{item.identification} - ${index}` })
                                })
                              }
                            </b>
                          </div>
                        </Col>
                        <Col className='gutter-row' span={8}>
                          <div label='ชื่อบริษัท' name='snam' style={{ marginBottom: 3 }}>
                            <span>
                              {getData1?.snam} : </span> <b>{getData1?.firstname + ' ' + getData1?.lastname}</b>
                          </div>
                        </Col>
                      </Row>
                    </>
                    :
                    <>
                      <Row justify={'left'}><b><u>รายละเอียดผู้ขอกู้</u></b></Row>
                      <Row gutter={32} justify={'center'}>
                        <Col className='gutter-row' span={8}>
                          <Form.Item
                            style={{ marginBottom: 0, textAlign: 'left' }}
                            label='ชื่อ-นามสกุล'
                          >
                            <b>{getData1?.snam + '   ' + getData1?.firstname + '   ' + getData1?.lastname}</b>
                          </Form.Item>

                          <Form.Item
                            style={{ marginBottom: 0, textAlign: 'left' }}
                            label='ชื่อเล่น'
                          >
                            <b>{getData1?.nickname}</b>
                          </Form.Item>
                        </Col>
                        <Col className='gutter-row' span={7}>
                          <Form.Item
                            style={{ marginBottom: 0, textAlign: 'left' }}
                            label='เลขบัตรประชาชน'
                          >
                            <b>{getData1?.identificationId}</b>
                          </Form.Item>
                          <Row gutter={32}>
                            <Col className='gutter-row' span={24}>
                              <Form.Item
                                style={{ marginBottom: 0, textAlign: 'left' }}
                              >
                                <b>
                                  {
                                    dataPhoneCus?.map((item, index) => {
                                      return renderItemPhones({ item, index, key: `{item.identification} - ${index}` })
                                    })
                                  }
                                </b>
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                        <Col className='gutter-row' span={9}>
                          <Form.Item
                            style={{ margin: 0, textAlign: 'left' }}
                            label='เพศ'
                          >
                            <b>{getData1?.gender === "M" ? "ชาย" : "หญิง"}</b>
                          </Form.Item>

                          <Form.Item
                            style={{ marginBottom: 0, textAlign: 'left' }}
                            label={dataSocials?.socialType}
                          >
                            <b>{dataSocials?.socialData}</b>
                          </Form.Item>
                        </Col>
                      </Row>
                    </>
                }
              </Card>
              {
                address?.length >= 1 ?
                  <>
                    <Card style={{ width: '1000px' }}>
                      <Row justify={'left'}><b><u>รายละเอียดที่อยู่</u></b></Row>
                      <Row justify={'center'} gutter={32}>
                        {
                          address?.map((item, index) => {
                            return renderItemAddress({ item, index })
                          })
                        }
                      </Row>
                    </Card>
                  </>
                  : null
              }
              {career !== undefined || career?.length > 0 ?
                <>
                  {career?.map((e, index) => {
                    return (
                      <Card style={{ width: '1000px' }}>
                        <Row justify={'left'}><b><u>อาชีพ - รายได้</u></b></Row>
                        <Row gutter={32} justify={'center'}>
                          <Col className='gutter-row' span={8}>
                            <Form.Item
                              style={{ marginBottom: 0, textAlign: 'left' }}
                              label='กลุ่มอาชีพ'
                            >
                              <b>{e?.desc}</b>
                            </Form.Item>
                            <Form.Item
                              style={{ marginBottom: 0, textAlign: 'left' }}
                              label='ชื่อบริษัท(สถานที่ทำงาน)'
                            >
                              <b>{e?.companyName}</b>
                            </Form.Item>
                          </Col>
                          <Col className='gutter-row' span={8}>
                            <Form.Item
                              style={{ marginBottom: 0, textAlign: 'left' }}
                              label='อาชีพ'
                            >
                              <b>{e?.descSub}</b>
                            </Form.Item>
                            <Form.Item
                              style={{ marginBottom: 0, textAlign: 'left' }}
                              label='รายได้ / เดือน'
                            >
                              <b>{currencyFormatOne(e?.incomeMonth)}</b>
                            </Form.Item>
                          </Col>
                          <Col className='gutter-row' span={8}>
                            <Form.Item
                              style={{ marginBottom: 0, textAlign: 'left' }}
                              label='สาขาอาชีพ'
                            >
                              <b>{e?.descSection}</b>
                            </Form.Item>
                            <Form.Item
                              style={{ marginBottom: 0, textAlign: 'left' }}
                              label='รายจ่าย / เดือน'
                            >
                              <b>{currencyFormatOne(e?.expensesMonth)}</b>
                            </Form.Item>
                          </Col>
                        </Row>
                      </Card>
                    )
                  })}
                </> :
                <>***ยังไม่ได้เพิ่มอาชีพ***</>
              }
              {
                guarantor?.data?.typeLoan === "car" ?
                  <>
                    {
                      getData1?.cars?.carLoanDetails?.productLoanType === 'ย้ายไฟแนนซ์' ||
                        getData1?.cars?.carLoanDetails?.productLoanType === 'Pre-Aaprove-ย้ายไฟแนนซ์' ?
                        <>
                          <Row justify={'center'} gutter={32}>
                            <Card style={{ width: '1000px' }}>
                              <Row justify={'left'}><b><u>รายละเอียดย้ายไฟแนนซ์เดิม</u></b></Row>
                              <Row gutter={32} justify={'center'}>
                                <Col className='gutter-row' span={8}>
                                  <Form.Item
                                    style={{ marginBottom: 0, textAlign: 'center' }}
                                    label='ปิดจากไฟแนนซ์'
                                  >
                                    <b>{getData1?.cars?.carOldLoanDetails?.issuno}</b>

                                  </Form.Item>
                                  <Form.Item
                                    style={{ marginBottom: 0, textAlign: 'center' }}
                                    label='ยอดปิดไฟแนนซ์'
                                  >
                                    <b>{currencyFormatOne(getData1?.cars?.carOldLoanDetails?.oldLoanAmount)}</b>
                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                      บาท
                                    </span>
                                  </Form.Item>
                                </Col>
                                <Col className='gutter-row' span={8}>
                                  <Form.Item
                                    style={{ marginBottom: 0, textAlign: 'center' }}
                                    label='ค่างวด / เดือน'
                                  >
                                    <b>{currencyFormatOne(getData1?.cars?.carOldLoanDetails?.oldMonthlyPayment)}</b>
                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                      บาท
                                    </span>
                                  </Form.Item>
                                </Col>
                                <Col className='gutter-row' span={8}>
                                  <Form.Item
                                    style={{ marginBottom: 0, textAlign: 'center' }}
                                    label='จำนวนงวดที่ผ่อน'
                                  >
                                    <b>{getData1?.cars?.carOldLoanDetails?.oldLoanTerm}</b>
                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                      งวด
                                    </span>
                                  </Form.Item>
                                </Col>
                              </Row>
                            </Card>
                            <Card style={{ width: '1000px' }}>
                              <Row justify={'left'}><b><u>รายละเอียดขอกู้</u></b></Row>
                              <Row justify={'center'} gutter={32}>
                                <Col className='gutter-row' span={7}>
                                  <Form.Item
                                    style={{ marginBottom: 0, textAlign: 'center' }}
                                    label='ยอดกู้'
                                  >
                                    <b>{currencyFormatOne(getData1?.cars?.carLoanDetails?.loanAmount)}</b>
                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                      บาท
                                    </span>
                                  </Form.Item>
                                  <Form.Item
                                    style={{ marginBottom: 0, textAlign: 'center' }}
                                    label='จำนวนงวด'
                                  >
                                    <b>{getData1?.cars?.carLoanDetails?.loanTerm}</b>
                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                      งวด
                                    </span>
                                  </Form.Item>
                                </Col>
                                <Col className='gutter-row' span={7}>
                                  <Form.Item
                                    style={{ marginBottom: 0, textAlign: 'center' }}
                                    label='ดอกเบี้ย'
                                  >
                                    <b>{currencyFormatOne(getData1?.cars?.carLoanDetails?.interest)}</b>
                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                      บาท
                                    </span>
                                  </Form.Item>
                                  <Form.Item
                                    style={{ marginBottom: 0, textAlign: 'center' }}
                                    label='จำนวนผ่อนต่อเดือน'
                                  >
                                    <b>{currencyFormatOne(getData1?.cars?.carLoanDetails?.monthlyPayment)}</b>
                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                      บาท
                                    </span>
                                  </Form.Item>
                                </Col>
                                <Col className='gutter-row' span={10}>
                                  <Form.Item
                                    style={{ marginBottom: 0, textAlign: 'center' }}
                                    label='ผ่อนรวมดอกเบี้ย'
                                  >
                                    <b>{currencyFormatOne(~~getData1?.cars?.carLoanDetails?.monthlyPayment * ~~getData1?.cars?.carLoanDetails?.loanTerm)}</b>
                                    <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                      บาท
                                    </span>
                                  </Form.Item>
                                  <Form.Item
                                    style={{ marginBottom: 0, textAlign: 'center' }}
                                    label='ผู้เสนอเคส'
                                  >
                                    <b>{getData1?.cars?.carLoanDetails?.proposalBy}</b>

                                  </Form.Item>
                                  <b> <Form.Item
                                    style={{ margin: 0, textAlign: 'center' }}
                                    label='เรทรถ'
                                  >
                                    <b style={{ fontSize: '20px' }}><u>{currencyFormatOne(getData1?.cars?.carPrice)}</u></b>

                                    <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                                      บาท
                                    </span>
                                  </Form.Item>
                                  </b>
                                </Col>

                              </Row>
                            </Card>
                          </Row>
                        </>
                        :
                        <>
                          <Row gutter={32} justify={'center'}>
                            <Card style={{ width: '1000px' }}>
                              <>
                                <Row justify={'left'}><b><u>รายละเอียด{' ' + getData1?.cars?.carLoanDetails?.productLoanType} ({getData1?.cars?.carLoanDetails?.productType} )</u><span style={{ color: 'red' }}>({getData1?.cars?.idModel})</span></b></Row>
                                <Row justify={'center'} gutter={32}>
                                  <Col className='gutter-row' span={7}>
                                    <Form.Item
                                      style={{ marginBottom: 0, textAlign: 'left' }}
                                      label='ยอดกู้'
                                    >
                                      <b>{currencyFormatOne(getData1?.cars?.carLoanDetails?.loanAmount)}</b>
                                      <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                        บาท
                                      </span>
                                    </Form.Item>
                                    <Form.Item
                                      style={{ marginBottom: 0, textAlign: 'left' }}
                                      label='จำนวนงวด'
                                    >
                                      <b>{getData1?.cars?.carLoanDetails?.loanTerm}</b>
                                      <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                        งวด
                                      </span>
                                    </Form.Item>
                                  </Col>
                                  <Col className='gutter-row' span={7}>
                                    <Form.Item
                                      style={{ marginBottom: 0, textAlign: 'left' }}
                                      label='ดอกเบี้ย'
                                    >
                                      <b>{currencyFormatOne(getData1?.cars?.carLoanDetails?.interest)}</b>
                                      <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                        บาท
                                      </span>
                                    </Form.Item>
                                    <Form.Item
                                      style={{ marginBottom: 0, textAlign: 'left' }}
                                      label='จำนวนผ่อนต่อเดือน'
                                    >
                                      <b>{currencyFormatOne(getData1?.cars?.carLoanDetails?.monthlyPayment)}</b>
                                      <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                        บาท
                                      </span>
                                    </Form.Item>
                                  </Col>
                                  <Col className='gutter-row' span={10}>
                                    <Form.Item
                                      style={{ marginBottom: 0, textAlign: 'left' }}
                                      label='ผ่อนรวมดอกเบี้ย'
                                    >
                                      <b>{currencyFormatOne(~~getData1?.cars?.carLoanDetails?.monthlyPayment * ~~getData1?.cars?.carLoanDetails?.loanTerm)}</b>
                                      <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                        บาท
                                      </span>
                                    </Form.Item>
                                    <Form.Item
                                      style={{ marginBottom: 0, textAlign: 'left' }}
                                      label='ผู้เสนอเคส'
                                    >
                                      <b>{getData1?.cars?.carLoanDetails?.proposalBy}</b>

                                    </Form.Item>
                                    <b> <Form.Item
                                      style={{ margin: 0, textAlign: 'left' }}
                                      label='เรทรถ'
                                    >
                                      <b style={{ fontSize: '20px' }}><u>{currencyFormatOne(getData1?.cars?.carPrice)}</u></b>

                                      <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                                        บาท
                                      </span>
                                    </Form.Item>
                                    </b>
                                  </Col>
                                </Row>
                              </>
                            </Card>
                          </Row>
                        </>
                    }
                  </>
                  :
                  <>
                    {getData1?.lands?.landLoanDetails?.productLoanLandType === 'ย้ายไฟแนนซ์' ||
                      getData1?.lands?.landLoanDetails?.productLoanType === 'Pre-Aaprove-ย้ายไฟแนนซ์' ?
                      <>
                        <Row justify={'center'} gutter={32}>
                          <Card style={{ width: '1000px' }}>
                            <Row justify={'left'}><b><u>รายละเอียดย้ายไฟแนนซ์</u></b></Row>
                            <Row gutter={32} justify={'center'}>
                              <Col className='gutter-row' span={8}>
                                <Form.Item
                                  style={{ marginBottom: 0, textAlign: 'center' }}
                                  label='ปิดจากไฟแนนซ์'
                                >
                                  <b>{getData1?.lands?.landOldLoanDetails?.issuno}</b>

                                </Form.Item>
                                <Form.Item
                                  style={{ marginBottom: 0, textAlign: 'center' }}
                                  label='ยอดปิดไฟแนนซ์'
                                >
                                  <b>{currencyFormatOne(getData1?.lands?.landOldLoanDetails?.oldLandLoanTerm)}</b>
                                  <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                    บาท
                                  </span>
                                </Form.Item>
                              </Col>
                              <Col className='gutter-row' span={8}>

                                <Form.Item
                                  style={{ marginBottom: 0, textAlign: 'left' }}
                                  label='ค่างวด / เดือน'
                                >
                                  <b>{currencyFormatOne(getData1?.lands?.landOldLoanDetails?.oldLandMonthlyPayment)}</b>
                                  <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                    บาท
                                  </span>
                                </Form.Item>
                              </Col>
                              <Col className='gutter-row' span={8}>
                                <Form.Item
                                  style={{ marginBottom: 0, textAlign: 'left' }}
                                  label='จำนวนงวดที่ผ่อน'
                                >
                                  <b>{getData1?.lands?.landOldLoanDetails?.oldLandLoanTerm}</b>
                                  <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                    งวด
                                  </span>
                                </Form.Item>
                                {/* <Form.Item
                                  style={{ marginBottom: 0, textAlign: 'center' }}
                                  label='ค้างกี่งวด'
                                >
                                  <b>{getData1?.lands?.landOldLoanDetails?.oldLandKangPaymentTerm}</b>
                                  <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                    งวด
                                  </span>
                                </Form.Item> */}
                              </Col>
                            </Row>
                          </Card>
                        </Row>
                        <Card style={{ width: '1000px' }}>
                          <Row justify={'left'}><b><u>รายละเอียดย้ขอกู้</u></b></Row>
                          <Row gutter={32} justify={'center'}>
                            <Col className='gutter-row' span={8}>
                              <Form.Item
                                label='ยอดจัด'
                                style={{ marginBottom: 0, }}
                              >
                                <b>{currencyFormatOne(getData1?.lands?.landLoanDetails?.loanAmountLand)}</b>
                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                  บาท
                                </span>
                              </Form.Item>
                              <Form.Item
                                label='ดอกเบี้ย'
                                style={{ marginBottom: 0, }}
                              >
                                <b>{currencyFormatOne(getData1?.lands?.landLoanDetails?.interestLand)}</b>
                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                  บาท
                                </span>
                              </Form.Item>
                            </Col>
                            <Col className='gutter-row' span={8}>
                              <Form.Item
                                label='จำนวนงวด'
                                style={{ marginBottom: 0, }}
                              >
                                <b>{getData1?.lands?.landLoanDetails?.loanLandTerm}</b>
                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                  งวด
                                </span>
                              </Form.Item>
                              <Form.Item
                                label='ราคารวม'
                                style={{ marginBottom: 0, }}
                              >
                                <b>{currencyFormatOne(~~getData1?.lands?.landLoanDetails?.loanLandTerm * ~~getData1?.lands?.landLoanDetails?.monthlyPaymentLand)}</b>
                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                  บาท
                                </span>
                              </Form.Item>
                            </Col>
                            <Col className='gutter-row' span={8}>
                              <Form.Item
                                label='งวดละ'
                                style={{ marginBottom: 0, }}
                              >
                                <b>{currencyFormatOne(getData1?.lands?.landLoanDetails?.monthlyPaymentLand)}</b>
                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                  บาท
                                </span>
                              </Form.Item>
                              <Form.Item
                                label='ผู้เสนอเคสเช่าซื้อ'
                                style={{ marginBottom: 0, }}
                              >
                                <b>{getData1?.lands?.landLoanDetails?.proposalBy}</b>
                              </Form.Item>
                            </Col>
                          </Row>
                        </Card>
                      </>
                      :
                      <Row gutter={32} justify={'center'}>
                        <Card style={{ width: '1000px' }}>
                          <Row justify={'left'}><b><u>รายละเอียด{getData1?.lands?.landLoanDetails?.productLoanLandType}</u></b></Row>
                          <Row gutter={32} justify={'center'}>
                            <Col className='gutter-row' span={8}>
                              <Form.Item
                                label='ยอดจัด'
                                style={{ marginBottom: 0, }}
                              >
                                <b>{currencyFormatOne(getData1?.lands?.landLoanDetails?.loanAmountLand)}</b>
                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                  บาท
                                </span>
                              </Form.Item>
                              <Form.Item
                                label='ดอกเบี้ย'
                                style={{ marginBottom: 0, }}
                              >
                                <b>{currencyFormatOne(getData1?.lands?.landLoanDetails?.interestLand)}</b>
                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                  บาท
                                </span>
                              </Form.Item>
                            </Col>
                            <Col className='gutter-row' span={8}>
                              <Form.Item
                                label='จำนวนงวด'
                                style={{ marginBottom: 0, }}
                              >
                                <b>{getData1?.lands?.landLoanDetails?.loanLandTerm}</b>
                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                  งวด
                                </span>
                              </Form.Item>
                              <Form.Item
                                label='ราคารวม'
                                style={{ marginBottom: 0, }}
                              >
                                <b>{currencyFormatOne(~~getData1?.lands?.landLoanDetails?.loanLandTerm * ~~getData1?.lands?.landLoanDetails?.monthlyPaymentLand)}</b>
                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                  บาท
                                </span>
                              </Form.Item>
                            </Col>
                            <Col className='gutter-row' span={8}>
                              <Form.Item
                                label='งวดละ'
                                style={{ marginBottom: 0, }}
                              >
                                <b>{currencyFormatOne(getData1?.lands?.landLoanDetails?.monthlyPaymentLand)}</b>
                                <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                  บาท
                                </span>
                              </Form.Item>
                              <Form.Item
                                label='ผู้เสนอเคสเช่าซื้อ'
                                style={{ marginBottom: 0, }}
                              >
                                <b>{getData1?.lands?.landLoanDetails?.proposalBy}</b>
                              </Form.Item>
                            </Col>
                          </Row>
                        </Card>
                      </Row>
                    }
                  </>
              }
              {
                guarantor?.data?.typeLoan === "car" ?
                  <>
                    {
                      getData1?.cars?.carLoanDetailsRe?.productLoanType === "Pre-Aaprove-เปลี่ยนสัญญา" ||
                        getData1?.cars?.carLoanDetailsRe?.productLoanType === "Pre-Aaprove-รี+เปลี่ยนสัญญา" ||
                        getData1?.cars?.carLoanDetailsRe?.productLoanType === "รี+เปลี่ยนสัญญา" ||
                        getData1?.cars?.carLoanDetailsRe?.productLoanType === "เปลี่ยนสัญญา"
                        ?
                        <Card style={{ width: '1000px' }}>
                          <Divider orientation={'left'}><b><u>ค่าใช้จ่ายที่ลูกค้าต้องจ่าย</u></b></Divider>
                          <Row style={{ background: '#061178', padding: '8px', color: 'white' }} className='center'>
                            <Col span={8} name='priceChange' style={{ marginBottom: 3 }}>
                              <span>ค่าเปลี่ยนสัญญา : </span> <b> {currencyFormatOne(getData1?.cars?.carLoanDetailsRe?.priceChange)}</b>
                            </Col>
                            <Col span={8} name='overdue' style={{ marginBottom: 3 }}>
                              <span>ยอดค้าง + เบี้ยปรับ : </span> <b> {currencyFormatOne(getData1?.cars?.carLoanDetailsRe?.overdue)}</b>
                            </Col>
                            <Col span={8} name='preChange' style={{ marginBottom: 3 }}>
                              <span>ค่างวดล่วงหน้า 3 งวด : </span> <b> {currencyFormatOne(getData1?.cars?.carLoanDetailsRe?.preChange)}</b>
                              <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                บาท
                              </span><br />
                              <span>ยอดรวม : </span> <b> {currencyFormatOne(~~getData1?.cars?.carLoanDetailsRe?.preChange + ~~getData1?.cars?.carLoanDetailsRe?.overdue + ~~getData1?.cars?.carLoanDetailsRe?.priceChange
                              )}</b>
                              <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                บาท
                              </span>
                            </Col>
                          </Row>
                          <Divider style={{ margin: 3 }} />
                        </Card>
                        :
                        null
                    }
                  </>
                  :
                  <>
                    {
                      getData1?.lands?.landLoanDetailsRe?.productLoanLandType === "Pre-Aaprove-เปลี่ยนสัญญา" ||
                        getData1?.lands?.landLoanDetailsRe?.productLoanLandType === "Pre-Aaprove-รี+เปลี่ยนสัญญา" ||
                        getData1?.lands?.landLoanDetailsRe?.productLoanLandType === "เปลี่ยนสัญญา" ||
                        getData1?.lands?.landLoanDetailsRe?.productLoanLandType === "รี+เปลี่ยนสัญญา"
                        ?
                        <Card style={{ width: '1000px' }}>
                          <Divider orientation={'left'}><b><u>ค่าใช้จ่ายที่ลูกค้าต้องจ่าย</u></b></Divider>
                          <Row style={{ background: '#061178', padding: '8px', color: 'white' }} className='center'>
                            <Col span={8} name='priceChange' style={{ marginBottom: 3 }}>
                              <span>ค่าเปลี่ยนสัญญา : </span> <b> {currencyFormatOne(getData1?.lands?.landLoanDetailsRe?.priceChange)}</b>
                            </Col >
                            <Col span={8} name='overdue' style={{ marginBottom: 3 }}>
                              <span>ยอดค้าง + เบี้ยปรับ : </span> <b> {currencyFormatOne(getData1?.lands?.landLoanDetailsRe?.overdue)}</b>
                            </Col >
                            <Col span={8} name='preChange' style={{ marginBottom: 3 }}>
                              <span>ค่างวดล่วงหน้า : </span> <b> {currencyFormatOne(getData1?.lands?.landLoanDetailsRe?.preChange)}</b>
                              <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                บาท
                              </span><br />
                              <span>ยอดรวม : </span> <b> {currencyFormatOne(~~getData1?.lands?.landLoanDetailsRe?.preChange + ~~getData1?.lands?.landLoanDetailsRe?.overdue + ~~getData1?.lands?.landLoanDetailsRe?.priceChange)}</b>
                              <span style={{ display: 'inline-block', marginLeft: '10px' }}>
                                บาท
                              </span>
                            </Col >
                          </Row>
                          <Divider style={{ margin: 3 }} />
                        </Card>
                        :
                        null
                    }
                  </>
              }
              {
                getData1?.cars?.carsApproveCeo?.id > 0 ? (
                  <>
                    <Row justify={"center"} gutter={32}>
                      {/* <Card style={{}}> */}
                      <Card
                        style={{
                          width: "1000px",
                          backgroundColor: "lightyellow",
                        }}
                      >
                        <Row justify={"left"}>
                          <b>
                            <u style={{ backgroundColor: "#f39c12" }}>
                              เงื่อนไขการปรับโครงสร้าง (
                              {getData1?.cars?.carsApproveCeo?.CONTNO})
                            </u>
                          </b>
                        </Row>
                        <Col span={24} style={{ textAlign: "right" }}>
                          <Image
                            width={100}
                            src={`${process.env.PUBLIC_URL}/logo.jpg`}
                            alt="My Image"
                          />
                        </Col>
                        <Row gutter={32} justify={"center"}>
                          <Col className="gutter-row" span={10}>
                            <Form.Item
                              style={{ marginBottom: 0, textAlign: "center" }}
                              label="วันที่"
                            >
                              <b>{dateceo}</b>
                            </Form.Item>
                          </Col>
                          <Col className="gutter-row" span={10}>
                            <Form.Item
                              style={{ marginBottom: 0, textAlign: "center" }}
                              label="มูลหนี้เช่าซื้อคงเหลือรวม"
                            >
                              <b>{moonnee}</b>
                              <span
                                style={{
                                  display: "inline-block",
                                  marginLeft: "10px",
                                }}
                              >
                                บาท
                              </span>
                            </Form.Item>
                          </Col>
                          <Col className="gutter-row" span={10}>
                            <Form.Item
                              style={{ marginBottom: 0, textAlign: "center" }}
                              label="หักส่วนลด"
                            >
                              <b>{hug}</b>
                              <span
                                style={{
                                  display: "inline-block",
                                  marginLeft: "10px",
                                }}
                              >
                                บาท
                              </span>
                            </Form.Item>
                          </Col>
                          <Col className="gutter-row" span={10}>
                            <Form.Item
                              style={{ marginBottom: 0, textAlign: "center" }}
                              label="คงเหลือ"
                            >
                              <b>{kong}</b>
                              <span
                                style={{
                                  display: "inline-block",
                                  marginLeft: "10px",
                                }}
                              >
                                บาท
                              </span>
                            </Form.Item>
                          </Col>
                          <Col className="gutter-row" span={10}>
                            <Form.Item
                              style={{ marginBottom: 0, textAlign: "center" }}
                              label="จ่ายเงิน"
                            >
                              <b>{jaimoney}</b>
                              <span
                                style={{
                                  display: "inline-block",
                                  marginLeft: "10px",
                                }}
                              >
                                บาท
                              </span>
                            </Form.Item>
                          </Col>
                          <Col className="gutter-row" span={10}>
                            <Form.Item
                              style={{ marginBottom: 0, textAlign: "center" }}
                              label="จำนวนงวด"
                            >
                              <b>{ngod}</b>
                              <span
                                style={{
                                  display: "inline-block",
                                  marginLeft: "10px",
                                }}
                              >
                                บาท
                              </span>
                            </Form.Item>
                          </Col>
                          <Col className="gutter-row" span={10}>
                            <Form.Item
                              style={{ marginBottom: 0, textAlign: "center" }}
                              label="ผ่อน"
                            >
                              <b>{pon}</b>
                              <span
                                style={{
                                  display: "inline-block",
                                  marginLeft: "10px",
                                }}
                              >
                                งวด (รวมภาษีมูลค่าเพิ่ม)
                              </span>
                            </Form.Item>
                          </Col>
                          <Col className="gutter-row" span={10}>
                            <Form.Item
                              style={{ marginBottom: 0, textAlign: "center" }}
                              label="ยอดจัด"
                            >
                              <b style={{ fontSize: "20px" }}>
                                <u>{yodjut}</u>
                              </b>
                              <span
                                style={{
                                  display: "inline-block",
                                  marginLeft: "10px",
                                }}
                              >
                                บาท
                              </span>
                            </Form.Item>
                            <Form.Item
                              style={{ margin: 0, textAlign: "center" }}
                              label="ใบนี้หมดอายุ ณ วันที่"
                            >
                              <b style={{ color: "red", fontSize: "20px" }}>
                                <u>{exp}</u>
                              </b>
                            </Form.Item>
                          </Col>
                        </Row>
                      </Card>
                    </Row>
                  </>
                ) : null
              }

              {
                loanRe?.reLoanId > 0 ? (
                  <>
                    <Row justify={"center"} gutter={32}>
                      <Card style={{ width: "1000px" }}>
                        <Row justify={"left"}>
                          <b>
                            <u>รายละเอียดสัญญา ({isu})</u>
                          </b>
                        </Row>
                        {
                          splitISSUNO[0] === '3' ?
                            <>
                              <Row gutter={32} justify={"center"}>
                                <Col span={11}>
                                  <div style={{ marginBottom: 0 }}>
                                    <span>ยอดจัดครั้งที่แล้ว : </span>
                                    <b>
                                      {currencyFormatOne(loanRe?.tcshprc)} บาท
                                    </b>
                                  </div>
                                  <div style={{ marginBottom: 0 }}>
                                    <span>ยอดจัด+ดอกเบี้ย : </span>
                                    <b>{currencyFormatOne(
                                      (parseInt(loanRe?.tcshprc || "0", 10)) + (parseInt(loanRe?.INTEREST || "0", 10))
                                    )} บาท</b>
                                  </div>
                                  <div style={{ marginBottom: 0 }}>
                                    <span>ค่างวด : </span>
                                    <b>{currencyFormatOne(loanRe?.DAMT)} บาท</b>
                                  </div>
                                  <div style={{ marginBottom: 0 }}>
                                    <span>ยอดที่ชำระมาแล้วทั้งหมด : </span>
                                    <b>
                                      {currencyFormatOne(loanRe?.totalpayment)}{" "}
                                      บาท
                                    </b>
                                  </div>
                                  <div style={{ marginBottom: 0 }}>
                                    <span>ล/น คงเหลือรวม : </span>
                                    <b>
                                      {currencyFormatOne(loanRe?.neekong)} บาท
                                    </b>
                                  </div>
                                  <div style={{ marginBottom: 0 }}>
                                    <span>จำนวนงวดทั้งหมด : </span>
                                    <b>{(loanRe?.nopays)} งวด</b>
                                  </div>
                                  <div style={{ marginBottom: 0 }}>
                                    <span>จำนวนงวดที่ผ่อนมาแล้ว : </span>
                                    <b>{(loanRe?.NOPAY)}</b>
                                    <span style={{ marginLeft: "5px", marginRight: '10px' }}>งวด</span>
                                    <span>ตัดสด งวดที่ </span>
                                    <b>{(loanRe?.reqNo)}</b>
                                  </div>
                                </Col>
                                <Col span={11}>
                                  <div style={{ marginBottom: 0 }}>
                                    <span>เงินต้นคงเหลือ : </span>
                                    <b>{currencyFormatOne(loanRe?.TON)} บาท</b>
                                  </div>
                                  <div style={{ marginBottom: 0, }}>
                                    <span>ดอกเบี้ย : </span>
                                    <b>{currencyFormatOne(loanRe?.INTEREST)} บาท</b>
                                  </div>
                                  <div style={{ marginBottom: 0 }}>
                                    <span>เบี้ยปรับ : </span>
                                    <b>
                                      {currencyFormatOne(loanRe?.beeypup)} บาท
                                    </b>
                                  </div>
                                  <div style={{ marginBottom: 0 }}>
                                    <span>ค่าธรรมเนียมล่าช้า : </span>
                                    <b>{currencyFormatOne(loanRe?.FOLLOW)} บาท</b>
                                  </div>
                                  <div style={{ marginBottom: 0 }}>
                                    <span>ลูกหนี้อื่นๆ : </span>
                                    <b>{currencyFormatOne(loanRe?.OTHR)} บาท</b>
                                  </div>
                                  <div style={{ marginBottom: 0, background: "#1677ff" }}>
                                    <span>จำนวนงวดที่เหลือ : </span>
                                    <b>{~~loanRe?.nopays - ~~loanRe?.NOPAY}</b>
                                    <span style={{ marginLeft: "10px" }}>งวด</span>
                                  </div>
                                  <div style={{ marginBottom: 0, fontSize: "16px" }}>
                                    <span><b>ยอดรีไฟแนนซ์เพื่อจัดใหม่ : </b></span>
                                    <b><span style={{ color: 'red', fontSize: '20px' }}> {currencyFormatOne(loanRe?.re1)}</span> บาท</b>
                                  </div>
                                  <div style={{ marginBottom: 0 }}>
                                    <b>
                                      <div style={{ margin: 0, fontSize: "16px" }}>
                                        <span>ใบนี้หมดอายุ ณ วันที่ : </span>
                                        <b style={{ color: 'red', fontSize: '20px' }}><u>{dayjs(loanRe?.newOfferDate).format("DD-MM-YYYY")}</u></b>
                                      </div>
                                    </b>
                                  </div>
                                </Col>
                              </Row>
                            </>
                            :
                            <>
                              <Row gutter={32} justify={"center"}>
                                <Col span={11}>
                                  {/* <aside style={{ width: "90%" }}> */}
                                  <div style={{ marginBottom: 0 }}>
                                    <span>ค่างวด : </span>
                                    <b>{currencyFormatOne(loanRe?.DAMT)} บาท</b>
                                  </div>
                                  <div style={{ marginBottom: 0 }}>
                                    <span>ยอดจัดครั้งที่แล้ว : </span>
                                    <b>
                                      {currencyFormatOne(loanRe?.tcshprc)} บาท
                                    </b>
                                  </div>
                                  <div style={{ marginBottom: 0 }}>
                                    <span>ราคาเช่าซื้อครั้งที่แล้ว : </span>
                                    <b>{currencyFormatOne(loanRe?.balanc)} บาท</b>
                                  </div>
                                  <div style={{ marginBottom: 0 }}>
                                    <span>ยอดที่ชำระมาแล้วทั้งหมด : </span>
                                    <b>
                                      {currencyFormatOne(loanRe?.totalpayment)}{" "}
                                      บาท
                                    </b>
                                  </div>
                                  <div style={{ marginBottom: 0 }}>
                                    <span>ล/น คงเหลือรวม : </span>
                                    <b>
                                      {currencyFormatOne(loanRe?.neekong)} บาท
                                    </b>
                                  </div>
                                  <div style={{ marginBottom: 0 }}>
                                    <span>เบี้ยปรับ : </span>
                                    <b>
                                      {currencyFormatOne(loanRe?.beeypup)} บาท
                                    </b>
                                  </div>
                                </Col>
                                <Col span={11}>
                                  <div style={{ marginBottom: 0 }}>
                                    <span>ลูกหนี้อื่นๆ : </span>
                                    <b>{currencyFormatOne(loanRe?.OTHR)} บาท</b>
                                  </div>
                                  <div style={{ marginBottom: 0 }}>
                                    <span>จำนวนงวดที่ผ่อนมาแล้ว : </span>
                                    <b>{(loanRe?.NOPAY)} งวด</b>
                                  </div>
                                  <div style={{ marginBottom: 0 }}>
                                    <span>จำนวนงวดทั้งหมด : </span>
                                    <b>{(loanRe?.nopays)} งวด</b>
                                  </div>
                                  <div style={{ marginBottom: 0 }}>
                                    <span>ตัดสด งวดที่ : </span>
                                    <b>{(loanRe?.reqNo)}</b>
                                  </div>
                                  <div
                                    style={{ marginBottom: 0, background: "#1677ff" }}
                                  >
                                    <span>จำนวนงวดที่ค้าง : </span>
                                    <b>
                                      {~~loanRe?.nopays - ~~loanRe?.NOPAY} งวด
                                    </b>
                                  </div>
                                  <div style={{ marginBottom: 0, fontSize: "16px" }}>
                                    <span><b>ยอดรีไฟแนนซ์เพื่อจัดใหม่ : </b></span>
                                    <b><span style={{ color: 'red', fontSize: '20px' }}> {currencyFormatOne(loanRe?.re1)}</span> บาท</b>
                                  </div>
                                  <div style={{ marginBottom: 0 }}>
                                    <b>
                                      <div style={{ margin: 0, fontSize: "16px" }}>
                                        <span>ใบนี้หมดอายุ ณ วันที่ : </span>
                                        <b style={{ color: 'red', fontSize: '20px' }}><u>{dayjs(loanRe?.newOfferDate).format("DD-MM-YYYY")}</u></b>
                                      </div>
                                    </b>
                                  </div>
                                </Col>
                                {/* </aside> */}
                              </Row>
                            </>
                        }
                      </Card>
                    </Row>
                  </>
                ) : null
              }

              {
                dataApproved?.id > 0 ? (
                  <Card>
                    <Divider
                      orientation="center"
                      align={"middle"}
                      style={{ marginTop: "10px", marginBottom: "10px" }}
                    >
                      <b>คำอนุมัติใหม่</b>
                    </Divider>

                    <Row>
                      <Col span={24} style={{ textAlign: "center" }}>
                        <div
                          label="ค่างวดที่ต้องชำระ"
                          style={{
                            fontFamily: "Arial, Helvetica, sans-serif",
                          }}
                        >
                          <b>ค่างวดที่ต้องชำระ : </b>
                          {!dataApproved?.newDamt
                            ? "-"
                            : currencyFormatOne(dataApproved?.newDamt)}{" "}
                          บาท
                        </div>
                        <div
                          label="ค่าติดตาม"
                          style={{
                            fontFamily: "Arial, Helvetica, sans-serif",
                          }}
                        >
                          <b>ค่าติดตาม : </b>
                          {!dataApproved?.followPay
                            ? "-"
                            : currencyFormatOne(dataApproved?.followPay)}{" "}
                          บาท
                        </div>
                        <div
                          label="เงินต้น"
                          style={{
                            fontFamily: "Arial, Helvetica, sans-serif",
                          }}
                        >
                          <b>เงินต้น : </b>
                          {!dataApproved?.approvedLoanAmount
                            ? "-"
                            : currencyFormatOne(
                              dataApproved?.approvedLoanAmount
                            )}{" "}
                          บาท
                        </div>
                        <div
                          label="จำนวนงวด"
                          style={{
                            fontFamily: "Arial, Helvetica, sans-serif",
                          }}
                        >
                          <b>จำนวนงวด : </b>
                          {!dataApproved?.approvedLoanTerm
                            ? null
                            : dataApproved?.approvedLoanTerm}{" "}
                          {" ถึง: "}
                          {!dataApproved?.approvedLoanTermTo
                            ? "-"
                            : dataApproved?.approvedLoanTermTo}{" "}
                          งวด
                        </div>
                      </Col>
                    </Row>
                  </Card>
                ) : null
              }
              {guarantor?.data?.typeLoan === "car" ?
                <>
                  <Row gutter={32} justify={'center'}>
                    <Card style={{ width: '1000px' }}>
                      <Row justify={'left'}><b><u>รายละเอียดรถ</u></b></Row>
                      <Row gutter={32} justify={'center'}>
                        <Col className='gutter-row' span={8}>
                          <Form.Item
                            style={{ marginBottom: 0, textAlign: 'left' }}
                            label='ยี่ห้อ'
                          >
                            <b>{getData1?.cars?.carBrand}</b>
                          </Form.Item>
                          <Form.Item
                            style={{ marginBottom: 0, textAlign: 'left' }}
                            label='เลขทะเบียน'
                          >
                            <b>{getData1?.cars?.carPlateNumber}</b>
                          </Form.Item>
                          <Form.Item
                            style={{ marginBottom: 0, textAlign: 'left' }}
                            label='เลขเครื่อง'
                          >
                            <b>{getData1?.cars?.carEngineNumber}</b>
                          </Form.Item>
                        </Col>
                        <Col className='gutter-row' span={8}>

                          <Form.Item
                            style={{ marginBottom: 0, textAlign: 'left' }}
                            label='รุ่นสินค้า'
                          >
                            <b>{getData1?.cars?.carModel}</b>
                          </Form.Item>
                          <Form.Item
                            style={{ marginBottom: 0, textAlign: 'left' }}
                            label='เลขคัสซี'
                          >
                            <b>{getData1?.cars?.carChassisNumber}</b>
                          </Form.Item>
                          <Form.Item
                            style={{ marginBottom: 0, textAlign: 'left' }}
                            label='สี'
                          >
                            <b>{getData1?.cars?.carColor}</b>
                          </Form.Item>

                        </Col>
                        <Col className='gutter-row' span={8}>
                          <Form.Item
                            style={{ marginBottom: 0, textAlign: 'left' }}
                            label='แบบรถ'
                          >
                            <b>{getData1?.cars?.carBaab}</b>
                          </Form.Item>
                          <Form.Item
                            style={{ marginBottom: 0, textAlign: 'left' }}
                            label='ปีรถ'
                          >
                            <b>{getData1?.cars?.carYear}</b>
                          </Form.Item>
                          <Form.Item
                            style={{ marginBottom: 0, textAlign: 'left' }}
                            label='จังหวัด'
                          >
                            <b>{getData1?.cars?.carProvince}</b>
                          </Form.Item>


                        </Col>
                      </Row>
                    </Card>
                  </Row>
                </>
                :
                <>
                  <Row gutter={32} justify={'center'}>
                    {
                      newDataLand?.map((item, index) => {
                        return renderLandNew({ item, index, key: `{item.identificationId} - ${index}` })
                      })
                    }
                    {/* <Card style={{ width: '1000px' }}>
                      <Row justify={'left'}><b><u>รายละเอียดที่ดิน</u></b></Row>
                      <Row gutter={32} justify={'center'}>
                        <Col className='gutter-row' span={9}>
                          <Form.Item
                            style={{ marginBottom: 0, textAlign: 'left' }}
                            label='กลุ่มสินค้า'
                          >
                            <b>{getData1?.lands?.landLoanDetails?.productType}</b>
                          </Form.Item>
                          <Form.Item
                            style={{ marginBottom: 0, textAlign: 'left' }}
                            label='เลขที่ดินหรือระวาง'
                          >
                            <b>{getData1?.lands?.numberLandlawang}</b>
                          </Form.Item>
                          <Form.Item
                            style={{ marginBottom: 0, textAlign: 'left' }}
                            label='พื้นที่ไร่'
                          >
                            <b>{getData1?.lands?.rai}</b>
                            <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                              ไร่
                            </span>
                          </Form.Item>
                          <Form.Item
                            style={{ marginBottom: 0, textAlign: 'left' }}
                            label='ราคาประเมินที่ดิน'
                          >
                            <b>{currencyFormatOne(getData1?.lands?.landPrice)}</b> บาท/ตารางวา
                          </Form.Item>
                        </Col>
                        <Col className='gutter-row' span={7}>
                          <Form.Item
                            style={{ marginBottom: 0, textAlign: 'left' }}
                            label='ประเภทเอกสาร'
                          >
                            <b>{getData1?.lands?.landLoanDetails?.productLoanLandType}</b>
                          </Form.Item>
                          <Form.Item
                            style={{ marginBottom: 0, textAlign: 'left' }}
                            label='จังหวัด'
                          >
                            <b>{getData1?.lands?.provinces}</b>
                          </Form.Item>
                          <Form.Item
                            style={{ marginBottom: 0, textAlign: 'left' }}
                            label='พื้นที่งาน'
                          >
                            <b>{getData1?.lands?.workArea}</b>
                            <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                              งาน
                            </span>
                          </Form.Item>
                          <Form.Item
                            style={{ marginBottom: 0, textAlign: 'left' }}
                            label='ราคารวม'
                          >
                            <b>{currencyFormatOne(getData1?.lands?.resultLandPrice)}</b>
                            <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                              บาท
                            </span>
                          </Form.Item>
                        </Col>
                        <Col className='gutter-row' span={8}>
                          <Form.Item
                            style={{ marginBottom: 0, textAlign: 'left' }}
                            label='เลขโฉนดที่ดิน'
                          >
                            <b>{getData1?.lands?.numberLand}</b>
                          </Form.Item>
                          <Form.Item
                            style={{ marginBottom: 0, textAlign: 'left' }}
                            label='อำเภอ'
                          >
                            <b>{getData1?.lands?.district}</b>
                          </Form.Item>
                          <Form.Item
                            style={{ marginBottom: 0, textAlign: 'left' }}
                            label='พื้นที่ตารางวา'
                          >
                            <b>{getData1?.lands?.squareWaArea}</b>
                            <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                              บาท
                            </span>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Card> */}
                  </Row>

                </>
              }

              {getData1?.lands?.approval?.priceStatus === true ?
                <>
                  <Row gutter={32} justify={'center'}>
                    {
                      newDataLand?.map((item, index) => {
                        return renderPriceApprove({ item, index, key: `{item.identificationId} - ${index}` })
                      })
                    }
                    {/* <Card style={{ width: '1000px' }}>
                      <Row justify={'left'}><b><u>ราคาประเมินที่ดิน</u></b></Row>
                      <Row style={{ width: '100%' }}>
                        <Col className='gutter-row' span={8}>
                          <Form.Item
                            style={{ marginBottom: 0, textAlign: 'left' }}
                            label='ราคาต่ำสุด'
                          >
                            <b>{currencyFormatOne(getData1?.lands?.approval?.minPrice)}</b>
                            <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                              บาท
                            </span>
                          </Form.Item>
                        </Col>

                        <Col className='gutter-row' span={8}>
                          <Form.Item
                            style={{ marginBottom: 0, textAlign: 'left' }}
                            label='ราคาสูงสุด'
                          >
                            <b>{currencyFormatOne(getData1?.lands?.approval?.maxPrice)}</b>
                            <span style={{ display: 'inline-block', marginLeft: '30px' }}>
                              บาท
                            </span>
                          </Form.Item>
                        </Col>

                        <Col className='gutter-row' span={8}>
                          <Form.Item
                            style={{ marginBottom: 0, textAlign: 'left' }}
                            label='หมายเหตุ'
                          >
                            <b>{getData1?.lands?.approval?.note}</b>
                            <span style={{ display: 'inline-block', marginLeft: '30px' }}></span>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Card> */}
                  </Row>
                </>
                : null
              }
              {
                getData1?.lands?.approval?.mixNote === "" ||
                  getData1?.lands?.approval?.mixNote === undefined ||
                  getData1?.lands?.approval?.mixNote === null ?
                  null
                  :
                  <>
                    {
                      newDataLand?.map((item, index) => {
                        return renderMixNote({ item, index, key: `{item.identificationId} - ${index}` })
                      })
                    }
                  </>
              }
              {
                dataBroker?.length > 0 ?
                  ShowDataBrokersAD(dataBroker)
                  : null
              }

              <Row gutter={32} justify={'center'}>
                <Card style={{ width: '1000px' }}>
                  <Row justify={'left'}>
                    <b>
                      <u>คนค้ำประกัน</u>
                    </b>
                  </Row>
                  {/* <Col style={{ textAlign: 'right' }}>
                    <Button type="primary" onClick={modalG}><PlusOutlined />เพิ่มคนค้ำประกัน</Button>
                  </Col> */}
                  {/* <Row gutter={32} justify={'center'}> */}
                  {
                    guarantorData?.length > 0 ?
                      <>
                        {
                          guarantorData?.map((item, index) => {
                            console.log("ite,msdsdasd", item)
                            return renderGuarantorNewImg({ item, index, key: `{item.identificationId} - ${index}` })
                          })
                        }
                      </> : null
                  }
                  {/* </Row> */}
                </Card>
              </Row>
              <Row gutter={32} justify={'center'} style={{ width: '1100px' }}>
                {guarantor?.data?.typeLoan === "car" ?
                  <>
                    <Form
                      name="cbData"
                      form={formcb}
                    >
                      <Card style={{ width: '1000px' }}>
                        <Col span={24} style={{ textAlign: 'center' }}>
                          <Checkbox onChange={onChangePreaaprove} checked={cbData?.cbPreaaprove === true ? true : false} style={{ fontSize: '20px' }}>Pre-aaprove</Checkbox>
                          <Checkbox onChange={onChangeCar} checked={cbData?.cbCar === true ? true : false} style={{ fontSize: '20px' }}>รถ</Checkbox>
                          <Checkbox onChange={onChangeHome} checked={cbData?.cbHome === true ? true : false} style={{ fontSize: '20px' }}>บ้าน</Checkbox>
                          <Checkbox onChange={onChangeHomeRent} checked={cbData?.cbHomeRent === true ? true : false} style={{ fontSize: '20px' }}>บ้านเช่า</Checkbox>
                          <Checkbox onChange={onChangeHomeGov} checked={cbData?.cbHomeGov === true ? true : false} style={{ fontSize: '20px' }}>บ้านพักราชการ</Checkbox>
                          <Checkbox onChange={onChangePark} checked={cbData?.cbPark === true ? true : false} style={{ fontSize: '20px' }}>ลานจอด</Checkbox>
                          <Checkbox onChange={onChangeVat} checked={cbData?.cbVat === true ? true : false} style={{ fontSize: '20px' }}>ภาษี</Checkbox>
                          <Checkbox onChange={onChangeVDO} checked={cbData?.cbVdo === true ? true : false} style={{ fontSize: '20px' }}>VDO</Checkbox>
                          <Checkbox onChange={onChangeGPS} checked={cbData?.cbGps === true ? true : false} style={{ fontSize: '20px' }}>GPS</Checkbox>
                          <Button type="primary" onClick={AddCheckbox} style={{ backgroundColor: "green" }}>บันทึก</Button>
                        </Col>
                      </Card>
                    </Form>
                  </>
                  :
                  <>
                    <Form
                      name="cbData"
                      form={formcb}
                    >
                      <Card style={{ width: '1000px' }}>
                        <Col span={24} style={{ textAlign: 'center' }}>
                          <Checkbox onChange={onChangePreaaprove} checked={cbData?.cbPreaaprove === true ? true : false} style={{ fontSize: '20px' }}>Pre-aaprove</Checkbox>
                          <Checkbox onChange={onChangeLand} checked={cbData?.cbLand === true ? true : false} style={{ fontSize: '20px' }}>ที่ดิน</Checkbox>
                          <Checkbox onChange={onChangeHome} checked={cbData?.cbHome === true ? true : false} style={{ fontSize: '20px' }}>บ้าน</Checkbox>
                          <Checkbox onChange={onChangeHomeRent} checked={cbData?.cbHomeRent === true ? true : false} style={{ fontSize: '20px' }}>บ้านเช่า</Checkbox>
                          <Checkbox onChange={onChangeHomeGov} checked={cbData?.cbHomeGov === true ? true : false} style={{ fontSize: '20px' }}>บ้านพักราชการ</Checkbox>
                          <Checkbox onChange={onChangePark} checked={cbData?.cbPark === true ? true : false} style={{ fontSize: '20px' }}>ลานจอด</Checkbox>
                          <Checkbox onChange={onChangeVat} checked={cbData?.cbVat === true ? true : false} style={{ fontSize: '20px' }}>ภาษี</Checkbox>
                          <Checkbox onChange={onChangeVDO} checked={cbData?.cbVdo === true ? true : false} style={{ fontSize: '20px' }}>VDO</Checkbox>
                          <Checkbox onChange={onChangeGPS} checked={cbData?.cbGps === true ? true : false} style={{ fontSize: '20px' }}>GPS</Checkbox>
                          <Button type="primary" onClick={AddCheckbox} style={{ backgroundColor: "green" }}>บันทึก</Button>
                        </Col>
                      </Card>
                    </Form>
                  </>
                }
              </Row>
              <Row gutter={32} justify={'center'} style={{ width: '1100px' }}>
                <Card style={{ width: '1000px' }}>
                  <Col span={24} style={{ textAlign: 'center' }}>
                    <Checkbox
                      key={"0"}
                      value={1}
                      style={{ fontSize: '20px' }}
                      checked={checkGua === "ค้ำ-ไม่โอน"}
                      isPacked={true}
                    >
                      ค้ำ-ไม่โอน
                    </Checkbox>
                    <Checkbox
                      key={"1"}
                      value={2}
                      style={{ fontSize: '20px' }}
                      checked={checkGua === "ค้ำ-โอน"}
                      isPacked={true}
                    >
                      ค้ำ-โอน
                    </Checkbox>
                    <Checkbox
                      key={"2"}
                      value={3}
                      style={{ fontSize: '20px' }}
                      checked={checkGua === "ไม่ค้ำ-ไม่โอน"}
                      isPacked={true}
                    >
                      ไม่ค้ำ-ไม่โอน
                    </Checkbox>
                    <Checkbox
                      key={"3"}
                      value={4}
                      style={{ fontSize: '20px' }}
                      checked={checkGua === "ไม่ค้ำ-โอน"}
                      isPacked={true}
                    >
                      ไม่ค้ำ-โอน
                    </Checkbox>
                  </Col>
                </Card>
              </Row>
              <Row gutter={32} >
                <Card style={{ width: '1000px' }}>
                  <Row justify={'left'}>
                    <b>
                      <u>หมายเหตุ</u>
                    </b>
                  </Row>
                  {/* <Row gutter={32}> */}
                  <Row gutter={32} justify={'center'}>

                    {dataNote?.length >= 1 ?
                      dataNote?.map((item, index) => {
                        return renderDataNote({ item, index, key: `{item.identificationId} - ${index}` });
                      })
                      : <>* ยังไม่เพิ่มหมายเหตุ!</>
                    }
                  </Row>
                </Card>
              </Row>
              {
                branch === "MIT" || branch === "UD" || branch === "LEX" || branch === "S4" ?
                  <>
                    {
                      // username === "pg" ?
                      username === "MIT00003" ?
                        <>
                          <Row gutter={32} >
                            <Card style={{ width: '1000px' }}>
                              <Row justify={'left'}><b><u>Memo</u></b></Row>
                              <Row gutter={32} justify={'center'}>
                                <Col className='gutter-row' span={8}>
                                  {
                                    guarantor?.data?.typeLoan === "car" ?
                                      <>
                                        <Form.Item
                                          style={{ marginBottom: 0, textAlign: 'left' }}
                                          label='Memo รถ'
                                        >
                                          <b>{getData1?.cars?.carMemo}</b>
                                        </Form.Item>
                                      </>
                                      : null
                                  }
                                </Col>
                                <Col className='gutter-row' span={8}>
                                </Col>
                                <Col className='gutter-row' span={8}>
                                </Col>
                              </Row>
                            </Card>
                          </Row>
                        </>
                        : null
                    }
                  </>
                  // null 
                  :
                  <>
                    <Row gutter={32} >
                      <Card style={{ width: '1000px' }}>
                        <Row justify={'left'}><b><u>Memo</u></b></Row>
                        <Row gutter={32} justify={'center'}>
                          <Col className='gutter-row' span={8}>
                            {
                              guarantor?.data?.typeLoan === "car" ?
                                <>
                                  <Form.Item
                                    style={{ marginBottom: 0, textAlign: 'left' }}
                                    label='Memo รถ'
                                  >
                                    <b>{getData1?.cars?.carMemo}</b>
                                  </Form.Item>
                                </>
                                : null
                            }
                          </Col>
                          <Col className='gutter-row' span={8}>
                          </Col>
                          <Col className='gutter-row' span={8}>
                          </Col>
                        </Row>
                      </Card>
                    </Row>
                  </>
              }


              {


                guarantor?.data?.typeLoan === "car" ?
                  <>
                    <Row gutter={32} justify={'center'}>
                      <Card style={{ width: '1000px' }}>
                        <Row justify={'left'}><b><u>ใบประเมิน / รูปรถ / สัญญาไฟแนนซ์เดิม / ใบเสร็จไฟแนนซ์เดิม</u></b></Row>
                        <Row gutter={32} justify={'center'}>
                          <Space direction="vertical">
                            <Row justify={'center'}>
                              {dataMemo?.cars?.carMemo !== "" && dataMemo?.cars?.carMemo !== undefined ?
                                <>
                                  <Row justify={'left'}>
                                    <Col span={24} >
                                      <b>คอมเม้นรถ :  </b>
                                    </Col>
                                  </Row>
                                  <Row justify={'center'}>
                                    <Col span={24} >
                                      <Form.Item name="carMemo" >
                                        <TextArea disabled name='carMemo' style={{ width: '800px', color: 'blue' }} rows={8}>
                                          {/* <b style={{ color: 'blue' }}>{dataModalCars?.cars?.carMemo}</b> */}
                                        </TextArea>
                                      </Form.Item>
                                    </Col>

                                  </Row>
                                </>
                                : null
                              }
                            </Row>
                            <Row justify={'center'} style={{ width: '1000px' }}>
                              <Col span={22} style={{ textAlign: 'center' }}>
                                <Image.PreviewGroup
                                  preview={{
                                    countRender: (index, total) => {
                                      const displayedIndex = Math.min(index, imageCount1);
                                      const displayTotal = Math.max(imageCount1, imageCount1);
                                      return `${displayedIndex} / ${displayTotal}`;
                                    }, // ใช้เพื่อแสดงจำนวนภาพ
                                    toolbarRender: (
                                      _,
                                      {
                                        transform: { scale },
                                        actions: {
                                          onActive,
                                          onFlipY,
                                          onFlipX,
                                          onRotateLeft,
                                          onRotateRight,
                                          onZoomOut,
                                          onZoomIn,
                                          onReset,
                                        },
                                      },
                                    ) => (
                                      <>
                                        <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                                          {carimage1?.map((e, index) => {
                                            return (
                                              <>
                                                <Image
                                                  width={auto}
                                                  key={index}
                                                  onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                                  src={`${getImagess}/${e.pathImage}?token=${token}`}
                                                  alt={`รูปภาพ ${index + 1}`}
                                                  style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                                />
                                              </>
                                            );
                                          })}
                                        </Row>
                                        <Row style={{ zIndex: 2 }}>
                                          <Space size={12} className="toolbar-wrapper">
                                            {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                                    <RightOutlined onClick={() => onActive?.(1)} /> */}
                                            <DownloadOutlined onClick={onDownload} />
                                            <SwapOutlined rotate={90} onClick={onFlipY} />
                                            <SwapOutlined onClick={onFlipX} />
                                            <RotateLeftOutlined onClick={onRotateLeft} />
                                            <RotateRightOutlined onClick={onRotateRight} />
                                            <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                            <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                            <UndoOutlined onClick={onReset} />
                                          </Space>
                                        </Row>
                                      </>
                                    ),
                                    onChange: (index) => {
                                      setCurrent(index);
                                    },
                                  }}
                                >
                                  <Row gutter={32} justify={'center'}>
                                    {carimage1?.map((e, index) => {
                                      return (
                                        <>
                                          <Image
                                            width={150}
                                            key={index}
                                            onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                            src={`${getImagess}/${e.pathImage}?token=${token}`}
                                            alt={`รูปภาพ ${index + 1}`}
                                            style={{ cursor: 'pointer' }}
                                          />
                                        </>
                                      );
                                    })}
                                  </Row>

                                </Image.PreviewGroup>
                              </Col>
                            </Row>
                          </Space>
                        </Row>
                        <Divider />
                        <Row justify={'left'}><b><u>หน้าคู่มือ</u></b></Row>
                        <Row gutter={32} justify={'center'}>
                          {/* <Space direction="vertical" > */}
                          <Image.PreviewGroup
                            preview={{
                              countRender: (index, total) => {
                                const displayedIndex = Math.min(index, imageCount2);
                                const displayTotal = Math.max(imageCount2, imageCount2);
                                return `${displayedIndex} / ${displayTotal}`;
                              }, // ใช้เพื่อแสดงจำนวนภาพ
                              toolbarRender: (
                                _,
                                {
                                  transform: { scale },
                                  actions: {
                                    onActive,
                                    onFlipY,
                                    onFlipX,
                                    onRotateLeft,
                                    onRotateRight,
                                    onZoomOut,
                                    onZoomIn,
                                    onReset,
                                  },
                                },
                              ) => (
                                <>
                                  <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                                    {carimage2?.map((e, index) => {
                                      return (
                                        <>
                                          <Image
                                            width={auto}
                                            key={index}
                                            onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                            src={`${getImagess}/${e.pathImage}?token=${token}`}
                                            alt={`รูปภาพ ${index + 1}`}
                                            style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                          />
                                        </>
                                      );
                                    })}
                                  </Row>
                                  <Row style={{ zIndex: 2 }}>
                                    <Space size={12} className="toolbar-wrapper">
                                      {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                                    <RightOutlined onClick={() => onActive?.(1)} /> */}
                                      <DownloadOutlined onClick={onDownload} />
                                      <SwapOutlined rotate={90} onClick={onFlipY} />
                                      <SwapOutlined onClick={onFlipX} />
                                      <RotateLeftOutlined onClick={onRotateLeft} />
                                      <RotateRightOutlined onClick={onRotateRight} />
                                      <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                      <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                      <UndoOutlined onClick={onReset} />
                                    </Space>
                                  </Row>
                                </>
                              ),
                              onChange: (index) => {
                                setCurrent(index);
                              },
                            }}
                          >
                            <Row gutter={32} justify={'center'}>
                              {carimage2?.map((e, index) => {
                                return (
                                  <>
                                    <Image
                                      width={150}
                                      key={index}
                                      onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                      src={`${getImagess}/${e.pathImage}?token=${token}`}
                                      alt={`รูปภาพ ${index + 1}`}
                                      style={{ cursor: 'pointer' }}
                                    />
                                  </>
                                );
                              })}
                            </Row>
                          </Image.PreviewGroup>
                        </Row>
                        <Divider />
                        <Row justify={'left'}><b><u>รูปบัตรประชาชนคนกู้</u></b></Row>
                        <Row gutter={32} justify={'center'}>
                          {/* <Space direction="vertical" > */}
                          <Image.PreviewGroup
                            preview={{
                              countRender: (index, total) => {
                                const displayedIndex = Math.min(index, imageCount4);
                                const displayTotal = Math.max(imageCount4, imageCount4);
                                return `${displayedIndex} / ${displayTotal}`;
                              }, // ใช้เพื่อแสดงจำนวนภาพ
                              toolbarRender: (
                                _,
                                {
                                  transform: { scale },
                                  actions: {
                                    onActive,
                                    onFlipY,
                                    onFlipX,
                                    onRotateLeft,
                                    onRotateRight,
                                    onZoomOut,
                                    onZoomIn,
                                    onReset,
                                  },
                                },
                              ) => (
                                <>
                                  <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                                    {carimage4?.map((e, index) => {
                                      return (
                                        <>
                                          <Image
                                            width={auto}
                                            key={index}
                                            onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                            src={`${getImagess}/${e.pathImage}?token=${token}`}
                                            alt={`รูปภาพ ${index + 1}`}
                                            style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                          />
                                        </>
                                      );
                                    })}
                                  </Row>
                                  <Row style={{ zIndex: 2 }}>
                                    <Space size={12} className="toolbar-wrapper">
                                      {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                                    <RightOutlined onClick={() => onActive?.(1)} /> */}
                                      <DownloadOutlined onClick={onDownload} />
                                      <SwapOutlined rotate={90} onClick={onFlipY} />
                                      <SwapOutlined onClick={onFlipX} />
                                      <RotateLeftOutlined onClick={onRotateLeft} />
                                      <RotateRightOutlined onClick={onRotateRight} />
                                      <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                      <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                      <UndoOutlined onClick={onReset} />
                                    </Space>
                                  </Row>
                                </>
                              ),
                              onChange: (index) => {
                                setCurrent(index);
                              },
                            }}
                          >
                            <Row gutter={32} justify={'center'}>
                              {carimage4?.map((e, index) => {
                                return (
                                  <>
                                    <Image
                                      width={150}
                                      key={index}
                                      onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                      src={`${getImagess}/${e.pathImage}?token=${token}`}
                                      alt={`รูปภาพ ${index + 1}`}
                                      style={{ cursor: 'pointer' }}
                                    />
                                  </>
                                );
                              })}
                            </Row>
                          </Image.PreviewGroup>
                        </Row>
                        <Divider />
                        <Row justify={'left'}><b><u>ประวัติผู้กู้ / ทะเบียนบ้าน / อาชีพ2</u></b></Row>
                        <Row gutter={32} justify={'center'}>
                          {/* <Space direction="vertical" > */}
                          <Image.PreviewGroup
                            preview={{
                              countRender: (index, total) => {
                                const displayedIndex = Math.min(index, imageCount6);
                                const displayTotal = Math.max(imageCount6, imageCount6);
                                return `${displayedIndex} / ${displayTotal}`;
                              }, // ใช้เพื่อแสดงจำนวนภาพ
                              toolbarRender: (
                                _,
                                {
                                  transform: { scale },
                                  actions: {
                                    onActive,
                                    onFlipY,
                                    onFlipX,
                                    onRotateLeft,
                                    onRotateRight,
                                    onZoomOut,
                                    onZoomIn,
                                    onReset,
                                  },
                                },
                              ) => (
                                <>
                                  <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                                    {carimage6?.map((e, index) => {
                                      return (
                                        <>
                                          <Image
                                            width={auto}
                                            key={index}
                                            onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                            src={`${getImagess}/${e.pathImage}?token=${token}`}
                                            alt={`รูปภาพ ${index + 1}`}
                                            style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                          />
                                        </>
                                      );
                                    })}
                                  </Row>
                                  <Space size={12} className="toolbar-wrapper">
                                    {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                                    <RightOutlined onClick={() => onActive?.(1)} /> */}
                                    <DownloadOutlined onClick={onDownload} />
                                    <SwapOutlined rotate={90} onClick={onFlipY} />
                                    <SwapOutlined onClick={onFlipX} />
                                    <RotateLeftOutlined onClick={onRotateLeft} />
                                    <RotateRightOutlined onClick={onRotateRight} />
                                    <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                    <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                    <UndoOutlined onClick={onReset} />
                                  </Space>
                                </>
                              ),
                              onChange: (index) => {
                                setCurrent(index);
                              },
                            }}
                          >
                            <Row gutter={32} justify={'center'}>
                              {carimage6?.map((e, index) => {
                                return (
                                  <>
                                    <Image
                                      width={150}
                                      key={index}
                                      onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                      src={`${getImagess}/${e.pathImage}?token=${token}`}
                                      alt={`รูปภาพ ${index + 1}`}
                                      style={{ cursor: 'pointer' }}
                                    />
                                  </>
                                );
                              })}
                            </Row>
                          </Image.PreviewGroup>

                        </Row>
                        <Divider />
                        <Row justify={'left'}><b><u>รูปสเตทเมนต์ผู้กู้ </u></b></Row>
                        <Row gutter={32} justify={'center'}>
                          {/* <Space direction="vertical" > */}
                          <Image.PreviewGroup
                            preview={{
                              countRender: (index, total) => {
                                const displayedIndex = Math.min(index, imageCount8);
                                const displayTotal = Math.max(imageCount8, imageCount8);
                                return `${displayedIndex} / ${displayTotal}`;
                              }, // ใช้เพื่อแสดงจำนวนภาพ
                              toolbarRender: (
                                _,
                                {
                                  transform: { scale },
                                  actions: {
                                    onActive,
                                    onFlipY,
                                    onFlipX,
                                    onRotateLeft,
                                    onRotateRight,
                                    onZoomOut,
                                    onZoomIn,
                                    onReset,
                                  },
                                },
                              ) => (
                                <>
                                  <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                                    {carimage8?.map((e, index) => {
                                      return (
                                        <>
                                          <Image
                                            width={auto}
                                            key={index}
                                            onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                            src={`${getImagess}/${e.pathImage}?token=${token}`}
                                            alt={`รูปภาพ ${index + 1}`}
                                            style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                          />
                                        </>
                                      );
                                    })}
                                  </Row>
                                  <Row style={{ zIndex: 2 }}>
                                    <Space size={12} className="toolbar-wrapper">
                                      {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                                    <RightOutlined onClick={() => onActive?.(1)} /> */}
                                      <DownloadOutlined onClick={onDownload} />
                                      <SwapOutlined rotate={90} onClick={onFlipY} />
                                      <SwapOutlined onClick={onFlipX} />
                                      <RotateLeftOutlined onClick={onRotateLeft} />
                                      <RotateRightOutlined onClick={onRotateRight} />
                                      <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                      <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                      <UndoOutlined onClick={onReset} />
                                    </Space>
                                  </Row>
                                </>
                              ),
                              onChange: (index) => {
                                setCurrent(index);
                              },
                            }}
                          >
                            <Row gutter={32} justify={'center'}>
                              {carimage8?.map((e, index) => {
                                return (
                                  <>
                                    <Image
                                      width={150}
                                      key={index}
                                      onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                      src={`${getImagess}/${e.pathImage}?token=${token}`}
                                      alt={`รูปภาพ ${index + 1}`}
                                      style={{ cursor: 'pointer' }}
                                    />
                                  </>
                                );
                              })}
                            </Row>
                          </Image.PreviewGroup>
                        </Row>
                        <Divider />
                        <Row justify={'left'}><b><u>รูปสเตทเมนต์ผู้กู้ ไฟล์ PDF</u></b></Row>
                        <Row gutter={32} justify={'center'}>
                          {/* <Space direction="vertical" > */}
                          {carimage7?.map((e, index) => {
                            return (
                              <div key={index}  >
                                {/* <object style={{ width: 300 }} data={`${getImagess}/${e.pathImage}?token=${token}`} type="application/pdf" width="350px" height="450px">
                                  <p>Alternative text - include a link <a href={`${getImagess}/${e.pathImage}?token=${token}`}>to the PDF!</a></p>
                                </object> */}
                                <object data={`${getImagessPre}/${e.pathImage}?token=${token}`} type="application/pdf" width="350px" height="450px">
                                  <p>Alternative text - include a link <a href={`${getImagess}/${e.pathImage}?token=${token}`}>to the PDF!</a></p>
                                </object>
                              </div>
                            )
                          })}
                          {/* </Space> */}
                        </Row>
                        <Divider />
                        <Row justify={'left'}><b><u>เอกสารเพิ่มเติม</u></b></Row>
                        <Row gutter={32} justify={'center'}>
                          <Image.PreviewGroup
                            preview={{
                              countRender: (index, total) => {
                                const displayedIndex = Math.min(index, imageCount12);
                                const displayTotal = Math.max(imageCount12, imageCount12);
                                return `${displayedIndex} / ${displayTotal}`;
                              }, // ใช้เพื่อแสดงจำนวนภาพ
                              toolbarRender: (
                                _,
                                {
                                  transform: { scale },
                                  actions: {
                                    onActive,
                                    onFlipY,
                                    onFlipX,
                                    onRotateLeft,
                                    onRotateRight,
                                    onZoomOut,
                                    onZoomIn,
                                    onReset,
                                  },
                                },
                              ) => (
                                <>
                                  <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                                    {carimage12?.map((e, index) => {
                                      return (
                                        <>
                                          <Image
                                            width={auto}
                                            key={index}
                                            onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                            src={`${getImagess}/${e.pathImage}?token=${token}`}
                                            alt={`รูปภาพ ${index + 1}`}
                                            style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                          />
                                        </>
                                      );
                                    })}
                                  </Row>
                                  <Row style={{ zIndex: 2 }}>
                                    <Space size={12} className="toolbar-wrapper">
                                      {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                                    <RightOutlined onClick={() => onActive?.(1)} /> */}
                                      <DownloadOutlined onClick={onDownload} />
                                      <SwapOutlined rotate={90} onClick={onFlipY} />
                                      <SwapOutlined onClick={onFlipX} />
                                      <RotateLeftOutlined onClick={onRotateLeft} />
                                      <RotateRightOutlined onClick={onRotateRight} />
                                      <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                      <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                      <UndoOutlined onClick={onReset} />
                                    </Space>
                                  </Row>
                                </>
                              ),
                              onChange: (index) => {
                                setCurrent(index);
                              },
                            }}
                          >
                            <Row gutter={32} justify={'center'}>
                              {carimage12?.map((e, index) => {
                                return (
                                  <>
                                    <Image
                                      width={150}
                                      key={index}
                                      onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                      src={`${getImagess}/${e.pathImage}?token=${token}`}
                                      alt={`รูปภาพ ${index + 1}`}
                                      style={{ cursor: 'pointer' }}
                                    />
                                  </>
                                );
                              })}
                            </Row>
                          </Image.PreviewGroup>
                        </Row>
                      </Card>
                    </Row>
                  </>
                  :
                  <>
                    <Row gutter={32} justify={'center'}>
                      <Card style={{ width: '1000px' }}>
                        {
                          newDataLand?.map((item, index) => { //3
                            // console.log("item1", item)
                            const mapData = item?.img
                              .map((e, i) => {
                                if (e.type === 3) {
                                  return { ...e, index: i };  // เพิ่ม index เข้าไปในแต่ละข้อมูล
                                }
                                return null;
                              })
                              .filter(e => e !== null);  // ลบค่าว่างที่คืนจากกรณี type ไม่ตรง

                            return renderTitleDeed({ item, mapData, index, key: `{item.identificationId} - ${index}` })
                          })
                        }
                        {
                          newDataLand?.map((item, index) => { //10
                            const mapData = item?.img
                              .map((e, i) => {
                                if (e.type === 10) {
                                  return { ...e, index: i };  // เพิ่ม index เข้าไปในแต่ละข้อมูล
                                }
                                return null;
                              })
                              .filter(e => e !== null);  // ลบค่าว่างที่คืนจากกรณี type ไม่ตรง

                            return renderDivision({ item, mapData, index, key: `{item.identificationId} - ${index}` })
                          })
                        }
                        {
                          newDataLand?.map((item, index) => { //11
                            const mapData = item?.img
                              .map((e, i) => {
                                if (e.type === 11) {
                                  return { ...e, index: i };  // เพิ่ม index เข้าไปในแต่ละข้อมูล
                                }
                                return null;
                              })
                              .filter(e => e !== null);  // ลบค่าว่างที่คืนจากกรณี type ไม่ตรง


                            return renderLawang({ item, mapData, index, key: `{item.identificationId} - ${index}` })
                          })
                        }
                        {
                          newDataLand?.map((item, index) => { //1
                            const mapData = item?.img
                              .map((e, i) => {
                                if (e.type === 1) {
                                  return { ...e, index: i };  // เพิ่ม index เข้าไปในแต่ละข้อมูล
                                }
                                return null;
                              })
                              .filter(e => e !== null);  // ลบค่าว่างที่คืนจากกรณี type ไม่ตรง

                            return renderLandBuildings({ item, mapData, index, key: `{item.identificationId} - ${index}` })
                          })
                        }

                        <Row justify={'left'}><b><u>รูปคนกู้</u></b></Row>
                        <Row gutter={32} justify={'center'}>
                          <Image.PreviewGroup
                            preview={{
                              countRender: (index, total) => {
                                const displayIndex = Math.min(index, imageCount4);
                                const displayTotal = Math.min(imageCount4, imageCount4);
                                return `${displayIndex} / ${displayTotal}`;
                              }, // ใช้เพื่อแสดงจำนวนภาพ
                              toolbarRender: (
                                _,
                                {
                                  transform: { scale },
                                  actions: {
                                    onActive,
                                    onFlipY,
                                    onFlipX,
                                    onRotateLeft,
                                    onRotateRight,
                                    onZoomOut,
                                    onZoomIn,
                                    onReset,
                                  },
                                },
                              ) => (
                                <>
                                  <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                                    {carimage4?.map((e, index) => {
                                      return (
                                        <>
                                          <Image
                                            width={auto}
                                            key={index}
                                            onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                            src={`${getImagess}/${e.pathImage}?token=${token}`}
                                            alt={`รูปภาพ ${index + 1}`}
                                            style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                          />
                                        </>
                                      );
                                    })}
                                  </Row>
                                  <Row style={{ zIndex: 2 }}>
                                    <Space size={12} className="toolbar-wrapper">
                                      {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                                      <RightOutlined onClick={() => onActive?.(1)} /> */}
                                      <DownloadOutlined onClick={onDownload} />
                                      <SwapOutlined rotate={90} onClick={onFlipY} />
                                      <SwapOutlined onClick={onFlipX} />
                                      <RotateLeftOutlined onClick={onRotateLeft} />
                                      <RotateRightOutlined onClick={onRotateRight} />
                                      <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                      <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                      <UndoOutlined onClick={onReset} />
                                    </Space>
                                  </Row>
                                </>
                              ),
                              onChange: (index) => {
                                setCurrent(index);
                              },
                            }}
                          >
                            <Row gutter={32} justify={'center'}>
                              {carimage4?.map((e, index) => {
                                return (
                                  <>
                                    <Image
                                      width={150}
                                      key={index}
                                      onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                      src={`${getImagess}/${e.pathImage}?token=${token}`}
                                      alt={`รูปภาพ ${index + 1}`}
                                      style={{ cursor: 'pointer' }}
                                    />
                                  </>
                                );
                              })}
                            </Row>
                          </Image.PreviewGroup>
                        </Row>
                        < Divider />
                        < Row justify={'left'}><b><u>ประวัติผู้กู้ / ทะเบียนบ้าน / อาชีพ</u></b></Row>
                        <Row gutter={32} justify={'center'}>
                          <Image.PreviewGroup
                            preview={{
                              countRender: (index, total) => {
                                const displayedIndex = Math.min(index, imageCount6);
                                const displayTotal = Math.max(imageCount6, imageCount6);
                                return `${displayedIndex} / ${displayTotal}`;
                              }, // ใช้เพื่อแสดงจำนวนภาพ
                              toolbarRender: (
                                _,
                                {
                                  transform: { scale },
                                  actions: {
                                    onActive,
                                    onFlipY,
                                    onFlipX,
                                    onRotateLeft,
                                    onRotateRight,
                                    onZoomOut,
                                    onZoomIn,
                                    onReset,
                                  },
                                },
                              ) => (
                                <>
                                  <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                                    {carimage6?.map((e, index) => {
                                      return (
                                        <>
                                          <Image
                                            width={auto}
                                            key={index}
                                            onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                            src={`${getImagess}/${e.pathImage}?token=${token}`}
                                            alt={`รูปภาพ ${index + 1}`}
                                            style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                          />
                                        </>
                                      );
                                    })}
                                  </Row>
                                  <Row style={{ zIndex: 2 }}>
                                    <Space size={12} className="toolbar-wrapper">
                                      {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                                    <RightOutlined onClick={() => onActive?.(1)} /> */}
                                      <DownloadOutlined onClick={onDownload} />
                                      <SwapOutlined rotate={90} onClick={onFlipY} />
                                      <SwapOutlined onClick={onFlipX} />
                                      <RotateLeftOutlined onClick={onRotateLeft} />
                                      <RotateRightOutlined onClick={onRotateRight} />
                                      <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                      <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                      <UndoOutlined onClick={onReset} />
                                    </Space>
                                  </Row>
                                </>
                              ),
                              onChange: (index) => {
                                setCurrent(index);
                              },
                            }}
                          >
                            <Row gutter={32} justify={'center'}>
                              {carimage6?.map((e, index) => {
                                return (
                                  <>
                                    <Image
                                      width={150}
                                      key={index}
                                      onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                      src={`${getImagess}/${e.pathImage}?token=${token}`}
                                      alt={`รูปภาพ ${index + 1}`}
                                      style={{ cursor: 'pointer' }}
                                    />
                                  </>
                                );
                              })}
                            </Row>
                          </Image.PreviewGroup>
                        </Row>
                        <Divider />
                        <Row justify={'left'}><b><u>รูปสเตทเมนรูปภาพ</u></b></Row>
                        <Row gutter={32} justify={'center'}>
                          <Image.PreviewGroup
                            preview={{
                              countRender: (index, total) => {
                                const displayedIndex = Math.min(index, imageCount8);
                                const displayTotal = Math.max(imageCount8, imageCount8);
                                return `${displayedIndex} / ${displayTotal}`;
                              }, // ใช้เพื่อแสดงจำนวนภาพ
                              toolbarRender: (
                                _,
                                {
                                  transform: { scale },
                                  actions: {
                                    onActive,
                                    onFlipY,
                                    onFlipX,
                                    onRotateLeft,
                                    onRotateRight,
                                    onZoomOut,
                                    onZoomIn,
                                    onReset,
                                  },
                                },
                              ) => (
                                <>
                                  <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                                    {carimage8?.map((e, index) => {
                                      return (
                                        <>
                                          <Image
                                            width={auto}
                                            key={index}
                                            onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                            src={`${getImagess}/${e.pathImage}?token=${token}`}
                                            alt={`รูปภาพ ${index + 1}`}
                                            style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                          />
                                        </>
                                      );
                                    })}
                                  </Row>
                                  <Row style={{ zIndex: 2 }}>
                                    <Space size={12} className="toolbar-wrapper">
                                      {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                                    <RightOutlined onClick={() => onActive?.(1)} /> */}
                                      <DownloadOutlined onClick={onDownload} />
                                      <SwapOutlined rotate={90} onClick={onFlipY} />
                                      <SwapOutlined onClick={onFlipX} />
                                      <RotateLeftOutlined onClick={onRotateLeft} />
                                      <RotateRightOutlined onClick={onRotateRight} />
                                      <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                      <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                      <UndoOutlined onClick={onReset} />
                                    </Space>
                                  </Row>
                                </>
                              ),
                              onChange: (index) => {
                                setCurrent(index);
                              },
                            }}
                          >
                            <Row gutter={32} justify={'center'}>
                              {carimage8?.map((e, index) => {
                                return (
                                  <>
                                    <Image
                                      width={150}
                                      key={index}
                                      onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                      src={`${getImagess}/${e.pathImage}?token=${token}`}
                                      alt={`รูปภาพ ${index + 1}`}
                                      style={{ cursor: 'pointer' }}
                                    />
                                  </>
                                );
                              })}
                            </Row>
                          </Image.PreviewGroup>
                        </Row>
                        <Divider />
                        <Row justify={'left'}><b><u>รูปสเตทเมนต์ผู้กู้ ไฟล์ PDF</u></b></Row>
                        <Row gutter={32} justify={'center'}>
                          {carimage7?.map((e, index) => {
                            return (
                              <div key={index}>
                                {/* <object style={{ width: 300 }} data={`${getImagess}/${e.pathImage}?token=${token}`} type="application/pdf" width="350px" height="450px">
                                  <p>Alternative text - include a link <a href={`${getImagess}/${e.pathImage}?token=${token}`}>to the PDF!</a></p>
                                </object> */}

                                <object data={`${getImagessPre}/${e.pathImage}?token=${token}`} type="application/pdf" width="350px" height="450px">
                                  <p>Alternative text - include a link <a href={`${getImagess}/${e.pathImage}?token=${token}`}>to the PDF!</a></p>
                                </object>

                              </div>
                            )
                          })}
                        </Row>
                        <Divider />
                        <Row justify={'left'}><b><u>เอกสารเพิ่มเติม</u></b></Row>
                        <Row gutter={32} justify={'center'}>
                          <Image.PreviewGroup
                            preview={{
                              countRender: (index, total) => {
                                const displayedIndex = Math.min(index, imageCount8);
                                const displayTotal = Math.max(imageCount8, imageCount8);
                                return `${displayedIndex} / ${displayTotal}`;
                              }, // ใช้เพื่อแสดงจำนวนภาพ
                              toolbarRender: (
                                _,
                                {
                                  transform: { scale },
                                  actions: {
                                    onActive,
                                    onFlipY,
                                    onFlipX,
                                    onRotateLeft,
                                    onRotateRight,
                                    onZoomOut,
                                    onZoomIn,
                                    onReset,
                                  },
                                },
                              ) => (
                                <>
                                  <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                                    {carimage12?.map((e, index) => {
                                      return (
                                        <>
                                          <Image
                                            width={auto}
                                            key={index}
                                            onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                            src={`${getImagess}/${e.pathImage}?token=${token}`}
                                            alt={`รูปภาพ ${index + 1}`}
                                            style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                          />
                                        </>
                                      );
                                    })}
                                  </Row>
                                  <Row style={{ zIndex: 2 }}>
                                    <Space size={12} className="toolbar-wrapper">
                                      {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                                    <RightOutlined onClick={() => onActive?.(1)} /> */}
                                      <DownloadOutlined onClick={onDownload} />
                                      <SwapOutlined rotate={90} onClick={onFlipY} />
                                      <SwapOutlined onClick={onFlipX} />
                                      <RotateLeftOutlined onClick={onRotateLeft} />
                                      <RotateRightOutlined onClick={onRotateRight} />
                                      <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                      <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                      <UndoOutlined onClick={onReset} />
                                    </Space>
                                  </Row>
                                </>
                              ),
                              onChange: (index) => {
                                setCurrent(index);
                              },
                            }}
                          >
                            <Row gutter={32} justify={'center'}>
                              {carimage12?.map((e, index) => {
                                return (
                                  <>
                                    <Image
                                      width={150}
                                      key={index}
                                      onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                      src={`${getImagess}/${e.pathImage}?token=${token}`}
                                      alt={`รูปภาพ ${index + 1}`}
                                      style={{ cursor: 'pointer' }}
                                    />
                                  </>
                                );
                              })}
                            </Row>
                          </Image.PreviewGroup>
                          <Image.PreviewGroup
                            preview={{
                              countRender: (index, total) => {
                                const displayedIndex = Math.min(index, imageCount12);
                                const displayTotal = Math.max(imageCount12, imageCount12);
                                return `${displayedIndex} / ${displayTotal}`;
                              }, // ใช้เพื่อแสดงจำนวนภาพ
                              toolbarRender: (
                                _,
                                {
                                  transform: { scale },
                                  actions: {
                                    onActive,
                                    onFlipY,
                                    onFlipX,
                                    onRotateLeft,
                                    onRotateRight,
                                    onZoomOut,
                                    onZoomIn,
                                    onReset,
                                  },
                                },
                              ) => (
                                <>
                                  <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                                    {carimage12?.map((e, index) => {
                                      return (
                                        <>
                                          <Image
                                            width={auto}
                                            key={index}
                                            onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                            src={`${getImagess}/${e.pathImage}?token=${token}`}
                                            alt={`รูปภาพ ${index + 1}`}
                                            style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                          />
                                        </>
                                      );
                                    })}
                                  </Row>
                                  <Row style={{ zIndex: 2 }}>
                                    <Space size={12} className="toolbar-wrapper">
                                      {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                                    <RightOutlined onClick={() => onActive?.(1)} /> */}
                                      <DownloadOutlined onClick={onDownload} />
                                      <SwapOutlined rotate={90} onClick={onFlipY} />
                                      <SwapOutlined onClick={onFlipX} />
                                      <RotateLeftOutlined onClick={onRotateLeft} />
                                      <RotateRightOutlined onClick={onRotateRight} />
                                      <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                      <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                      <UndoOutlined onClick={onReset} />
                                    </Space>
                                  </Row>
                                </>
                              ),
                              onChange: (index) => {
                                setCurrent(index);
                              },
                            }}
                          >
                            <Row gutter={32} justify={'center'}>
                              {carimage12?.map((e, index) => {
                                return (
                                  <>
                                    <Image
                                      width={150}
                                      key={index}
                                      onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                      src={`${getImagess}/${e.pathImage}?token=${token}`}
                                      alt={`รูปภาพ ${index + 1}`}
                                      style={{ cursor: 'pointer' }}
                                    />
                                  </>
                                );
                              })}
                            </Row>
                          </Image.PreviewGroup>
                        </Row>
                      </Card >
                    </Row>
                  </>
              }
              {/* getImgs */}
              <Row gutter={32} justify={'center'}>
                <Card style={{ width: '1000px' }}>
                  <Row justify={'left'}><b><u>เอกสารขอรี/ปรับ/เปลี่ยน</u></b></Row>
                  <Row gutter={32} justify={'center'}>
                    <Space direction="vertical">
                      <Image.PreviewGroup
                        preview={{
                          countRender: (index, total) => {
                            const displayedIndex = Math.min(index, imageCount14);
                            const displayTotal = Math.max(imageCount14, imageCount14);
                            console.log("displayTotal", displayTotal)
                            return `${displayedIndex} / ${displayTotal}`;
                          }, // ใช้เพื่อแสดงจำนวนภาพ
                          toolbarRender: (
                            _,
                            {
                              transform: { scale },
                              actions: {
                                onActive,
                                onFlipY,
                                onFlipX,
                                onRotateLeft,
                                onRotateRight,
                                onZoomOut,
                                onZoomIn,
                                onReset,
                              },
                            },
                          ) => (
                            <>
                              <Row justify={'center'} style={{ height: '60px', marginBottom: '15px' }}>
                                {carimage14?.map((e, index) => {
                                  return (
                                    <>
                                      <Image
                                        width={auto}
                                        key={index}
                                        onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                        src={`${getImagess}/${e.pathImage}?token=${token}`}
                                        alt={`รูปภาพ ${index + 1}`}
                                        style={{ cursor: 'pointer', width: '80px', margin: 2, objectFit: 'cover' }}
                                      />
                                    </>
                                  );
                                })}
                              </Row>
                              <Row style={{ zIndex: 2 }}>
                                <Space size={12} className="toolbar-wrapper">
                                  {/* <LeftOutlined onClick={() => onActive?.(-1)} />
                                    <RightOutlined onClick={() => onActive?.(1)} /> */}
                                  <DownloadOutlined onClick={onDownload} />
                                  <SwapOutlined rotate={90} onClick={onFlipY} />
                                  <SwapOutlined onClick={onFlipX} />
                                  <RotateLeftOutlined onClick={onRotateLeft} />
                                  <RotateRightOutlined onClick={onRotateRight} />
                                  <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                                  <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                                  <UndoOutlined onClick={onReset} />
                                </Space>
                              </Row>
                            </>
                          ),
                          onChange: (index) => {
                            setCurrent(index);
                          },
                        }}
                      >
                        <Row gutter={32} justify={'center'}>
                          {carimage14?.map((e, index) => {
                            return (
                              <>
                                <Image
                                  width={150}
                                  key={index}
                                  onClick={() => handleSelectImage(e)} // เมื่อคลิกเลือกภาพ
                                  src={`${getImagess}/${e.pathImage}?token=${token}`}
                                  alt={`รูปภาพ ${index + 1}`}
                                  style={{ cursor: 'pointer' }}
                                />
                              </>
                            );
                          })}
                        </Row>
                      </Image.PreviewGroup>
                    </Space>
                  </Row>
                  <Divider />
                </Card>
              </Row>
            </Row>
          </Form>
        </Row >
        <Divider style={{ margin: 5 }} />
        <Row gutter={32} justify={'center'}>
          <Col className='gutter-row' span={6} style={{ textAlign: 'left' }}>
            {/* <Button
            style={{ margin: '3px', marginBottom: 0, background: '#bfbfbf', color: '#000000', }}
            type='primary'
            onClick={onChangeBack}
          >
            ย้อนกลับ
          </Button> */}
          </Col>
          <Col className='gutter-row' span={18} style={{ textAlign: 'right' }}>
            <Space>
              <Button type="text" onClick={handleCancel} style={{ background: "lightgray" }}>ปิดหน้าต่าง</Button>
              <Button type="primary" danger onClick={handleReject}>Reject</Button>
              <Button type="primary" onClick={onUnApprove} style={{ background: "gray" }} >ไม่อนุมัติ</Button>
              <Button type="primary" onClick={onApprove} >อนุมัติ</Button>
              <Button type="primary" onClick={onApproveCon} >อนุมัติแบบมีเงื่อนไข</Button>
            </Space>
          </Col>
        </Row>
        {
          modalAddGuarantor ?
            <ModalAddGuarantor
              open={modalAddGuarantor}
              close={setModalAddGuarantor}
              getData={getData}
              fucnAdd={fucnAdd}
              dataCusid={dataPost}
              getCarId={getCarId} />
            : null
        }
        {
          ModalEditData ?
            <ModalEditGuarantor
              open={ModalEditData}
              close={setModalEditData}
              fucnEdit={fucnEdit}
              dataindex={index1}
              shootdata={dataedit}
              getCarId={getCarId} />
            : null
        }
        {
          openShowImage ?
            <ModalShowImages
              open={openShowImage}
              close={setOpenShowImage}
              carimage1={carshowMadol}
              nuarantorNew={nuarantorNew}
              nuarantorNum={nuarantorNum}
              setGuarantorNum={setGuarantorNum}
            />

            : null
        }
        {/* {
          modalnote ?
            <Note
              open={modalnote}
              close={setModalNote}
              setCheckApprove={setCheckApprove}
              closeall={close}
              fucnEdit={fucnEdit}
              dataindex={index1}
              shootdata={getData1}
              appStatus={appStatus}
              guarantorData={guarantorData}
              setGetNotification={setGetNotification}
              sendback={sendback}
              newDataLand={newDataLand}
            />
            : null
        } */}
        {
          modalnote ?
            <ModalMainNote
              open={modalnote}
              close={setModalNote}
              setCheckApprove={setCheckApprove}
              closeall={close}
              fucnEdit={fucnEdit}
              shootdata={getData1}
              appStatus={appStatus}
              guarantorData={guarantorData}
              setGetNotification={setGetNotification}
              sendback={sendback}
              newDataLand={newDataLand}
            />
            : null
        }
        {contextHolder}
      </Spin >
    </>
  )
};

export default Conclusion