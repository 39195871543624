import React, { useEffect, useState } from "react";
import { Modal, Card, Spin, Input, Button, Form, Row, Select, Col, Space, notification } from "antd";
import axios from "axios";
import dayjs from "dayjs";
import Currency from "currency.js";
import { loadDetailAunSendPG, showcausename } from "../../../file_mid/all_api";
import { HirePurchase } from "../../../offer_case/hire_purchase/HirePurchase";
import { Installment } from "../../../offer_case/ploan/Installment";
import YellowCardApproval from "./YellowCardApproval";
import { nopay } from "../../../file_mid/all_options";
const Memo = ({ open, close, id, data, closeModal, onChangeStatus, status, shootdata }) => {
  const [form] = Form.useForm();
  const [formData, setFormData] = useState({ Memo: "" });
  const [loading, setLoading] = useState(false);
  const user = localStorage.getItem("username");
  const guarantor = "car";
  const appStatus = "อนุมัติ";
  const [addNote, setAddNote] = useState({ name: "" });
  const [options, setOptions] = useState([]);
  const [api, contextHolder] = notification.useNotification();

  const [statusAccept, setstatusAccept] = useState({});

  //ส่งค่าไปในตาราง p-loan
  const [installmentAmountt, setinstallmentAmountt] = useState({})

  const [checkType, setCheckType] = useState();
  const [typeCar, setTypeCar] = useState();
  const [checkDis, setCheckDis] = useState(true);

  const [checkCar23, setCheckCar23] = useState(false);

  const [openTable, setOpenTable] = useState(false); //ตารางค่างวด p-loan
  const [openTableHP, setOpenTableHP] = useState(false); //ตารางค่างวด เช่าซื้อ

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [money] = useState({}); //loan
  const branch = localStorage.getItem("branch");
  const [preData, setpreData] = useState(); //car
  const [checkApprovalConditions] = useState(0);

  const [carsApproveve, setCarsApproveve] = useState();
  const [dataDB2, setDataDB2] = useState();
  const [notesCarsApprove, setNotesCarsApprove] = useState();
  const [filteredOptions, setFilteredOptions] = useState([]); //หางวดตามช่วงของเฮียอนุมัติ
  const [dataTonew, setDataToNew] = useState({}); //หางวดตามช่วงของเฮียอนุมัติ
  const [openMemo, setOpenMemo] = useState(false);
  const [checkTypeCar, setCheckTypeCar] = useState(true);
  // const [checkType2, setCheckType2] = useState(true);

  // console.log("keys", keys)
  console.log("data", data)
  // console.log("shootdata", shootdata)
  // console.log("carsApproveve", carsApproveve)
  // console.log("preData", preData)
  // console.log("statusAccept", statusAccept)
  // console.log(" เปิด Memo แล้ว")


  useEffect(() => {
    setOpenMemo(open);

    if (openMemo === true) {
      loadDataReAun();
      loadCauseName();
      if (data?.DATA_TYPE === "sfhp" || data?.DATA_TYPE === "vsfhp") {
        console.log("เช่าซื้อ");
        if (data?.products === "car") {
          setpreData({
            ...preData,
            // apProductType: data?.products,
            // apProductLoanType: data?.productLoanType ? data?.productLoanType : null,
            // apLoanAmount: data?.approvedLoanAmount ? data?.approvedLoanAmount : null,
            // apDownPayment: data?.downPayment ? data?.downPayment : null,
            // apInterest: data?.interest ? data?.interest : null,
            // apInterestRate: data?.interestRate ? data?.interestRate : null,
            // pgLoanTerm: "",
            // pgLoanTermTo: "",
            // apMonthlyPayment: data?.monthlyPayment ? data?.monthlyPayment : null,
            // apInstallmentWithInterest: ~~data?.monthlyPayment * ~~data?.loanTerm,
          });
        } else if (data?.products === "land") {
        }
      } else {
        console.log("p-loan");
        setpreData({ ...preData, })
      }
    }
  }, [openMemo]);

  useEffect(() => {
    if (carsApproveve?.approvedLoanTerm && carsApproveve?.approvedLoanTermTo) {
      const filtered = nopay.filter(
        (option) =>
          option.value >= carsApproveve.approvedLoanTerm &&
          option.value <= carsApproveve.approvedLoanTermTo
      );
      // console.log("filtered", filtered);
      setFilteredOptions(filtered);
    }

  }, [carsApproveve]);


  useEffect(() => {
    if (preData?.pgLoanTerm && preData?.apInterestRate) {
      if (data?.products === "car") {
        if (checkType === "เช่าซื้อ1") {
          console.log("เช่าซื้อ1")
          car_Calculate();
        } else if (typeCar !== 1 && checkType === "เช่าซื้อ") {
          console.log("เช่าซื้อ รถใหญ่")
          car_Calculate();
        }
      } else if (data?.products === "land") {
        // if (appStatus === "อนุมัติ" || appStatus === "อนุมัติแบบมีเงื่อนไข") {
        land_Calculate();
        // }
        // console.log("land")
      }
    }
  }, [
    preData?.apLoanAmount,
    preData?.pgLoanTerm,
    preData?.apInterestRate,
    checkType,
  ]);


  const saveSuccess = () => {
    api.success({
      message: "บันทึกสำเร็จ ",
    });
  };

  const loadCauseName = async () => {
    await axios.get(showcausename)
      .then((res) => {
        if (res.status === 200) {
          setOptions(res.data);
        }
      })
      .catch((err) => console.log(err));
  };

  const currencyFormatOne = (amount) => {
    if (amount) {
      return Number(amount)
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,");
    }
  };

  const loadDataReAun = async () => {
    // setLoading(true)
    axios
      .post(loadDetailAunSendPG, { contno: data?.CONTNO })
      .then((res) => {
        if (res.status === 200) {
          console.log("res.dataNewPageAun", res.data);

          setCarsApproveve(res?.data?.carsApproveve);
          setDataDB2(res.data?.dataDB2);
          setNotesCarsApprove(res?.data?.notesCarsApprove);
          // setLoading(false)
        }
      })
      .catch((err) => {
        console.log("err", err);
        // setLoading(false)
      });
  };

  const handleMoney = (values) => {
    //console.log("values",values)
    setpreData({
      ...preData,
      apInterestRate: values?.interestRate,
      apLoanAmount: values?.loanAmountt,
      pgLoanTerm: values?.installmentAmountt,
      pgLoanTermTo: carsApproveve?.approvedLoanTermTo ? carsApproveve?.approvedLoanTermTo : null,
      apInterest: values?.interestt,
      apMonthlyPayment: values?.monthlyPayment,
      apInstallmentWithInterest: values?.installmentWithInterestt,
    });
    form.setFieldsValue({
      apLoanAmount: values?.loanAmountt,
      apInterestRate: values?.interestRate,
      pgLoanTerm: values?.installmentAmountt,
      apInterest: currencyFormatOne(values?.interestt),
      apMonthlyPayment: currencyFormatOne(values?.monthlyPayment),
      apInstallmentWithInterest: currencyFormatOne(
        values?.installmentWithInterestt
      ),
    });
  };

  const handleMoneyHP = (values) => {
    // console.log("valueeeee", values);
    const secondDecimalDigit = Math.floor(values?.interestRate * 100) % 10; // หาตำแหน่งทศนิยมที่ 2
    let roundedRate;

    if (secondDecimalDigit > 5) {
      roundedRate = Math.ceil(values?.interestRate * 100) / 100;
    } else {
      roundedRate = Math.floor(values?.interestRate * 100) / 100;
    }

    // ((parseFloat(preData.apLoanAmount) * Currency(interestRateCar, { precision: 5 }).value) * preData.pgLoanTerm)
    setpreData({
      ...preData,
      apLoanAmount: values?.loanAmountt,
      apInterestRate: roundedRate ? roundedRate : values?.interestRate,
      pgLoanTerm: values?.installmentAmountt,
      pgLoanTermTo: carsApproveve?.approvedLoanTermTo ? carsApproveve?.approvedLoanTermTo : null,
      apInterest: values?.interestt,
      apMonthlyPayment: values?.monthlyPayment,
      apInstallmentWithInterest: values?.installmentWithInterestt,
    });
    form.setFieldsValue({
      apLoanAmount: currencyFormatOne(values?.loanAmountt),
      apInterestRate: roundedRate,
      pgLoanTerm: values?.installmentAmountt,
      apInterest: currencyFormatOne(values?.interestt),
      apMonthlyPayment: currencyFormatOne(values?.monthlyPayment),
      apInstallmentWithInterest: currencyFormatOne(
        values?.installmentWithInterestt
      ),
    });
  };

  const changeLoanTerm = (value) => {
    setpreData({ ...preData, pgLoanTerm: value });
  };

  const changeInterestRate = (value) => {
    // console.log("apInterestRate", value);
    setpreData({ ...preData, apInterestRate: value });
  };

  const handleClose = () => {
    close(false);
  };

  const handleChangePLorCH = (value) => {
    // console.log("value เช่าซื้อ1 หรือ เช่าซื้อ", value);
    // console.log("typeCar", typeCar);
    setCheckType(value);
    form.setFieldsValue({
      // ...preData,
      apLoanAmount: carsApproveve?.approvedLoanAmount,
      pgLoanTerm: "",
      apInterestRate: "",
      apInterest: "",
      apLoanAmount: "",
      apMonthlyPayment: "",
      apInstallmentWithInterest: "",
      apProductType: "",
      // apProductType1: "",
    });
    setpreData({
      // ...preData,
      apLoanAmount: carsApproveve?.approvedLoanAmount,
      pgLoanTerm: "",
      apInterestRate: "",
      apInterest: "",
      apLoanAmount: "",
      apMonthlyPayment: "",
      apInstallmentWithInterest: "",
      apProductType: "",
    });
    if (typeCar === 1) {
      setCheckCar23(false);
      if (value === "เช่าซื้อ") {
        setCheckDis(true);
        // setCheckCar23(false);
        setpreData({
          apLoanAmount: carsApproveve?.approvedLoanAmount,
          apProductType: "เช่าซื้อ",
          pgLoanTermTo: carsApproveve?.approvedLoanTermTo ? carsApproveve?.approvedLoanTermTo : null,
        });
        form.setFieldsValue({
          apLoanAmount: currencyFormatOne(carsApproveve?.approvedLoanAmount),
        })
      } else if (value === "เช่าซื้อ1") {
        setCheckDis(false);
        // setCheckCar23(false);
        form.setFieldsValue({
          apLoanAmount: carsApproveve?.approvedLoanAmount,
          pgLoanTerm: "",
          apInterestRate: "",
          apInterest: "",
          apMonthlyPayment: "",
          apInstallmentWithInterest: "",
          apProductType: "",
        });
        setpreData({
          apProductType: "เช่าซื้อ1",
          apLoanAmount: carsApproveve?.approvedLoanAmount,
          pgLoanTermTo: carsApproveve?.approvedLoanTermTo ? carsApproveve?.approvedLoanTermTo : null,
        });
      }
    } else if (typeCar === 2 || typeCar === 3) {
      //รถบรรทุก
      //รถเครื่องจักรการเกษตร 
      // console.log("typeCar", typeCar)
      if (value === "p-loan") {
        setCheckDis(true);
        setCheckCar23(false);
      } else if (value === "เช่าซื้อ") {
        setCheckDis(false);
        setCheckCar23(true);
        form.setFieldsValue({
          apLoanAmount: carsApproveve?.approvedLoanAmount,
          pgLoanTerm: "",
          apInterestRate: "",
          apInterest: "",
          apMonthlyPayment: "",
          apInstallmentWithInterest: "",
          apProductType: "",
        });
        setpreData({
          apProductType: "เช่าซื้อ",
          apLoanAmount: carsApproveve?.approvedLoanAmount,
          pgLoanTermTo: carsApproveve?.approvedLoanTermTo ? carsApproveve?.approvedLoanTermTo : null,
        });


      }
    };
  }

  const car_Calculate = () => {
    //แก้ใหม่
    var interestRateCar = parseFloat(preData.apInterestRate) / 100; // อัตราดอก / 100
    var rateCar =
      parseFloat(preData.apLoanAmount) *
      Currency(interestRateCar, { precision: 5 }).value *
      preData.pgLoanTerm; // (ยอดจัด * อัตราดอกที่หาร100แล้ว ,precision: 3 คือทศนิยม 3 )*งวด
    var loanTermCar =
      (rateCar + parseFloat(preData.apLoanAmount)) /
      parseInt(preData.pgLoanTerm); // (ดอกเบี้ยทั้งหมด + ยอดจัด)/ งวด
    var newLoanTermCar = Math.ceil(Currency(loanTermCar * 0.07 + loanTermCar), {
      precision: 5,
    }); // งวดละใหม่ = ( งวดละ * 0.07 ) + งวดละ
    var resultRateCar =
      Math.ceil(
        Currency(newLoanTermCar, { precision: 5 }) *
        parseInt(preData.pgLoanTerm)
      ) - parseFloat(preData.apLoanAmount); // งวดละใหม่ * งวด - ยอดจัด
    var newResultPriceCar = Math.ceil(
      Currency(newLoanTermCar) * parseInt(preData.pgLoanTerm),
      { precision: 5 }
    ); // งวดละใหม่ * งวด

    setpreData({
      ...preData,
      apInterestRate: Math.ceil(preData.apInterestRate * 100) / 100,
      apMonthlyPayment: newLoanTermCar,
      apInterest: resultRateCar,
      apInstallmentWithInterest: newResultPriceCar,
      apLoanAmount: preData.apLoanAmount,
      pgLoanTerm: preData.pgLoanTerm,
    });

    form.setFieldsValue({
      apMonthlyPayment: currencyFormatOne(newLoanTermCar),
      apInterest: currencyFormatOne(resultRateCar),
      apInstallmentWithInterest: currencyFormatOne(newResultPriceCar),
    });
  };

  const land_Calculate = () => {
    var interestRateLand = parseFloat(shootdata.lands.landLoanDetails.interestRateLand) / 100; // อัตราดอก / 100
    var rate = parseFloat(preData?.apLoanAmount) * Currency(interestRateLand, { precision: 3 }).value * parseInt(preData?.pgLoanTerm); // (ยอดจัด * อัตราดอกที่หาร100แล้ว ,precision: 3 คือทศนิยม 3 )*งวด
    var loanTerm = Math.ceil(Currency((rate + parseFloat(preData?.apLoanAmount)) / parseInt(preData?.pgLoanTerm), { precision: 2 }).value
    ); // (ดอกเบี้ยทั้งหมด + ยอดจัด)/ งวด
    var resultRate = loanTerm * parseInt(preData?.pgLoanTerm) - parseFloat(preData?.apLoanAmount);
    var resultPrice = loanTerm * parseInt(preData?.pgLoanTerm);

    form.setFieldsValue({
      apMonthlyPayment: loanTerm,
      apInterest: resultRate,
      apInstallmentWithInterest: resultPrice,
    });

    if (appStatus === "อนุมัติ" && checkApprovalConditions === 0) {
      setstatusAccept({
        ...statusAccept,
        apMonthlyPayment: loanTerm,
        apInterest: resultRate,
        apInstallmentWithInterest: resultPrice,
        apLoanAmount: preData?.apLoanAmount,
        pgLoanTerm: preData?.pgLoanTerm,

        status: appStatus,
        landId: shootdata?.lands?.landId,
        loanId: shootdata?.lands?.landLoanDetails?.landLoanId,
        customerId: shootdata?.customerId,
        approvalStatus: appStatus,
        appStatus: appStatus,
        ApprovedBy: user,

        apProductType: shootdata?.lands?.landLoanDetails?.productType,
        apProductLoanType:
          shootdata?.lands?.landLoanDetails?.productLoanLandType,
        // apLoanAmount: shootdata?.lands?.landLoanDetails?.loanAmountLand,
        apDownPayment: shootdata?.lands?.landLoanDetails?.downPayment,
        // apInterest: shootdata?.lands?.landLoanDetails?.interestLand,
        apInterestRate: parseFloat(
          shootdata?.lands?.landLoanDetails?.interestRateLand
        ),
        // pgLoanTerm: shootdata?.lands?.landLoanDetails?.loanLandTerm,
        // apMonthlyPayment: shootdata?.lands?.landLoanDetails?.monthlyPayment,
        apProposalBy: shootdata?.lands?.landLoanDetails?.proposalBy,
        apReviewedBy: shootdata?.lands?.landLoanDetails?.reviewedBy,
        apapprovalStatus: appStatus,
        apApprovedBy: user,
        apApprovalDate: dayjs(),

        apCheckGua: shootdata?.lands?.landLoanDetails?.checkGua,
        apBranch: branch,
        approvalConditions: false,
      });
    } else {
      setstatusAccept({
        ...statusAccept,
        apMonthlyPayment: loanTerm,
        apInterest: resultRate,
        apInstallmentWithInterest: resultPrice,
        apLoanAmount: preData?.apLoanAmount,
        pgLoanTerm: preData?.pgLoanTerm,

        status: appStatus,
        landId: shootdata?.lands?.landId,
        loanId: shootdata?.lands?.landLoanDetails?.landLoanId,
        customerId: shootdata?.customerId,
        approvalStatus: appStatus,
        appStatus: appStatus,
        ApprovedBy: user,
      });
    }
  };

  const onFinish = () => {
    // console.log('Success:', values);
    const dataNew = {
      ...preData,
      pgLoanTermTo: carsApproveve?.approvedLoanTermTo
        ? carsApproveve?.approvedLoanTermTo
        : null,
      Memo: formData?.Memo,
      installment: preData?.apMonthlyPayment ? preData?.apMonthlyPayment : null,
      interestRate: preData?.apInterestRate.toString(),
      productTypeCar: typeCar,
      productType: checkType
    };
    console.log("dataNew", dataNew);
    setDataToNew(dataNew);
    setIsModalOpen(true);
  };

  /////////////// เลือก ประเภทรถ ///////////////////
  const handleTypeCars = (value) => {
    setCheckTypeCar(false)
    // console.log("value", value);
    // apInterestRate
    setCheckType("");
    setCheckCar23(false)
    setCheckDis(true)
    setTypeCar(value);
    if (value = 1) {
      setCheckCar23(false)
      form.setFieldsValue({
        apProductType2: "",
        apProductType1: "",
        pgLoanTerm: "",
        apInterestRate: "",
        apLoanAmount: "",
        apInterest: "",
        apMonthlyPayment: "",
        apInstallmentWithInterest: "",
      });
    } else if (value === 2 || value === 3) {
      setCheckCar23(true)
      form.setFieldsValue({
        apProductType1: "",
        apProductType2: "",
        pgLoanTerm: "",
        apInterestRate: "",
        apLoanAmount: "",
        apInterest: "",
        apMonthlyPayment: "",
        apInstallmentWithInterest: "",
      });
    }
    // setShootdata(value)
    //  1 คือ รถเล็ก(รถยนต์)
    //  2 คือ รถบรรทุก(รถใหญ่)
  };
  const handleCancel = () => {
    close(false);
  };
  const getTable = () => {
    setOpenTable(true);
  };
  const getTableHP = () => {
    setOpenTableHP(true);
  };

  return (
    <>
      <Modal
        title={'ส่งข้อมูล'}
        open={open}
        onCancel={handleClose}
        width={850}
        footer={[null]}
      >
        <Card>
          <Form
            form={form}
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 24,
            }}
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            autoComplete="off"
          >
            <Spin spinning={loading} size="large" tip=" Loading... ">
              <div
                title="memo"
                name="memo"
                labelCol={{
                  span: 12,
                }}
                wrapperCol={{
                  span: "24",
                }}
              >
                <Row>
                  <Col span={22}>
                    {data?.approvalStatus === 3 ? (
                      <>
                        {data?.products === "car" ? (
                          <>
                            <Form.Item label="ประเภทรถ" name="ProductType">
                              <Select
                                placeholder={"เลือก"}
                                style={{ height: "40px" }}
                                onChange={(value) => {
                                  handleTypeCars(value);
                                }}
                                options={[
                                  {
                                    label: "รถยนต์",
                                    value: 1,
                                  },
                                  {
                                    label: "รถบรรทุก",
                                    value: 2,
                                  },
                                  {
                                    label: "รถเครื่องจักรการเกษตร",
                                    value: 3,
                                  },
                                ]}
                              />
                            </Form.Item>
                            {
                              data?.DATA_TYPE === "rpsl" ?
                                <>
                                  {
                                    typeCar === 1 ?
                                      <>
                                        <Form.Item
                                          label="ประเภทค่างวด"
                                          name="apProductType1"
                                        >
                                          <Select
                                            placeholder={"เลือก"}
                                            style={{ height: "40px" }}
                                            onChange={(value) => {
                                              handleChangePLorCH(value);
                                            }}
                                            options={[
                                              {
                                                label: 'P-LOAN',
                                                value: 'p-loan',
                                              },
                                              {
                                                label: "เช่าซื้อ",
                                                value: "เช่าซื้อ",
                                              },
                                              {
                                                label: "เช่าซื้อ (0.84-0.75)",
                                                value: "เช่าซื้อ1",
                                              },
                                            ]}
                                          />
                                        </Form.Item>
                                      </>
                                      : typeCar === 2 ?
                                        <>
                                          <Form.Item
                                            label="ประเภทค่างวด"
                                            name="apProductType2"
                                          >
                                            <Select
                                              placeholder={"เลือก"}
                                              style={{ height: "40px" }}
                                              onChange={(value) => {
                                                handleChangePLorCH(value);
                                              }}
                                              options={[
                                                {
                                                  label: 'P-LOAN',
                                                  value: 'p-loan',
                                                },
                                                // {
                                                //   label: 'เช่าซื้อ',
                                                //   value: 'เช่าซื้อ',
                                                // },
                                                {
                                                  label: "เช่าซื้อ",
                                                  value: "เช่าซื้อ",
                                                },
                                              ]}
                                            />
                                          </Form.Item>
                                        </>
                                        :
                                        <>
                                          <Form.Item
                                            label="ประเภทค่างวด"
                                            name="apProductType2"
                                          >
                                            <Select
                                              disabled={checkTypeCar}
                                              placeholder={"เลือก"}
                                              style={{ height: "40px" }}
                                              onChange={(value) => {
                                                handleChangePLorCH(value);
                                              }}
                                              options={[
                                                {
                                                  label: 'P-LOAN',
                                                  value: 'p-loan',
                                                },
                                                // {
                                                //   label: 'เช่าซื้อ',
                                                //   value: 'เช่าซื้อ',
                                                // },
                                                {
                                                  label: "เช่าซื้อ",
                                                  value: "เช่าซื้อ",
                                                },
                                              ]}
                                            />
                                          </Form.Item>
                                        </>
                                  }
                                </>
                                :
                                <>
                                  {
                                    typeCar === 1 ?
                                      <>
                                        <Form.Item
                                          label="ประเภทค่างวด"
                                          name="apProductType1"
                                        >
                                          <Select
                                            placeholder={"เลือก"}
                                            style={{ height: "40px" }}
                                            onChange={(value) => {
                                              handleChangePLorCH(value);
                                            }}
                                            options={[
                                              // {
                                              //   label: 'P-LOAN',
                                              //   value: 'p-loan',
                                              // },
                                              {
                                                label: "เช่าซื้อ",
                                                value: "เช่าซื้อ",
                                              },
                                              {
                                                label: "เช่าซื้อ (0.84-0.75)",
                                                value: "เช่าซื้อ1",
                                              },
                                            ]}
                                          />
                                        </Form.Item>
                                      </>
                                      : typeCar === 2 ?
                                        <>
                                          <Form.Item
                                            label="ประเภทค่างวด"
                                            name="apProductType2"
                                          >
                                            <Select
                                              placeholder={"เลือก"}
                                              style={{ height: "40px" }}
                                              onChange={(value) => {
                                                handleChangePLorCH(value);
                                              }}
                                              options={[
                                                // {
                                                //   label: 'P-LOAN',
                                                //   value: 'p-loan',
                                                // },
                                                // {
                                                //   label: 'เช่าซื้อ',
                                                //   value: 'เช่าซื้อ',
                                                // },
                                                {
                                                  label: "เช่าซื้อ",
                                                  value: "เช่าซื้อ",
                                                },
                                              ]}
                                            />
                                          </Form.Item>
                                        </>
                                        :
                                        <>
                                          <Form.Item
                                            label="ประเภทค่างวด"
                                            name="apProductType2"
                                          >
                                            <Select
                                              disabled={checkTypeCar}
                                              placeholder={"เลือก"}
                                              style={{ height: "40px" }}
                                              onChange={(value) => {
                                                handleChangePLorCH(value);
                                              }}
                                              options={[
                                                // {
                                                //   label: 'P-LOAN',
                                                //   value: 'p-loan',
                                                // },
                                                // {
                                                //   label: 'เช่าซื้อ',
                                                //   value: 'เช่าซื้อ',
                                                // },
                                                {
                                                  label: "เช่าซื้อ",
                                                  value: "เช่าซื้อ",
                                                },
                                              ]}
                                            />
                                          </Form.Item>
                                        </>
                                  }
                                </>
                            }
                            {checkType === "p-loan" ? (
                              <>
                                {/* ติดต่อไอที */}
                                <Form.Item label='ตารางค่างวด'>
                                  <Button type="primary" onClick={getTable}>
                                    ตารางค่างวด P-LOAN
                                  </Button>
                                </Form.Item>
                                {
                                  openTable ?
                                    <Installment open={openTable} close={setOpenTable} money={handleMoney}
                                      type={money}
                                      cp={
                                        ~~preData.approvedLoanAmount
                                          ? ~~preData?.approvedLoanAmount
                                          : ~~carsApproveve?.approvedLoanAmount
                                      }
                                      cp2={~~carsApproveve?.approvedLoanTerm}
                                      cp3={~~carsApproveve?.approvedLoanTermTo}
                                      checkGARFE={4}
                                      checkPG={1} />
                                    : null
                                }
                              </>
                            ) : checkType === "เช่าซื้อ" && typeCar === 1 ? (
                              <>
                                <Form.Item label="ตารางค่างวด">
                                  <Button type="primary" onClick={getTableHP}>
                                    ตารางค่างวด เช่าซื้อ
                                  </Button>
                                </Form.Item>
                                {openTableHP ? (
                                  <HirePurchase
                                    open={openTableHP}
                                    close={setOpenTableHP}
                                    money={handleMoneyHP}
                                    type={money}
                                    cp={
                                      ~~preData.approvedLoanAmount
                                        ? ~~preData?.approvedLoanAmount
                                        : ~~carsApproveve?.approvedLoanAmount
                                    }
                                    cp2={~~carsApproveve?.approvedLoanTerm}
                                    cp3={~~carsApproveve?.approvedLoanTermTo}
                                    checkGARFE={4}
                                    checkPG={1}
                                  />
                                ) : null}
                              </>
                            ) : checkType === "เช่าซื้อ" && typeCar !== 1 ? (
                              <>

                              </>
                            ) : null}
                            <Form.Item
                              label="ยอดที่อนุมัติ"
                              name="apLoanAmount"
                              style={{ lineHeight: "50px" }}
                              rules={[
                                {
                                  required: true,
                                  message: "กรุณากรอกข้อมูล !",
                                },
                              ]}
                            >
                              <Input
                                // disabled={checkDis}
                                disabled
                                style={{ color: "black" }}
                                suffix="บาท"
                                onChange={(e) =>
                                  setpreData({
                                    ...preData,
                                    apLoanAmount: parseInt(e.target.value),
                                  })
                                }
                              />
                            </Form.Item>
                            <Form.Item
                              label="จำนวนงวด"
                              name="pgLoanTerm"
                              rules={[
                                {
                                  required: true,
                                  message: "กรุณากรอกข้อมูล !",
                                },
                              ]}
                            >
                              <Select
                                disabled={checkDis}
                                style={{ height: "40px" }}
                                placeholder="เลือกจำนวนงวด"
                                onChange={changeLoanTerm}
                                options={filteredOptions}
                              />
                            </Form.Item>
                            {checkCar23 === true ? (
                              <Form.Item
                                label="อัตราดอกเบี้ย"
                                name="apInterestRate"
                                rules={[
                                  {
                                    required: true,
                                    message: "กรุณากรอกข้อมูล !",
                                  },
                                ]}
                              >
                                <Input
                                  disabled={checkDis}
                                  style={{ color: "black", height: "40px" }}
                                  suffix="% +VAT"
                                  onChange={(e) =>
                                    setpreData({
                                      ...preData,
                                      apInterestRate: parseFloat(
                                        e.target.value
                                      ),
                                    })
                                  }
                                />
                              </Form.Item>
                            ) : (
                              <Form.Item
                                label="อัตราดอกเบี้ย"
                                name="apInterestRate"
                                rules={[
                                  {
                                    required: true,
                                    message: "กรุณากรอกข้อมูล !",
                                  },
                                ]}
                              >
                                <Select
                                  disabled={checkDis}
                                  style={{ height: "40px" }}
                                  placeholder="เลือกอัตราดอกเบี้ย"
                                  onChange={changeInterestRate}
                                  options={[
                                    {
                                      label: 0.84,
                                      value: 0.84,
                                    },
                                    {
                                      label: 0.75,
                                      value: 0.75,
                                    },
                                  ]}
                                />
                              </Form.Item>
                            )}
                            <Form.Item label="งวดละ" name="apMonthlyPayment">
                              <Input
                                disabled
                                style={{ color: "black" }}
                                suffix="บาท"
                                onChange={(e) =>
                                  setstatusAccept({
                                    ...statusAccept,
                                    apMonthlyPayment: e.target.value,
                                  })
                                }
                              />
                            </Form.Item>
                            <Form.Item label="ดอกเบี้ย" name="apInterest">
                              <Input
                                disabled
                                style={{ color: "black" }}
                                suffix="บาท"
                                onChange={(e) =>
                                  setstatusAccept({
                                    ...statusAccept,
                                    apInterest: e.target.value,
                                  })
                                }
                              />
                            </Form.Item>
                            <Form.Item
                              label="รวมราคา"
                              name="apInstallmentWithInterest"
                            >
                              <Input
                                disabled
                                style={{ color: "black" }}
                                suffix="บาท"
                                onChange={(e) =>
                                  setstatusAccept({
                                    ...statusAccept,
                                    apInstallmentWithInterest: e.target.value,
                                  })
                                }
                              />
                            </Form.Item>
                          </>
                        ) : (
                          <>
                            {/* <p>ที่ดิน</p> */}
                            {/* // land_Calculate() */}
                            <Form.Item
                              label="ยอดที่อนุมัติ"

                              name="apLoanAmount"
                              rules={[
                                {
                                  required: true,
                                  message: "กรุณากรอกข้อมูล !",
                                },
                              ]}
                            >
                              <Input
                                type="number"
                                suffix="บาท"
                                disabled
                                onChange={(e) =>
                                  setpreData({
                                    ...preData,
                                    apLoanAmount: parseInt(e.target.value),
                                  })
                                }
                              />
                            </Form.Item>
                            <Form.Item
                              label="จำนวนงวด"
                              name="pgLoanTerm"
                              rules={[
                                {
                                  required: true,
                                  message: "กรุณากรอกข้อมูล !",
                                },
                              ]}
                            >
                              <Input
                                type="number"
                                suffix="งวด"
                                onChange={(e) =>
                                  setpreData({
                                    ...preData,
                                    pgLoanTerm: parseInt(e.target.value),
                                  })
                                }
                              />
                            </Form.Item>
                            <Form.Item label="งวดละ" name="apMonthlyPayment">
                              <Input
                                type="number"
                                suffix="บาท"
                                disabled
                                style={{ color: "black" }}
                                onChange={(e) =>
                                  setpreData({
                                    ...preData,
                                    apMonthlyPayment: parseInt(e.target.value),
                                  })
                                }
                              />
                            </Form.Item>
                            <Form.Item label="ดอกเบี้ย" name="apInterest">
                              <Input
                                type="number"
                                suffix="บาท"
                                disabled
                                style={{ color: "black" }}
                                onChange={(e) =>
                                  setpreData({
                                    ...preData,
                                    apInterest: parseInt(e.target.value),
                                  })
                                }
                              />
                            </Form.Item>
                            <Form.Item
                              label="รวมราคา"
                              name="apInstallmentWithInterest"
                            >
                              <Input
                                disabled
                                style={{ color: "black" }}
                                suffix="บาท"
                                onChange={(e) =>
                                  setpreData({
                                    ...preData,
                                    apInstallmentWithInterest: parseInt(
                                      e.target.value
                                    ),
                                  })
                                }
                              />
                            </Form.Item>
                          </>
                        )}
                      </>
                    ) : null}
                    <Form.Item
                      label="ช่องใส่ข้อมูลหมายเหตุ"
                      name="note"
                      rules={[
                        {
                          required: true,
                          message: "กรุณากรอกข้อมูล !",
                        },
                      ]}
                    >
                      <Input
                        style={{
                          width: "100%",
                          padding: "10px",
                        }}
                        placeholder="กรอกข้อมูลหมายเหตุ"
                        type="text"
                        name="Memo"

                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            Memo: e.target.value,
                          })
                        }
                      />
                    </Form.Item>
                    <Form.Item
                      label="หมายเหตุ"
                    >
                      <Card>
                        {formData.Memo ? formData.Memo : null}
                      </Card>
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <div style={{ textAlign: "center" }}>
                      <Space>
                        <Button
                          key="back"
                          type="primary"
                          danger
                          onClick={handleCancel}
                        >
                          ปิด
                        </Button>

                        <Button
                          type="primary"
                          htmlType='submit'
                          style={{ backgroundColor: "Green" }}
                        >
                          ต่อไป
                        </Button>


                      </Space>
                    </div>
                  </Col>
                </Row>
              </div>
            </Spin>
          </Form>
        </Card>
      </Modal>
      {isModalOpen ? (
        <YellowCardApproval
          open={isModalOpen}
          closeModal={closeModal}
          id={id}
          data={data}
          dataTonew={dataTonew}
          dataDB2={dataDB2}
          carsApproveve={carsApproveve}
          notesCarsApprove={notesCarsApprove}
          close={setIsModalOpen}
          onChangeStatus={onChangeStatus}
          status={status}
          notSave={"5"}
        />
      ) : null}
      {
        // YellowCardApproval
      }
    </>
  );
};

export default Memo;
