import React from "react";
import { Row, Col } from "antd";
import "./css/mainPage.css";
import P1 from './Pic/P1.jpg';
import P2 from './Pic/P2.png';
import P3 from './Pic/P3.jpg';
import P4 from './Pic/P4.png';
import P5 from './Pic/P5.png';
import moment from "moment";
import THBText from 'thai-baht-text';
import { QRCodeCanvas } from "qrcode.react";
function Payment({tableSearch,userPrint}) {
  console.log("tableSearch",tableSearch);
  //96 px
  const company = 'บริษัท วัน มันนี่ จำกัด (สำนักงานใหญ่)';
  const companyAddress = '1/20-24 ถ.มิตรภาพ ต.ในเมือง อ.เมือง จ.ขอนแก่น 40000';
  const companyTel = 'เบอร์โทร 043-239-888 และ 043-239-074';



  return (
    <div>
      {tableSearch && tableSearch.length > 0
        ? tableSearch.map((tableSearch, index) => (
    <div className="divA4" key={index}>
      <div className="divEdge96">
        <Row>
          <Col span={24} className="colLeft">ชื่อลูกค้า : {tableSearch?.SNAM}{tableSearch?.NAME1} {tableSearch?.NAME2}</Col>
          <Col span={24} className="colLeft">ที่อยู่ : {tableSearch?.ADDRES} {tableSearch?.SOI} {tableSearch?.TUMB}</Col>
          <Col span={24} className="colLeft">{tableSearch?.AUMP} {tableSearch?.PROVDES} {tableSearch?.ZIP}</Col>
          <Col span={24} className="colLeft">เบอร์โทร : {tableSearch?.TELP}</Col>
          <Col span={24} style={{marginBottom: '90px'}}></Col>
          <Col span={24} className="colUnderline"/>
          <Col span={24} style={{marginBottom: '30px'}}></Col>
          <Col span={24} className="colLeft" style={{ fontWeight: 'bold', fontSize: '20px', color: 'red' }}>{tableSearch?.FLAG === 'C' ? 'ยกเลิก' : ''}</Col>
          <Col span={24} className="colCenter">ใบรับเงิน</Col>

          <Col span={18} className="colLeft">เลขสัญญา : {tableSearch?.CONTNO}</Col>
          <Col span={6} className="colLeft">วันที่พิมพ์ : {moment(new Date()).format('DD/MM/YYYY')}</Col>

          <Col span={18} className="colLeft">ยี่ห้อ : {tableSearch?.TYPE}  รุ่น : {tableSearch?.MODELDES}  สี : {tableSearch?.COLOR}</Col>
          <Col span={6} className="colLeft">เลขที่ใบรับ : {tableSearch?.TMBILL}</Col>

          <Col span={18} className="colLeft">เลขตัวถัง : {tableSearch?.STRNO}  ทะเบียน : {tableSearch?.REGNO}</Col>
          <Col span={6} className="colLeft">วันที่รับ : {moment(tableSearch?.INPDATE || new Date()).format('DD/MM/YYYY')}</Col>

          <Col span={18} className="colLeft">ชำระโดย : {tableSearch?.PAYDESC}</Col>
          <Col span={6} className="colLeft">คงเหลือยกมา : {(tableSearch?.NCARCST ?? 0).toLocaleString('th-TH', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} บาท</Col>
          <Col span={24} className="colLeft" style={{  padding: '6px' }}></Col>
          <div className="table">
            <Row className="rowTable">
              <Col span={2} className="colTable">ลำดับ</Col>
              <Col span={4} className="colTable">ชำระค่า</Col>
              <Col span={3} className="colTable">หักเงินต้น</Col>
              <Col span={3} className="colTable">ดอกเบี้ย</Col>
              <Col span={3} className="colTable">ส่วนลด</Col>
              <Col span={3} className="colTable">ค่าทวงถาม</Col>
              <Col span={3} className="colTable">ลดค่าทวงถาม</Col>
              <Col span={3} className="colTable">รับสุทธิ</Col>
            </Row>
            <Row className="rowTable">
            {/* <Col span={2} className="colTable" style={{ height: '100px' }}>1<br />2</Col> */}
            <Col span={2} className="colTable" style={{ height: '100px' }}>1</Col>
              <Col span={4} className="colTable">{(tableSearch?.FORDESC ?? 0).toLocaleString('th-TH', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
              <Col span={3} className="colTable">{(tableSearch?.PAYAMT_TON ?? 0).toLocaleString('th-TH', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
              <Col span={3} className="colTable">{(tableSearch?.PAYAMT_DOK ?? 0).toLocaleString('th-TH', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
              <Col span={3} className="colTable">{(tableSearch?.DISCT ?? 0).toLocaleString('th-TH', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
              <Col span={3} className="colTable">{(tableSearch?.INTAMT ?? 0).toLocaleString('th-TH', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
              <Col span={3} className="colTable">{(tableSearch?.DIFINTAMT ?? 0).toLocaleString('th-TH', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
              <Col span={3} className="colTable">{(tableSearch?.NETPAY ?? 0).toLocaleString('th-TH', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
            </Row>
            <Row className="rowTable">
              <Col span={6} className="colTable" style={{ flex: 2 }}>จำนวนเงินเป็นตัวอักษร</Col>
              <Col span={9} className="colTable" style={{ flex: 3 }}>{THBText(tableSearch?.NETPAY)}</Col>
              <Col span={6} className="colTable">จำนวนเงิน</Col>
              <Col span={3} className="colTable">{(tableSearch?.NETPAY ?? 0).toLocaleString('th-TH', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
            </Row>
          </div>
          <Col span={24} className="colLeft">ยอดเงินคงเหลือ : {((tableSearch?.TCARCST ?? 0)-(tableSearch?.PAYAMT_N ?? 0)).toLocaleString('th-TH', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} บาท </Col>

          {/* <Col span={17} className="colLeft14">ค้างชำระ 1.00 งวด เป็นเงิน 445.00 บาท จากงวด 46.00 ถึงงวด 46.00 ค้างดอกเบี้ยล่าช้า 0.00 บาท</Col> */}
          <Col span={17} className="colCenter"></Col>
          <Col span={7} className="colCenter">ผู้รับเงิน {userPrint}</Col>

          <Col span={17} className="colLeft12">ใบเสร็จรับเงินฉบับนี้จะสมบูรณ์เมื่อปรากฎลายเซ็นของผู้รับเงินครบถ้วนและเช็คของท่านผ่านบัญชีบริษัทฯ เรียบร้อยแล้ว</Col>
          <Col span={7} className="colCenter">( ออกโดยระบบอัตโนมัติ )</Col>
          
          <Col span={24} className="colUnderline"/>
          {/* ส่วนล่าง */}
          <Col span={13} className="colTableB">ใบนำฝากชำระเงินค่าสินค้าหรือบริการ (Bill Payment Pay-in-silp)</Col>
          <Col span={11} className="colRight">ส่วนของธนาคาร</Col>

          <Col span={13} className="colLeft">{company}</Col>
          <Col span={11} className="colRight">โปรดเรียกเก็บค่าธรรมเนียมจากผู้ชำระเงิน</Col>

          <Col span={12} className="colLeft">{companyAddress}</Col>
          <Col span={6} className="colTable">สาขา........................................</Col>
          <Col span={6} className="colTable">วันที่.........................................</Col>
          
          <Col span={12} className="colLeft" style={{ lineHeight: '16px'}}>{companyTel}<br/>
          <div className="box"></div><img src={P1} alt="" className="picA"/>ธ.กสิกรไทย A/C No. (15/20)
          <br/><div className="box"></div><img src={P2} alt="" className="picA"/>ธ.กรุงไทย A/C No. CODE : 6624 (15)
          </Col>

          <Col span={12} className="colTable" style={{ lineHeight: '16px',textAlign: 'Left'}}>&nbsp;ชื่อลูกค้า &nbsp;&nbsp;&nbsp; {tableSearch?.NAME1} {tableSearch?.NAME2}
          <br/>&nbsp;Customer No (Ref. No.1) &nbsp;&nbsp;&nbsp; 006 
          <br/>&nbsp;Customer No (Ref. No.2) &nbsp;&nbsp;&nbsp; {tableSearch?.CONTNOCUT}</Col>
          <Col span={24} className="colLeft" style={{lineHeight: '16px',marginBottom:'40px'}}><div className="box"></div><img src={P3} alt="" className="picA"/> ธ. ไทยพานิชย์ A/C No. (Bill Payment) (15/20)
          <br/> <div className="box"></div><img src={P4} alt="" className="picA"/>เคาน์เตอร์เซอร์วิส
          <br/> <div className="box"></div><img src={P5} alt="" className="picA" />เทสโก้โลตัส และ เทสโก้โลตัส เอ็กซ์เพรส</Col>

          <Col span={9} className="colCenter">รับชำระเป็นเงินสดเท่านั้น</Col>
          <Col span={5} className="colTableB">จำนวนเงิน/Amount</Col>
          <Col span={5} className="colTableB"></Col>
          <Col span={5} className="colTableB">บาท/Baht</Col>

          <Col span={9} className="colTableB">จำนวนเงินเป็นตัวอักษร/Amount in Words</Col>
          <Col span={15} className="colTableB"></Col>

          <Col span={9} className="colLeft">ผู้นำฝาก / Deposit By ........................................</Col>
          <Col span={6} className="colLeft"><div className="divQr"><QRCodeCanvas value={`|040555200043500 006 ${tableSearch?.CONTNOCUT} 0`} size={90} /></div></Col>
          <Col span={9} className="colTableB">สำหรับเจ้าหน้าที่ธนาคาร</Col>

          <Col span={9} className="colLeft">โทรศัพท์ / Telephone ........................................</Col>
          <Col span={6} className="colLeft"></Col>
          <Col span={9} className="colTableB">ผู้รับเงิน .............................................................</Col>
        </Row>
      </div>
    </div>
    ))
    : []}
  </div>
  );
}
export default Payment;
