import React from "react";
import { Row, Col } from "antd";
//import moment from "moment";
import "./css/mainPage.css";
import moment from "moment";
import THBText from "thai-baht-text";
function Payment({ tableSearch, userPrint }) {
  console.log("tableSearch2", tableSearch);
  //96 px
  const company = "บริษัท วัน มันนี่ จำกัด (CAL)";
  return (
    <div>
      {tableSearch && tableSearch.length > 0
        ? tableSearch.map((tableSearch, index) => (
            <div className="divA4Cut" key={index}>
              <div className="divEdge80">
              <Row>
            <Col span={24} className="colCenter">ใบสำคัญรับเงิน</Col>
            <Col span={24} className="colCenter">RECIVE VOUCHER</Col>
            <Col span={24} className="colLeft" style={{ fontWeight: 'bold', fontSize: '20px', color: 'red' }}>{tableSearch?.FLAG === 'C' ? 'ยกเลิก' : ''}</Col>

            <Col span={18} className="colLeft">บริษัท : {company}</Col>
            <Col span={6} className="colLeft">เลขที่ใบรับเงิน : {tableSearch?.TMBILL}</Col>
  
            <Col span={10} className="colLeft">ชื่อลูกค้า : {tableSearch?.NAME1} {tableSearch?.NAME2}</Col>
            <Col span={8} className="colLeft">เลขสัญญา : {tableSearch?.CONTNO}</Col>
            <Col span={6} className="colLeft">วันที่รับเงิน : {moment(tableSearch?.INPDATE).format('DD/MM/YYYY')}</Col>

            <Col span={24} className="colLeft" style={{  padding: '10px' }}></Col>
            <div className="table">
              <Row className="rowTable">
                <Col span={3} className="colTable">ลำดับ</Col>
                <Col span={9} className="colTable">รายการ</Col>
                <Col span={4} className="colTable">จำนวนเงิน</Col>
                <Col span={4} className="colTable">ส่วนลด</Col>
                <Col span={4} className="colTable">รับสุทธิ</Col>
              </Row>
              <Row className="rowTable">
              {/* <Col span={2} className="colTable" style={{ height: '100px' }}>1<br />2</Col> */}
              <Col span={3} className="colTable" style={{ height: '100px' }}>1</Col>
                <Col span={9} className="colTable">{tableSearch?.FORDESC} ({tableSearch?.PAYFOR}) (ไม่ตัดค่างวด)</Col>
                <Col span={4} className="colTable">{(tableSearch?.NETPAY ?? 0).toLocaleString('th-TH', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                <Col span={4} className="colTable">{(tableSearch?.DIFINTAMT ?? 0).toLocaleString('th-TH', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
                <Col span={4} className="colTable">{(tableSearch?.NETPAY ?? 0).toLocaleString('th-TH', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
            </Row>
            <Row className="rowTable">
              <Col span={16} className="colTable" style={{  border: 'none' }}><div className="box"></div>&nbsp;เงินสด <div className="box"></div>&nbsp;เช็ค <div className="box">/</div>&nbsp;อื่นๆ ({tableSearch?.PAYTYP})</Col>
              <Col span={4} className="colTable">จำนวนเงิน</Col>
              <Col span={4} className="colTable">{(tableSearch?.NETPAY ?? 0).toLocaleString('th-TH', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
            </Row>
            <Row className="rowTable">
              <Col span={16} className="colTable" style={{  border: 'none',textAlign: 'Left' }}>จำนวนเงินเป็นหนังสือ</Col>
              <Col span={4} className="colTable">ส่วนลด</Col>
              <Col span={4} className="colTable" >{(tableSearch?.DIFINTAMT ?? 0).toLocaleString('th-TH', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
            </Row>
            <Row className="rowTable">
              <Col span={16} className="colTable" style={{  border: 'none' }}><span  style={{  border: '1px solid black', fontSize: '14px', paddingLeft: '60px', paddingRight: '60px' }}>{THBText(tableSearch?.NETPAY)}</span></Col>
              <Col span={4} className="colTable">รวมรับสุทธิ</Col>
              <Col span={4} className="colTable">{(tableSearch?.NETPAY ?? 0).toLocaleString('th-TH', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</Col>
            </Row>
            </div>
            
            <Col span={24} className="colLeft" style={{  padding: '20px' }}></Col>

            <Col span={3} className="colLeft"></Col>
            <Col span={5} className="colLeft" style={{  borderBottom: '1px solid black' }}></Col>
            <Col span={6} className="colLeft"></Col>
            <Col span={7} className="colLeft" style={{  borderBottom: '1px solid black' }}></Col>
            <Col span={3} className="colLeft"></Col>
            
            <Col span={3} className="colLeft"></Col>
            <Col span={5} className="colCenter">ผู้รับเงิน</Col>
            <Col span={6} className="colLeft"></Col>
            <Col span={7} className="colCenter">ผู้มีอำนาจลงนาม</Col>
            <Col span={3} className="colLeft"></Col>
          </Row>
              </div>
            </div>
          ))
        : []}
    </div>
  );
}
export default Payment;
