import React, { useState, useEffect } from 'react'
import { Form, Modal, Input, Row, Col, Button, Spin, Card, Divider, Space, Image, DatePicker, Select, notification, InputNumber, message, Tag } from 'antd'
import TextArea from 'antd/es/input/TextArea';
import axios from 'axios';
import dayjs from 'dayjs';
import { getImagess, infoRateZeroBoss, confirmRateBoss, editRateBoss } from '../../file_mid/all_api';

export default function Rate({ open, close, dataMain, des }) {
    const token = localStorage.getItem('token');
    const user = localStorage.getItem('username');
    const { confirm } = Modal
    const [api, contextHolder] = notification.useNotification();
    const { Option } = Select;
    const [loading, setLoading] = useState(false);
    const [dataCar, setDataCar] = useState({
        user: "",
        typeConfirm: 1,
        loanId: 0,
        reLoanId: 0,
        carId: 0,
        customerId: 0,
        productTypeCar: 1,
        carModel: "",
        carBrand: "",
        carBaab: "",
        carYear: "",
        carPrice: 0,
        noteBoss: "",
        reviewedBy: "",
        approvalStatusName: "",
        ApprovalStatusReName: "",
    });
    const [imageBlobzz, setImageBlobzz] = useState([]);
    const [form] = Form.useForm()
    useEffect(() => {
        console.log("dataMain", dataMain)
        if (dataMain?.carId && dataMain?.carId > 0) {
            loadData()
        }
    }, [dataMain])


    const loadData = async (value) => {
        setLoading(true)
        await axios.post(infoRateZeroBoss, { carId: dataMain?.carId })
            .then(res => {
                if (res.data) {
                    console.log("res.data loadData", res.data)
                    setDataCar({
                        user: user,
                        typeConfirm: 1,
                        loanId: res.data?.loanId,
                        reLoanId: res.data?.carLoanDetailsRe?.reLoanId,
                        carId: res.data?.carId,
                        customerId: res.data?.customerId,
                        productTypeCar: res.data?.productTypeCar,
                        carModel: res.data?.carModel,
                        carBrand: res.data?.carBrand,
                        carBaab: res.data?.carBaab,
                        carYear: res.data?.carYear,
                        carPrice: res.data?.carPrice,
                        noteBoss: "",
                        reviewedBy: res.data?.carLoanDetails?.reviewedBy,
                        approvalStatusName: dataMain.approvalStatusName,
                        ApprovalStatusReName: dataMain.ApprovalStatusReName,
                        ISSUNO: res.data?.ISSUNO,
                        productLoanType: res.data?.carLoanDetailsRe?.productLoanType,
                        proposalBy: res.data?.carLoanDetailsRe?.proposalBy,
                    })
                    form.setFieldsValue(
                        {
                            loanId: res.data?.loanId,
                            reLoanId: res.data?.carLoanDetailsRe?.reLoanId,
                            carId: res.data?.carId,
                            customerId: res.data?.customerId,
                            productTypeCar: res.data?.productTypeCar,
                            carModel: res.data?.carModel,
                            carBrand: res.data?.carBrand,
                            carBaab: res.data?.carBaab,
                            carYear: dayjs().year(res.data?.carYear),
                            carPrice: res.data?.carPrice,
                            noteBoss: "",
                        }
                    )
                    setImageBlobzz(res.data?.img)

                }
                setLoading(false)
            }).catch((err) => {
                console.log("err", err)
                setLoading(false)
            })
    }
    const ErrorRate = (placement) => {
        api.error({
            message: `บันทึกไม่สำเร็จ`,
            description: 'กรุณาติดต่อไอที !',
            placement,
        });
    };
    const ErrorRate2 = (placement) => {
        api.error({
            message: `บันทึกไม่สำเร็จ`,
            description: 'กรุณาตรวจสอบข้อมูล และกรอกข้อมูลให้ถูกต้อง !',
            placement,
        });
    };
    const SuccessRate = (placement) => {
        api.success({
            message: `บันทึกสำเร็จ`,
            description: 'บันทึกสำเร็จ',
            placement,
        });
    };

    const onFinish2 = (value, index) => {
        confirm({
            title: 'คุณต้องการที่จะไม่อนุมัติใช่หรือไม่...?',
            content: 'กด OK เคสจะถูกปรับเป็น Reject ทันที',
            onOk() {
                onFinish(2)
            },
            onCancel() {
                message.error('ยกเลิก')
            },
        });
    };

    const onFinish = async (value) => {
        setLoading(true)
        console.log("dataCar", dataCar, value)
        if (dataCar.carPrice === null || dataCar.carPrice === undefined || dataCar.carPrice <= 0 || isNaN(dataCar.carPrice)) {
            ErrorRate2('top')
            setLoading(false)
        } else {
            if (value === 2) {
                console.log("ไม่อนุมัติ")
                await axios.post(confirmRateBoss, {
                    user: user,
                    typeConfirm: 2,
                    loanId: dataCar?.loanId,
                    reLoanId: dataCar?.reLoanId,
                    carId: dataCar?.carId,
                    customerId: dataCar?.customerId,
                    productTypeCar: dataCar?.productTypeCar,
                    carModel: dataCar?.carModel,
                    carBrand: dataCar?.carBrand,
                    carBaab: dataCar?.carBaab,
                    carYear: dataCar?.carYear,
                    carPrice: dataCar?.carPrice,
                    noteBoss: dataCar?.noteBoss,
                    reviewedBy: dataCar?.reviewedBy,
                    approvalStatusName: dataMain.approvalStatusName,
                    ApprovalStatusReName: dataMain.ApprovalStatusReName,
                    ISSUNO: dataCar?.ISSUNO,
                    productLoanType: dataCar?.productLoanType,
                    proposalBy: dataCar?.proposalBy,
                })
                    .then(res => {
                        if (res.data) {
                            SuccessRate('top')
                            setTimeout(() => {
                                close(false);
                                des("load");
                                setLoading(false);
                            }, 1500);
                        } else {
                            ErrorRate('top')
                            setLoading(false);
                        }
                    }).catch((err) => {
                        console.log("err", err)
                        setLoading(false);
                        ErrorRate('top')
                    })
            } else {
                if (!dataMain.flagApBoss) {
                    console.log("อนุมัติ")
                    await axios.post(confirmRateBoss, dataCar)
                        .then(res => {
                            if (res.data) {
                                SuccessRate('top')
                                setTimeout(() => {
                                    close(false);
                                    des("load");
                                    setLoading(false);
                                }, 1500);
                            } else {
                                ErrorRate('top')
                                setLoading(false);
                            }
                        }).catch((err) => {
                            console.log("err", err)
                            setLoading(false);
                            ErrorRate('top')
                        })
                } else {
                    console.log("อนุมัติ แก้ไข")
                    await axios.post(editRateBoss, dataCar)
                        .then(res => {
                            if (res.data) {
                                SuccessRate('top')
                                setTimeout(() => {
                                    close(false);
                                    des("load");
                                    setLoading(false);
                                }, 1500);
                            } else {
                                ErrorRate('top')
                                setLoading(false);
                            }
                        }).catch((err) => {
                            console.log("err", err)
                            setLoading(false);
                            ErrorRate('top')
                        })
                }
            }
        }
    };

    const handleCancel = () => {
        close(false);
        des("close");
    };

    // const handleCancelCase = () => {
    //     setIsModalCancelCaseRe(true)
    // }
    let color
    if (dataMain?.approvalStatus === 23 || dataMain?.approvalStatusRe === 23) {
        color = "red"
    } else {
        color = "geekblue"
    }
    return (
        <div>
            <Modal title="ข้อมูลเรทรถ" open={open} onCancel={handleCancel} footer={[null]} width={1000}>
                <Spin spinning={loading} size='large' tip=" Loading... ">
                    <Form
                        name="basic"
                        labelCol={{
                            span: 5,
                        }}
                        wrapperCol={{
                            span: 16,
                        }}
                        onFinish={onFinish}
                        form={form}
                    >
                        <Card>
                            <Row justify={'center'}>
                                <aside style={{ width: '85%', textAlign: 'center' }}>
                                    <div>

                                        {
                                            dataCar?.reLoanId > 0 ?
                                                <>
                                                    <Col span={24} style={{ textAlign: 'right' }}>
                                                        <div style={{ margin: 0 }}>
                                                            สถานะ : <Tag color={color}>{dataMain?.approvalStatusReName}</Tag>
                                                        </div>
                                                    </Col>
                                                    <Divider orientation="left"><b>รายละเอียดรถ มาจากสัญญา {dataCar?.ISSUNO}</b></Divider>
                                                </>
                                                :
                                                <>
                                                    <Col span={24} style={{ textAlign: 'right' }}>
                                                        <div style={{ margin: 0 }}>
                                                            สถานะ : <Tag color={color}>{dataMain?.approvalStatusName}</Tag>
                                                        </div>
                                                    </Col>
                                                    <Divider orientation="left"><b>รายละเอียดรถ</b></Divider>
                                                </>
                                        }
                                        <Form.Item label="ชนิดรถ" name="productTypeCar">
                                            <Select
                                                style={{ color: 'black', height: '40px' }}
                                                onChange={(e) => setDataCar({ ...dataCar, productTypeCar: e })}>
                                                <Option value={1}>รถยนต์</Option>
                                                <Option value={2}>รถเครื่องจักการเกษตร</Option>
                                                <Option value={3}>รถบรรทุก</Option>
                                            </Select>
                                        </Form.Item>
                                        <Form.Item label="ยี่ห้อ" name="carBrand"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'กรุณากรอกข้อมูล !'
                                                },
                                            ]}>
                                            <Input onChange={(e) => setDataCar({ ...dataCar, carBrand: e.target.value })} />
                                        </Form.Item>
                                        <Form.Item label="รุ่นรถ" name="carModel"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'กรุณากรอกข้อมูล !'
                                                },
                                            ]}>
                                            <Input onChange={(e) => setDataCar({ ...dataCar, carModel: e.target.value })} />
                                        </Form.Item>
                                        <Form.Item label="แบบรถ" name="carBaab"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'กรุณากรอกข้อมูล !'
                                                },
                                            ]}>
                                            <Input onChange={(e) => setDataCar({ ...dataCar, carBaab: e.target.value })} />
                                        </Form.Item>
                                        <Form.Item label="ปีรถ" name="carYear"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'กรุณากรอกข้อมูล !'
                                                },
                                            ]}>
                                            <DatePicker style={{ width: "100%", height: '40px' }} onChange={(date, dateString) => setDataCar({ ...dataCar, carYear: dateString })} picker="year" />
                                        </Form.Item>
                                        <Form.Item label="เรทรถ" name="carPrice"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: 'กรุณากรอกข้อมูล !'
                                                },
                                            ]}>
                                            <InputNumber
                                                style={{ width: '100%', height: '40px', color: 'black' }}
                                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                                                onChange={(value) => setDataCar({ ...dataCar, carPrice: parseFloat(value) })} />
                                        </Form.Item>
                                        <Form.Item name="noteBoss" label="หมายเหตุ">
                                            <TextArea
                                                name='noteBoss'
                                                style={{ color: 'blue' }}
                                                rows={8}
                                                onChange={(e) => setDataCar({ ...dataCar, noteBoss: e.target.value })}
                                            />
                                            <div style={{ marginTop: '10px' }}><span><u style={{ fontSize: '20', color: 'red' }}>*** ถ้าไม่มี ไม่จำเป็นต้องกรอก ***</u></span></div>
                                        </Form.Item>
                                        <Divider orientation="left"><b>รูปภาพ</b></Divider>
                                        <Form.Item label="หน้าคู่มือ">
                                            <Space size={[10, 10]} wrap>
                                                {imageBlobzz?.map((e, index) => {
                                                    if (e.type === 2) {
                                                        return (
                                                            <>
                                                                <Image width={200} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                            </>
                                                        );
                                                    }
                                                    return null;
                                                })}
                                            </Space>
                                        </Form.Item>
                                        <Divider />
                                        <Form.Item label="ใบประเมิน / รูปรถ">
                                            <Space size={[10, 10]} wrap>
                                                {imageBlobzz?.map((e, index) => {
                                                    if (e.type === 1) {
                                                        return (
                                                            <>
                                                                <Image width={200} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                            </>
                                                        );
                                                    }
                                                    return null;
                                                })}
                                            </Space>
                                        </Form.Item>
                                        <Divider />
                                        {
                                            dataCar?.reLoanId > 0 ?
                                                <Form.Item label="เอกสารขอรี/ปรับ/เปลี่ยน">
                                                    <Space size={[10, 10]} wrap>
                                                        {imageBlobzz?.map((e, index) => {
                                                            if (e.type === 14) {
                                                                return (
                                                                    <>
                                                                        <Image width={200} key={index} src={`${getImagess}/${e.pathImage}?token=${token}`} alt={`รูปภาพ ${index}`} />
                                                                    </>
                                                                );
                                                            }
                                                            return null;
                                                        })}
                                                    </Space>
                                                </Form.Item>
                                                : null
                                        }
                                    </div>
                                </aside>
                            </Row>
                        </Card>
                        <div style={{ textAlign: 'center', marginTop: 10 }}>
                            <Space >
                                <Button type='primary' onClick={handleCancel} danger>ปิด</Button>
                                {
                                    !dataMain.flagApBoss ?
                                        <>
                                            {
                                                dataMain?.approvalStatus !== 23 && dataMain?.approvalStatusRe !== 23 ?
                                                    <>
                                                        <Button type='primary' onClick={onFinish2} style={{ backgroundColor: "red" }}>ไม่อนุมัติ</Button>
                                                        <Button type='primary' htmlType='submit' style={{ backgroundColor: "green" }}>อนุมัติ</Button>
                                                    </>
                                                    : null
                                            }
                                        </>
                                        :
                                        <>
                                            {
                                                dataMain?.approvalStatus !== 23 && dataMain?.approvalStatusRe !== 23 ?
                                                    <>
                                                        <Button type='primary' htmlType='submit' style={{ backgroundColor: "green" }}>อนุมัติ</Button>
                                                    </>
                                                    : null
                                            }
                                        </>
                                }
                            </Space>
                        </div>
                    </Form>
                </Spin>
                {contextHolder}
            </Modal >
        </div >
    )
}
