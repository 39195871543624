"https://offercase-car-i67f6gaaqa-as.a.run.app"
"https://api-marketing-i67f6gaaqa-as.a.run.app" // การตลาด
"https://api-upload-image-i67f6gaaqa-as.a.run.app"
"https://garfe-i67f6gaaqa-as.a.run.app" // ธุรการ
"https://shark-app-j9jc9.ondigitalocean.app"
"https://api-occupations-i67f6gaaqa-as.a.run.app"
"https://onemoney.ngrok.app"
"https://api-check-validate-i67f6gaaqa-as.a.run.app" // แม่น้ำ
"https://eua-i67f6gaaqa-as.a.run.app"
"https://pg-api-i67f6gaaqa-as.a.run.app" // PG
"https://case-i67f6gaaqa-as.a.run.app"
"https://api-checkinfoland-i67f6gaaqa-as.a.run.app"
"https://api-delete-image-i67f6gaaqa-as.a.run.app"
"https://login-api-i67f6gaaqa-as.a.run.app"
"https://api-resend-i67f6gaaqa-as.a.run.app"
"https://api-rate0-i67f6gaaqa-as.a.run.app"
"https://test-i67f6gaaqa-as.a.run.app"
"https://api-land-i67f6gaaqa-as.a.run.app"
"https://api-manager-i67f6gaaqa-as.a.run.app"
"https://api-call-sync-i67f6gaaqa-as.a.run.app"
"https://checkinfo-land-nrbozk5hnq-as.a.run.app"

const CALLSYNC = process.env.REACT_APP_CALLSYNC;
const CHECKVALIDATE = process.env.REACT_APP_CHECKVALIDATE;
const DELETEIMG = process.env.REACT_APP_DELETEIMG;
const LAND = process.env.REACT_APP_LAND;
const MANAGER = process.env.REACT_APP_MANAGER;
const MARKETING = process.env.REACT_APP_MARKETING;
const OCCUPATIONS = process.env.REACT_APP_OCCUPATIONS;
const RATE0 = process.env.REACT_APP_RATE0;
const RESEND = process.env.REACT_APP_RESEND;
const SEARCHCONTNO = process.env.REACT_APP_SEARCHCONTNO;
const UPLOADIMG = process.env.REACT_APP_UPLOADIMG;
const CASE = process.env.REACT_APP_CASE;
const CHECKINFOLAND = process.env.REACT_APP_CHECKINFOLAND;
const DOWNLOADIMG = process.env.REACT_APP_DOWNLOADIMG;
const EUA = process.env.REACT_APP_EUA;
const GARFE = process.env.REACT_APP_GARFE;
const GETIMG = process.env.REACT_APP_GETIMG;
const LOGIN = process.env.REACT_APP_LOGIN;
const OFFERCASECAR = process.env.REACT_APP_OFFERCASECAR;
const PG = process.env.REACT_APP_PG;

export const uploadJojonoi = `https://test-i67f6gaaqa-as.a.run.app/upload`; //ซ้ำ
export const getcontnoJojonoi = `https://apimobile-i67f6gaaqa-as.a.run.app/recontno/getcontno`;
export const testupload = `https://test-i67f6gaaqa-as.a.run.app/upload`; // upรูปบัตรประชาชนมั้ง yes

export const checkcountmkcar = `${MARKETING}/auth-mk/checkcountmkcar`; // แสดงแถบด้านบนของการตลาดรถหน้า main YES             //success api gateway
export const checkinfomkcar = `${MARKETING}/mid/checkinfomkcar`; // โหลดข้อมูลลงตารางของการตลาดรถหน้า main YES            //success api gateway
export const findcarmk = `${MARKETING}/api/findcarmk`; // โหลดข้อมูลรถแว่นขยาย YES                                        //success api gateway
export const customerconfirmcar = `${MARKETING}/api/customerconfirmcar`; // ลูกค้า ยอมรับ/ไม่ยอมรับ YES                         //success api gateway
export const checkguarantors = `${MARKETING}/api-marketing/checkguarantors`; // โหลดคนค้ำ yes                                      //success api gateway
export const deletegarno = `${MARKETING}/api/deletegarno`; // ลบคนค้ำ yes                                                //success api gateway
export const editcustomer = `${MARKETING}/api/editcustomer`; // yes                                                    //success api gateway
export const addcusforgua = `${MARKETING}/api/addcusforgua`; // สร้างคนค้ำ yes                                            //success api gateway
export const editcarloan = `${MARKETING}/api/editcarloan`; // แก้ไข car loan yes                                        //success api gateway 
export const makenoteresend = `${MARKETING}/api/makenoteresend`; // สร้าง yes                                            //success api gateway
export const findlandmk = `${MARKETING}/api/findlandmk`; // yes                                                        //success api gateway
export const editlandloan = `${MARKETING}/api/editlandloan`; // yes                                                    //success api gateway
export const customerconfirmland = `${MARKETING}/api/customerconfirmland`; // yes                                      //success api gateway


export const mutips2pre = `${UPLOADIMG}/Apimg/mutips-2-pre`; // อัพรูปใหม่(pre) การตลาดรถ YES                  //success api gateway
export const mutips2 = `${UPLOADIMG}/Apimg/mutips-2`; // อัพรูป การตลาดรถ (เก่า) YES                           //success api gateway
export const uploadImgGuaTable = `${UPLOADIMG}/Apimg/uploadImgGuaTable`; // อัพรูปคนค้ำ yes                 //success api gateway
export const upimg = `${UPLOADIMG}/Apimg/upimg`; // upimg yes                               //success api gateway
export const upimgland = `${UPLOADIMG}/Apimg/upimgland`; // upimgland yes                   //success api gateway
export const newUpimgland = `${UPLOADIMG}/Apimg/newUpimgland`; // yes                       //success api gateway
export const mutips4 = `${UPLOADIMG}/Apimg/mutips-4`; // yes                                //success api gateway
export const mutips4pre = `${UPLOADIMG}/Apimg/mutips-4-pre`; // yes                         //success api gateway
export const uploadImgGua = `${UPLOADIMG}/Apimg/uploadImgGua`; // yes                       //success api gateway
export const mutips3 = `${UPLOADIMG}/Apimg/mutips-3`; // yes                                //success api gateway
export const newUpimg = `${UPLOADIMG}/Apimg/newUpimg`; // yes                               //success api gateway
export const mutips1 = `${UPLOADIMG}/Apimg/mutips-1`; // yes                                //success api gateway



export const checkcarcus = `${GARFE}/api/checkcarcus`; // โหลดข้อมูลรถที่เคยเสนอมา YES                 
// export const edit = `http://localhost:8080/api/edit`; // แก้ไขข้อมูล customer product การตลาดรถ YES                             //success api gateway
export const edit = `${GARFE}/api/edit`; // แก้ไขข้อมูล customer product การตลาดรถ YES                //success api gateway
export const findcarall = `${GARFE}/api/findcarall`; // โหลดข้อมูลรถ การตลาด YES                     //success api gateway
export const makegarno = `${GARFE}/api/makegarno`; // makegarno yes                                //success api gateway
export const deleteguarantors = `${GARFE}/api/deleteguarantors`; // makegarno yes                  //success api gateway
export const addguarantors = `${GARFE}/api/addguarantors`; // addguarantors yes                    //success api gateway
export const accept = `${GARFE}/api/accept`; // yes                                                //success api gateway
export const findlandpn = `${GARFE}/pn/findlandpn`; // yes                                         //success api gateway
export const checkcountadmain = `${GARFE}/mid/checkcountadmain`; // yes                            //success api gateway
export const checkinfoadmain = `${GARFE}/mid/checkinfoadmain`; // yes                              //success api gateway
// export const checkinfoadmain = `http://localhost:8080/mid/checkinfoadmain`; // yes                               
export const editguarantors = `${GARFE}/api/editguarantors`; // yes                                //success api gateway
export const findlandall = `${GARFE}/api/findlandall`; // yes                                      //success api gateway
export const resendlandmk = `${GARFE}/api/resendlandmk`; // yes                                    //success api gateway
export const checkcarad = `${GARFE}/api/checkcarad`; // yes                                        //success api gateway
export const confirmcarcontno = `${GARFE}/api/confirmcarcontno`; // yes                            //success api gateway
export const confirmlandcontno = `${GARFE}/api/confirmlandcontno`; // yes                          //success api gateway
export const checksynccar = `${GARFE}/api/checksynccar`; // yes                                    //success api gateway
export const updatgarno1 = `${GARFE}/api/updatgarno`; // yes                                       //success api gateway
export const resendcarpg = `${GARFE}/api/resendcarpg`; // yes                                      //success api gateway
export const resendlandpg = `${GARFE}/api/resendlandpg`; // yes                                    //success api gateway
export const reject = `${GARFE}/api/reject`; // yes                                                //success api gateway
export const approve = `${GARFE}/api/approve`; // yes                                              //success api gateway
export const cancelnote = `${GARFE}/api/cancelnote`; // yes                                        //success api gateway
export const approvepre = `${GARFE}/api/approve-pre`; // yes                                       //success api gateway
export const showcausename = `${GARFE}/api/showcausename`; // yes                                  //success api gateway

export const addcausename = `${GARFE}/api/addcausename`; // yes                                    //success api gateway
export const notpass = `${GARFE}/api/notpass`; // yes                                              //success api gateway
export const checkduplicate = `${GARFE}/api/checkduplicate`; // yes                                //success api gateway
export const deleteoccgua = `${GARFE}/api/delete-occ-gua`; // yes                                  //success api gateway
export const getguarantorsbyiden = `${GARFE}/api/getguarantorsbyiden`; // yes                      //success api gateway

export const checkgu = `${GARFE}/api-garfe/checkgu`; // โหลดคนค้ำ yes                                      //success api gateway
export const deletephone = `${GARFE}/api-garfe/deletephone`; // ลบเบอร์ yes                               //success api gateway
export const deletesocial = `${GARFE}/api-garfe/deletesocial`; // deletesocial yes                       //success api gateway
export const deleteemail = `${GARFE}/api-garfe/deleteemail`; // deleteemail yes                          //success api gateway
export const customerconfirmcar2 = `${GARFE}/api-garfe/customerconfirmcar`; // ลูกค้าคอมเฟิร์ม yes           //success api gateway
export const deleteaddress = `${GARFE}/api-garfe/deleteaddress`; // deleteaddress yes                    //success api gateway
export const showrelationship1 = `${GARFE}/api-garfe/showrelationship`; // yes                           //success api gateway
export const addaddress = `${GARFE}/api-garfe/addaddress`; // yes                                        //success api gateway
export const editadd = `${GARFE}/api-garfe/editadd`; // yes                                              //success api gateway
export const createemail1 = `${GARFE}/api-garfe/createemail`; // yes                                     //success api gateway
export const updateemail1 = `${GARFE}/api-garfe/updateemail`; // yes                                     //success api gateway
export const checklandcus = `${GARFE}/api/checklandcus`; // yes                                    //success api gateway
export const checklandformix = `${GARFE}/api/checklandformix`; // yes                              //success api gateway
export const checklandpn = `${GARFE}/api/checklandpn`; // yes                                      //success api gateway
export const checklandpntest = `${GARFE}/api/checklandpntest`; // yes                              //success api gateway
export const checkcountcar = `${GARFE}/mid/checkcountcar`; // yes                                  //success api gateway
export const loadimage = `${GARFE}/api-garfe/loadimage`; // โหลดรูป YES                                   //success api gateway


export const loadBrand = `https://shark-app-j9jc9.ondigitalocean.app/ratecars/brand/`; // loadBrand การตลาด
export const loadModel = `https://shark-app-j9jc9.ondigitalocean.app/ratecars/model`; // loadModel การตลาด
export const getprice = `https://shark-app-j9jc9.ondigitalocean.app/ratecars/price`; // getprice yes
export const dataModel = `https://shark-app-j9jc9.ondigitalocean.app/ratecars/model_te/`; // yes
export const model1 = `https://shark-app-j9jc9.ondigitalocean.app/ratecars/model_te/model/`; //  yes
export const ratecarsmodel = `https://shark-app-j9jc9.ondigitalocean.app/ratecars/model`; // yes


export const newgetall = `${OCCUPATIONS}/api-occupation/new-getall`; // โหลดอาชีพ yes           //success api gateway
export const newupdatecustomerocc = `${OCCUPATIONS}/add/new-updatecustomerocc`; // แก้ไขอาชีพ yes            //success api gateway
export const newgetsub = `${OCCUPATIONS}/api-occupation/new-getsub`; // loadSubOccupation yes  //success api gateway
export const newgetsection = `${OCCUPATIONS}/api-occupation/new-getsection`; // yes            //success api gateway
export const getocc = `${OCCUPATIONS}/api-occupation/getocc`; // yes                           //success api gateway
export const deletecustomerocc = `${OCCUPATIONS}/add/deletecustomerocc`; // yes                //success api gateway
export const getcustomerocc = `${OCCUPATIONS}/add/getcustomerocc`; // yes                      //success api gateway
export const occupationIncom = `${OCCUPATIONS}/add/occupationIncome`; // yes                   //success api gateway
export const getsub = `${OCCUPATIONS}/api-occupation/getsub`; // yes                           //success api gateway
export const getsection = `${OCCUPATIONS}/api-occupation/getsection`; // yes                   //success api gateway
export const newoccupationIncome = `${OCCUPATIONS}/add/new-occupationIncome`; // yes           //success api gateway
export const updatecustomerocc = `${OCCUPATIONS}/add/updatecustomerocc`; // yes                //success api gateway
export const newdeletecustomerocc = `${OCCUPATIONS}/add/new-deletecustomerocc`; // yes         //success api gateway


export const checkCCAP = `${OFFERCASECAR}/Api/checkCCAP`; // กดตรวจบัตร ปชช. yes                   //success api gateway
export const checkNewCCAP = `${OFFERCASECAR}/Api/checkNewCCAP`; // ดึงข้อมูล customer ตอนกรอกบัตร yes //success api gateway
export const allcreate = `${OFFERCASECAR}/Api/allcreate`; // allcreate yes                        //success api gateway
export const newallcreate = `${OFFERCASECAR}/Api/newallcreate`; // yes                            //success api gateway
export const bookCar = `${OFFERCASECAR}/Api/bookCar`; // yes                                      //success api gateway
export const bookCarBig = `${OFFERCASECAR}/Api/bookCarBig`; // yes                                //success api gateway


export const GetProvince = `https://onemoney.ngrok.app/GetProvince`; // GetProvince yes
export const PostProvince1 = `https://onemoney.ngrok.app/PostProvince`; // PostProvince yes
export const GetAmphur1 = `https://onemoney.ngrok.app/GetAmphur`; // GetAmphur1 yes
export const AllData = `https://onemoney.ngrok.app/AllData`; // AllData yes


export const deletephone2 = `${CHECKVALIDATE}/api-check-validate/deletephone`; // deletephone yes            //success api gateway
export const deleteaddress2 = `${CHECKVALIDATE}/api-check-validate/deleteaddress`; // deletephone yes        //success api gateway
export const updateemail = `${CHECKVALIDATE}/api-check-validate/updateemail`; // yes                         //success api gateway
export const showrelationship = `${CHECKVALIDATE}/api-check-validate/showrelationship`; // yes               //success api gateway
export const addphoneandcus = `${CHECKVALIDATE}/api-check-validate/addphoneandcus`; // yes                   //success api gateway
export const editphone = `${CHECKVALIDATE}/api-check-validate/editphone`; // yes                             //success api gateway
export const createsocial = `${CHECKVALIDATE}/api-check-validate/createsocial`; // yes                       //success api gateway
export const updatesocial = `${CHECKVALIDATE}/api-check-validate/updatesocial`; // yes                       //success api gateway
export const createemail = `${CHECKVALIDATE}/api-check-validate/createemail`; // yes                         //success api gateway
export const addaddress1 = `${CHECKVALIDATE}/api-check-validate/addaddress`; // yes                          //success api gateway
export const editadd1 = `${CHECKVALIDATE}/api-check-validate/editadd`; // yes                                //success api gateway
export const loadimage1 = `${CHECKVALIDATE}/api-check-validate/loadimage`; // yes                            //success api gateway
export const checkgu1 = `${CHECKVALIDATE}/api-check-validate/checkgu`; // yes                                //success api gateway

export const maincdcount = `${CHECKVALIDATE}/mid/maincdcount`; // yes                         //success api gateway
export const maincdcountksm = `${CHECKVALIDATE}/mid/maincdcountksm`; // yes                   //success api gateway
export const maincddata = `${CHECKVALIDATE}/mid/maincddata`; // yes                           //success api gateway
export const maincddataksm = `${CHECKVALIDATE}/mid/maincddataksm`; // yes                     //success api gateway
export const showguarantorsdoc = `${CHECKVALIDATE}/api/showguarantorsdoc`; // yes             //success api gateway
export const addcheckbox = `${CHECKVALIDATE}/api/addcheckbox`; // yes                         //success api gateway
export const findcarcd = `${CHECKVALIDATE}/api/findcarcd`; // yes                             //success api gateway
export const precontractcar = `${CHECKVALIDATE}/api/precontractcar`; // yes                   //success api gateway
export const precontractland = `${CHECKVALIDATE}/api/precontractland`; // yes                 //success api gateway
export const deletesocial1 = `${CHECKVALIDATE}/api/deletesocial`; // yes                      //success api gateway
export const deleteemail1 = `${CHECKVALIDATE}/api/deleteemail`; // yes                        //success api gateway
export const deleteguarantor = `${CHECKVALIDATE}/api/deleteguarantor`; // yes                 //success api gateway
export const editgarno = `${CHECKVALIDATE}/api/editgarno`; // yes                             //success api gateway
export const editProductDocCar = `${CHECKVALIDATE}/api/editProductDocCar`; // yes             //success api gateway
export const editProductDocLand = `${CHECKVALIDATE}/api/editProductDocLand`; // yes           //success api gateway
export const findlandcd = `${CHECKVALIDATE}/api/findlandcd`; // yes                           //success api gateway



export const createaddresstocustomer = `${EUA}/Api/createaddresstocustomer`; // yes                //success api gateway
export const upsert = `${EUA}/Api/upsert`; // yes                                                  //success api gateway
export const checkCCAP2 = `${EUA}/Api/checkCCAP`; // yes                                           //success api gateway
export const insertemail = `${EUA}/Api/insertemail`; // yes                                        //success api gateway
export const insertphone = `${EUA}/Api/insertphone`; // yes                                        //success api gateway
export const insertsocail = `${EUA}/Api/insertsocail`; // yes                                      //success api gateway
export const customerbroker = `${EUA}/api-broker/customerbroker`; // yes                           //success api gateway
export const loadOJS = `${EUA}/api-occupation/new-getall`; // โหลดอาชีพ yes                         //success api gateway
export const loadProvinces = `${EUA}/Api/provinces`; // load จังหวัด เกือบ YES                        //success api gateway
export const updateaddress = `${EUA}/Api/updateaddress`; // yes                                    //success api gateway
export const updateemail2 = `${EUA}/Api/updateemail`; // yes                                       //success api gateway
export const createaddress = `${EUA}/Api/createaddress`; // yes                                    //success api gateway        
export const updatephone1 = `${EUA}/Api/updatephone`; // yes                                       //success api gateway  
export const updatesocial1 = `${EUA}/Api/updatesocial`; // yes                                     //success api gateway

// สำหรับเลือกจังหวัดใหม่
export const getdistrict = `${EUA}/Api/districted/`; // yes                                        //success api gateway
export const getsubdistrict = `${EUA}/Api/subdistricted/`; // yes                                  //success api gateway
export const getzipcodes = `${EUA}/Api/zipcodes/`; // yes                                          //success api gateway
//สำหรับดึงข้อมูลเพื่อแก้ไขจังหวัด
export const getprovinceall = `${EUA}/Api/provinces/`; // yes                                      //success api gateway
export const getdistrictall = `${EUA}/Api/districts/`; // yes                                      //success api gateway
export const getsubdistrictsall = `${EUA}/Api/subdistricts/`; // yes                               //success api gateway




export const pgmaincount = `${PG}/mid/pgmaincount`; // yes                                     //success api gateway
export const pgmaincountksm = `${PG}/mid/pgmaincountksm`; // yes                               //success api gateway
export const pgmaindata = `${PG}/mid/pgmaindata`; // yes                                       //success api gateway
export const pgmaindataksm = `${PG}/mid/pgmaindataksm`; // yes                                 //success api gateway
export const findcase = `${PG}/mid/findcase`; // yes                                           //success api gateway
export const findcarpg = `${PG}/api/findcarpg`; // yes                                         //success api gateway
export const findlandpg = `${PG}/api/findlandpg`; // yes                                       //success api gateway
export const checkalldata = `${PG}/api/check-all-data`; // yes                                 //success api gateway
export const acceptpg2 = `${PG}/api/acceptpg2`; // yes                                         //success api gateway
export const checkguarantors1 = `${PG}/api-pg/checkguarantors`; // yes                            //success api gateway
export const addcheckbox1 = `${PG}/api/addcheckbox`; // yes                                    //success api gateway
export const rejectpg = `${PG}/api/rejectpg`; // yes                                           //success api gateway
// export const approvepgnew = `http://localhost:8080/api/approvepgnew`; // yes                                                 
export const approvepgnew = `${PG}/api/approvepgnew`; // yes                                   //success api gateway
// export const approvepgcon = `http://localhost:8080/api/approvepgcon`; // yes                                                 
export const approvepgcon = `${PG}/api/approvepgcon`; // yes                                   //success api gateway
export const unapprovepgnew = `${PG}/api/unapprovepgnew`; // yes                               //success api gateway
export const pgmaincountmemo = `${PG}/mid/pgmaincountmemo`; // yes                             //success api gateway
export const pgmaindatamemo = `${PG}/mid/pgmaindatamemo`; // yes                               //success api gateway
export const addmemo = `${PG}/api/addmemo`; // yes                                             //success api gateway
export const findcase1 = `${PG}/api/findcase`; // yes                                          //success api gateway
export const pgmaincountre = `${PG}/mid/pgmaincountre`; // yes (NEW)                           //success api gateway
export const pgmaindatare = `${PG}/mid/pgmaindatare`; // yes (NEW)                             //success api gateway


export const testfindCount = `${CASE}/AuthCase/testfindCount`; // yes                              //success api gateway
export const selectcase = `${CASE}/AuthCase/select-case`; // yes                                   //success api gateway
export const rockingWorkCar = `${CASE}/ApiCase/rockingWorkCar`; // yes                             //success api gateway
export const rockingWorkLand = `${CASE}/ApiCase/rockingWorkLand`; // yes                           //success api gateway
export const stopWork = `${CASE}/AuthCase/stopWork`; // yes                                        //success api gateway
export const cancelTime = `${CASE}/ApiCase/cancelTime`; // yes                                     //success api gateway
export const addTime = `${CASE}/ApiCase/addTime`; // yes                                           //success api gateway


export const getallpreland = `${CHECKINFOLAND}/mid/getallpreland`; // yes                      //success api gateway
export const loadimgland = `${CHECKINFOLAND}/info_land/loadimgland`; // yes                    //success api gateway
export const editLand = `${CHECKINFOLAND}/info_land/editLand`; // yes                          //success api gateway
export const mklandmix = `${CHECKINFOLAND}/info_land/mk-land-mix`; // yes                      //success api gateway
export const precreateland = `${CHECKINFOLAND}/info_land/precreateland`; // yes                //success api gateway
export const getallinfo = `${CHECKINFOLAND}/mid/getallinfo`; // yes                            //success api gateway
export const acceptpn = `${CHECKINFOLAND}/info_land/acceptpn`; // yes                          //success api gateway
export const shownotepn = `${CHECKINFOLAND}/info_land/shownotepn`; // yes                      //success api gateway
export const shownotepnre = `${CHECKINFOLAND}/info_land/shownotepnre`; // yes                  //success api gateway
export const approvalPriceNew = `${CHECKINFOLAND}/info_land/approvalPriceNew`; // yes          //success api gateway
export const approvalEditPriceNew = `${CHECKINFOLAND}/info_land/approvalEditPriceNew`; // yes  //success api gateway

export const getlandpn1 = `${CHECKINFOLAND}/api-checkinfo/getlandpn`; // yes                       //success api gateway

// export const getlandpn = `https://api-checkinfoland-i67f6gaaqa-as.a.run.app/info_land/getlandpn`; // yes                           //success api gateway

export const getlandrepn = `${CHECKINFOLAND}/info_land/getlandrepn`;                           //success api gateway
export const loadlandrepn = `${CHECKINFOLAND}/info_land/loadlandrepn`;                         //success api gateway
export const approvalPriceNewRe = `${CHECKINFOLAND}/info_land/approvalPriceRe`; //เคาะราคา      //success api gateway
export const editApprovePriceRe = `${CHECKINFOLAND}/info_land/editApprovalPriceRe`; //แก้ยอด    //success api gateway




export const storeDBLine = `${DELETEIMG}/storeDBLine`; // yes                               //success api gateway
export const deleteFolderImgGuaCar = `${DELETEIMG}/deleteFolderImgGuaCar`; // yes           //success api gateway
export const deleteAllCar = `${DELETEIMG}/deleteAllCar`; // yes                             //success api gateway
export const deleteAllLand = `${DELETEIMG}/deleteAllLand`; // yes                           //success api gateway
export const deleteFolderImgGuaLand = `${DELETEIMG}/deleteFolderImgGuaLand`; // yes         //success api gateway


export const permission = `${LOGIN}/Auth/permission`; //  yes                                  //success api gateway
export const resetpassword = `${LOGIN}/resetpassword`; // yes                                  //success api gateway
// export const login = `${LOGIN}/login`; // yes                                                  //success api gateway
export const loginline = `${LOGIN}/loginline`; // yes                                          //success api gateway
export const GetBranch = `${LOGIN}/GetBranch`; // yes                                          //success api gateway
export const registers = `${LOGIN}/Auth/registers`; // yes                                     //success api gateway
export const showallrole = `${LOGIN}/Auth/showallrole`; // yes                                 //success api gateway
export const editpermisuser = `${LOGIN}/Auth/editpermisuser`; // yes                           //success api gateway
export const updatepermisuser = `${LOGIN}/Auth/updatepermisuser`; // yes                       //success api gateway 
export const table = `${LOGIN}/Auth/table`; // yes                                             //success api gateway 
export const deleteuser = `${LOGIN}/Auth/deleteuser`; // yes                                   //success api gateway 


export const resendcarmk = `${RESEND}/Api-Resend/resendcarmk`; // ส่งเคสอีกครั้ง YES               //success api gateway 
export const resendlandmk1 = `${RESEND}/Api-Resend/resendlandmk`; // yes                       //success api gateway 


export const editcarrate = `${RATE0}/api/editcarrate`; // yes                                  //success api gateway


export const getall = `${MANAGER}/boss/getall`; // yes                                         //success api gateway
export const tablemanager = `${MANAGER}/midle/tablemanager`; // yes                            //success api gateway


export const newmix = `${CALLSYNC}/tst-db2/new-mix`; // yes                                   //success api gateway




export const newallcreateland = `${LAND}/api_land/newallcreateland`; // yes                    //success api gateway
export const createPreLand = `${LAND}/api_land/createPreLand`; // yes                          //success api gateway
export const allcreateland = `${LAND}/api_land/allcreateland`; // allcreateland yes            //success api gateway

// 30/05/67

export const getImagess = `${GETIMG}/ApimgGet/GetImagess`;// yes                                //success api gateway
export const getImagessPre = `${GETIMG}/ApimgGet/GetImagessPre`;// yes                          //success api gateway
export const getFolderGuaImg = `${GETIMG}/ApimgGet/GetFolderGuaImg`;// yes                      //success api gateway
export const getAllFolderGuaImg = `${GETIMG}/ApimgGet/GetAllFolderGuaImg`;// yes                //success api gateway

export const deleteImgGua = `${DELETEIMG}/deleteImgGua`;// yes                              //success api gateway
export const deleteImgGuaCar = `${DELETEIMG}/deleteImgGuaCar`;// yes                        //success api gateway
export const deleteFolderImgGua = `${DELETEIMG}/deleteFolderImgGua`;// yes                  //success api gateway

export const findDataBroker = `${MARKETING}/api/find-data-broker`;                             //success api gateway
export const detailBroker = `${EUA}/api-broker/detailbroker`; // yes                              //success api gateway
export const createbroker = `${EUA}/api-broker/createbroker`;                                       //success api gateway
export const updateDetail = `${EUA}/api-broker/update-detail`;  // yes                             //success api gateway
export const payBroker = `${EUA}/api-broker/pay-broker`; // yes                                    //success api gateway
export const confirmUpdateDataBroker = `${MARKETING}/api/confirmUpdateDataBroker`;             //success api gateway
export const checkBroker = `${EUA}/api-broker/checkbroker`;  // yes                               //success api gateway

export const testToken = `${LOGIN}/Auth/testToken`; // เพิ่ม route 30/05/67                      //success api gateway

export const pgmaincountratezero = `${PG}/mid/pgmaincountratezero`;                            //success api gateway
export const pgmaindataratezero = `${PG}/mid/pgmaindataratezero`;                              //success api gateway

export const findlandinfo = `${CHECKINFOLAND}/mid/findlandinfo`;  // yes                       //success api gateway
export const findprelandinfo = `${CHECKINFOLAND}/mid/findprelandinfo`;  // yes                 //success api gateway

export const deleteNewUpCar = `${DELETEIMG}/deleteNewUpCar`;  // yes                        //success api gateway
export const deleteNewUpLand = `${DELETEIMG}/deleteNewUpLand`; // yes                       //success api gateway
export const deleteImgGuaLand = `${DELETEIMG}/deleteImgGuaLand`; // yes                     //success api gateway
export const deletePreCar = `${DELETEIMG}/deletePreCar`;                                    //success api gateway
export const deletePreLand = `${DELETEIMG}/deletePreLand`;                                  //success api gateway

export const getEmployeeHistory = `${MARKETING}/api/get-employee-history`;                     //success api gateway

export const checkonecusadd = `${GARFE}/api/checkonecusadd`; // yes                                //success api gateway

export const loadCarPGRE = `${PG}/api/loadCarPGRE`;                                            //success api gateway
export const loadLandPGRE = `${PG}/api/loadLandPGRE`;                                          //success api gateway
export const acceptpgre = `${PG}/api/acceptpgre`;                                              //success api gateway
export const findcarpgre = `${PG}/api/findcarpgre`;                                            //success api gateway        
export const findlandpgre = `${PG}/api/findlandpgre`;                                          //success api gateway
export const editredate = `${PG}/api/editredate`;                                              //success api gateway
export const approveRE = `${PG}/api/approveRE`;                                                //success api gateway
export const approveREcon = `${PG}/api/approveREcon`;                                          //success api gateway
export const docnotpasspgre = `${PG}/api/docnotpasspgre`;                                      //success api gateway
export const approveRELand = `${PG}/api/approveRELand`;                                        //success api gateway
export const approveREconLand = `${PG}/api/approveREconLand`;                                  //success api gateway
export const docnotpasspgreland = `${PG}/api/docnotpasspgreland`;                              //success api gateway



export const showre = `${MARKETING}/auth-mk/showre`;  // yes                                   //success api gateway
export const createReMk = `${MARKETING}/api/create-re-mk`;                                     //success api gateway
export const createReLandMk = `${MARKETING}/api/create-reland-mk`;                             //success api gateway
export const showOffercaseRe = `${MARKETING}/auth-mk/show-offercase-re`;                       //success api gateway
export const showOffercaseReLand = `${MARKETING}/auth-mk/show-offercase-re-land`;              //success api gateway
export const loadDataRe = `${MARKETING}/api/load-data-re`;                                     //success api gateway
export const loadDataReLand = `${MARKETING}/api/load-data-re-land`;                            //success api gateway
export const confirmre = `${MARKETING}/api/confirmre`;                                         //success api gateway
export const confirmreland = `${MARKETING}/api/confirmreland`;                                 //success api gateway
// export const resendeditcasere = `${MARKETING}/api/resendeditcasere`;                           //success api gateway
// export const resendeditcasereland = `http://localhost:8080/api/resendeditcasereland`;                           //success api gateway
// export const resendeditcasereland = `${MARKETING}/api/resendeditcasereland`;                           //success api gateway
// export const editcasere = `${MARKETING}/api/editcasere`;                                       //success api gateway
// export const editcasereland = `http://localhost:8080/api/editcasereland`;                                       //success api gateway
// export const editcasereland = `${MARKETING}/api/editcasereland`;                                       //success api gateway


export const alldataland1 = `${SEARCHCONTNO}/api-db2/alldataland1`;                           //success api gateway
export const findpayment = `${SEARCHCONTNO}/api-db2/findpayment`;  // เพิ่ม route 30/05/67          //success api gateway
export const downloadImagess = `${DOWNLOADIMG}/ApimgDownload/DownloadImagess`;  // เพิ่ม route 30/05/67       //success api gateway
export const downloadImagessPre = `${DOWNLOADIMG}/ApimgDownload/DownloadImagessPre`;  // เพิ่ม route 30/05/67         //success api gateway


// https://sendcase-mk-d2flonv3.an.gateway.dev
export const addcontnopgre = `${PG}/api/addcontnopgre`;                              //เพิ่ม 15/06/24     //success api gateway  check แล้ว ใช้ได้
export const loadcontnopgre = `${PG}/api/loadcontnopgre`;                              //เพิ่ม 15/06/24    //success api gateway   check แล้ว ใช้ได้
export const cancelcontnopgre = `${PG}/api/cancelcontnopgre`;                              //เพิ่ม 15/06/24    //success api gateway     check แล้ว ใช้ได้
export const checkcontnopgre = `${PG}/mid/checkcontno-pgre`;                              //เพิ่ม 15/06/24    //success api gateway    check แล้ว ใช้ได้

export const loadcontnomkre = `${MARKETING}/api/loadcontnomkre`;                              //เพิ่ม 15/06/24   //success api gateway    check แล้ว ใช้ได้

export const loadContMKLeaving = `${MANAGER}/midle/cont-boss`; // 18/06/67          //success api gateway  check แล้ว ใช้ได้
export const loadMKActive = `${MANAGER}/group/selectMkActive`; // 18/06/67          //success api gateway     check แล้ว ใช้ได้
export const loadContnoMK = `${MANAGER}/midle/cont-mk`;  // 18/06/67                //success api gateway  check แล้ว ใช้ได้

export const confirmMKContLeaving = `${SEARCHCONTNO}/api-db2/selectContnoMK`; // 18/06/67       //success api gateway     check แล้ว ใช้ได้
export const detaillandchangepg = `${SEARCHCONTNO}/api-pg-db2/detail-land-change-pg`; // 19/06/67     //success api gateway   check แล้ว ใช้ได้
export const detailcarchangepg = `${SEARCHCONTNO}/api-pg-db2/detail-car-change-pg`; // 19/06/67      //success api gateway   check แล้ว ใช้ได้

export const mainPGDataRocking = `${PG}/mid/pgmaindata-rocking`; // 21/06/67 


//  22/06/2024
export const resendeditcasere = `${MARKETING}/api/resendeditcasere`;   //  22/06/2024   //success api gateway     check แล้ว ใช้ได้
export const resendeditcasereland = `${MARKETING}/api/resendeditcasereland`;   //  22/06/2024   //success api gateway     check แล้ว ใช้ได้
export const editcasere = `${MARKETING}/api/editcasere`;   //  22/06/2024   //success api gateway     check แล้ว ใช้ได้
export const editcasereland = `${MARKETING}/api/editcasereland`;   //  22/06/2024   //success api gateway     check แล้ว ใช้ได้
//  22/06/2024                               


// 24/06/2024
export const showcausenamere = `${GARFE}/api/showcausenamere`;   // 24/06/2024    //success api gateway     check แล้ว ใช้ได้
// 24/06/2024 

export const newfindprelandinfo = `${CHECKINFOLAND}/mid/newfindprelandinfo` // 26/06/67  //success api gateway     check แล้ว ใช้ได้
export const searchfindallpre = `${CHECKINFOLAND}/mid/searchfindallpre` // 26/06/67  //success api gateway     check แล้ว ใช้ได้

export const newfindlandinfo = `${CHECKINFOLAND}/mid/newfindlandinfo` // 02/07/67  //success api gateway     check แล้ว ใช้ได้


export const changestatus = `${PG}/api/change-status`; // 09/07/2024  //success api gateway     check แล้ว ใช้ได้
export const changeproductLoanType = `${PG}/api/change-productLoanType`; // 09/07/2024  //success api gateway     check แล้ว ใช้ได้


export const loadMainBoss = `${MANAGER}/boss/load-main-boss`; // 15/07/2024  ยังไม่ได้มีการเรียกใช้
export const loadDetailBoss = `${MANAGER}/api-manager/load-detail-boss`; // 15/07/2024   ยังไม่ได้มีการเรียกใช้

// 19/07/2567
export const updateApproves = `${PG}/api/edit-al`; // 19/07/2024    //success api gateway     check แล้ว ใช้ได้


export const tellAun = `${SEARCHCONTNO}/api-db2/tell-aun`; // 30/07/67   //success api gateway     check แล้ว ใช้ได้

export const newOffercaseReCar = `${MARKETING}/api/new-offercase-re-car`; // 30/07/2024     //success api gateway    check แล้ว ใช้ได้
export const newOffercaseReLand = `${MARKETING}/api/new-offercase-re-land`; // 30/07/2024     //success api gateway   check แล้ว ใช้ได้
export const loadInfoMk = `${MARKETING}/api/load-info-mk`; // 30/07/2024      //success api gateway  check แล้ว ใช้ได้

// 06/08/2567
export const loadSendPG = `${PG}/api/load-send-pg`; // 06/08/2024      GET  //success api gateway
export const loadDetailAunSendPG = `${PG}/api/load-detail-aunsendpg`; // 06/08/2024      POST  //success api gateway
export const loadNoteAun = `${PG}/api/load-note-aun`; // 06/08/2024      POST  //success api gateway    

// 13/08/2567

export const detailfollowexpeditethedebt = `${MANAGER}/api-manager/detail-follow-expeditethedebt`  // 13/08/2567 POST  //success api gateway
export const mainfollowexpeditethedebt = `${MANAGER}/midle/main-follow-expeditethedebt`  // 13/08/2567  GET   //success api gateway

export const mainexpeditethedebt = `${MANAGER}/midle/main-expeditethedebt`  // 13/08/2567  GET   //success api gateway
export const searchmainexpeditethedebt = `${MANAGER}/midle/search-main-expeditethedebt`  // 13/08/2567   POST  //success api gateway

export const aunsendpg = `${MANAGER}/api-manager/aun-send-pg`  // 13/08/2567   POST   //success api gateway

export const insertcase = `${MANAGER}/api-manager/insert-case`  // 13/08/2567   POST  //success api gateway
export const acceptceo = `${MANAGER}/api-manager/accept-ceo`  // 13/08/2567   POST  //success api gateway
export const approvalceo = `${MANAGER}/api-manager/approval-ceo`  // 13/08/2567   POST  //success api gateway
export const editapprovalceo = `${MANAGER}/api-manager/edit-approval-ceo`  // 13/08/2567   POST  //success api gateway
export const loaddetailmysqlDB2 = `${MANAGER}/api-manager/load-detail-mysqlDB2`  // 13/08/2567   POST  //success api gateway

export const loadmaineditdate = `${MANAGER}/boss/load-main-editdate`  // 13/08/2567   GET  //success api gateway
export const recovercase = `${MANAGER}/api-manager/recover-case`  // 13/08/2567   POST  //success api gateway
export const loaddetaileditdate = `${MANAGER}/api-manager/load-detail-editdate`  // 13/08/2567   POST  //success api gateway

export const updatere1 = `${PG}/api/update-re1`  // 15/08/2567   PUT  //success api gateway

// 17/08/2567
export const createyellowcard = `${MANAGER}/api-manager/create-yellowcard`  // 15/08/2567   PUT //success api gateway
export const login = `${LOGIN}/login-mongo`;       //19/08/67                                          //success api gateway

//24/08/2567
export const updateSwitch = `${LOGIN}/update-switch`;       //24/08/67           //success api gateway                      
export const getuser = `${LOGIN}/users`;       //24/08/67                   //success api gateway                   
export const getdata = `${LOGIN}/data`;       //24/08/67                 //success api gateway                  
export const createuser = `${LOGIN}/create-users`;       //24/08/67        //success api gateway                             
export const edituser = `${LOGIN}/update-user`;       //24/08/67            //success api gateway                      
export const updatepassword = `${LOGIN}/update-password`;       //24/08/67      //success api gateway                              
export const updaterigth = `${LOGIN}/update-rigth`;       //24/08/67               //success api gateway    


export const pguploadcar = `${UPLOADIMG}/Apimg/pg-upload-car`;       //28/08/67           //success api gateway                     
export const pguploadland = `${UPLOADIMG}/Apimg/pg-upload-land`;       //28/08/67               //success api gateway                 

export const FindDataNotiHDPAYMENT = `${SEARCHCONTNO}/api-db2/FindDataNotiHDPAYMENT`;  //30/08/67 //success api gateway

export const newnote = `${PG}/api/load-data-noteforpg`;  //31/08/67 //success api gateway

export const getImagessGua = `${GETIMG}/ApimgGet/GetImagessGua`; // 05/09/2567 //success api gateway

export const loadCuscarceo = `${MANAGER}/api-manager/load-cuscarceo`; //  10/09/2567  //success api gateway

export const checklandad = `${GARFE}/api/checklandad`; // yes  15/10/2567    //success api gateway

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// { 
// การเงิน เทส
// const PAYMENT = "https://api-payment-539174983798.asia-southeast1.run.app"; // เทส
// const PAYMENT = "https://api-payment-1024031113167.asia-southeast1.run.app"; // จริง
const PAYMENT = process.env.REACT_APP_PAYMENT;
// const PAYMENT = "http://localhost:8070";
//
export const mainReceipt = `${PAYMENT}/api-payment/main-receipt`; // โหลด main ใบเสร็จ
export const searchReceipt = `${PAYMENT}/api-payment/search-receipt`; // main ใบเสร็จ ค้นหา
export const printReceipt = `${PAYMENT}/api-payment/print-receipt`; // กดพิมพ์ใบเสร็จ
export const printReceiptAuto = `${PAYMENT}/api-payment/print-receipt-auto`; // กดพิมพ์ใบเสร็จ auto
export const getPaytype = `${PAYMENT}/api-payment/get-paytype`; // เก็ต pay-type
export const FindPAYTYP = `${PAYMENT}/api-payment/find-pay`; // ค้นหาจาก ปชช. ทะเบียน
export const loadPayamt = `${PAYMENT}/api-payment/load-payamt`;  // ข้อมูลสัญญา
export const payamt = `${PAYMENT}/api-payment/payamt`;  // กดคำนวณ
export const confirmPay = `${PAYMENT}/auth-payment/confirm-pay`;  // กด insert db2 
export const insertMemo1 = `${PAYMENT}/api-payment/insert-memo1`;
// auto
export const loadDataAuto = `${PAYMENT}/api-payment/load-data-auto`;  // โหลด main auto
export const insertPayamt = `${PAYMENT}/auth-payment/insert-payamt`; // insert ยังก่อน 
export const mainReceiptAuto = `${PAYMENT}/api-payment/main-receipt-auto`;  // ใบเสร็จ main auto (โตโร่)
// cancel
export const loadMainCancelpay = `${PAYMENT}/api-payment/load-main-cancelpay`;  // โหลด main cancel 
export const detailCancelpay = `${PAYMENT}/api-payment/detail-cancelpay`;  // รายละเอียด cancel 
export const updateCancelpay = `${PAYMENT}/auth-payment/update-cancelpay`;  // กด update cancel
// orther
export const mainArothr = `${PAYMENT}/api-payment/main-arothr`; // main อื่นๆ
export const contnoArothr = `${PAYMENT}/api-payment/contno-arothr`; // สัญญาที่มี อื่นๆ
export const confirmPayArothr = `${PAYMENT}/auth-payment/confirm-pay-arothr`;  // กด insert orther db2 
export const loadArothrCancelpay = `${PAYMENT}/api-payment/load-arothr-cancelpay`;  // โหลด main cancel  arothr
export const detailArothrCancelpay = `${PAYMENT}/api-payment/detail-arothr-cancelpay`;  // รายละเอียด cancel  arothr
export const updateArothrCancelpay = `${PAYMENT}/auth-payment/update-arothr-cancelpay`;  // กด update cancel arothr
export const mainReceiptArothr = `${PAYMENT}/api-payment/main-receipt-arothr`; // โหลด main ใบเสร็จ arothr
export const searchReceiptArothr = `${PAYMENT}/api-payment/search-receipt-arothr`; // main ใบเสร็จ ค้นหา arothr
export const printReceiptArothr = `${PAYMENT}/api-payment/print-receipt-arothr`; // กดพิมพ์ใบเสร็จ arothr
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// }
// { เรท 0 CEO
export const mainRateZeroBoss = `http://localhost:8060/boss/main-rate-zero-boss`; // main
export const infoRateZeroBoss = `http://localhost:8060/boss/info-rate-zero-boss`; // info
export const confirmRateBoss = `http://localhost:8060/boss/confirm-rate-boss`; // info insert
export const editRateBoss = `http://localhost:8060/boss/edit-rate-boss`; // info edit
// }


// คนค้ำเคสรี
export const getGuarantor = `${MARKETING}/api/checkguarantor`;  // Get ข้อมูลคนค้ำเส้นใหม่
export const deleteGua = `${MARKETING}/api/deleteguarantor-re`;  // ลบข้อมูลคนค้ำเส้นใหม่
export const addGuarantor = `${MARKETING}/api/addguarantor`;  // เพิ่มข้อมูลคนค้ำเส้นใหม่

export const loadBunshe3 = `${SEARCHCONTNO}/api-db2/check-rpsl`;

export const loadDataBunshe3 = `https://rate-car-539174983798.asia-southeast1.run.app/api/PostbySumRate`;

export const checkcaradDB2 = `${SEARCHCONTNO}/api-db2/checkcar`;

