import React, { useState, useEffect } from "react";
import { FormOutlined, SearchOutlined, FileImageOutlined } from '@ant-design/icons';
import { Button, Col, Input, Row, Table, Space, Card, Radio, Tag, notification, Statistic, Spin, Divider, Select, DatePicker, Typography, Pagination } from 'antd';
import { addCustomer } from '../../redux/Customer';
import { addCar } from '../../redux/Car';
import { addGuarantor } from '../../redux/Guarantor';
import dayjs from "dayjs";
import axios from "axios";
import ModalAccept from "./modals/ModalAccept";
import ModalPreApprove from "./modals/ModalPreApprove";
import { useDispatch } from 'react-redux';
import { colorApprovalStatus, backc, textc } from "../file_mid/status_color";
import { op } from "../file_mid/all_options";
import { checkinfoadmain } from "../file_mid/all_api";
import ModalInfoADPGDC from "../file_mid/info/ModalInfoADPGDC";
import ModalNote from "../report_pg/Modal/Note";
const { Text } = Typography;
const { Countdown } = Statistic;

export default function Main() {
  const token = localStorage.getItem('token')
  const currentDateTime = dayjs()
  const showDay1 = dayjs("2023-01-01")
  const currentDateDay2 = dayjs().format("YYYY-MM-DD")
  // const [dataPost, setDataPost] = useState({ sec: 0, days1: dayjs("2023-01-01"), days2: currentDateDay2, approvalStatus: "รอธุรการรับ" })
  const [dataPost, setDataPost] = useState({ sec: 0, days1: dayjs("2023-01-01"), days2: currentDateDay2, approvalStatus: "16" })
  // const guarantor = useSelector(state => state.guarantors)
  const dispatch = useDispatch()
  const user = localStorage.getItem('username');
  const [editData, setEditData] = useState();
  const [isModalAccept, setIsModalAccept] = useState(false); // Accept
  const [isModalPreApprove, setIsModalPreApprove] = useState(false); // PreApprove
  const [cData, setCData] = useState([]);
  const [axiosData, setAxiosData] = useState([]);
  const [arrayTable, setArrayTable] = useState([]);
  const [keyWord, setKeyWord] = useState("ทั้งหมด");
  const [api, contextHolder] = notification.useNotification();
  const [loading, setLoading] = useState(false)
  // const [types, setTypes] = useState([])
  const [testPage, setTestPage] = useState();
  const [ssPage, setSsPage] = useState({ numberPage: 1, pageSize: 10 });
  const [currentPage, setCurrentPage] = useState(1)
  const [query, setQuery] = useState("");
  const [isModalInfoADPGDC, setIsModalInfoADPGDC] = useState(false);
  const [isModalNote, setIsModalNote] = useState(false)
  const [isOpenNewNote, setIsOpenNewNote] = useState()


  // console.log('arrayTable', arrayTable)

  useEffect(() => {
    loadData();
    //loadCountData();
  }, [keyWord, isModalAccept, isModalPreApprove, editData, isModalInfoADPGDC, dataPost.approvalStatus, dataPost.days1, dataPost.days2, ssPage])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      // console.log(query)
      searchLoadData(query)
    }, 1500)
    return () => clearTimeout(delayDebounceFn)
  }, [query])

  const searchLoadData = async (data) => {
    // console.log("searchLoadData data", data)
    if (data !== "") {
      // console.log("OK searchLoadData data")
      setCurrentPage(1)
      await loadData(data)
    }
  }
  const search = async (data) => {
    if (data.target.value !== "") {
      setQuery(data.target.value)
    } else {
      setSsPage({ numberPage: 1, pageSize: 10 })
      setCurrentPage(1)
      setDataPost({ sec: 0, days1: dayjs("2023-01-01"), days2: currentDateDay2, approvalStatus: "16" })
      setQuery("")
    }
  };

  const loadCountData = async (data) => {
    // const ez = data.filter(
    //   (item) =>
    //     item.approvalStatus === dataPost?.approvalStatus
    // );
    // setTestPage(...ez)
    // setCData(data)

    if (!data) {
      setCData([])
    } else {
      const ez = data.filter(
        (item) =>
          item.approvalStatusId === dataPost?.approvalStatus
        // item.approvalStatusId === keyWord
      );
      setTestPage(...ez)
      setCData(data)
    }

  }

  const loadData = async (data) => {
    let mainData = {}
    setLoading(true)
    const tk = JSON.parse(token)
    const headers = {
      "Authorization": `Bearer ${tk}`,
      "Menu": JSON.stringify("4")
    }
    // console.log(query, data)
    if (query !== "" && data !== "stop") {
      mainData = { reviewedBy: user, approvalStatus: dataPost.approvalStatus, numberPage: ssPage?.numberPage, pageSize: ssPage?.pageSize, keyword: query, day1: dataPost.days1, day2: dataPost.days2 }
    } else {
      if (data === "stop") {
        mainData = { reviewedBy: user, approvalStatus: "ทั้งหมด", numberPage: ssPage?.numberPage, pageSize: ssPage?.pageSize, day1: dataPost.days1, day2: dataPost.days2 }
      } else {
        mainData = { reviewedBy: user, approvalStatus: dataPost.approvalStatus, numberPage: ssPage?.numberPage, pageSize: ssPage?.pageSize, day1: dataPost.days1, day2: dataPost.days2 }
      }
    }
    console.log("mainData", mainData)
    await axios.post(checkinfoadmain, mainData, { headers: headers })
      .then(async (res) => {
        // console.log("loadDataADADADAD", res.data)
        if (res.data) {
          if (res.data.JoinTableShow) {
            setArrayTable(res.data.JoinTableShow)
            setAxiosData(res.data.JoinTableShow)
          } else {
            setArrayTable([])
            setAxiosData([])
          }
          if (res.data.Countsttatus) {
            await loadCountData(res.data.Countsttatus);
          } else {
            setTestPage()
            setCData([])
          }
        } else {
          setArrayTable([])
          setAxiosData([])
        }
        setLoading(false)
      }).catch((err) => console.log(err))
    setLoading(false)
  }
  // const search = (data) => {
  //   // อนาคต ควรหาใน database โดยตรง
  //   const greaterThanTen = axiosData.filter(
  //     (item) =>
  //       item.snam.toLowerCase().includes(data.target.value) ||
  //       item.firstName.toLowerCase().includes(data.target.value) ||
  //       item.carPlateNumber.toLowerCase().includes(data.target.value)
  //   );
  //   setArrayTable(greaterThanTen)
  // };

  const onChangeKeyWord = (value) => {
    // console.log("value", value)
    setQuery("")
    setDataPost({ ...dataPost, approvalStatus: value })
    setCurrentPage(1)
    setSsPage({ numberPage: 1, pageSize: 10 })
  }
  const onChangePagination = (e, pageSize) => {
    setCurrentPage(e)
    setSsPage({ numberPage: e, pageSize: pageSize })
  }

  const handleviewImg = (record) => {
    localStorage.setItem('viewAD', JSON.stringify(record))
    window.open('/view-ad', '_blank');
  }

  const columns = [
    {
      fixed: true,
      key: 'index',
      title: "ลำดับ",
      dataIndex: "index",
      width: "5%",
      align: 'center',
      render: (text, object, index) => index + 1
    },
    {
      key: 'carInfo',
      title: "รายละเอียด",
      dataIndex: "carPlateNumber",
      width: "20%",
      align: 'center',
      render: (text, record) => (
        <>
          <div>{record.snam}{record.firstName} {record.lastName}</div>
          {/* <div>{record.carBrand} {record.carModel} </div> */}
          <div>
            {
              record.car === "car" ?
                <div>
                  <div>{record.carBrand} {record.carModel} ปีรถ {parseInt(record.carYear) + 543} เลขทะเบียน {record.carPlateNumber}</div>
                  <div>เสนอโดย <Space style={{ color: "red" }}><b>{record.proposalBy}</b></Space></div>
                  {record.ISSUNO === "" ? null : <div>มาจากสัญญา <Space style={{ color: "red" }}><b>{record.ISSUNO}</b></Space></div>}
                </div>
                :
                <div>
                  <div>{record.carBrand} {record.carModel} เลขโฉนด {record.carPlateNumber}</div>
                  {/* <div>เสนอโดย {record.proposalBy}</div> */}
                  <div>เสนอโดย <Space style={{ color: "red" }}><b>{record.proposalBy}</b></Space></div>
                  {record.ISSUNO === "" ? null : <div>มาจากสัญญา <Space style={{ color: "red" }}><b>{record.ISSUNO}</b></Space></div>}

                </div>
            }
          </div>
        </>
      )
    },
    {
      key: 'productLoanType',
      title: "ประเภทขอกู้",
      dataIndex: "productLoanType",
      width: "11%",
      align: 'center',
      render: (text, record) => (
        <>
          <div>
            {
              record.car === "car" ?
                <Tag color="purple">รถ</Tag>
                :
                <Tag color="green">ที่ดิน</Tag>
            }
            {/* </div>
          <div> */}
            {
              <Tag color="geekblue">{record.productLoanType}</Tag>
            }
          </div>
        </>
      ),
    },
    {
      key: 'loanAmount',
      title: "ยอดขอกู้",
      dataIndex: "loanAmount",
      width: "9%",
      align: 'center',
      sorter: {
        compare: (a, b) => a.loanAmount - b.loanAmount,
        multiple: 2,
      },
      render: (text, record) => (
        <Space size="middle">
          {
            <Space>
              <>{currencyFormatOne(record.loanAmount)}</>
            </Space>
          }
        </Space>
      ),
    },
    {
      key: 'carPrice',
      title: "เรท",
      dataIndex: "carPrice",
      width: "9%",
      align: 'center',
      sorter: {
        compare: (a, b) => a.carPrice - b.carPrice,
        multiple: 2,
      },
      render: (text, record) => (
        <Space size="middle">
          {
            <Space>
              <>{currencyFormatOne(record.carPrice)}</>
            </Space>
          }
        </Space>
      ),
    },
    {
      key: 'carInput',
      title: "วันที่เสนอ",
      dataIndex: "carInput",
      width: "9%",
      align: 'center',
      defaultSortOrder: 'descend',
      sorter: {
        compare: (a, b) => dayjs(a.carInput) - dayjs(b.carInput),
        multiple: 1,
      },
      render: (text, record) => (
        <>{dayjs(record.carInput).format("DD-MM-YY HH:mm")}</>
      ),

    },
    {
      key: 'acceptDate',
      title: "วันที่รับ",
      dataIndex: "acceptDate",
      width: "8%",
      align: 'center',
      sorter: {
        compare: (a, b) => dayjs(a.acceptDate) - dayjs(b.acceptDate),
        multiple: 2,
      },
      render: (text, record) => (
        <Space size="middle">
          {
            // record.acceptDate !== "" ?
            record.acceptDate !== "0001-01-01T00:00:00Z" ?
              <Space>
                <>{dayjs(record.acceptDate).format("DD-MM-YY HH:mm")}</>
              </Space>
              :
              <Text type="secondary">ยังไม่รับงาน</Text>
          }
        </Space>
      ),
    },
    {
      key: 'acceptDate',
      title: "ระยะเวลาที่คาดว่าจะเสร็จ",
      dataIndex: "",
      width: "10%",
      align: 'center',
      render: (text, record) => (
        <Space size="middle">
          {
            // record.acceptDate !== "" ?
            record.acceptDate !== "0001-01-01T00:00:00Z" ?
              <Space>
                <Countdown value={dayjs(record.acceptDate).add(3, 'hour')} />
              </Space>
              : <Text type="secondary">ยังไม่รับงาน</Text>
          }
        </Space>
      ),
    },
    {
      key: 'approvalStatus',
      title: "สถานะ",
      dataIndex: "approvalStatus",
      width: "10%",
      align: 'center',
      render: (text, record) => {
        let color
        color = colorApprovalStatus(record?.productLoanType, record?.approvalStatus)
        return (
          <>
            <Tag color={color}>
              {record?.approvalStatusName}
            </Tag>
          </>
        );
      },
    },
    {
      key: 'Action',
      title: "Action",
      fixed: 'right',
      align: 'center',
      width: "10%",
      render: (record) => (
        <Space size="middle">
          {
            record.approvalStatus === 9 ?
              <Tag color="geekblue">กรุณาติดต่อเร่งรัดภาคสนาม</Tag>
              : record.approvalStatus === 16 ?
                // <span>
                <Button type="primary" onClick={() => {
                  setIsModalAccept(true)
                  setEditData(record)
                }}
                  style={{ backgroundColor: "green" }}>รับงาน</Button>
                : record.approvalStatus === 18 ?
                  <>
                    <Button onClick={() => {
                      let dataCar = { carId: record.carId, typeLoan: record.car, loanId: record.loanId }
                      setIsModalPreApprove(true)
                      dispatch(addCustomer(record.customerId))
                      dispatch(addCar(record.carId))
                      dispatch(addGuarantor(dataCar))
                      setEditData(record)
                    }}><FormOutlined /></Button>
                    <Button onClick={() => {
                      handleviewImg(record)
                    }}><FileImageOutlined /></Button>
                  </>
                  : record.approvalStatus === 17 ?
                    <>
                      <Button style={{ backgroundColor: "blue", color: 'white', pointerEvents: 'none' }}>
                        รออนุมัติราคารถ
                      </Button>
                    </>
                    :
                    <>

                      <Button onClick={() => {
                        let dataCar = { carId: record.carId, typeLoan: record.car, loanId: record.loanId }
                        dispatch(addGuarantor(dataCar))
                        setIsModalInfoADPGDC(true)
                        setEditData(record)
                      }}><SearchOutlined /></Button>
                    </>
          }
        </Space>
      ),
    },
  ];

  const Success = (placement) => {
    api.success({
      message: <b>รับงานแล้ว</b>,
      description:
        'สถานะถูกเปลี่ยนจาก รอธุการรับ เป็น รอวิเคราะห์',
      placement,
    });
  };
  const RateCar = (placement) => {
    api.warning({
      message: <b>รออนุมัติเรทรถ</b>,
      description:
        'สถานะถูกเปลี่ยนจาก รอธุการรับ เป็น รออนุมัติราคารถ กรุณาเพิ่มเรทรถ',
      placement,
    });
  };
  const RateLand = (placement) => {
    api.success({
      message: <b>รับงานแล้ว</b>,
      description:
        'สถานะถูกเปลี่ยนจาก รอธุการรับ เป็น รอวิเคราะห์',
      placement,
    });
  };
  const Considered = (placement) => {
    api.success({
      message: <b>ผ่านการวิเคราะห์</b>,
      description:
        'สถานะถูกเปลี่ยนจาก รอวิเคราะห์ เป็น ผ่านการวิเคราะห์',
      placement,
    });
  };
  const NotConsidered = (placement) => {
    api.error({
      message: <b>ไม่ผ่านการวิเคราะห์</b>,
      description:
        'สถานะถูกเปลี่ยนจาก รอวิเคราะห์ เป็น ไม่ผ่านการวิเคราะห์',
      placement,
    });
  };
  const currencyFormatOne = (amount) => {
    return Number(amount)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, '$&,')
  }

  return (
    <>
      <Card
        bordered={false}
        style={{
          width: 'auto',
        }}
      >
        <Row style={{ textAlign: 'center' }}>
          <Col span={24}>
            <Spin spinning={loading} size='large' tip=" Loading... ">
              <div className='text-center'>
                <h2>ตรวจสอบข้อมูล(ธุรการ)</h2>
              </div>
              <Row gutter={32} justify={'center'}>
                <Row gutter={24} justify={'center'} style={{ margin: 10 }}>
                  <Col className='gutter-row' span={24} style={{ textAlign: 'center' }}>
                    <Space>
                      <>{"เลือกวันที่ต้องการแสดงเคส :"}</>
                      <DatePicker format={'YYYY-MM-DD'}
                        defaultValue={showDay1}
                        style={{ height: '40px' }}
                        onChange={(e) => {
                          if (e) {
                            setDataPost({ ...dataPost, days1: e.format('YYYY-MM-DD') })
                          }
                        }} />
                      <>{"ถึง"}</>
                      <DatePicker format={'YYYY-MM-DD'}
                        defaultValue={currentDateTime}
                        style={{ height: '40px' }}
                        onChange={(e) => {
                          if (e) {
                            setDataPost({ ...dataPost, days2: e.format('YYYY-MM-DD') })
                          }
                        }} />
                      {/* <span style={{ display: 'inline-block' }}>
                      <Button type="primary" onClick={(e) => { handleYG(e) }}>ตรวจสอบ</Button>
                    </span> */}
                    </Space>
                  </Col>
                </Row>

                <Row gutter={24} justify={'center'} style={{ margin: 10 }}>
                  <Col className='gutter-row' span={24} style={{ textAlign: 'center' }}>
                    <Space>
                      <>{"เลือกสถานะ :"}</>
                      <Select
                        value={dataPost.approvalStatus}
                        style={{ width: 200 }}
                        // onChange={(value) => setDataPost({ ...dataPost, approvalStatus: value })}
                        onChange={(value) => onChangeKeyWord(value)}
                        options={op}
                      />
                    </Space>
                  </Col>
                </Row>
                <Divider style={{ margin: 5 }} />
                {/* <Radio.Group value={keyWord} onChange={(e) => onChangeKeyWord(e.target.value)} > */}
                <Radio.Group disabled value={keyWord} onChange={(e) => setKeyWord(e.target.value)} >
                  {
                    cData.sort((a, b) => {
                      const order = [
                        "รอธุรการรับ",
                        "รออนุมัติราคารถ",
                        "รอวิเคราะห์",
                        "ผ่านการวิเคราะห์",
                        "ไม่ผ่านการวิเคราะห์",
                        "รออนุมัติ",
                        "อนุมัติ",
                        "อนุมัติแบบมีเงื่อนไข",
                        "ไม่อนุมัติ",
                        "รอตรวจสอบเอกสาร",
                        "รอทำสัญญา",
                        "ลูกค้าปฏิเสธ",
                        "Reject",
                        "ทั้งหมด",
                      ];

                      return order.indexOf(a.approvalStatus) - order.indexOf(b.approvalStatus);
                    })
                      .map((item) => (
                        <Radio.Button
                          style={{
                            backgroundColor: backc[item.approvalStatus],
                            color: textc[item.approvalStatus]
                          }}
                          value={item.approvalStatusId}
                        >
                          {item.approvalStatus} <b>{item.totalCount}</b>
                        </Radio.Button>
                      ))}

                </Radio.Group>
              </Row>
              <Row gutter={32} style={{ margin: 10 }}>
                <Col className='gutter-row' span={24} style={{ textAlign: 'right' }}>
                  <Input.Search
                    style={{ width: '250px' }}
                    placeholder="ค้นหา...."
                    onChange={search}
                    name="search"
                    id="search"
                    value={query}
                  />
                </Col>
              </Row>
              <Row gutter={32}>
                <Col span={24}>
                  <Table
                    pagination={false}
                    rowKey={(record) => record.uid}
                    scroll={{
                      x: 1500,
                      y: 400,
                    }}
                    dataSource={arrayTable}
                    columns={columns}
                  >
                  </Table>
                  <Divider />
                  <Pagination
                    current={currentPage}
                    onChange={onChangePagination}
                    onShowSizeChange={onChangePagination}
                    defaultPageSize={10}
                    defaultCurrent={1}
                    total={testPage?.totalCount}
                  />
                  <Divider />
                </Col>
              </Row>
            </Spin>
          </Col>
          <Col span={24} style={{ textAlign: 'center' }}>
            <Space>
              {/* <Button href="/offercase" type="primary" ><PlusCircleFilled />เพิ่ม</Button>
              <Button type="primary" ><ScanOutlined />สแกน</Button>
              <Button type="primary"><InfoCircleFilled />วิธีใช้งาน</Button>
              <Button href="/" type="primary"><HomeFilled />หน้าหลัก</Button> */}
            </Space>
          </Col>
        </Row>
      </Card>
      {
        isModalAccept ?
          <ModalAccept
            open={isModalAccept}
            close={setIsModalAccept}
            dataFromTable={editData}
            Success={Success}
            RateCar={RateCar}
            RateLand={RateLand}
          />
          : null
      }
      {
        isModalPreApprove ?
          <ModalPreApprove
            open={isModalPreApprove}
            close={setIsModalPreApprove}
            Considered={Considered}
            NotConsidered={NotConsidered}
            dataFromTable={editData}
          />
          : null
      }
      {
        isModalInfoADPGDC ?
          <ModalInfoADPGDC
            open={isModalInfoADPGDC}
            close={setIsModalInfoADPGDC}
            dataFromTable={editData}
            typeLoans={editData.car}
            checkPosi={"ad"} />
          : null
      }
      {
        isModalNote ?
          <ModalNote open={isModalNote} close={setIsModalNote} dataFromTable={isOpenNewNote} />
          : null
      }

      {contextHolder}
    </>
  )
}

